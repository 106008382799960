import type { UISystemManager } from "../ui-system/view-model/model";
import { DoctorDetailsViewModel } from "./model/DoctorDetailsViewModel";
import * as Entity from "@/domain/entity";
import { BehaviorSubject, Subject } from "rxjs";
import type { GetDoctor } from "@/domain/usecase/doctor/model/GetDoctor";
import type { AddDoctor } from "@/domain/usecase/doctor/model/AddDoctor";
import type { UpdateDoctor } from "@/domain/usecase/doctor/model/UpdateDoctor";
import type { DeleteDoctor } from "@/domain/usecase/doctor/model/DeleteDoctor";
import { injectable } from "inversify";
import * as ViewData from "@/application/view-data";
import type { UploadImage } from "@/domain/usecase/gcs/model/UploadImage";
import type { GetHospital } from "@/domain/usecase/hospital/model/GetHospital";

@injectable()
export class DoctorDetailsViewModelImpl implements DoctorDetailsViewModel {
  data: DoctorDetailsViewModel["data"] = {
    hospitalId: null,
    mode: "CREATE",
    doctorId: 0,
    name: "",
    rank: "NORMAL",
    specialty: "",
    briefHistory: "",
    subjects: [],
    profile: {
      id: 0,
      originUrl: "",
      resizedUrl: "",
      ordinal: 0,
    },
    serviceConfiguration: null,
    faceFitConfiguration: null,
    privateAgreement: false,
  };
  output: DoctorDetailsViewModel["output"] = {
    loading: new Subject<boolean>(),
    mode: new BehaviorSubject<"CREATE" | "UPDATE">("CREATE"),
    doctorId: new BehaviorSubject<number | null>(null),
    name: new BehaviorSubject<string>(""),
    rank: new BehaviorSubject<Entity.Type.DoctorRank | null>(null),
    specialty: new BehaviorSubject<string>(""),
    isFaceFitExposure: new BehaviorSubject<boolean>(false),
    isAppExposure: new BehaviorSubject<boolean>(false),
    briefHistory: new BehaviorSubject<string>(""),
    subjects: new BehaviorSubject<ViewData.Doctor.Subject[]>([]),
    profile: new BehaviorSubject<ViewData.Doctor.ProfilePhotoItem | null>(null),
    serviceConfiguration: new BehaviorSubject<ViewData.Doctor.Configuration | null>(null),
    faceFitConfiguration: new BehaviorSubject<ViewData.Doctor.Configuration | null>(null),
    specialties: new BehaviorSubject<ViewData.Doctor.Specialty[]>([]),
    privateAgreement: new BehaviorSubject<boolean>(false),
  };
  route: DoctorDetailsViewModel["route"] = {
    toDoctorDetail: new Subject<{ doctorId: number }>(),
    toBack: new Subject<void>(),
    toDoctors: new Subject<void>(),
    toPrivacyDocument: new Subject<void>(),
  };

  constructor(
    doctorId: number | null,
    readonly uiSystem: UISystemManager,
    private readonly getHospital: GetHospital,
    private readonly getDoctor: GetDoctor, // private readonly ucUpdateDoctor: UpdateDoctor, // private readonly ucGetDoctor: GetDoctor, // private readonly ucDeleteDoctor: DeleteDoctor,
    private readonly addDoctor: AddDoctor,
    private readonly updateDoctor: UpdateDoctor,
    private readonly deleteDoctor: DeleteDoctor,
    private readonly uploadImage: UploadImage,
  ) {
    this.getHospitalId();
    if (!isNaN(Number(doctorId)) && doctorId) {
      this.data.mode = "UPDATE";
      this.init(doctorId);
    } else {
      this.data.mode = "CREATE";
      this.data.serviceConfiguration = {
        profileImageUrl: "",
        exposed: false,
        exposureOrdinal: 1,
      };
      this.data.faceFitConfiguration = {
        profileImageUrl: "",
        exposed: false,
        exposureOrdinal: 1,
      };
      this.output.mode.next(this.data.mode);
    }
  }

  input: DoctorDetailsViewModel["input"] = {
    clickFaceFitExposure: () => {
      if (this.data.faceFitConfiguration) {
        this.data.faceFitConfiguration.exposed = !this.data.faceFitConfiguration.exposed;
        this.output.faceFitConfiguration.next({ ...this.data.faceFitConfiguration });
      }
    },
    clickAppExposure: () => {
      if (this.data.serviceConfiguration) {
        this.data.serviceConfiguration.exposed = !this.data.serviceConfiguration.exposed;
        this.output.serviceConfiguration.next({ ...this.data.serviceConfiguration });
      }
    },
    clickUploadFaceFitProfile: (file) => {
      this.uiSystem.loadingHandler.backdropLoading.next(true);
      const hospitalId = this.data.hospitalId;
      if (hospitalId) {
        const sub = this.uploadImage
          .execute({ public: true, file, bucketPath: `${hospitalId}/doctors/face-fit-profile`, ordinal: 1, type: "png" })
          .subscribe({
            next: (image) => {
              if (this.data.faceFitConfiguration) {
                this.data.faceFitConfiguration.profileImageUrl = image.originUrl;
                this.output.faceFitConfiguration.next({ ...this.data.faceFitConfiguration });
              }
              this.uiSystem.loadingHandler.backdropLoading.next(false);
              sub.unsubscribe();
            },
            error: () => {
              this.uiSystem.loadingHandler.backdropLoading.next(false);
              sub.unsubscribe();
            },
          });
      }
    },
    clickUploadProfile: (file) => {
      this.uiSystem.loadingHandler.backdropLoading.next(true);
      const hospitalId = this.data.hospitalId;
      if (hospitalId) {
        const sub = this.uploadImage.execute({ public: true, file, bucketPath: `${hospitalId}/doctors/profile`, ordinal: 1 }).subscribe({
          next: (image) => {
            if (this.data.serviceConfiguration) {
              this.data.serviceConfiguration.profileImageUrl = image.originUrl;
              this.output.serviceConfiguration.next({ ...this.data.serviceConfiguration });
            }
            this.uiSystem.loadingHandler.backdropLoading.next(false);
            sub.unsubscribe();
          },
          error: () => {
            this.uiSystem.loadingHandler.backdropLoading.next(false);
            sub.unsubscribe();
          },
        });
      }
    },
    changeName: (name: string) => {
      this.data.name = name;
      this.output.name.next(this.data.name);
    },
    changeBriefHistory: (briefHistory: string) => {
      this.data.briefHistory = briefHistory;
      this.output.briefHistory.next(this.data.briefHistory);
    },
    clickCheckboxByRank: (check: boolean) => {
      if (check) {
        this.data.rank = "CHIEF";
      } else {
        this.data.rank = "NORMAL";
      }
      this.output.rank.next(this.data.rank);
    },
    clickCheckboxBySubjects: (subjectCode, name) => {
      const selectedIndex = this.data.subjects.findIndex((s) => s.code === subjectCode);
      if (selectedIndex > -1) {
        this.data.subjects.splice(selectedIndex, 1);
      } else {
        this.data.subjects.push({ code: subjectCode, defaultName: name });
      }
      this.output.subjects.next([...this.data.subjects]);
    },
    clickSpecialty: (specialty: string) => {
      this.data.specialty = specialty;
      this.output.specialty.next(this.data.specialty);
    },
    clickPrivateAgreement: () => {
      this.data.privateAgreement = !this.data.privateAgreement;
      this.output.privateAgreement.next(this.data.privateAgreement);
    },
    clickSubmit: () => {
      this.uiSystem.popupHandler.alert.confirm.next({
        open: true,
        infoMessage: "작성된 내용으로 추가하시겠습니까?",
        confirm: () => {
          this.uiSystem.loadingHandler.backdropLoading.next(true);
          const sub = this.addDoctor.execute(this.mapDoctor()).subscribe({
            next: () => {
              this.route.toDoctors.next();
              this.uiSystem.loadingHandler.backdropLoading.next(false);
              sub.unsubscribe();
            },
            error: (error) => {
              if (error?.error_message.include("not allowed to access")) {
                this.uiSystem.errorHandler.alert.next({ message: "수정권한이 없습니다.\n관리자에게 문의해주세요." });
              }
              this.uiSystem.loadingHandler.backdropLoading.next(false);
              sub.unsubscribe();
            },
          });
        },
      });
    },
    clickUpdate: () => {
      this.uiSystem.popupHandler.alert.confirm.next({
        open: true,
        infoMessage: "작성된 내용으로 정보를 수정하시겠습니까?",
        confirm: () => {
          if (this.data.doctorId) {
            this.uiSystem.loadingHandler.backdropLoading.next(true);
            const sub = this.updateDoctor.execute(this.mapDoctor()).subscribe({
              next: () => {
                this.uiSystem.loadingHandler.backdropLoading.next(false);
                this.route.toDoctors.next();
                sub.unsubscribe();
              },
              error: () => {
                this.uiSystem.loadingHandler.backdropLoading.next(false);
                sub.unsubscribe();
              },
            });
          }
        },
      });
    },
    clickDelete: () => {
      this.uiSystem.popupHandler.alert.confirm.next({
        open: true,
        infoMessage: `지정된 의사 정보가 삭제됩니다.\n정말 삭제하시겠습니까?`,
        confirm: () => {
          this.uiSystem.loadingHandler.backdropLoading.next(true);
          const sub = this.deleteDoctor.execute({ doctorId: this.data.doctorId }).subscribe({
            next: () => {
              this.uiSystem.loadingHandler.backdropLoading.next(false);
              this.route.toDoctors.next();
              sub.unsubscribe();
            },
            error: () => {
              this.uiSystem.loadingHandler.backdropLoading.next(false);
              sub.unsubscribe();
            },
          });
        },
      });
    },
    clickCancel: () => {
      this.uiSystem.popupHandler.alert.confirm.next({
        open: true,
        infoMessage: `지금 나가면 변경된 사항은 수정되지 않습니다.\n수정하지 않고 나가시겠습니까?`,
        confirm: () => {
          this.route.toBack.next();
        },
      });
    },
    clickPrivacyAgreementDocument: () => {
      this.route.toPrivacyDocument.next();
    },
  };
  private init(doctorId: number) {
    const sub = this.getDoctor.execute({ doctorId }).subscribe({
      next: (doctor) => {
        this.data = {
          ...this.data,
          doctorId: doctor.id,
          name: doctor.profile.name,
          rank: doctor.profile.rank,
          specialty: doctor.profile.specialty,
          subjects: doctor.medicalSubjects,
          serviceConfiguration: doctor.afoterConfiguration,
          faceFitConfiguration: doctor.faceFitConfiguration,
          briefHistory: doctor.profile.briefHistory ?? "",
          privateAgreement: false,
        };

        this.output.name.next(this.data.name);
        this.output.rank.next(this.data.rank);
        this.output.serviceConfiguration.next(this.data.serviceConfiguration);
        this.output.faceFitConfiguration.next(this.data.faceFitConfiguration);
        this.output.specialty.next(this.data.specialty);
        this.output.subjects.next(this.data.subjects);
        this.output.briefHistory.next(this.data.briefHistory);
        this.output.mode.next(this.data.mode);
        sub.unsubscribe();
      },
      error: () => {
        sub.unsubscribe();
      },
    });
  }

  private getHospitalId = () => {
    const sub = this.getHospital.execute({ infoTypes: ["BASIC"] }).subscribe({
      next: (hospital) => {
        this.data.hospitalId = hospital.id;
        this.output.loading.next(false);
        sub.unsubscribe();
      },
      error: () => {
        sub.unsubscribe();
      },
    });
  };

  private mapDoctor = () => {
    return {
      doctorId: this.data.doctorId,
      profile: {
        name: this.data.name,
        rank: this.data.rank,
        introduction: null,
        experienceYear: null,
        career: null,
        briefHistory: this.data.briefHistory,
        profileImageUrl: this.data.profile.originUrl,
        specialty: this.data.specialty,
      },
      medicalSubjects: this.data.subjects.map((subject) => subject.code),
      afoterConfiguration: {
        profileImageUrl: this.data.serviceConfiguration?.profileImageUrl ?? "",
        exposed: this.data.serviceConfiguration?.exposed ?? false,
        exposureOrdinal: this.data.serviceConfiguration?.exposureOrdinal ?? 1,
      },
      faceFitConfiguration: {
        profileImageUrl: this.data.faceFitConfiguration?.profileImageUrl ?? "",
        exposed: this.data.faceFitConfiguration?.exposed ?? false,
        exposureOrdinal: this.data.faceFitConfiguration?.exposureOrdinal ?? 1,
      },
    };
  };
}
