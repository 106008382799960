import { injectable, inject } from "inversify";
import { Observable } from "rxjs";
import { UpdateConsultingStatus, UpdateConsultingStatusInput, UpdateConsultingStatusOutput } from "./model/UpdateConsultingStatus";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class UpdateConsultingStatusImpl implements UpdateConsultingStatus {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute(input: UpdateConsultingStatusInput): Observable<UpdateConsultingStatusOutput> {
    return this.operationConsultingRepository.updateConsultingStatus({ noteId: input.noteId, consultingStatus: input.consultingStatus });
  }
}
