import { ReactNode } from "react";
import BasicTable from "./BasicTable";

export interface TableProps {
  head: TableHeadColumn[];
  rows?: TableBodyRows[];
  size?: number;
  total?: number;
  // height?: string;
  isLoading?: boolean;
  colSpan?: number;
  emptyStatePlaceholder?: string;
  noTotalCountLabel?: boolean;
  // isUpdate?: boolean;
}

export interface CollapsibleTableProps {
  head: TableHeadColumn[];
  rows?: CollapsibleBodyRow[];
  size?: number;
  // height?: string;
  isLoading?: boolean;
  colSpan?: number;
  emptyStatePlaceholder?: string;
  // isUpdate?: boolean;
}

export interface TableHeadColumn {
  id: string;
  value: number | string | React.ReactNode;
  width?: string;
  align?: "left" | "center" | "right";
  onClick?: () => void;
  checkbox?: {
    onClick: () => void;
    checked: boolean;
    disabled?: boolean;
    indeterminate?: boolean;
  };
  style?: React.CSSProperties;
}

export interface TableBodyRows {
  id: string | number;
  contents: RowContent[];
  bgColor?: string;
  width?: string;
  height?: string;
  align?: "left" | "center" | "right";
  onClick?: () => void;
  isTaskDone?: boolean;
  isactivenone?: boolean;
  isDisabledClickRow?: boolean;
  checkbox?: {
    onClick: () => void;
    checked: boolean;
    disabled?: boolean;
    indeterminate?: boolean;
  };
  activecolor?: string;
}

export interface CollapsibleBodyRow extends TableBodyRows {
  collapseNode: ReactNode;
}

interface RowContent {
  value: number | string | React.ReactNode;
  width?: number;
  align?: "left" | "center" | "right";
  activecolor?: string;
  onClick?: () => void;
}

export { BasicTable };
