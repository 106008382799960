import { injectable, inject } from "inversify";
import {
  CreateOperationConsultingLog,
  CreateOperationConsultingLogInput,
  CreateOperationConsultingLogOutput,
} from "./model/CreateOperationConsultingLog";
import { Observable } from "rxjs";
import { TYPES } from "@/data/interactor/repository/index.container.type";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";

@injectable()
export class CreateOperationConsultingLogImpl implements CreateOperationConsultingLog {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute = (input: CreateOperationConsultingLogInput): Observable<CreateOperationConsultingLogOutput> => {
    return this.operationConsultingRepository.createOperationConsultingLog(input.noteId, input.productCode, input.logCode);
  };
}
