import { injectable, inject } from "inversify";
import { Observable } from "rxjs";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { TYPES } from "@/data/interactor/repository/index.container.type";
import { GetTags, GetTagsOutput } from "./model/GetTags";

@injectable()
export class GetTagsImpl implements GetTags {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute = (): Observable<GetTagsOutput> => {
    return this.operationConsultingRepository.getTags();
  };
}
