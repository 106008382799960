import { injectable, inject } from "inversify";
import {
  CreateConsultingTranscriptionAnalysis,
  CreateConsultingTranscriptionAnalysisInput,
  CreateConsultingTranscriptionAnalysisOutput,
} from "./model/CreateConsultingTranscriptionAnalysis";
import { Observable } from "rxjs";
import { TYPES } from "@/data/interactor/repository/index.container.type";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";

@injectable()
export class CreateConsultingTranscriptionAnalysisImpl implements CreateConsultingTranscriptionAnalysis {
  private assistant = process.env.OPEN_AI_ASSISTANTS
    ? (JSON.parse(process.env.OPEN_AI_ASSISTANTS) as { face_fit_keywords_and_summary_bot: string })
    : { face_fit_keywords_and_summary_bot: "" };
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}
  execute = (input: CreateConsultingTranscriptionAnalysisInput): Observable<CreateConsultingTranscriptionAnalysisOutput> => {
    return this.operationConsultingRepository.createConsultingTranscriptionAnalysis(
      input.recordId,
      this.assistant.face_fit_keywords_and_summary_bot,
    );
  };
}
