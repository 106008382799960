import { inject, injectable } from "inversify";
import { map, Observable, of } from "rxjs";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import {
  UpdateConsultingReservationDate,
  UpdateConsultingReservationDateInput,
  UpdateReservationDateByAdvertiseOutput,
} from "./model/UpdateConsultingReservationDate";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class UpdateConsultingReservationDateImpl implements UpdateConsultingReservationDate {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute = (input: UpdateConsultingReservationDateInput): Observable<UpdateReservationDateByAdvertiseOutput> => {
    return this.operationConsultingRepository.updateConsultingReservationDate(input).pipe(
      map((res) => {
        return {
          consultingNoteId: res.operationConsultingNoteId,
        };
      }),
    );
  };
}
