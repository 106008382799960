import Icon from "@icon/index";
import { SubMenu } from "@view-data/Product";
import { useState } from "react";
import styled from "styled-components";
import LeftMenuItem from "./LeftMenuItem";
import useSideMenu from "./useSideMenu";

interface SideMenuProps {
  sideMenus: SubMenu[];
  expanded: boolean;
  clickExpand: () => void;
  clickCollpase: () => void;
}

export const SideMenu: React.FC<SideMenuProps> = (props) => {
  const { isShowSideMenus } = useSideMenu();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <SideMenuLayout
      $expanded={props.expanded}
      $isDisplay={isShowSideMenus}
    >
      <IconWrapper
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        $expanded={props.expanded}
        onClick={props.expanded ? props.clickCollpase : props.clickExpand}
      >
        {props.expanded ? (
          <Icon.MenuCollpase
            medium
            color={"drwo_bg_prim_point400"}
          />
        ) : (
          <Icon.MenuExpand
            medium
            color={"drwo_bg_prim_icon"}
          />
        )}
        {isHovered ? (
          <Tooltip>
            <TooltipText>{props.expanded ? "사이드바 접기" : "사이드바 열기"}</TooltipText>
          </Tooltip>
        ) : null}
      </IconWrapper>

      <LeftMenuItemsWrapper>
        <LeftMenuItemsWrapperLayout>
          {props.sideMenus.map((sideMenu) => (
            <LeftMenuItem
              key={sideMenu.name}
              expanded={props.expanded}
              pathname={sideMenu.pathname}
              menuName={sideMenu.name}
              clickMenu={sideMenu.click}
            />
          ))}
        </LeftMenuItemsWrapperLayout>
      </LeftMenuItemsWrapper>
    </SideMenuLayout>
  );
};

const SideMenuLayout = styled.ul<{ $expanded: boolean; $isDisplay: boolean }>`
  width: 100%;
  height: 100%;
  display: ${({ $isDisplay }) => ($isDisplay ? "block" : "none")};
  border-right: 1px solid #eceef2;
  background-color: ${(props) => (props.$expanded ? "#f3f4f0" : "#14443F")};
`;

const LeftMenuItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const LeftMenuItemsWrapperLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const IconWrapper = styled.div<{ $expanded: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.$expanded ? "flex-end" : "center")};
  padding: 12px;
  width: 100%;

  position: relative;
  cursor: pointer;
`;
const Tooltip = styled.div`
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(-8.5px, -50%);
  background-color: #141e20;
  color: #c9e6e2;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 10;
`;

const TooltipText = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  text-align: left;
`;
