import { NoticeRepository } from "@/domain/interactor/repository/NoticeRepository";
import { inject, injectable } from "inversify";
import { Observable, map } from "rxjs";
import Utils from "./Utils";
import { TYPES } from "@/data/remote/api/index.container.type";
import type { NoticeAPI } from "@/data/remote/api/notice/model/NoticeAPI";
import { PageModel } from "@/domain/interactor/repository/vo";
import { NoticeModel } from "@/domain/interactor/repository/vo/Notice";

@injectable()
export class NoticeRepositoryImpl implements NoticeRepository {
  constructor(@inject(TYPES.NoticeAPI) private readonly noticeAPI: NoticeAPI) {}

  getNotices = (page: number, size: number): Observable<PageModel<NoticeModel>> => {
    return this.noticeAPI
      .getListBy({
        page,
        size,
      })
      .pipe(
        map((response) => {
          return {
            page: response.page,
            size: response.size,
            total: response.total,
            last: response.last,
            items: response.items.map((item) => ({
              id: item.id,
              title: item.title,
              content: item.content,
              createdAt: Utils.convertDateStringToLocalDate(item.createdAt),
              updatedAt: Utils.convertDateStringToLocalDate(item.updatedAt),
            })),
          };
        }),
      );
  };
}
