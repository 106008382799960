import { TYPES } from "@/data/remote/api/index.container.type";
import type { UserAPI } from "@/data/remote/api/user/model/UserAPI";
import { UserRepository } from "@/domain/interactor/repository/UserRepository";
import * as VO from "@/domain/interactor/repository/vo";
import { inject, injectable } from "inversify";
import { map, Observable } from "rxjs";
import * as Entity from "@/domain/entity";

@injectable()
export class UserRepositoryImpl implements UserRepository {
  constructor(@inject(TYPES.UserAPI) private readonly userAPI: UserAPI) {}

  getUserV2 = (): Observable<VO.User.Information> => {
    return this.userAPI.getUser().pipe(
      map((userInfo) => ({
        user: {
          id: userInfo.user.id,
          status: userInfo.user.status,
          role: userInfo.user.role,
          userKey: userInfo.user.userKey,
          name: userInfo.user.name,
          phoneNumber: userInfo.user.phoneNumber,
          productAccessRights: userInfo.user.productAccessRights,
        },
        hospital: {
          id: userInfo.hospital.id,
          name: userInfo.hospital.name,
          status: userInfo.hospital.status,
          logoImageUrl: userInfo.hospital.logoImageUrl,
        },
        contract: {
          id: userInfo.contract.id,
          plan: {
            name: userInfo.contract.plan.name,
            products: userInfo.contract.plan.products
              ? userInfo.contract.plan.products.map((product) => ({
                  code: product.code,
                }))
              : [],
          },
        },
      })),
    );
  };

  createUser = (loginId: string, loginPassword: string, name: string, role: Entity.Type.UserRole): Observable<{ userId: number }> => {
    return this.userAPI.postUser({ loginId, loginPassword, name, role });
  };

  updateUser = (
    userId: number,
    currentLoginPassword: string | null,
    newLoginPassword: string | null,
    name: string,
    role: Entity.Type.UserRole,
    status: Entity.Type.Status,
  ): Observable<{ userId: number }> => {
    return this.userAPI.putUser({ userId, currentLoginPassword, newLoginPassword, name, role, status });
  };

  // validateLoginId = ()
}
