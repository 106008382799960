import { Config, Coordinate } from "../index.interface";
import { DrawingFacialLandmarks } from "../face/DrawingFacialLandmarks";
import Calc from "../utils/Calc";
import { BestRatioLineStyle, DotLineStyle, PhotoBaseLine } from "../utils/Styles";

export class ZygomaticRatio extends DrawingFacialLandmarks {
  private coordinate: {
    top: Coordinate;
    bottom: Coordinate;
    right: { top: Coordinate; bottom: Coordinate };
    rightEye: {
      right: { top: Coordinate; bottom: Coordinate };
      left: { top: Coordinate; bottom: Coordinate };
    };
    leftEye: {
      right: { top: Coordinate; bottom: Coordinate };
      left: { top: Coordinate; bottom: Coordinate };
    };
    left: { top: Coordinate; bottom: Coordinate };
  };

  private bestRatioCoordinate: {
    right: { top: Coordinate; bottom: Coordinate };
    rightEye: {
      right: { top: Coordinate; bottom: Coordinate };
      left: { top: Coordinate; bottom: Coordinate };
    };
    leftEye: {
      right: { top: Coordinate; bottom: Coordinate };
      left: { top: Coordinate; bottom: Coordinate };
    };
    left: { top: Coordinate; bottom: Coordinate };
  };

  ratio: {
    leftFace: number;
    leftEye: number;
    midFace: number;
    rightEye: number;
    rightFace: number;
  };

  constructor(config: Config) {
    super(config);
    this.coordinate = this.getCoordinates();
    this.bestRatioCoordinate = this.getBestRatioCoordinates();
    this.ratio = this.calcRatioByPhotoBase();
  }

  drawPhotoBase = async () => {
    this.photoBaseDrawer.drawGuideLines();
    await this.delay(300);
    this.photoBaseDrawer.drawFaceLines();
    await this.delay(600);
    this.photoBaseDrawer.drawRatioText();
  };

  drawBestRatio = async () => {
    this.bestRatioDrawer.drawGuideLines();
    await this.delay(300);
    this.bestRatioDrawer.drawFaceLines();
    await this.delay(600);
    this.bestRatioDrawer.drawTextValue();
  };

  private photoBaseDrawer = {
    drawFaceLines: async () => {
      this.drawLineBetweenPoints({
        point1: this.coordinate.right.top,
        point2: this.coordinate.right.bottom,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: this.coordinate.rightEye.right.top,
        point2: this.coordinate.rightEye.right.bottom,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: this.coordinate.rightEye.left.top,
        point2: this.coordinate.rightEye.left.bottom,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: this.coordinate.leftEye.right.top,
        point2: this.coordinate.leftEye.right.bottom,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: this.coordinate.leftEye.left.top,
        point2: this.coordinate.leftEye.left.bottom,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: this.coordinate.left.top,
        point2: this.coordinate.left.bottom,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
    },

    drawGuideLines: async () => {
      this.drawLineBetweenPoints({
        point1: this.coordinate.right.top,
        point2: this.coordinate.left.top,
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
    },

    drawRatioText: async () => {
      const textStyle = { x: 0, y: -18, fontSize: 18, color: "#FF9900" };
      this.drawTextBetweenPoints(`${this.ratio.rightFace}`, this.coordinate.right.top, this.coordinate.rightEye.right.top, textStyle);
      this.drawTextBetweenPoints(
        `${this.ratio.rightEye}`,
        this.coordinate.rightEye.right.top,
        this.coordinate.rightEye.left.top,
        textStyle,
      );
      this.drawTextBetweenPoints(`${this.ratio.midFace}`, this.coordinate.rightEye.left.top, this.coordinate.leftEye.right.top, textStyle);
      this.drawTextBetweenPoints(`${this.ratio.leftEye}`, this.coordinate.leftEye.right.top, this.coordinate.leftEye.left.top, textStyle);
      this.drawTextBetweenPoints(`${this.ratio.leftFace}`, this.coordinate.leftEye.left.top, this.coordinate.left.top, textStyle);
    },
  };

  private bestRatioDrawer = {
    drawFaceLines: async () => {
      this.drawLineBetweenPoints({
        point1: this.bestRatioCoordinate.right.top,
        point2: this.bestRatioCoordinate.right.bottom,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: this.bestRatioCoordinate.rightEye.right.top,
        point2: this.bestRatioCoordinate.rightEye.right.bottom,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: this.bestRatioCoordinate.rightEye.left.top,
        point2: this.bestRatioCoordinate.rightEye.left.bottom,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: this.bestRatioCoordinate.leftEye.right.top,
        point2: this.bestRatioCoordinate.leftEye.right.bottom,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: this.bestRatioCoordinate.leftEye.left.top,
        point2: this.bestRatioCoordinate.leftEye.left.bottom,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: this.bestRatioCoordinate.left.top,
        point2: this.bestRatioCoordinate.left.bottom,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });
    },
    drawGuideLines: () => {
      this.drawLineBetweenPoints({
        point1: this.bestRatioCoordinate.right.top,
        point2: this.bestRatioCoordinate.left.top,
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
    },

    drawTextValue: () => {
      const textStyle = { x: 0, y: -43, fontSize: 18, color: "#00D7CA" };
      this.drawTextBetweenPoints(`${0.5}`, this.bestRatioCoordinate.right.top, this.bestRatioCoordinate.rightEye.right.top, textStyle);
      this.drawTextBetweenPoints(
        `${1}`,
        this.bestRatioCoordinate.rightEye.right.top,
        this.bestRatioCoordinate.rightEye.left.top,
        textStyle,
      );
      this.drawTextBetweenPoints(`${1}`, this.bestRatioCoordinate.rightEye.left.top, this.bestRatioCoordinate.leftEye.right.top, textStyle);
      this.drawTextBetweenPoints(`${1}`, this.bestRatioCoordinate.leftEye.right.top, this.bestRatioCoordinate.leftEye.left.top, textStyle);
      this.drawTextBetweenPoints(`${0.5}`, this.bestRatioCoordinate.leftEye.left.top, this.bestRatioCoordinate.left.top, textStyle);
    },
  };

  private getCoordinates = (): {
    top: Coordinate;
    bottom: Coordinate;
    right: { top: Coordinate; bottom: Coordinate };
    rightEye: {
      right: { top: Coordinate; bottom: Coordinate };
      left: { top: Coordinate; bottom: Coordinate };
    };
    leftEye: {
      right: { top: Coordinate; bottom: Coordinate };
      left: { top: Coordinate; bottom: Coordinate };
    };
    left: { top: Coordinate; bottom: Coordinate };
  } => {
    return {
      top: this.faceCoordinates[10],
      bottom: this.faceCoordinates[2],
      right: {
        top: { ...this.faceCoordinates[162], y: this.faceCoordinates[10].y },
        bottom: { ...this.faceCoordinates[162], y: this.faceCoordinates[152].y },
      },
      rightEye: {
        right: {
          top: { ...this.faceCoordinates[226], y: this.faceCoordinates[10].y },
          bottom: { ...this.faceCoordinates[226], y: this.faceCoordinates[152].y },
        },
        left: {
          top: { ...this.faceCoordinates[133], y: this.faceCoordinates[10].y },
          bottom: { ...this.faceCoordinates[133], y: this.faceCoordinates[152].y },
        },
      },
      leftEye: {
        right: {
          top: { ...this.faceCoordinates[362], y: this.faceCoordinates[10].y },
          bottom: { ...this.faceCoordinates[362], y: this.faceCoordinates[152].y },
        },
        left: {
          top: { ...this.faceCoordinates[446], y: this.faceCoordinates[10].y },
          bottom: { ...this.faceCoordinates[446], y: this.faceCoordinates[152].y },
        },
      },
      left: {
        top: { ...this.faceCoordinates[389], y: this.faceCoordinates[10].y },
        bottom: { ...this.faceCoordinates[389], y: this.faceCoordinates[152].y },
      },
    };
  };

  private getBestRatioCoordinates = (): {
    right: { top: Coordinate; bottom: Coordinate };
    rightEye: {
      right: { top: Coordinate; bottom: Coordinate };
      left: { top: Coordinate; bottom: Coordinate };
    };
    leftEye: {
      right: { top: Coordinate; bottom: Coordinate };
      left: { top: Coordinate; bottom: Coordinate };
    };
    left: { top: Coordinate; bottom: Coordinate };
  } => {
    const coordinate = this.faceCoordinates;
    const rightEyeDistance = Calc.calculateDistance(this.coordinate.rightEye.right.top, this.coordinate.rightEye.left.top, "2d");

    return {
      right: {
        top: { ...coordinate[226], x: coordinate[226].x - rightEyeDistance / 2, y: coordinate[10].y },
        bottom: { ...coordinate[226], x: coordinate[226].x - rightEyeDistance / 2, y: coordinate[152].y },
      },
      rightEye: {
        right: {
          top: { ...coordinate[226], y: coordinate[10].y },
          bottom: { ...coordinate[226], y: coordinate[152].y },
        },
        left: {
          top: { ...coordinate[133], y: coordinate[10].y },
          bottom: { ...coordinate[133], y: coordinate[152].y },
        },
      },
      leftEye: {
        right: {
          top: { ...coordinate[133], x: coordinate[133].x + rightEyeDistance, y: coordinate[10].y },
          bottom: { ...coordinate[133], x: coordinate[133].x + rightEyeDistance, y: coordinate[152].y },
        },
        left: {
          top: { ...coordinate[133], x: coordinate[133].x + rightEyeDistance * 2, y: coordinate[10].y },
          bottom: { ...coordinate[133], x: coordinate[133].x + rightEyeDistance * 2, y: coordinate[152].y },
        },
      },
      left: {
        top: { ...coordinate[133], x: coordinate[133].x + rightEyeDistance * 2 + rightEyeDistance / 2, y: coordinate[10].y },
        bottom: { ...coordinate[133], x: coordinate[133].x + rightEyeDistance * 2 + rightEyeDistance / 2, y: coordinate[152].y },
      },
    };
  };

  //황금 비율 계산
  calcRatioByPhotoBase = () => {
    const rightAndRightEyeRatio = Calc.calculateDistance(this.coordinate.right.top, this.coordinate.rightEye.right.top, "2d");
    const rightEyeRatio = Calc.calculateDistance(this.coordinate.rightEye.right.top, this.coordinate.rightEye.left.top, "2d");
    const middleRatio = Calc.calculateDistance(this.coordinate.rightEye.left.top, this.coordinate.leftEye.right.top, "2d");
    const leftEyeRatio = Calc.calculateDistance(this.coordinate.leftEye.right.top, this.coordinate.leftEye.left.top, "2d");
    const leftEyeAndLeftRatio = Calc.calculateDistance(this.coordinate.leftEye.left.top, this.coordinate.left.top, "2d");

    Calc.calcRatio(rightEyeRatio, rightAndRightEyeRatio);

    return {
      rightFace: Calc.calcRatio(rightEyeRatio, rightAndRightEyeRatio),
      rightEye: Calc.calcRatio(rightEyeRatio, rightEyeRatio),
      midFace: Calc.calcRatio(rightEyeRatio, middleRatio),
      leftEye: Calc.calcRatio(rightEyeRatio, leftEyeRatio),
      leftFace: Calc.calcRatio(rightEyeRatio, leftEyeAndLeftRatio),
    };
  };
  private delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
}
