import { DrawingFacialLandmarks } from "../face/DrawingFacialLandmarks";
import Calc from "../utils/Calc";
import { Config, Coordinate } from "../index.interface";
import { BestRatioLineStyle, PhotoBaseLine } from "../utils/Styles";

export class NoseHeightRatio extends DrawingFacialLandmarks {
  private frontalFaceCanvasDrawer: DrawingFacialLandmarks;
  private coordinate: {
    noseLength: {
      top: Coordinate;
      bottom: Coordinate;
    };
    noseHeight: {
      left: Coordinate;
      right: Coordinate;
    };
  };

  ratio: {
    noseLength: number;
    noseHeight: number;
  };

  constructor({ config, frontalFaceCanvas }: { config: Config; frontalFaceCanvas: HTMLCanvasElement }) {
    super(config);
    this.frontalFaceCanvasDrawer = new DrawingFacialLandmarks({
      imageEle: config.imageEle,
      canvasEle: frontalFaceCanvas,
      normalizedCoordinate: config.normalizedCoordinate,
    });
    this.coordinate = this.getCoordinate();
    this.ratio = this.calcRatio();
  }

  drawPhotoBase = async () => {
    this.photoBaseDrawer.drawFrontalFaceLines();
    this.photoBaseDrawer.drawNoseLines();
    await this.delay(300);
    this.photoBaseDrawer.drawRatioText();
  };

  drawBestRatio = async () => {
    this.bestRatioDrawer.drawFrontalFaceLines();
    this.bestRatioDrawer.drawNoseLines();
    await this.delay(300);
    this.bestRatioDrawer.drawRatioText();
  };

  private photoBaseDrawer = {
    drawFrontalFaceLines: () => {
      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.faceCoordinates[168],
        point2: this.faceCoordinates[4],
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });

      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.faceCoordinates[129],
        point2: this.faceCoordinates[4],
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
    },
    drawNoseLines: () => {
      this.drawLineBetweenPoints({
        point1: this.coordinate.noseLength.top,
        point2: this.coordinate.noseLength.bottom,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: this.coordinate.noseHeight.left,
        point2: this.coordinate.noseHeight.right,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
    },

    drawRatioText: () => {
      const lengthTextStyle = { x: 27, y: 0, fontSize: 18, color: "#FF9900" };
      const heightTextStyle = { x: 70, y: 18, fontSize: 18, color: "#FF9900" };
      this.drawTextBetweenPoints(`${1}`, this.coordinate.noseLength.top, this.coordinate.noseLength.bottom, lengthTextStyle);
      this.drawTextBetweenPoints(
        `${parseFloat((this.ratio.noseHeight * 100).toFixed(2)).toString()}%`,
        this.coordinate.noseHeight.left,
        this.coordinate.noseHeight.right,
        heightTextStyle,
      );
    },
  };

  private bestRatioDrawer = {
    drawFrontalFaceLines: () => {
      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.faceCoordinates[168],
        point2: this.faceCoordinates[4],
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.faceCoordinates[129],
        point2: this.faceCoordinates[4],
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });
    },
    drawNoseLines: () => {
      this.drawLineBetweenPoints({
        point1: this.coordinate.noseLength.top,
        point2: this.coordinate.noseLength.bottom,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: this.coordinate.noseHeight.left,
        point2: this.coordinate.noseHeight.right,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });
    },
    drawRatioText: () => {
      const lengthTextStyle = { x: 47, y: 0, fontSize: 18, color: "#00D7CA" };
      const heightTextStyle = { x: 70, y: 48, fontSize: 18, color: "#00D7CA" };
      this.drawTextBetweenPoints(`${1}`, this.coordinate.noseLength.top, this.coordinate.noseLength.bottom, lengthTextStyle);
      this.drawTextBetweenPoints(`${67}%`, this.coordinate.noseHeight.left, this.coordinate.noseHeight.right, heightTextStyle);
    },
  };

  private getCoordinate = (): {
    noseLength: {
      top: Coordinate;
      bottom: Coordinate;
    };
    noseHeight: {
      left: Coordinate;
      right: Coordinate;
    };
  } => {
    const offsetX = 30;
    const coordinate168 = this.sideFaceCoordinate[168];
    const coordinate4 = this.sideFaceCoordinate[4];

    return {
      noseLength: {
        top: { ...coordinate168, x: coordinate168.x + offsetX },
        bottom: { ...coordinate4, x: coordinate4.x + offsetX },
      },
      noseHeight: {
        left: this.sideFaceCoordinate[129],
        right: { ...coordinate4, x: coordinate4.x + offsetX },
      },
    };
  };

  private calcRatio = () => {
    const noseLengthDistance = Calc.calculateDistance(this.coordinate.noseLength.top, this.coordinate.noseLength.bottom, "2d");
    const noseHeightDistance = Calc.calculateDistance(this.sideFaceCoordinate[129], this.sideFaceCoordinate[4], "2d");

    return {
      noseLength: 1,
      noseHeight: Calc.calcRatio(noseLengthDistance, noseHeightDistance),
    };
  };

  private delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
}
