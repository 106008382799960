import React from "react";
import Dialog from "@mui/material/Dialog";

interface CenterModalFrameProps {
  open: boolean;
  children: React.ReactNode;
  paperProps?: React.CSSProperties;
  borderRadius?: string;
  sx?: React.CSSProperties;
  close: () => void;
}

const CenterModalFrame: React.FC<CenterModalFrameProps> = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.close();
      }}
      PaperProps={{ sx: { borderRadius: props.borderRadius ?? "12px", maxWidth: "none", margin: 0, ...props.paperProps } }}
      sx={props.sx}
    >
      {props.children}
    </Dialog>
  );
};

export default CenterModalFrame;
