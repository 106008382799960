import styled from "styled-components";
import { ButtonProps } from ".";
import colors from "@view/styles/colors";

export interface FilledButtonProps extends ButtonProps {
  id?: string;
  icon?: React.ReactNode;
  disabledColor?: string;
  disabledBackground?: string;
  isLarge?: boolean;
  hasBorderOnHover?: boolean;
  hoverBordercolor?: string;
  hoverOpacity?: number;
  handleKeyDown?: (e: KeyboardEvent) => void;
}

const FilledButton = (props: FilledButtonProps) => {
  const {
    color = colors.neutralWhiteDefault,
    backgroundColor = colors.neutralBlack,
    disabledColor = colors.neutralGray600,
    disabledBackground = colors.neutralGray800,
    isLarge = true,
    disabled = false,
    hasBorderOnHover = false,
    hoverBordercolor = "none",
    hoverOpacity = 0.7,
    ...restProps
  } = props;
  return (
    <FilledButtonWrapper {...restProps}>
      <FilledButtonStyled
        color={color}
        backgroundColor={backgroundColor}
        disabledColor={disabledColor}
        disabledBackground={disabledBackground}
        isLarge={isLarge}
        disabled={disabled}
        hasBorderOnHover={hasBorderOnHover}
        hoverBordercolor={hoverBordercolor}
        hoverOpacity={hoverOpacity}
        {...restProps}
      >
        {props.icon}
        {props.text}
      </FilledButtonStyled>
    </FilledButtonWrapper>
  );
};

export const Large = ({ ...props }: FilledButtonProps) => {
  return <FilledButton isLarge={true} {...props} />;
};

export const Small = ({ ...props }: FilledButtonProps) => {
  return <FilledButton isLarge={false} {...props} />;
};

const FilledButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FilledButtonStyled = styled.button<FilledButtonProps>`
  background: ${(props) => props.backgroundColor};
  border-radius: 5px;
  border: none;
  color: ${(props) => props.color};
  height: ${(props) => (props.isLarge ? props.height ?? "48" : props.height ?? "32")}px;
  width: ${(props) => (props.width ? `${props.width}px` : "100%")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : props.isLarge ? 16 : 14)}px;
  font-weight: 500;
  line-height: 130%;
  padding: ${(props) => (props.isLarge ? "12px 16px" : "7px 14px")};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 4px;
  &:hover {
    cursor: pointer;
    opacity: ${(props) => (props.hoverOpacity ? props.hoverOpacity : 0.7)};
    border: ${(props) => (props.hasBorderOnHover ? `1px solid ${props.hoverBordercolor}` : "none")};
    color: ${(props) => (props.hasBorderOnHover ? props.hoverBordercolor : props.color)};
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 1 !important;
    background: ${(props) => props.disabledBackground};
    color: ${(props) => props.disabledColor};
  }
`;
