import type { HospitalRepository } from "@/domain/interactor/repository/HospitalRepository";
import { inject, injectable } from "inversify";
import { Observable } from "rxjs";
import { UpdateHospital, UpdateHospitalInput } from "./model/UpdateHospital";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class UpdateHospitalImpl implements UpdateHospital {
  constructor(@inject(TYPES.HospitalRepository) private readonly hospitalRepository: HospitalRepository) {}

  execute(input: UpdateHospitalInput): Observable<void> {
    return this.hospitalRepository.updateHospital(input.infoTypes, input);
  }
}
