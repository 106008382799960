import { Subject, zip } from "rxjs";
import type { UISystemManager } from "../ui-system/view-model/model";
import { DoctorsV2ViewModel } from "./model/DoctorsV2ViewModel";
import type { GetDoctors } from "@/domain/usecase/doctor/model/GetDoctors";
import * as ViewData from "@/application/view-data";
import { injectable } from "inversify";
import type { GetUserV2 } from "@/domain/usecase/auth/model/GetUserV2";

@injectable()
export class DoctorsV2ViewModelImpl implements DoctorsV2ViewModel {
  data: DoctorsV2ViewModel["data"] = {
    doctors: null,
    myRole: null,
  };
  output: DoctorsV2ViewModel["output"] = { doctors: new Subject<ViewData.Doctor.Information[]>() };
  route: DoctorsV2ViewModel["route"] = {
    toDoctorDetail: new Subject<{ doctorId: number }>(),
    toAddDoctor: new Subject<void>(),
  };

  constructor(readonly uiSystem: UISystemManager, private readonly ucGetDoctors: GetDoctors, private readonly getUser: GetUserV2) {
    this.init();
  }
  input: DoctorsV2ViewModel["input"] = {
    clickAddDoctor: () => {
      if (this.data.myRole === "ADMIN") {
        this.route.toAddDoctor.next();
      } else {
        this.uiSystem.errorHandler.alert.next({ message: "관리자계정으로 관리해주세요." });
      }
    },
    clickUpdateDoctor: (doctorId: number) => {
      if (this.data.myRole === "ADMIN") {
        this.route.toDoctorDetail.next({ doctorId });
      } else {
        this.uiSystem.errorHandler.alert.next({ message: "관리자계정으로 관리해주세요." });
      }
    },
  };
  private init() {
    const sub = zip(this.ucGetDoctors.execute(), this.getUser.execute()).subscribe({
      next: ([{ items }, user]) => {
        this.data.doctors = items;
        this.data.myRole = user.user.role;
        this.output.doctors.next(this.data.doctors);
        sub.unsubscribe();
      },
      error: (error) => {
        if (error?.error_message === "user status is not activated") {
          this.uiSystem.errorHandler.alert.next({ message: "비활성화된 계정입니다.\n관리자에게 문의해주세요." });
        }
        sub.unsubscribe();
      },
    });
  }
}
