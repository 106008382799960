import Icon from "@icon/index";
import { UserInfo } from "@view-data/User";
import { Button, Skeleton, Typo } from "@view/widget";
import React, { useState } from "react";
import styled from "styled-components";

interface UserProfileProps {
  user?: UserInfo;
  clickSignOut: () => void;
  clickToKakao: () => void;
}

const UserProfile: React.FC<UserProfileProps> = ({ user, clickSignOut, clickToKakao }) => {
  const [serviceCenterHover, setServiceCenterHover] = useState<boolean>(false);
  const [logoutHover, setLogoutHover] = useState<boolean>(false);

  return (
    <UserProfileLayout>
      {user ? (
        <InnerLayout>
          <ProfileLayout>
            {user.hospital.profile ? <ProfileImage src={user.hospital.profile} /> : <EmptyProfile />}
            <Typo.B2
              value={`${user.hospital.name} ${user.name}`}
              color="#fff"
            />
          </ProfileLayout>
          <IconLayout>
            <Button.ComponentButton
              component={
                <ServiceCenterWrapper
                  onMouseEnter={() => setServiceCenterHover(true)}
                  onMouseLeave={() => setServiceCenterHover(false)}
                >
                  <Icon.GlobalNavigationServiceCenter colorCode={serviceCenterHover ? "#5aaca0" : "#586A6A"} />
                  <ServiceCenterMenuWrapper>
                    <Menu>
                      <Text>고객센터</Text>
                    </Menu>
                  </ServiceCenterMenuWrapper>
                </ServiceCenterWrapper>
              }
              borderRadius={"50%"}
              onClick={clickToKakao}
            />
            <Button.ComponentButton
              component={
                <LogoutWrapper
                  onMouseEnter={() => setLogoutHover(true)}
                  onMouseLeave={() => setLogoutHover(false)}
                >
                  <Icon.Logout colorCode={logoutHover ? "#5aaca0" : "#586A6A"} />
                  <LogoutMenuWrapper>
                    <Menu>
                      <Text>로그아웃</Text>
                    </Menu>
                  </LogoutMenuWrapper>
                </LogoutWrapper>
              }
              onClick={clickSignOut}
            />
          </IconLayout>
        </InnerLayout>
      ) : (
        <Skeleton
          width={190}
          height={42}
          sx={{ borderRadius: "12px" }}
        />
      )}
    </UserProfileLayout>
  );
};

export default UserProfile;

const UserProfileLayout = styled.div`
  height: 42px;
  background-color: #2b3335;
  border-radius: 12px;
`;

const InnerLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
  padding-inline: 8px;
  height: 100%;
`;

const ProfileLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  height: 100%;
`;

const ProfileImage = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  object-fit: cover;
`;

const EmptyProfile = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: gray;
`;

const IconLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ServiceCenterWrapper = styled.div`
  position: relative;
  &:hover {
    background-color: #1e2527;
    border-radius: 3px;
  }
`;
const LogoutWrapper = styled.div`
  &:hover {
    background-color: #1e2527;
    border-radius: 3px;
  }
`;

const ServiceCenterMenuWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 20;
  cursor: pointer;

  ${ServiceCenterWrapper}:hover &,
  &:hover {
    display: block;
    cursor: pointer;
  }
`;

const Menu = styled.div`
  position: absolute;

  left: 50%;
  bottom: -40px;
  transform: translateX(-50%);
  min-width: 60px;
  background-color: white;
  border-radius: 4px;
  padding: 2px 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  ::after {
    content: "";
    border-width: 0px 4px 8px;
    border-style: solid;
    border-color: transparent transparent #fff;
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translate(-50%, 100%);
  }
  &:hover {
    background-color: #e7ebda;
    ::after {
      border-color: transparent transparent #e7ebda;
    }
  }
`;

const Text = styled.span`
  color: #033632;
  font-size: 11px;
  font-weight: 500;
  line-height: 14.3px;
  /* text-align: left; */
`;

const LogoutMenuWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 20;
  cursor: pointer;

  ${LogoutWrapper}:hover &,
  &:hover {
    display: block;
    cursor: pointer;
  }
`;
