import UISystemManagerImpl from "@/application/ui-system/view-model/UISystemManagerImpl";
import ErrorHandlerImpl from "@/application/ui-system/view-model/ErrorHandlerImpl";
import LoadingHandlerImpl from "@/application/ui-system/view-model/LoadingHandlerImpl";
import PopupHandlerImpl from "@/application/ui-system/view-model/PopupHandlerImpl";
import { UISystemManager } from "./view-model/model";
import ScrollHandlerImpl from "./view-model/ScrollHandlerImpl";
import ToastHandlerImpl from "./view-model/ToastHandlerImpl";

class Container {
  static instance = new Container();
  UISystem: UISystemManager;

  constructor() {
    this.UISystem = new UISystemManagerImpl(
      new PopupHandlerImpl(),
      new LoadingHandlerImpl(),
      new ErrorHandlerImpl(),
      new ScrollHandlerImpl(),
      new ToastHandlerImpl(),
    );
  }
}

export default Container;
