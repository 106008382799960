import { HTMLInputTypeAttribute } from "react";
import InputField from "./InputField";
import TextArea from "./TextArea";
import CheckBox from "./CheckBox";

export type InputType = HTMLInputTypeAttribute;
export type InputState = "default" | "warning" | "error";

export interface InputProps {
  type?: InputType;
  state?: InputState;
  onChange?: (value: string) => void;
  placeholder?: string;
  id?: string;
  name?: string;
  width?: string;
  height?: string;
  padding?: string;
  borderColor?: string;
}

export { InputField, TextArea, CheckBox };
