import BasicMenu from "./BasicMenu";

export interface DropdownProps {
  visible: boolean;
  overLay: React.ReactNode;
  children: React.ReactNode;
  onVisibleChange?: (visible: boolean) => void;
  disabled?: boolean;
}

export { BasicMenu };
