import AdCheck from "./ad-check.svg";
import AdList from "./ad-list.svg";
import AddImage from "./add-image.svg";
import Add from "./add.svg";
import Admin from "./admin.svg";
import ArrowClose from "./arrow-close.svg";
import ArrowOpen from "./arrow-open.svg";
import ArrowLeftSLine from "./arrow-left-s-line.svg";
import ArrowRightSLine from "./arrow-right-s-line.svg";
import ArrowZoom from "./arrow-zoom.svg";
import Attachment from "./attachment.svg";
import Calendar from "./calendar.svg";
import Cancel from "./cancel.svg";
import CheckBlack from "./check_black.svg";
import CheckWhite from "./check_white.svg";
import CheckLine from "./check-line.svg";
import CloseLine from "./close-line.svg";
import CloseCircleFill from "./close-circle-fill.svg";
import Close from "./close.svg";
import Coin from "./coin.svg";
import Database2Line from "./database-2-line.svg";
import DB from "./db.svg";
import DraftLine from "./draft-line.svg";
import Group from "./group.svg";
import HospitalLine from "./hospital-line.svg";
import Hospital from "./hospital.svg";
import Info20Line from "./info_20.svg";
import LineChartLine from "./line-chart-line.svg";
import List from "./list.svg";
import LockPasswordLine from "./lock-password-line.svg";
import Logout from "./logout.svg";
import Message2Line from "./message-2-line.svg";
import Minimize from "./minimize.svg";
import PhoneLine from "./phone-line.svg";
import Preview from "./preview.svg";
import ProfileLine from "./profile-line.svg";
import Register from "./register.svg";
import Remove from "./remove.svg";
import Reset from "./reset.svg";
import ViewImage from "./view-img.svg";
import Save from "./save.svg";
import Search from "./search.svg";
import ShieldCheckLine from "./shield-check-line.svg";
import Stay from "./stay.svg";
import StethoscopeLine from "./stethoscope-line.svg";
import TimeLine from "./time-line.svg";
import Upload16Line from "./upload_16.svg";
import HomeMail from "./home-mail.svg";
import HomeKakao from "./home-kakao.svg";
import UserSettingLine from "./user-settings-line.svg";
import EmpathizeLine from "./empathize-line.svg";
import EditPencil from "./edit-pencil.svg";
import VisibilityOff from "./visibility_off.svg";
import CheckGreen from "./check_green.svg";
import PreviewWhite from "./preview_white.svg";
import Empty from "./empty.svg";
import Kakao from "./kakao.svg";
import MenuLine from "./menu-line.svg";
import ArrowLeftLine from "./arrow-left-line.svg";
import LogoutCircleRLine from "./logout-circle-r-line.svg";
import CancelUnion from "./cancel-union.svg";
import Notice from "./notice-16.svg";
import FailRed from "./fail-red.svg";
import ExclamationMark from "./exclamation-mark.svg";
import QuestionLine from "./question-line.svg";
import Edit from "./edit.svg";
import UploadImage from "./upload-image.svg";
import Plus from "./plus.svg";
import Visibility from "./visibility.svg";
import FaceOnOff from "./face-scan-icon.svg";
import AddOutlined from "/public/icons/add-outlined.svg";
import FilledCheckBox from "/public/icons/filled-check-box.svg";
import OutlinedCheck from "/public/icons/outlined-check.svg";
import MenuAccount from "/public/icons/menu/menu_accounts.svg";
import MenuAdCustomers from "/public/icons/menu/menu_ad_customers.svg";
import MenuContacts from "/public/icons/menu/menu_contacts.svg";
import MenuDoctors from "/public/icons/menu/menu_doctors.svg";
import MenuFaceFitCustomers from "/public/icons/menu/menu_faceFit_customers.svg";
import MenuInformation from "/public/icons/menu/menu_information.svg";
import MenuPlan from "/public/icons/menu/menu_plan.svg";
import MenuExpand from "/public/icons/menu/menu_expand.svg";
import MenuCollpase from "/public/icons/menu/menu_collpase.svg";
import OutlinedSearch from "/public/icons/outlined-search.svg";
import OutlinedReset from "/public/icons/outlined-reset.svg";
import GlobalNavigationServiceCenter from "/public/icons/globalNavigation_service-center.svg";
import OutlinedLinedArrowBack from "/public/icons/outlined-arrow-back-line.svg";
import FilledCircleCheck from "/public/icons/filled-circle-check.svg";
import OutlinedPencil from "/public/icons/outlined-pencil.svg";
import OutlinedTrash from "/public/icons/outlined-trash.svg";
import FilledCamera from "/public/icons/filled-camera.svg";
import FilledFolder from "/public/icons/filled-folder.svg";
import OutlinedStyleFit from "/public/icons/outlined-style-fit.svg";
import FilledRecording from "/public/icons/filled_recording.svg";
import OutlinedStop from "/public/icons/outlined-stop.svg";
import FilledPlay from "/public/icons/filled-play.svg";
import FilledPause from "/public/icons/filled-pause.svg";
import OutlinedDownload from "/public/icons/outlined-download.svg";
import RoundedArrowIOS from "/public/icons/rounded-arrow-ios.svg";
import RoundedClose from "/public/icons/rounded-close.svg";
import FilledArrow from "/public/icons/filled-arrow.svg";
import FilledArrowUp from "/public/icons/fiiled-arrow-up.svg";
import FilledRuler from "/public/icons/filled-ruler.svg";
import FilledArrowLeft from "/public/icons/filled-arrow-left.svg";

export {
  AdCheck,
  AdList,
  Add,
  Admin,
  ArrowClose,
  ArrowOpen,
  AddImage,
  ArrowLeftSLine,
  ArrowRightSLine,
  ArrowZoom,
  Calendar,
  Cancel,
  CheckBlack,
  CheckWhite,
  CheckLine,
  CloseLine,
  CloseCircleFill,
  Close,
  Coin,
  Database2Line,
  DB,
  DraftLine,
  Group,
  HospitalLine,
  Hospital,
  Info20Line,
  LineChartLine,
  List,
  LockPasswordLine,
  Logout,
  Message2Line,
  Minimize,
  PhoneLine,
  Preview,
  ProfileLine,
  Register,
  Remove,
  Reset,
  ViewImage,
  Save,
  Search,
  ShieldCheckLine,
  Stay,
  StethoscopeLine,
  TimeLine,
  Upload16Line,
  HomeMail,
  HomeKakao,
  UserSettingLine,
  EmpathizeLine,
  EditPencil,
  VisibilityOff,
  CheckGreen,
  PreviewWhite,
  Empty,
  Kakao,
  MenuLine,
  ArrowLeftLine,
  LogoutCircleRLine,
  CancelUnion,
  Notice,
  FailRed,
  Attachment,
  ExclamationMark,
  QuestionLine,
  Edit,
  UploadImage,
  Plus,
  Visibility,
  FaceOnOff,
  AddOutlined,
  FilledCheckBox,
  OutlinedCheck,
  MenuAccount,
  MenuAdCustomers,
  MenuContacts,
  MenuDoctors,
  MenuFaceFitCustomers,
  MenuInformation,
  MenuPlan,
  MenuExpand,
  MenuCollpase,
  OutlinedSearch,
  OutlinedReset,
  GlobalNavigationServiceCenter,
  OutlinedLinedArrowBack,
  FilledCircleCheck,
  OutlinedPencil,
  OutlinedTrash,
  FilledCamera,
  FilledFolder,
  OutlinedStyleFit,
  FilledRecording,
  OutlinedStop,
  FilledPlay,
  FilledPause,
  OutlinedDownload,
  RoundedArrowIOS,
  RoundedClose,
  FilledArrow,
  FilledArrowUp,
  FilledRuler,
  FilledArrowLeft,
};
