import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { keyframes } from "@emotion/react";

interface ComponentButtonProps {
  component: React.ReactNode;
  disabled?: boolean;
  sx?: React.CSSProperties;
  touchColor?: string;
  borderRadius?: number | string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const ComponentButton: React.FC<ComponentButtonProps> = ({ component, disabled, sx, touchColor, borderRadius, onClick }) => {
  return (
    <StyledButton
      variant="text"
      disabled={disabled}
      $touchcolor={touchColor}
      sx={{
        minWidth: 0,
        padding: 0,
        color: "#000",
        textTransform: "unset",
        borderRadius: `${typeof borderRadius === "string" ? borderRadius : borderRadius + "px"}`,
        ...sx,
      }}
      onClick={(e) => onClick(e)}
    >
      {component}
    </StyledButton>
  );
};

export default ComponentButton;

const enterKeyframe = keyframes`
  0% {
    transform: scale(0);
    opacity: 0.1;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
`;

const StyledButton = styled(Button)<{ $touchcolor?: string }>`
  width: fit-content;

  &:hover {
  }
  && .MuiTouchRipple-child {
    background-color: ${({ $touchcolor }) => $touchcolor ?? "#808080"};
  }
  && .MuiTouchRipple-rippleVisible {
    opacity: 0.5;
    animation-name: ${enterKeyframe};
    animation-duration: 550ms;
    animation-timing-function: ${({ theme }) => theme.transitions.easing.easeInOut};
  }
`;
