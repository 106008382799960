import { injectable } from "inversify";
import { FaceScanCanvasesViewModel } from "./model/FaceScanCanvasesViewModel";
import type { GetAIAnalysis } from "@/domain/usecase/consulting/model/GetAIAnalysis";
import type { ReqeustFaceLandmark } from "@/domain/usecase/consulting/model/RequestFaceLandmark";
import { BehaviorSubject, EMPTY, from, interval, merge, mergeMap, of, zip } from "rxjs";
import { Consulting, FaceFit } from "@/application/view-data";
import type { RequestLandmarksForUnextractedPhotos } from "@/domain/usecase/consulting/model/RequestLandmarksForUnextractedPhotos";

@injectable()
export class FaceScanCanvasesViewModlImpl implements FaceScanCanvasesViewModel {
  data: FaceScanCanvasesViewModel["data"] = {
    note: null,
    frontal: null,
    right45: null,
    left45: null,
    right90: null,
    left90: null,
  };

  output: FaceScanCanvasesViewModel["output"] = {
    frontal: new BehaviorSubject<FaceFit.FaceScanResult | null>(null),
    right45: new BehaviorSubject<FaceFit.FaceScanResult | null>(null),
    left45: new BehaviorSubject<FaceFit.FaceScanResult | null>(null),
    right90: new BehaviorSubject<Consulting.Photo | null>(null),
    left90: new BehaviorSubject<Consulting.Photo | null>(null),
  };

  constructor(
    private readonly ucGetAIAnalysis: GetAIAnalysis,
    private readonly ucRequestFaceLandmark: ReqeustFaceLandmark,
    private readonly ucRequestLandmarksForUnextractedPhotos: RequestLandmarksForUnextractedPhotos,
  ) {}

  event: FaceScanCanvasesViewModel["event"] = {
    onInit: (note) => {
      let count = 0;
      this.data.note = note;
      const photoIds: number[] = [];

      note.photos?.sort((a, b) => {
        const order = ["FRONTAL", "LEFT45", "RIGHT45", "LEFT90", "RIGHT90"];
        return order.indexOf(a.type) - order.indexOf(b.type);
      });

      if (note.photos) {
        from(note.photos)
          .pipe(
            mergeMap((photo) => {
              if (note.id) {
                if (photo.type === "LEFT90" && photo.resizedUrl) {
                  this.data.left90 = photo;
                  this.output.left90.next(this.data.left90);
                }

                if (photo.type === "RIGHT90" && photo.resizedUrl) {
                  this.data.right90 = photo;
                  this.output.right90.next(this.data.right90);
                }

                return zip(this.ucGetAIAnalysis.execute({ noteId: note.id }), of(photo));
              } else {
                return EMPTY;
              }
            }),
          )
          .subscribe({
            next: ([{ faceLandmarks }, photo]) => {
              count += 1;
              const result = faceLandmarks.find((faceLandmark) => faceLandmark.notePhotoId === photo.id);

              if (result?.notePhotoId && result.result?.alignedImageURL && result.result.faceLandmarksJSONURL) {
                const type = photo.type.toLowerCase() as "frontal";

                this.data[type] = {
                  id: result.id,
                  notePhotoId: result.notePhotoId,
                  alignedImageURL: result.result?.alignedImageURL,
                  faceLandmarksJSONURL: result.result.faceLandmarksJSONURL,
                };

                this.output[type].next(this.data[type]);
              } else {
                if (photo.id && photo.type !== "LEFT90" && photo.type !== "RIGHT90") {
                  photoIds.push(photo.id);
                }
              }

              if (count === note.photos?.length && this.data.note?.id) {
                this.requestFaceLandmark(this.data.note.id, photoIds);
              }
            },
            error: () => {},
          });
      }
    },
  };

  private requestFaceLandmark = (noteId: number, photoIds: number[]) => {
    const sub = this.ucRequestLandmarksForUnextractedPhotos.execute({ noteId, photoIds }).subscribe({
      next: ({ faceFitAnalysis }) => {
        faceFitAnalysis.faceLandmarks.forEach((faceLandmark) => {
          this.data.note?.photos?.forEach((photo) => {
            if (faceLandmark.notePhotoId === photo.id) {
              const type = photo.type.toLowerCase() as "frontal";
              photo.alignedImageURL = faceLandmark.result?.alignedImageURL;
              photo.faceLandmarkJSONURL = faceLandmark.result?.faceLandmarksJSONURL;

              if (faceLandmark.result?.alignedImageURL && faceLandmark.result.faceLandmarksJSONURL && this.data[type] === null) {
                this.data[type] = {
                  id: faceLandmark.id,
                  notePhotoId: faceLandmark.notePhotoId,
                  alignedImageURL: faceLandmark.result?.alignedImageURL,
                  faceLandmarksJSONURL: faceLandmark.result.faceLandmarksJSONURL,
                };

                this.output[type].next({ ...this.data[type] });
              }
            }
          });
        });
      },
      error: (err) => {
        console.log("에러발생", err);
      },

      complete: () => {
        this.getAIAnalysis();
      },
    });
  };

  private getAIAnalysis = () => {
    const note = this.data.note;
    if (note?.photos) {
      note.photos?.sort((a, b) => {
        const order = ["FRONTAL", "LEFT45", "RIGHT45", "LEFT90", "RIGHT90"];
        return order.indexOf(a.type) - order.indexOf(b.type);
      });

      from(note.photos)
        .pipe(
          mergeMap((photo) => {
            if (note.id) {
              if (photo.type === "LEFT90" && photo.resizedUrl) {
                this.data.left90 = photo;
                this.output.left90.next(this.data.left90);
              }

              if (photo.type === "RIGHT90" && photo.resizedUrl) {
                this.data.right90 = photo;
                this.output.right90.next(this.data.right90);
              }

              return zip(this.ucGetAIAnalysis.execute({ noteId: note.id }), of(photo));
            } else {
              return EMPTY;
            }
          }),
        )
        .subscribe({
          next: ([{ faceLandmarks }, photo]) => {
            const result = faceLandmarks.find((faceLandmark) => faceLandmark.notePhotoId === photo.id);

            if (result?.notePhotoId && result.result?.alignedImageURL && result.result.faceLandmarksJSONURL) {
              const type = photo.type.toLowerCase() as "frontal";

              if (this.data[type] === null) {
                this.data[type] = {
                  id: result.id,
                  notePhotoId: result.notePhotoId,
                  alignedImageURL: result.result?.alignedImageURL,
                  faceLandmarksJSONURL: result.result.faceLandmarksJSONURL,
                };

                this.output[type].next(this.data[type]);
              }
            }
          },
          error: () => {},
        });
    }
  };
}
