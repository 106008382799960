import useRouter from "@view/hooks/useRouter";
import { useEffect, useState } from "react";

const useSideMenu = () => {
  const { pathname } = useRouter();
  const [isShowSideMenus, setIsShowSideMenus] = useState(true);

  useEffect(() => {
    if (pathname === "/face-fit/consultations/recent") {
      setIsShowSideMenus(false);
    } else {
      setIsShowSideMenus(true);
    }
  }, [pathname]);

  return {
    isShowSideMenus,
  };
};

export default useSideMenu;
