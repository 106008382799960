import type { HospitalRepository } from "@/domain/interactor/repository/HospitalRepository";
import { inject, injectable } from "inversify";
import { GetHospital, GetHospitalInput, GetHospitalOutput } from "./model/GetHospital";
import { Observable } from "rxjs";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class GetHospitalImpl implements GetHospital {
  constructor(@inject(TYPES.HospitalRepository) private readonly hospitalRepository: HospitalRepository) {}

  execute(input: GetHospitalInput): Observable<GetHospitalOutput> {
    return this.hospitalRepository.getHospital(input.infoTypes);
  }
}
