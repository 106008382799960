import AWS_SDK from "aws-sdk";

export class ObjectStorage {
  private endpoint: string = `https://kr.object.ncloudstorage.com`;
  private obejectStorage = new AWS_SDK.S3({
    endpoint: new AWS_SDK.Endpoint(this.endpoint),
    region: "kr-standard",
    credentials: {
      accessKeyId: "ncp_iam_BPAMKR22uJrwAZGWSHmd",
      secretAccessKey: "ncp_iam_BPKMKRXpBSJlG3aoKtvoNpHs8tHBgr6ZNT",
    },
  });

  constructor(private readonly bucketName: string) {}

  putObject = async (filename: string, path: string, file: File) => {
    const fileType = file.type.split("/")[1] ?? "jpeg";
    const dataKey = `${path}/${filename}.${fileType}`;
    const arrayBuffer = await file.arrayBuffer();
    await this.obejectStorage
      .putObject({
        Bucket: this.bucketName,
        Key: dataKey,
        ContentType: "multipart/form-data",
        ACL: "public-read",
        Body: arrayBuffer,
      })
      .promise();
    return `${this.endpoint}/${this.bucketName}/${dataKey}`;
  };

  getObject = async (url: string) => {
    const response = await this.obejectStorage
      .getObject({
        Bucket: this.bucketName,
        Key: this.getDataKey(url),
      })
      .promise();
    const unit8Array = response.Body as Uint8Array;
    return new Blob([unit8Array]);
  };

  private getDataKey = (url: string) => {
    return url.replace(`${this.endpoint}/${this.bucketName}/`, "");
  };
}
