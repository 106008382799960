export const TYPES = {
  DOCTOR: {
    GetDoctors: Symbol.for("GetDoctors"),
    GetDoctor: Symbol.for("GetDoctor"),
    AddDoctor: Symbol.for("AddDoctor"),
    UpdateDoctor: Symbol.for("UpdateDoctor"),
    DeleteDoctor: Symbol.for("DeleteDoctor"),
  },
  FACE_FIT: {
    GetFaceStyles: Symbol.for("GetFaceStyles"),
    GetOperationCategoryGroups: Symbol.for("GetOperationCategoryGroups"),
    CreateConsultationRecording: Symbol.for("CreateConsultationRecording"),
    GetConsultationRecording: Symbol.for("GetConsultationRecording"),
    CreateConsultingTranscriptions: Symbol.for("CreateConsultingTranscriptions"),
    CreateOperationConsultingLog: Symbol.for("CreateOperationConsultingLog"),
    GetConsultationKeywords: Symbol.for("GetConsultationKeywords"),
    CreateConsultingTranscriptionAnalysis: Symbol.for("CreateConsultingTranscriptionAnalysis"),
    RequestLandmarksForUnextractedPhotos: Symbol.for("RequestLandmarksForUnextractedPhotos"),
  },
  GCS: {
    GetSignedURL: Symbol.for("GetSignedURL"),
  },
  GetHospitalInformation: Symbol.for("GetHospitalInformation"),
  UpdateHospitalInformation: Symbol.for("UpdateHospitalInformation"),
  GetConsultingNotesByFaceFitApply: Symbol.for("GetConsultingNotesByFaceFitApply"),
  UpdateConsultingStatusByFaceFit: Symbol.for("UpdateConsultingStatusByFaceFit"),
  UpdateOperationStatusByFaceFit: Symbol.for("UpdateOperationStatusByFaceFit"),
  GetConsultingNotesByAdvertiseApply: Symbol.for("GetConsultingNotesByAdvertiseApply"),
  UpdateApplicationStatusByAdvertise: Symbol.for("UpdateApplicationStatusByAdvertise"),
  UpdateReservationStatusByAdvertise: Symbol.for("UpdateReservationStatusByAdvertise"),
  GetNotificationContacts: Symbol.for("GetNotificationContacts"),
  AddNotificationContact: Symbol.for("AddNotificationContact"),
  DeleteNotificationContact: Symbol.for("DeleteNotificationContact"),
  GetHospitalContract: Symbol.for("GetHospitalContract"),
  GetUser: Symbol.for("GetUser"),
  GetHospital: Symbol.for("GetHospital"),
  GetConsultantsByFaceFit: Symbol.for("GetConsultantsByFaceFit"),
  GetBestPracticePhotos: Symbol.for("GetBestPracticePhotos"),
  UpdateReservationDateByAdvertise: Symbol.for("UpdateReservationDateByAdvertise"),
  GetContract: Symbol.for("GetContract"),
  UpdateConsultingStatus: Symbol.for("UpdateConsultingStatus"),
  UpdateReservationStatus: Symbol.for("UpdateReservationStatus"),
  UpdateReservationMemoByAdvertise: Symbol.for("UpdateReservationMemoByAdvertise"),
  UpdateConsultingMemo: Symbol.for("UpdateConsultingMemo"),
  UpdateConsultingReservationDate: Symbol.for("UpdateConsultingReservationDate"),
  UpdateHospital: Symbol.for("UpdateHospital"),
  UploadImage: Symbol.for("UploadImage"),
  CreateAccount: Symbol.for("CreateAccount"),
  UpdateAccount: Symbol.for("UpdateAccount"),
  GetAccountLoginIdValidation: Symbol.for("GetAccountLoginIdValidation"),
  GetTags: Symbol.for("GetTags"),
};
