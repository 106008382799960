import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";

export interface ContextState {
  id: number;
  accountType: string;
  subway?: string;
}

const initialState: ContextState = {
  id: 0,
  accountType: "",
  subway: "",
};

interface Props {
  id?: number;
  accountType?: string;
  children: ReactNode;
}

const Context = createContext<{
  state: ContextState;
  setState: React.Dispatch<React.SetStateAction<ContextState>>;
}>({
  state: initialState,
  setState: () => {},
});

export const useContextState = () => useContext(Context);

export const ContextProvider = ({ id, accountType, children }: Props) => {
  const [state, setState] = useState<ContextState>(initialState);

  useEffect(() => {
    const userInfo = JSON.parse(sessionStorage.getItem("sessionUserInfo")!);

    if (userInfo) {
      setState({
        id: userInfo.id,
        accountType: userInfo.accountType,
        subway: "",
      });
    }
  }, []);

  useEffect(() => {
    if (id && accountType) {
      setState({
        id,
        accountType,
        subway: "",
      });
    }
  }, [id, accountType]);

  return <Context.Provider value={{ state, setState }}>{children}</Context.Provider>;
};
