import { datadogRum } from "@datadog/browser-rum";
import { useEffect, useState } from "react";

const useDatadogRUM = ({ init }: { init?: boolean } = {}) => {
  const [startViewName, setStartViewName] = useState<string>();

  useEffect(() => {
    if (init) {
      if (process.env.ENVIRONMENT !== "dev" && process.env.DATADOG_APPLICATION_ID && process.env.DATADOG_CLIENT_TOKEN) {
        datadogRum.init({
          applicationId: "77fbcbc5-660c-47b7-81fa-e62e990ad305",
          clientToken: "pub69bc0886610e09bc34cae84c0d7ec5e2",
          site: "us5.datadoghq.com",
          service: "dr.whole-in-one",
          env: process.env.ENVIRONMENT,
          version: process.env.VERSION,
          sessionSampleRate: 100,
          premiumSampleRate: 100,
          trackUserInteractions: true,
          defaultPrivacyLevel: "allow",
          allowedTracingUrls: [
            "https://stg-api.afoter.com",
            (url) => url.startsWith("https://stg-api.afoter.com"),
            "https://test-api.afoter.com",
            (url) => url.startsWith("https://test-api.afoter.com"),
            "https://new-api.afoter.com",
            (url) => url.startsWith("https://new-api.afoter.com"),
          ],
        });
        datadogRum.startSessionReplayRecording();
      }
    }
  }, []);

  useEffect(() => {
    if (startViewName) {
      datadogRum.onReady(() => {
        datadogRum.startView({ name: startViewName });
      });
    }
  }, [startViewName]);

  //   const startView = (view: View) => {
  //     if (
  //       !Boolean(startViewName) ||
  //       (view.name && view.name !== startViewName) ||
  //       (view.customName && view.customName !== startViewName)
  //     ) {
  //       setStartViewName(view.name ?? view.customName);
  //     }
  //   };

  //   const trackAction = (actionType: ActionType) => {
  //     datadogRum.onReady(() => {
  //       datadogRum.addAction(actionType.actionName, {
  //         flag: actionType.flag,
  //         service: actionType.service
  //       });
  //     });
  //   };

  return {
    // startView,
    // trackAction
  };
};

export default useDatadogRUM;
