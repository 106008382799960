import { DrawingFacialLandmarks } from "../face/DrawingFacialLandmarks";
import Calc from "../utils/Calc";
import { Config, ConnectionPoint, DenormalizedCoordinate, CanvasLineStyle, Coordinate, LandmarkPoint } from "../index.interface";
import { BestRatioLineStyle, PhotoBaseLine } from "../utils/Styles";

export class NasolabialAngle extends DrawingFacialLandmarks {
  private frontalFaceCanvasDrawer: DrawingFacialLandmarks;
  private coordinate: {
    baseLine: { top: Coordinate; bottom: Coordinate };
    noseLine: { left: Coordinate; right: Coordinate };
  };

  ratio: {
    angle: number;
  };

  constructor({ config, frontalFaceCanvas }: { config: Config; frontalFaceCanvas: HTMLCanvasElement }) {
    super(config);
    this.frontalFaceCanvasDrawer = new DrawingFacialLandmarks({
      imageEle: config.imageEle,
      canvasEle: frontalFaceCanvas,
      normalizedCoordinate: config.normalizedCoordinate,
    });
    this.coordinate = this.getCoordinate();
    this.ratio = this.calcRatio();
  }

  drawPhotoBase = async () => {
    this.photoBaseDrawer.drawNoseLines();
    this.photoBaseDrawer.drawFrontalFaceLines();
    this.delay(300);
    this.photoBaseDrawer.drawAngleText();
  };

  drawBestRatio = async () => {
    this.bestRatioDrawer.drawNoseLines();
    this.bestRatioDrawer.drawFrontalFaceLines();
    this.delay(300);
    this.bestRatioDrawer.drawAngleText();
  };

  private photoBaseDrawer = {
    drawNoseLines: () => {
      this.drawLineBetweenPoints({
        point1: this.coordinate.baseLine.top,
        point2: this.coordinate.baseLine.bottom,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: this.coordinate.noseLine.left,
        point2: this.coordinate.noseLine.right,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });

      this.drawArcBetweenPoints({
        center: this.coordinate.noseLine.left,
        point1: this.coordinate.noseLine.right,
        point2: this.coordinate.baseLine.bottom,
        radius: 15,
        lineStyle: PhotoBaseLine,
      });
    },

    drawAngleText: () => {
      const textStyle = { x: 25, y: 30, fontSize: 18, color: "#FF9900" };
      this.drawTextBetweenPoints(`${this.ratio.angle}°`, this.coordinate.noseLine.right, this.coordinate.noseLine.left, textStyle);
    },

    drawFrontalFaceLines: () => {
      const coordinateByInclinationToRight = Calc.getCoordinateByInclination({
        point1: this.faceCoordinates[240],
        point2: this.faceCoordinates[60],
        length: 40,
        direction: "RIGHT",
      });

      const intersectionPoint = Calc.findIntersectionPoint({
        lineA: { pointA: coordinateByInclinationToRight, pointB: this.faceCoordinates[60] },
        lineB: { pointA: this.faceCoordinates[9], pointB: this.faceCoordinates[199] },
      });

      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.faceCoordinates[9],
        point2: this.faceCoordinates[13],
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });

      if (intersectionPoint) {
        this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
          point1: this.faceCoordinates[240],
          point2: { ...intersectionPoint, x: this.faceCoordinates[13].x },
          lineStyle: PhotoBaseLine,
          isDrawEndPoint: true,
        });
      }
    },
  };

  private bestRatioDrawer = {
    drawNoseLines: () => {
      this.drawLineBetweenPoints({
        point1: this.coordinate.baseLine.top,
        point2: this.coordinate.baseLine.bottom,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: this.coordinate.noseLine.left,
        point2: this.coordinate.noseLine.right,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.drawArcBetweenPoints({
        center: this.coordinate.noseLine.left,
        point1: this.coordinate.noseLine.right,
        point2: this.coordinate.baseLine.bottom,
        radius: 15,
        lineStyle: BestRatioLineStyle,
      });
    },

    drawAngleText: () => {
      const textStyle = { x: 10, y: 57, fontSize: 18, color: "#00D7CA" };
      this.drawTextBetweenPoints(`${97}°`, this.coordinate.noseLine.right, this.coordinate.noseLine.left, textStyle);
    },

    drawFrontalFaceLines: () => {
      const coordinateByInclinationToRight = Calc.getCoordinateByInclination({
        point1: this.faceCoordinates[240],
        point2: this.faceCoordinates[60],
        length: 40,
        direction: "RIGHT",
      });

      const intersectionPoint = Calc.findIntersectionPoint({
        lineA: { pointA: coordinateByInclinationToRight, pointB: this.faceCoordinates[60] },
        lineB: { pointA: this.faceCoordinates[9], pointB: this.faceCoordinates[199] },
      });

      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.faceCoordinates[9],
        point2: this.faceCoordinates[13],
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      if (intersectionPoint) {
        this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
          point1: this.faceCoordinates[240],
          point2: { ...intersectionPoint, x: this.faceCoordinates[13].x },
          lineStyle: BestRatioLineStyle,
          isDrawEndPoint: true,
        });
      }
    },
  };

  private getCoordinate = () => {
    const coordinateByInclinationToLeft = Calc.getCoordinateByInclination({
      point1: this.sideFaceCoordinate[240],
      point2: this.sideFaceCoordinate[60],
      length: 40,
      direction: "LEFT",
    });

    const coordinateByInclinationToRight = Calc.getCoordinateByInclination({
      point1: this.sideFaceCoordinate[240],
      point2: this.sideFaceCoordinate[60],
      length: 60,
      direction: "RIGHT",
    });

    const intersectionPoint = Calc.findIntersectionPoint({
      lineA: { pointA: coordinateByInclinationToLeft, pointB: this.sideFaceCoordinate[60] },
      lineB: { pointA: this.sideFaceCoordinate[9], pointB: this.sideFaceCoordinate[199] },
    });

    return {
      baseLine: {
        top: this.sideFaceCoordinate[9],
        bottom: { ...this.sideFaceCoordinate[199], y: this.sideFaceCoordinate[199].y - 70 },
      },
      noseLine: {
        left: intersectionPoint!,
        right: coordinateByInclinationToRight,
      },
    };
  };

  private calcRatio = () => {
    const angle = Calc.calcAngle(
      {
        point1: this.coordinate.noseLine.right,
        center: this.coordinate.noseLine.left,
        point2: this.coordinate.baseLine.bottom,
      },
      "2d",
    );

    return {
      angle,
    };
  };
  private delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
}
