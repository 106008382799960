import { injectable } from "inversify";
import { Subject } from "rxjs";
import { PopupHandler } from "./model";
import * as ViewData from "@/application/view-data";
import { ConfirmAlert } from "./model/PopupHandler";

@injectable()
export default class PopupHandlerImpl implements PopupHandler {
  static popupHandler = new PopupHandlerImpl();
  open = new Subject<React.ReactNode | null>();
  // editor = {
  //   clinicEditor: new Subject<ViewData.ClinicEditorPopup>(),
  // };
  alert = {
    confirm: new Subject<ConfirmAlert>()
  };
}
