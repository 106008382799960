import type { AdRepository } from "@/domain/interactor/repository/AdRepository";
import { inject, injectable } from "inversify";
import { map, Observable } from "rxjs";
import { TYPES } from "@/data/interactor/repository/index.container.type";
import {
  UpdateReservationDateByAdvertise,
  UpdateReservationDateByAdvertiseInput,
  UpdateReservationDateByAdvertiseOutput,
} from "./model/UpdateReservationDateByAdvertise";

@injectable()
export class UpdateReservationDateByAdvertiseImpl implements UpdateReservationDateByAdvertise {
  constructor(@inject(TYPES.AdRepository) private readonly adRepository: AdRepository) {}

  execute = (input: UpdateReservationDateByAdvertiseInput): Observable<UpdateReservationDateByAdvertiseOutput> => {
    return this.adRepository.updateReservationDate(input.noteId, input.date).pipe(
      map((res) => {
        return {
          advertiseConsultingNoteId: res.advertisementDBId,
        };
      }),
    );
  };
}
