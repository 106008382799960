import { injectable, inject } from "inversify";
import { UpdateConsultingNote, UpdateConsultingNoteInput, UpdateConsultingNoteOutput } from "./model/UpdateConsultingNote";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { Observable } from "rxjs";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class UpdateConsultingNoteImpl implements UpdateConsultingNote {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute(noteId: number, input: UpdateConsultingNoteInput): Observable<UpdateConsultingNoteOutput> {
    const { customerName, photos, customerPhoneNumber, customerGender, referral, memo, hospitalCategories, reservationDate } = input;

    return this.operationConsultingRepository.updateConsultingNote(noteId, {
      customerGender: customerGender ?? null,
      customerName: customerName ?? null,
      customerPhoneNumber: customerPhoneNumber ?? null,
      memo: memo ?? null,
      referral: referral ?? null,
      reservationDate: reservationDate ?? null,
      hospitalCategories: hospitalCategories ?? [],
      photos: photos
        ? photos.map((photo, idx) => {
            return {
              ...photo,
              ordinal: idx,
            };
          })
        : [],
    });
  }
}
