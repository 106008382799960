import { useState, useEffect } from "react";
import { createPortal } from "react-dom";

import ModalFrame from "./ModalFrame";
import SideInModalFrame from "./SideInModalFrame";
import CenterModalFrame from "./CenterModalFrame";
import ModalLayout from "./ModalLayout";
import FullscreenModal from "./FullScreenModal";
import BottomModal from "./BottomModal";

export type ModalPosition = "center" | "top-left" | "top-right" | "top-center" | "bottom-left" | "bottom-right" | "bottom-center";

export type ModalLevel = 100 | 200 | 300 | 400 | 500 | 600 | 700;

export interface ModalProps {
  visible: boolean;
  maskDimmed?: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  position?: ModalPosition;
  modalLevel?: ModalLevel;
  disablePortal?: boolean;
  closeable?: boolean;
}

export interface PortalProps {
  portalId?: string;
  children: React.ReactNode;
}

export const Portal = ({ portalId = "modal-root", children }: PortalProps) => {
  const [element, setElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setElement(document.getElementById(portalId));
  }, []);

  return element && children ? createPortal(children, element) : null;
};

export { ModalFrame, SideInModalFrame, CenterModalFrame, ModalLayout, FullscreenModal, BottomModal };
