import { Observable } from "rxjs";
import { API } from "../../API";
import { injectable } from "inversify";
import { AuthAPI } from "./model/AuthAPI";
import { GenerateTokenRequest } from "./model/Request";
import { TokenResponse } from "./model/Response";

@injectable()
export class AuthAPIImpl implements AuthAPI {
  private readonly prefixPath = "/auth/token";

  generateTokenBy = (request: GenerateTokenRequest): Observable<TokenResponse> => {
    return API.post<TokenResponse>({
      credential: false,
      path: `${this.prefixPath}`,
      queryParams: {
        client_id: request.clientId,
        grant_type: request.grantType,
        code: request.code,
        redirect_uri: request.redirectUri,
      },
    });
  };
}
