import { TYPES } from "@/data/interactor/repository/index.container.type";
import type { DoctorRepository } from "@/domain/interactor/repository/DoctorRepository";
import { inject, injectable } from "inversify";
import { Observable } from "rxjs";
import { DeleteDoctor, DeleteDoctorInput } from "./model/DeleteDoctor";

@injectable()
export class DeleteDoctorImpl implements DeleteDoctor {
  constructor(@inject(TYPES.DoctorRepository) private readonly doctorRepository: DoctorRepository) {}

  execute(input: DeleteDoctorInput): Observable<void> {
    return this.doctorRepository.deleteDoctor(input);
  }
}
