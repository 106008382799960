import type { HospitalRepository } from "@/domain/interactor/repository/HospitalRepository";
import { inject, injectable } from "inversify";
import { UpdateAccount, UpdateAccountInput, UpdateAccountOutput } from "./model/UpdateAccount";
import { Observable } from "rxjs";
import { TYPES } from "@/data/interactor/repository/index.container.type";
import type { UserRepository } from "@/domain/interactor/repository/UserRepository";

@injectable()
export class UpdateAccountImpl implements UpdateAccount {
  constructor(@inject(TYPES.UserRepository) private readonly userRepository: UserRepository) {}

  execute(input: UpdateAccountInput): Observable<UpdateAccountOutput> {
    return this.userRepository.updateUser(
      input.userId,
      input.currentLoginPassword,
      input.newLoginPassword,
      input.name,
      input.role,
      input.status,
    );
  }
}
