import { injectable, inject } from "inversify";
import { UpdateDoctor, UpdateDoctorInput } from "./model/UpdateDoctor";
import type { DoctorRepository } from "@/domain/interactor/repository/DoctorRepository";
import { Observable } from "rxjs";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class UpdateDoctorImpl implements UpdateDoctor {
  constructor(@inject(TYPES.DoctorRepository) private readonly doctorRepository: DoctorRepository) {}

  execute(input: UpdateDoctorInput): Observable<void> {
    return this.doctorRepository.updateDoctor(input);
  }
}
