import type { UISystemManager } from "@/application/ui-system/view-model/model";
import type { GetFaceLandmarks } from "@/domain/usecase/gcs/model/GetFaceLandmarks";
import type { GetAIAnalysis } from "@/domain/usecase/consulting/model/GetAIAnalysis";
import { Coordinate } from "@view-data/FaceLandmark";
import { FaceFit, UI, Consulting } from "@/application/view-data";
import { injectable } from "inversify";
import { delay, interval, mergeMap, of, Subject, tap } from "rxjs";
import { FaceScanViewModel } from "./model/FaceScanViewModel";
import { DrawingFacialLandmarks } from "./face-classification/face/DrawingFacialLandmarks";
import * as Entity from "@/domain/entity";
@injectable()
export class FaceScanViewModelImpl implements FaceScanViewModel {
  data: FaceScanViewModel["data"] = {
    landmarks: undefined,
    consultingPhotos: ["LEFT90", "LEFT45", "FRONTAL", "RIGHT45", "RIGHT90"],
    eyeGoldenRatio: {},
    facialProportionsRatio: {},
    facialSymmetryAngle: {},
    eyeScanDescriptions: null,
    noseScanDescriptions: null,
    faceContourScanDescriptions: null,
    operationParts: [
      { data: { code: "EYES", name: "눈" }, isSelected: true },
      { data: { code: "NOSE", name: "코" }, isSelected: false },
      { data: { code: "FACIAL_CONTOURING", name: "윤곽" }, isSelected: false },
    ],
    eyeScanVisibility: {
      goldenRatio: { before: true, best: true },
      asymmetry: { before: false, best: false },
      aspectRatio: { before: false, best: false },
      tailAngle: { before: false, best: false },
    },
    alignedPhoto: null,
    eyeScanResults: {
      goldenRatio: { before: null, best: "0.5 : 1 : 1 : 1 : 0.5" },
      asymmetry: { before: null, best: "1 : 1" },
      aspectRatio: { before: null, best: "1 : 3 / 1 : 3" },
      eyeTail: { before: null, best: "4° : 4°" },
    },
    eyeClassification: {
      goldenRatio: null,
      asymmetricalEye: null,
      eyeTail: null,
      eyeAspectRatio: null,
    },
    noseClassification: {
      noseLengthRatio: null,
      noseHeightRatio: null,
      foreheadNoseAngle: null,
      nasolabialAngle: null,
      noseTipShape: null,
      noseBridgeWidthRatio: null,
    },
    faceContourClassification: {
      faceShapeType: null,
      zygomaticRatio: null,
      mandibularRatio: null,
      symmetryRatio: null,
    },
    noseScanVisibility: {
      noseLength: { before: true, best: true },
      noseHeight: { before: false, best: false },
      foreheadNoseAngle: { before: false, best: false },
      nasolabialAngle: { before: false, best: false },
      noseTipShape: { before: false, best: false },
      noseBridgeWidth: { before: false, best: false },
    },
    noseScanResults: {
      noseHeight: { before: null, best: "코길이의 67%" },
      noseLength: { before: null, best: "1 : 1" },
      foreheadNoseAngle: { before: null, best: "150° (Female) 148° (Male)" },
      nasolabialAngle: { before: null, best: "95°~100° (Female)\n90°~95° (Male)" },
      noseTipShape: { before: null, best: "2 : 6 : 2, 완만한 갈매기라인" },
      noseBridgeWidth: { before: null, best: "1 : 8 : 1" },
    },
    faceContourVisibility: {
      faceShapeType: { before: true, best: true },
      zygomaticRatio: { before: false, best: false },
      mandibularRatio: { before: false, best: false },
      symmetryRatio: { before: false, best: false },
    },
    faceContourScanResults: {
      faceShapeType: { before: null, best: "70%" },
      zygomaticRatio: { before: null, best: "0.5 : 1 : 1 : 1 : 0.5" },
      mandibularRatio: { before: null, best: "1 : 0.8 / 33% : 67%" },
      symmetryRatio: { before: null, best: `90°:90°, 90°:90°` },
    },
  };

  output: FaceScanViewModel["output"] = {
    landmarks: new Subject<Coordinate[][]>(),
    alignedPhoto: new Subject<string>(),
    faceFocusAreaCoordinate: new Subject<{
      leftTop?: { x: number; y: number };
      rightTop?: { x: number; y: number };
      leftBottom?: { x: number; y: number };
      rightBottom?: { x: number; y: number };
    }>(),
    scanCompletion: new Subject<boolean>(),
    operationParts: new Subject<UI.SelectableItem<{ code: "EYES" | "NOSE" | "FACIAL_CONTOURING"; name: "눈" | "코" | "윤곽" }>[]>(),
    eyeScanVisibility: new Subject<FaceFit.EyeScanVisibility>(),
    eyeScanResults: new Subject<FaceFit.EyeScanResults>(),
    eyeScanResultsDescription: new Subject<FaceFit.FaceScanResultDescription | null>(),
    noseScanResultsDescription: new Subject<FaceFit.FaceScanResultDescription | null>(),
    noseScanVisibility: new Subject<FaceFit.NoseScanVisibility>(),
    noseScanResults: new Subject<FaceFit.NoseScanResults>(),
    faceContourScanResults: new Subject<FaceFit.FaceContourScanResults>(),
    faceContourVisibility: new Subject<FaceFit.FaceContourVisibility>(),
    faceContourScanResultsDescription: new Subject<FaceFit.FaceScanResultDescription | null>(),
    consultingPhotos: new Subject<Entity.Type.CONSULTING_PHOTO_TYPE[]>(),
    selectedAngle: new Subject<Entity.Type.CONSULTING_PHOTO_TYPE>(),
  };

  constructor(readonly uiSystem: UISystemManager) {}

  input: FaceScanViewModel["input"] = {
    clickOperationPart: (code) => {
      this.data.operationParts = this.data.operationParts.map((operationPart) => {
        return { ...operationPart, isSelected: operationPart.data.code === code };
      });
      this.output.operationParts.next(this.data.operationParts);
    },
    clickEyeScanContentVisibilityOption: (contentType, canvasType) => {
      this.data.eyeScanVisibility[contentType][canvasType] = !this.data.eyeScanVisibility[contentType][canvasType];
      this.output.eyeScanVisibility.next({ ...this.data.eyeScanVisibility });

      const eyeScanDescription = this.getEyeScanResultsDescription();
      this.output.eyeScanResultsDescription.next(eyeScanDescription);
    },
    clickNoseScanContentVisibilityOption: (contentType, canvasType) => {
      this.data.noseScanVisibility[contentType][canvasType] = !this.data.noseScanVisibility[contentType][canvasType];
      this.output.noseScanVisibility.next({ ...this.data.noseScanVisibility });

      const noseScanDescription = this.getNoseScanResultsDescription();
      this.output.noseScanResultsDescription.next(noseScanDescription);
    },

    clickFaceContourScanContentVisibilityOption: (contentType, canvasType) => {
      this.data.faceContourVisibility[contentType][canvasType] = !this.data.faceContourVisibility[contentType][canvasType];
      this.output.faceContourVisibility.next({ ...this.data.faceContourVisibility });

      const faceContourDescription = this.getFaceContourScanResultsDescription();
      this.output.faceContourScanResultsDescription.next(faceContourDescription);
    },

    clickEyeScanContentQuickView: (type: "BEFORE" | "BEST") => {
      const { goldenRatio, asymmetry, aspectRatio, tailAngle } = this.data.eyeScanVisibility;

      switch (type) {
        case "BEFORE":
          if (goldenRatio.before && asymmetry.before && aspectRatio.before && tailAngle.before) {
            this.data.eyeScanVisibility.aspectRatio.before = false;
            this.data.eyeScanVisibility.goldenRatio.before = false;
            this.data.eyeScanVisibility.asymmetry.before = false;
            this.data.eyeScanVisibility.tailAngle.before = false;
          } else {
            this.data.eyeScanVisibility.aspectRatio.before = true;
            this.data.eyeScanVisibility.goldenRatio.before = true;
            this.data.eyeScanVisibility.asymmetry.before = true;
            this.data.eyeScanVisibility.tailAngle.before = true;
          }

          break;
        case "BEST":
          if (goldenRatio.best && asymmetry.best && aspectRatio.best && tailAngle.best) {
            this.data.eyeScanVisibility.aspectRatio.best = false;
            this.data.eyeScanVisibility.goldenRatio.best = false;
            this.data.eyeScanVisibility.asymmetry.best = false;
            this.data.eyeScanVisibility.tailAngle.best = false;
          } else {
            this.data.eyeScanVisibility.aspectRatio.best = true;
            this.data.eyeScanVisibility.goldenRatio.best = true;
            this.data.eyeScanVisibility.asymmetry.best = true;
            this.data.eyeScanVisibility.tailAngle.best = true;
          }
          break;
      }

      this.output.eyeScanVisibility.next({ ...this.data.eyeScanVisibility });
    },

    clickNoseScanContentQuickView: (type) => {
      const { noseLength, noseHeight, foreheadNoseAngle, nasolabialAngle, noseBridgeWidth, noseTipShape } = this.data.noseScanVisibility;

      switch (type) {
        case "BEFORE":
          if (
            noseLength.before &&
            noseHeight.before &&
            foreheadNoseAngle.before &&
            nasolabialAngle.before &&
            noseBridgeWidth.before &&
            noseTipShape.before
          ) {
            this.data.noseScanVisibility.noseLength.before = false;
            this.data.noseScanVisibility.noseHeight.before = false;
            this.data.noseScanVisibility.foreheadNoseAngle.before = false;
            this.data.noseScanVisibility.nasolabialAngle.before = false;
            this.data.noseScanVisibility.noseTipShape.before = false;
            this.data.noseScanVisibility.noseBridgeWidth.before = false;
          } else {
            this.data.noseScanVisibility.noseLength.before = true;
            this.data.noseScanVisibility.noseHeight.before = true;
            this.data.noseScanVisibility.foreheadNoseAngle.before = true;
            this.data.noseScanVisibility.nasolabialAngle.before = true;
            this.data.noseScanVisibility.noseTipShape.before = true;
            this.data.noseScanVisibility.noseBridgeWidth.before = true;
          }

          break;
        case "BEST":
          if (
            noseLength.best &&
            noseHeight.best &&
            foreheadNoseAngle.best &&
            nasolabialAngle.best &&
            noseBridgeWidth.best &&
            noseTipShape.best
          ) {
            this.data.noseScanVisibility.noseLength.best = false;
            this.data.noseScanVisibility.noseHeight.best = false;
            this.data.noseScanVisibility.foreheadNoseAngle.best = false;
            this.data.noseScanVisibility.nasolabialAngle.best = false;
            this.data.noseScanVisibility.noseBridgeWidth.best = false;
            this.data.noseScanVisibility.noseTipShape.best = false;
          } else {
            this.data.noseScanVisibility.foreheadNoseAngle.best = true;
            this.data.noseScanVisibility.noseLength.best = true;
            this.data.noseScanVisibility.noseHeight.best = true;
            this.data.noseScanVisibility.nasolabialAngle.best = true;
            this.data.noseScanVisibility.noseBridgeWidth.best = true;
            this.data.noseScanVisibility.noseTipShape.best = true;
          }
          break;
      }

      this.output.noseScanVisibility.next({ ...this.data.noseScanVisibility });
    },

    clickFaceContourScanContentQuickView: (type) => {
      const { faceShapeType, zygomaticRatio, mandibularRatio, symmetryRatio } = this.data.faceContourVisibility;

      switch (type) {
        case "BEFORE":
          if (faceShapeType.before && zygomaticRatio.before && mandibularRatio.before && symmetryRatio.before) {
            this.data.faceContourVisibility.faceShapeType.before = false;
            this.data.faceContourVisibility.zygomaticRatio.before = false;
            this.data.faceContourVisibility.mandibularRatio.before = false;
            this.data.faceContourVisibility.symmetryRatio.before = false;
          } else {
            this.data.faceContourVisibility.faceShapeType.before = true;
            this.data.faceContourVisibility.zygomaticRatio.before = true;
            this.data.faceContourVisibility.mandibularRatio.before = true;
            this.data.faceContourVisibility.symmetryRatio.before = true;
          }

          break;
        case "BEST":
          if (faceShapeType.best && zygomaticRatio.best && mandibularRatio.best && symmetryRatio.best) {
            this.data.faceContourVisibility.faceShapeType.best = false;
            this.data.faceContourVisibility.zygomaticRatio.best = false;
            this.data.faceContourVisibility.mandibularRatio.best = false;
            this.data.faceContourVisibility.symmetryRatio.best = false;
          } else {
            this.data.faceContourVisibility.mandibularRatio.best = true;
            this.data.faceContourVisibility.faceShapeType.best = true;
            this.data.faceContourVisibility.zygomaticRatio.best = true;
            this.data.faceContourVisibility.symmetryRatio.best = true;
          }
          break;
      }

      this.output.faceContourVisibility.next({ ...this.data.faceContourVisibility });
    },
    clickAngleButton: (consultingPhotoType) => {
      const data: Entity.Type.CONSULTING_PHOTO_TYPE[] = ["LEFT90", "LEFT45", "FRONTAL", "RIGHT45", "RIGHT90"];

      const centerIndex = data.indexOf(consultingPhotoType);
      if (centerIndex === -1) {
        console.error("Invalid consultingPhotoType");
        return;
      }

      const rotatedData = [...data.slice(centerIndex), ...data.slice(0, centerIndex)];

      const result = [
        rotatedData[3 % rotatedData.length],
        rotatedData[4 % rotatedData.length],
        consultingPhotoType,
        rotatedData[1],
        rotatedData[2],
      ];

      this.data.consultingPhotos = result;

      this.output.selectedAngle.next(consultingPhotoType);
      this.output.consultingPhotos.next(this.data.consultingPhotos);
    },
  };

  event: FaceScanViewModel["event"] = {
    onUpdateFaceContourResults: (faceContourResults) => {
      this.data.faceContourScanResults = faceContourResults;
      this.output.faceContourScanResults.next(this.data.faceContourScanResults);
    },
    onUpdateEyeScanResults: (eyeScanResults) => {
      this.data.eyeScanResults = eyeScanResults;
      this.output.eyeScanResults.next(this.data.eyeScanResults);
    },
    onUpdateNoseScanResults: (noseScanResults) => {
      this.data.noseScanResults = noseScanResults;
      this.output.noseScanResults.next({ ...this.data.noseScanResults });
    },

    onUpateEyeScanDescriptions: (eyeScanDescriptions) => {
      this.data.eyeScanDescriptions = eyeScanDescriptions;
      const eyeScanDescription = this.getEyeScanResultsDescription();
      this.output.eyeScanResultsDescription.next(eyeScanDescription);
    },
    onUpateNoseScanDescriptions: (noseScanDescriptions) => {
      this.data.noseScanDescriptions = noseScanDescriptions;
      const noseScanResultDescriptions = this.getNoseScanResultsDescription();
      this.output.noseScanResultsDescription.next(noseScanResultDescriptions);
    },
    onUpdateFaceContourScanDescriptions: (faceContourScanDescriptions) => {
      this.data.faceContourScanDescriptions = faceContourScanDescriptions;
      const faceContourScanResultsDescription = this.getFaceContourScanResultsDescription();
      this.output.faceContourScanResultsDescription.next(faceContourScanResultsDescription);
    },

    onSetFaceLandmark: (photo, canvas) => {
      if (this.data.landmarks) {
        const drawingFacialLandmarks = new DrawingFacialLandmarks({
          imageEle: photo,
          canvasEle: canvas,
          normalizedCoordinate: this.data.landmarks,
        });

        drawingFacialLandmarks.drawLandmarkPoints();
      }
    },

    onStartSubjectSetup: (subjectCode) => {
      this.data.operationParts = this.data.operationParts.map((operationPart) => {
        return { ...operationPart, isSelected: operationPart.data.code === subjectCode };
      });
      this.output.operationParts.next(this.data.operationParts);
    },
  };

  private getEyeScanResultsDescription = (): FaceFit.FaceScanResultDescription | null => {
    const eyeKeysWithTrueValues = this.getVisibleKeys(
      this.data.eyeScanVisibility as { ["goldenRatio"]: { before: boolean; best: boolean } },
    );

    if (eyeKeysWithTrueValues.length > 1) {
      return null;
    }

    return this.data.eyeScanDescriptions ? this.data.eyeScanDescriptions[eyeKeysWithTrueValues[0]] : null;
  };

  private getNoseScanResultsDescription = () => {
    const noseKeysWithTrueValues = this.getVisibleKeys(
      this.data.noseScanVisibility as { ["noseLength"]: { before: boolean; best: boolean } },
    );

    if (noseKeysWithTrueValues.length > 1) {
      return null;
    }

    return this.data.noseScanDescriptions ? this.data.noseScanDescriptions[noseKeysWithTrueValues[0]] : null;
  };

  private getFaceContourScanResultsDescription = (): FaceFit.FaceScanResultDescription | null => {
    const faceContourKeysWithTrueValues = this.getVisibleKeys(
      this.data.faceContourVisibility as { ["faceShapeType"]: { before: boolean; best: boolean } },
    );

    if (faceContourKeysWithTrueValues.length > 1) {
      return null;
    }

    return this.data.faceContourScanDescriptions ? this.data.faceContourScanDescriptions[faceContourKeysWithTrueValues[0]] : null;
  };

  private getVisibleKeys = (scanVisibility: { [key: string]: { before: boolean; best: boolean } }): string[] => {
    const result: string[] = [];

    Object.keys(scanVisibility).forEach((key) => {
      if (scanVisibility[key].before || scanVisibility[key].best) {
        result.push(key);
      }
    });

    return result;
  };
}
