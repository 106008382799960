import { injectable, inject } from "inversify";
import {
  CreateConsultingTranscriptions,
  CreateConsultingTranscriptionsInput,
  CreateConsultingTranscriptionsOutput,
} from "./model/CreateConsultingTranscriptions";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { Observable } from "rxjs";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class CreateConsultingTranscriptionsImpl implements CreateConsultingTranscriptions {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute = (input: CreateConsultingTranscriptionsInput): Observable<CreateConsultingTranscriptionsOutput> => {
    return this.operationConsultingRepository.createConsultingTranscriptions(input.recordId);
  };
}
