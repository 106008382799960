import { injectable } from "inversify";
import { FaceShiftViewModel } from "./model/FaceShiftViewModel";
import { BehaviorSubject, from, interval, mergeMap, Subject } from "rxjs";
import { AIAnalysis, Consulting, FaceFit } from "@/application/view-data";
import { PersonaShiftStyle, PersonaShiftPart } from "@/domain/entity/ai-analysis/aiAnalysis.entity";
import type { GetInfluencerPhotos } from "@/domain/usecase/consulting/model/GetInfluencerPhotos";
import type { RequestPersonaShift } from "@/domain/usecase/consulting/model/ReqeustPersonaShift";
import type { UISystemManager } from "../ui-system/view-model/model";
import type { GetAIAnalysis } from "@/domain/usecase/consulting/model/GetAIAnalysis";
import type { RequestFaceSimilarity } from "@/domain/usecase/consulting/model/RequestFaceSimilarity";
import Utils from "@/application/view-model/util/ViewModelUtil";

@injectable()
export class FaceShiftViewModelImpl implements FaceShiftViewModel {
  data: FaceShiftViewModel["data"] = {
    note: null,
    faceShifts: [],
    influencerPhotos: null,
    option: { eye: [], nose: [], contour: [] },
    similarityMeasuredInfluencer: [],
  };

  output: FaceShiftViewModel["output"] = {
    faceShifts: new BehaviorSubject<AIAnalysis.PersonaShift[] | null>(null),
    isFaceShiftLoading: new Subject<boolean>(),
    faceShiftResultsSlider: new Subject<{ open: boolean; selectedPhoto: AIAnalysis.PersonaShift | null }>(),
    isFaceShiftActive: new Subject<boolean>(),
  };

  constructor(
    readonly note: Consulting.Note,
    readonly uiSystem: UISystemManager,
    private readonly requestPersonaShift: RequestPersonaShift,
    private readonly getAIAnalysis: GetAIAnalysis,
  ) {
    this.data.note = note;
  }

  input: FaceShiftViewModel["input"] = {
    clickFaceShiftHistoryItem: (note, faceShift) => {
      this.data.faceShifts = this.data.faceShifts.map((item) => {
        if (item.id === faceShift.id) {
          return { ...item, isShow: true, isSelected: true };
        } else {
          return { ...item, isShow: true, isSelected: false };
        }
      });

      this.output.faceShifts.next(this.data.faceShifts);
    },

    clickFaceShiftPhoto: (photo) => {
      this.output.faceShiftResultsSlider.next({ open: true, selectedPhoto: photo });
    },

    clickFaceShift: (note) => {
      const photo = note.photos?.find((photo) => photo.type === "FRONTAL");

      if (note.id && photo?.id) {
        let request = 0;
        const faceShiftItems: { noteId: number; photoId: number; operations: { part: PersonaShiftPart; styles: PersonaShiftStyle[] }[] }[] =
          [
            { noteId: note.id, photoId: photo.id, operations: [{ part: "eye", styles: ["inline"] }] },
            { noteId: note.id, photoId: photo.id, operations: [{ part: "eye", styles: ["outline"] }] },
          ];

        this.output.isFaceShiftActive.next(false);
        this.output.isFaceShiftLoading.next(true);

        const sub = from(faceShiftItems)
          .pipe(
            mergeMap((res) => {
              this.output.isFaceShiftActive.next(false);
              return this.requestPersonaShift.execute({ noteId: res.noteId, photoId: res.photoId, operations: res.operations });
            }),
          )
          .subscribe({
            next: ({ analysisId }) => {
              request += 1;
              if (note.id) {
                this.getAnalysisResults(note.id, analysisId, (analysisId) => {});
              }
              if (request === 2) {
                sub.unsubscribe();
              }
            },
            error: () => {
              this.uiSystem.errorHandler.alert.next({ message: "Face Shift 요청을 실패하였습니다." });
              this.output.isFaceShiftActive.next(true);
              sub.unsubscribe();
            },
          });
      }
    },
  };

  event: FaceShiftViewModel["event"] = {
    onGetFaceShift: (noteId) => {
      const sub = this.getAIAnalysis.execute({ noteId }).subscribe({
        next: ({ personaShifts }) => {
          if (personaShifts.length > 0) {
            const inlineResult = personaShifts.filter(
              (faceShift) => faceShift.status === "SUCCESS" && faceShift.result?.operations[0].styles.includes("inline"),
            )[0];
            const outlineResult = personaShifts.filter(
              (faceShift) => faceShift.status === "SUCCESS" && faceShift.result?.operations[0].styles.includes("outline"),
            )[0];

            if (inlineResult.result && outlineResult.result) {
              [inlineResult, outlineResult].forEach((result) => {
                if (result.result) {
                  Utils.createObjectURL(result.result?.imageURL).then((objectURL) => {
                    if (result?.status === "SUCCESS" && result.result) {
                      this.data.faceShifts.push({
                        id: result.id,
                        code: "PERSONA_SHIFT",
                        photoId: result.notePhotoId,
                        status: "SUCCESS",
                        result: {
                          imageURL: result.result?.imageURL,
                          operations: result.result.operations,
                          objURL: objectURL,
                        },
                        isSelected: true,
                        isShow: true,
                      });
                      this.data.faceShifts.sort((faceShift) => {
                        if (faceShift.result?.operations[0].styles[0] === "inline") {
                          return -1;
                        } else {
                          return 1;
                        }
                      });
                      this.output.faceShifts.next([...this.data.faceShifts]);
                    }
                  });
                }
              });
            }

            this.output.isFaceShiftActive.next(false);
          }
          sub.unsubscribe();
        },
        error: () => {
          sub.unsubscribe();
        },
      });
    },
  };

  private getAnalysisResults = (noteId: number, analysisId: number, completion: (analysisId: number) => void) => {
    let requestCount = 0;

    const sub = interval(2000)
      .pipe(
        mergeMap(() => {
          return this.getAIAnalysis.execute({ noteId });
        }),
      )
      .subscribe({
        next: ({ personaShifts }) => {
          requestCount += 1;

          const result = personaShifts.find((item) => item.id === analysisId);

          if (result?.status === "SUCCESS" && result.result) {
            Utils.createObjectURL(result.result.imageURL).then((objectURL) => {
              if (result?.status === "SUCCESS" && result.result) {
                this.data.faceShifts.push({
                  id: result.id,
                  code: "PERSONA_SHIFT",
                  photoId: result.notePhotoId,
                  status: "SUCCESS",
                  result: {
                    imageURL: result.result?.imageURL,
                    operations: result.result.operations,
                    objURL: objectURL,
                  },
                  isSelected: true,
                  isShow: true,
                });
                this.data.faceShifts.sort((faceShift) => {
                  if (faceShift.result?.operations[0].styles[0] === "inline") {
                    return -1;
                  } else {
                    return 1;
                  }
                });
                this.output.faceShifts.next([...this.data.faceShifts]);
                this.output.isFaceShiftLoading.next(false);
                completion(result.id);
              }
            });
            sub.unsubscribe();
          }

          if (requestCount === 30) {
            this.output.isFaceShiftLoading.next(false);
            sub.unsubscribe();
          }
        },
        error: () => {
          this.uiSystem.errorHandler.alert.next({ message: "Face Shift를 실패하였습니다." });
          this.output.isFaceShiftLoading.next(false);
          sub.unsubscribe();
        },
      });
  };
}
