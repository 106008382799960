/**
 * eslint-disable no-console
 *
 * @format
 */

import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { Environment, Logger, LoggerStatus, LoggerLevel, UrgentLevel, LogType } from ".";
import { catchError, map, OperatorFunction, throwError } from "rxjs";

class LoggerImpl implements Logger {
  data: {
    env: Environment;
    loggerLevel: LoggerLevel;
    urgentLevel: UrgentLevel;
  };
  constructor() {
    this.data = {
      loggerLevel: {
        info: 3,
        error: 5,
        warn: 4,
        debug: 1,
      },
      urgentLevel: {
        stg: 3,
        prd: 5,
        dev: 0,
      },
      env: "prd",
    };
  }

  init() {
    if (process.env.ENVIRONMENT && this.isEnvironment(process.env.ENVIRONMENT)) {
      this.data.env = process.env.ENVIRONMENT;
    }

    if (process.env.ENVIRONMENT !== "dev" && process.env.DATADOG_CLIENT_TOKEN) {
      datadogLogs.init({
        clientToken: process.env.DATADOG_CLIENT_TOKEN,
        service: "afoter-app",
        site: "us5.datadoghq.com",
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        env: process.env.ENVIRONMENT,
        version: process.env.WEB_VERSION,
        beforeSend(event) {
          const userData = datadogRum.getUser();
          event.message = `${event.message} user: ${userData ? JSON.stringify(userData) : "비회원"}`;
        },
      });
    }
  }

  /**
   * info log urgent Level 3
   * @param message message content string
   * @param data message context object
   */
  info(logType: LogType, message?: string, ...data: object[]) {
    if (this.isPassUrgentLevel("info")) {
      if (this.data.env === "dev") {
        console.log({ message, ...this.setDataToObject(data) });
      } else {
        if (logType !== "private") ({ message, data });
        datadogLogs.onReady(() =>
          datadogLogs.logger.info(message ?? "", {
            result: this.setDataToObject(data),
          }),
        );
      }
    }
  }

  /**
   * error log urgent Level 5
   * @param message message content string
   * @param data message context object
   */
  error(logType: LogType, message?: string, ...data: object[]) {
    if (this.isPassUrgentLevel("error")) {
      if (this.data.env === "dev") {
        console.error({ message, ...this.setDataToObject(data) });
      } else {
        if (logType !== "private") console.error({ message, data });
        datadogLogs.onReady(() =>
          datadogLogs.logger.error(message ?? "", {
            result: this.setDataToObject(data),
          }),
        );
      }
    }
  }

  /**
   * warn log urgent Level 4
   * @param message message content string
   * @param data message context object
   */
  warn(logType: LogType, message?: string, ...data: object[]) {
    if (this.isPassUrgentLevel("warn")) {
      if (this.data.env === "dev") {
        console.warn({ message, ...this.setDataToObject(data) });
      } else {
        if (logType !== "private") console.warn({ message, data });
        datadogLogs.onReady(() =>
          datadogLogs.logger.warn(message ?? "", {
            result: this.setDataToObject(data),
          }),
        );
      }
    }
  }

  /**
   * debug log urgent Level 1
   * @param message message content string
   * @param data message context object
   */
  debug(logType: LogType, message?: string, ...data: object[]) {
    if (this.isPassUrgentLevel("debug")) {
      if (this.data.env === "dev") {
        console.debug({ message, ...this.setDataToObject(data) });
      } else {
        if (logType !== "private") console.debug({ message, data });
        datadogLogs.onReady(() =>
          datadogLogs.logger.debug(message ?? "", {
            result: this.setDataToObject(data),
          }),
        );
      }
    }
  }

   /**
   * rx operator
   * @param logType
   * @param location
   * @param data
   */
   write<T>(
    logType: LogType,
    location: string,
    ...data: object[]
  ): OperatorFunction<T, T> {
    return (
      map<T, T>((res) => {
        this.info(logType, `${location} success`, { location }, data);
        return res;
      }),
      catchError((err) => {
        this.error(logType, `${location} fail`, { location }, data, { err });
        return throwError(() => err);
      })
    );
  }


  private isEnvironment(envString: string): envString is Environment {
    return envString === "stg" || envString === "prd" || envString === "dev";
  }

  private isPassUrgentLevel(logStatus: LoggerStatus) {
    return this.data.urgentLevel[this.data.env] <= this.data.loggerLevel[logStatus];
  }

  private setDataToObject(data: { [key: string]: any }[]) {
    const result: { [key: string]: any } = {};

    data.forEach((d, i) => {
      if (typeof d === "string") {
        result[`value${i}`] = d;
      } else {
        const key = Object.keys(d)[0];
        result[key] = d[key];
      }
    });

    return result;
  }
}

export default LoggerImpl;
