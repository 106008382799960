import { useState } from "react";
import type { ReactNode } from "react";
import styled from "styled-components";

import colors from "@view/styles/colors";
import type { InputProps } from "../input";
import Typography from "../typography";

interface InputFieldProps extends InputProps {
  type?: "text" | "password" | "number";
  isClearable?: boolean;
  clearIcon?: ReactNode;
  errorMessage?: string;
  onClickClear?: () => void;
  allowClear?: boolean;
  readOnly?: boolean;
  width?: string;
  height?: string;
  value?: string | number;
  focusColor?: string;
  borderColor?: string;
  placeholderColor?: string;
  suffix?: ReactNode;
  onSubmit?: () => void;
  multiLine?: boolean;
  disabled?: boolean;
  disableAutoComplete?: boolean;
  backgroundColor?: string;
}

const InputField: React.FC<InputFieldProps> = (props) => {
  const {
    isClearable = false,
    clearIcon,
    padding = "16px 8px",
    focusColor = colors.neutralLineActive,
    borderColor = colors.neutralLineDefault,
    placeholderColor = colors.neutralGray600,
    value,
    type = "text",
    suffix,
    errorMessage,
    height = "48px",
    multiLine = false,
    disableAutoComplete = false,
    onChange,
    onSubmit,
    ...restProps
  } = props;
  const [blockEnter, setBlockEnter] = useState(false);

  const handlePressKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !blockEnter && onSubmit) {
      setBlockEnter(true);
      onSubmit();
      setTimeout(() => {
        setBlockEnter(false);
      }, 500);
    }
  };

  const handleInputField = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e.target.value);
  };

  const handleTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) onChange(e.target.value);
  };

  return multiLine ? (
    <TextAreaStyled
      onChange={handleTextArea}
      borderColor={borderColor}
      focusColor={focusColor}
      height={height}
      value={value}
      {...restProps}
    />
  ) : (
    <div>
      <InputFieldContainer
        padding={padding}
        focusColor={focusColor}
        borderColor={borderColor}
        height={height}
        {...restProps}
      >
        <InputFieldStyled
          onChange={handleInputField}
          type={type}
          value={value}
          padding={padding}
          focusColor={focusColor}
          placeholderColor={placeholderColor}
          onKeyDown={handlePressKey}
          readOnly={props.readOnly}
          backgroundColor={props.backgroundColor}
          autoComplete={disableAutoComplete ? "new-password" : ""}
          {...restProps}
        />
        {suffix}
      </InputFieldContainer>
      {errorMessage && (
        <Typography.C2
          value={errorMessage}
          color={colors.semanticError}
        />
      )}
    </div>
  );
};

type InputFieldStyledProps = Omit<InputFieldProps, "onChange">;

const InputFieldContainer = styled.div<InputFieldStyledProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 6px;
  /* padding: ${(props) => props.padding}; */
  padding: 0px 8px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: 0px;
  background-color: ${({ backgroundColor }) => backgroundColor ?? "inherit"};
  ${(props) => props.readOnly && `background-color: ${colors.neutralLineDefault};`}

  &:hover {
    border-color: ${(props) => (props.readOnly ? props.borderColor : props.focusColor)};
  }
  &:focus-within {
    border-color: ${(props) => (props.readOnly ? props.borderColor : props.focusColor)};
    border-right-width: 1px;
    outline: 0;
  }
`;

const InputFieldStyled = styled.input<InputFieldStyledProps>`
  display: flex;
  font-size: 12px;
  border: none;
  width: 100%;
  padding: 0px;
  background-color: ${({ backgroundColor }) => backgroundColor ?? "inherit"};
  ${(props) => props.readOnly && `background-color: ${colors.neutralLineDefault};`}
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${(props) => props.placeholderColor};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
  &:disabled {
    color: ${(props) => props.placeholderColor};
    border-color: ${colors.neutralLineDefault} !important;
  }
  &:-webkit-autofill::first-line {
    font-family: SUIT !important;
  }
`;

const TextAreaStyled = styled.textarea<InputFieldStyledProps>`
  font-style: SUIT, sans-serif;
  font-family: inherit;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: ${({ backgroundColor }) => backgroundColor ?? "inherit"};
  outline: none;
  resize: none;
  padding: 16px 8px;
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 6px;
  font-size: 14px;
  overflow: hidden scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
  &::placeholder {
    color: ${colors.neutralGray600};
  }
  &:hover {
    border-color: ${(props) => props.focusColor};
  }
  &:focus {
    border-color: ${(props) => props.focusColor};
  }
  &:disabled {
    color: ${(props) => props.placeholderColor};
    border-color: ${colors.neutralLineDefault} !important;
  }
`;

export default InputField;
