import { injectable } from "inversify";
import { PhotoByFaceLandmarkViewModel } from "@view-model/model/PhotoByFaceLandmarkViewModel";
import type { UISystemManager } from "@/application/ui-system/view-model/model";
import type { DrawConnectorsByMediaPipe } from "@/domain/usecase/classification/model/DrawConnectorsByMediaPipe";
import { EyeClassificationImpl } from "@view-model/model/util/face-classification/EyeClassification";
import { LandmarkType, Coordinate } from "@view-data/FaceLandmark";
import type { GetFaceLandmarks } from "@/domain/usecase/gcs/model/GetFaceLandmarks";
import { Subject } from "rxjs";
@injectable()
export class PhotoByFaceLandmarkViewModelImpl implements PhotoByFaceLandmarkViewModel {
  data: PhotoByFaceLandmarkViewModel["data"] = {
    landmarks: undefined,
  };
  output = {
    landmarks: new Subject<Coordinate[][]>(),
    beforeRatio: new Subject<string>(),
    bestRatio: new Subject<string>(),
  };
  event: {
    onGetLandmark: (jsonURL: string) => void;
    onDrawLandmark: (
      imageEle: HTMLImageElement,
      myRatioCanvas: HTMLCanvasElement,
      goldenRatioCanvas: HTMLCanvasElement,
      faceLandmarkCanvas: HTMLCanvasElement,
      landmarkType: LandmarkType,
      normalizedFaceLandmark?: { x: number; y: number; z: number; visibility: number }[][],
    ) => void;
  };

  constructor(
    readonly landmarkJSONURL: string | undefined,
    readonly uiSystem: UISystemManager,
    private readonly getFaceLandmarksByGCS: GetFaceLandmarks,
    readonly drawFaceLandmarkByMediaPipe: DrawConnectorsByMediaPipe,
  ) {
    this.event = {
      onGetLandmark: (jsonURL: string) => {
        this.getFaceLandmarksByGCS.execute({ url: jsonURL }).subscribe({
          next: ({ landmarks }) => {
            this.data.landmarks = landmarks;
            this.output.landmarks.next(this.data.landmarks);
          },
          error: (err) => {},
        });
      },
      onDrawLandmark: (imageEle, myRatioCanvas, goldenRatioCanvas, faceLandmarkCanvas, landmarkType, normalizedFaceLandmark) => {
        if (this.data.landmarks) {
          const myEyeRatio = new EyeClassificationImpl(imageEle, this.data.landmarks, myRatioCanvas, 1);
          const bestRatio = new EyeClassificationImpl(imageEle, this.data.landmarks, goldenRatioCanvas, 1);

          if (this.data.landmarks) {
            drawFaceLandmarkByMediaPipe
              .execute({
                imageEle,
                canvasEle: faceLandmarkCanvas,
                normalizedFaceLandmarks: this.data.landmarks as any,
                connections: FACIAL_CONTOURING,
                style: {
                  lineWidth: 1,
                  color: "rgba(0,255,224)",
                },
              })
              .subscribe({
                next: () => {},
                error: (err) => {
                  console.log("에러>", err);
                },
              });
          }

          switch (landmarkType) {
            case "EYE_GOLDEN_RATIO":
              myEyeRatio.drawGoldenRatio({
                lineLength: 50,
                lineWidth: 1,
                lineColor: "#FF12E7",
                lineType: "SOLID",
              });
              bestRatio.drawBestGoldenRatio();
              const myGoldenRatio = myEyeRatio.calcGoldenRatio();

              this.output.beforeRatio.next(
                `${myGoldenRatio.좌얼굴} : ${myGoldenRatio.좌눈} : ${myGoldenRatio.중안} : ${myGoldenRatio.우눈} : ${myGoldenRatio.우얼굴}`,
              );
              this.output.bestRatio.next("0.5 : 1 : 1 : 1 :0.5");

              break;

            case "EYE_BALANCE":
            case "EYE_HEIGHT":
              myEyeRatio.drawBalance({
                lineLength: 50,
                lineWidth: 1,
                lineColor: "#FF12E7",
                lineType: "SOLID",
              });
              const ratio = myEyeRatio.calcBalance();
              myEyeRatio.drawBalance({ lineLength: 50, lineWidth: 1, lineColor: "#FF12E7", lineType: "SOLID" });
              bestRatio.drawBestBalance();
              this.output.beforeRatio.next(`${ratio.left} : ${ratio.right}`);
              this.output.bestRatio.next("1 : 1");
              break;

            case "EYE_TAILS_ANGLE":
              myEyeRatio.drawEyeTailAngle({
                lineLength: 50,
                lineWidth: 1,
                lineColor: "#FF12E7",
                lineType: "SOLID",
              });
              bestRatio.drawBestEyeTailAngle();
              const eyeTailsRatio = myEyeRatio.calcEyeTailsRatio();
              this.output.beforeRatio.next(`${eyeTailsRatio.왼눈}° : ${eyeTailsRatio.우눈}°`);
              this.output.bestRatio.next("4° : 4°");
              break;

            case "BABY_FACE":
              myEyeRatio.drawBabyFaceRatio({
                lineLength: 180,
                lineWidth: 1,
                lineColor: "#FF12E7",
                lineType: "SOLID",
              });
              bestRatio.drawBestBabyFaceRatio();
              const babyFaceratio = myEyeRatio.calcBabyFaceRatio();
              this.output.beforeRatio.next(`${babyFaceratio.상안} : ${babyFaceratio.하안}`);
              this.output.bestRatio.next("1 : 0.8");
              break;

            case "ASYMMETRY_RATIO":
              myEyeRatio.drawAsymmetryRatio({ lineLength: 100, lineWidth: 1, lineColor: "#FF12E7", lineType: "SOLID" });
              const asymmetryRatio = myEyeRatio.calcAsymmetryRatio();
              this.output.beforeRatio.next(
                `${asymmetryRatio.좌하단눈각도}° : ${asymmetryRatio.우하단눈각도}° : ${asymmetryRatio.우입술하단각도}° : ${asymmetryRatio.좌입술하단각도}°`,
              );
              bestRatio.drawBestAsymmetryRatio();
              this.output.bestRatio.next("90° : 90° : 90° : 90°");
              break;
          }
        }
      },
    };
  }
}

const FACIAL_CONTOURING = [
  { start: 193, end: 51 },
  { start: 51, end: 220 },
  { start: 220, end: 1 },
  { start: 1, end: 440 },
  { start: 440, end: 281 },
  { start: 281, end: 417 },
  { start: 220, end: 19 },
  { start: 19, end: 440 },
  { start: 440, end: 331 },
  { start: 331, end: 294 },
  { start: 294, end: 460 },
  { start: 460, end: 392 },
  { start: 392, end: 459 },
  { start: 459, end: 94 },
  { start: 94, end: 239 },
  { start: 239, end: 166 },
  { start: 166, end: 240 },
  { start: 240, end: 64 },
  { start: 64, end: 102 },
  { start: 102, end: 220 },
  { start: 198, end: 102 },
  { start: 420, end: 331 },
  { start: 220, end: 64 },
  { start: 338, end: 332 },
  { start: 332, end: 383 },
  { start: 376, end: 394 },
  { start: 394, end: 377 },
  { start: 377, end: 428 },
  { start: 428, end: 200 },
  { start: 200, end: 208 },
  { start: 208, end: 148 },
  { start: 148, end: 152 },
  { start: 152, end: 377 },
  { start: 261, end: 359 },
  { start: 359, end: 466 },
  { start: 466, end: 386 },
  { start: 386, end: 384 },
  { start: 384, end: 362 },
  { start: 362, end: 374 },
  { start: 374, end: 359 },
  { start: 374, end: 261 },
  { start: 261, end: 383 },
  { start: 383, end: 383 },
  { start: 383, end: 466 },
  { start: 383, end: 359 },
  { start: 383, end: 283 },
  { start: 283, end: 295 },
  { start: 295, end: 285 },
  { start: 285, end: 336 },
  { start: 336, end: 293 },
  { start: 293, end: 332 },
  { start: 332, end: 333 },
  { start: 333, end: 293 },
  { start: 333, end: 299 },
  { start: 299, end: 338 },
  { start: 299, end: 336 },
  { start: 293, end: 383 },
  { start: 283, end: 386 },
  { start: 384, end: 285 },
  { start: 291, end: 423 },
  { start: 423, end: 420 },
  { start: 464, end: 349 },
  { start: 417, end: 336 },
  { start: 420, end: 277 },
  { start: 277, end: 464 },
  { start: 464, end: 413 },
  { start: 413, end: 285 },
  { start: 261, end: 280 },
  { start: 280, end: 291 },
  { start: 376, end: 447 },
  { start: 447, end: 383 },
  { start: 383, end: 284 },
  { start: 284, end: 332 },
  { start: 261, end: 447 },
  { start: 423, end: 280 },
  { start: 37, end: 11 },
  { start: 11, end: 267 },
  { start: 267, end: 291 },
  { start: 291, end: 268 },
  { start: 268, end: 13 },
  { start: 13, end: 38 },
  { start: 181, end: 84 },
  { start: 84, end: 314 },
  { start: 314, end: 405 },
  { start: 405, end: 291 },
  { start: 109, end: 103 },
  { start: 103, end: 54 },
  { start: 54, end: 156 },
  { start: 156, end: 227 },
  { start: 227, end: 147 },
  { start: 147, end: 169 },
  { start: 169, end: 208 },
  { start: 156, end: 31 },
  { start: 31, end: 50 },
  { start: 203, end: 198 },
  { start: 198, end: 47 },
  { start: 47, end: 244 },
  { start: 244, end: 189 },
  { start: 189, end: 55 },
  { start: 193, end: 107 },
  { start: 107, end: 55 },
  { start: 55, end: 157 },
  { start: 157, end: 159 },
  { start: 159, end: 246 },
  { start: 246, end: 130 },
  { start: 130, end: 145 },
  { start: 145, end: 133 },
  { start: 133, end: 157 },
  { start: 159, end: 53 },
  { start: 53, end: 156 },
  { start: 156, end: 246 },
  { start: 156, end: 130 },
  { start: 130, end: 31 },
  { start: 31, end: 145 },
  { start: 244, end: 120 },
  { start: 55, end: 65 },
  { start: 65, end: 53 },
  { start: 156, end: 63 },
  { start: 63, end: 107 },
  { start: 107, end: 69 },
  { start: 69, end: 109 },
  { start: 69, end: 104 },
  { start: 104, end: 103 },
  { start: 103, end: 63 },
  { start: 63, end: 104 },
  { start: 103, end: 156 },
  { start: 31, end: 227 },
  { start: 109, end: 338 },
  { start: 107, end: 336 },
  { start: 169, end: 148 },
  { start: 394, end: 428 },
  { start: 50, end: 203 },
  { start: 227, end: 50 },
  { start: 447, end: 280 },
  { start: 194, end: 18 },
  { start: 18, end: 418 },
  { start: 418, end: 406 },
  { start: 406, end: 314 },
  { start: 84, end: 182 },
  { start: 182, end: 194 },
  { start: 394, end: 291 },
  { start: 187, end: 50 },
  { start: 187, end: 169 },
  { start: 280, end: 411 },
  { start: 411, end: 394 },
  { start: 411, end: 376 },
  { start: 187, end: 147 },
  { start: 61, end: 37 },
  { start: 38, end: 61 },
  { start: 61, end: 181 },
  { start: 50, end: 61 },
  { start: 61, end: 203 },
  { start: 169, end: 62 },
  { start: 62, end: 187 },
  { start: 291, end: 411 },
];
