import { ImageProcessor } from "@/image/processor";
import { catchError, from, map, Observable, Subscriber } from "rxjs";
import loadImage from "blueimp-load-image";
import { injectable } from "inversify";
import axios from "axios";
import logger from "@/logger";
import { ImageManager, ObjectURL, UploadGcsImage } from "@/domain/interactor/image-manager";

@injectable()
export default class ImageProcessorImpl implements ImageManager {
  public resize(file: File, type?: "png" | "jpeg"): Observable<UploadGcsImage> {
    return from(axios.post(`/api/sharp?filename=${file.name}&type=${type ?? "jpeg"}`, file)).pipe(
      map((res) => {
        return res.data;
      }),
      catchError((err) => {
        alert("이미지 업로드를 실패하였습니다. 다시 시도해주세요.");
        logger.error("public", "resize", { location: "ImageProcessorImpl" }, { err });
        throw err;
      }),
    );
  }

  createObjectURL(url: string): Observable<ObjectURL> {
    return new Observable<{ originURL: string; objectURL: string }>((observable: Subscriber<{ originURL: string; objectURL: string }>) => {
      async function imageUrlToBlob(url: string) {
        const response = await fetch(url);
        const blob = await response.blob();
        return blob;
      }

      imageUrlToBlob(url).then((blob) => {
        const objURL = URL.createObjectURL(blob);

        observable.next({ originURL: url, objectURL: objURL });
      });
    });
  }

  createURLToFile(url: string): Observable<File> {
    return new Observable<File>((observable: Subscriber<File>) => {
      async function imageUrlToBlob(url: string) {
        const response = await fetch(url);
        const blob = await response.blob();
        return blob;
      }
      imageUrlToBlob(url).then((blob) => {
        const urlSplit = url.split("/").pop();
        const filename = urlSplit?.split("?")[0];

        const metadata = { type: `image/png` };
        const file = new File([blob], encodeURI(filename ?? "unknown"), metadata);
        observable.next(file);
      });
    });
  }

  sortOrientation(file: File): Observable<File> {
    return new Observable<File>((observable: Subscriber<File>) => {
      // file.arrayBuffer().then((arrayBuffer) => {
      //   const blob = new Blob([new Uint8Array(arrayBuffer)], {
      //     type: file.type
      //   });
      //   observable.next(blob);
      // });
      loadImage(file, {
        meta: true,
        orientation: true,
        canvas: true,
      }).then((image) => {
        (image.image as any).toBlob((blob: any) => {
          const rotateFile = new File([blob], file.name, file);

          observable.next(rotateFile);
        }, file.type);
      });
      // observable.next(file);
    });
  }

  limitSize(file: File, size: number, byteUnit?: "KB" | "MB" | "GB" | "TB"): Observable<File> {
    return new Observable<File>((observable: Subscriber<File>) => {
      let sizeLimit: number = size;

      if (byteUnit === "KB") sizeLimit = sizeLimit * 1024;
      if (byteUnit === "MB") sizeLimit = sizeLimit * 1024 * 1024;
      if (byteUnit === "GB") sizeLimit = sizeLimit * 1024 * 1024 * 1024;
      if (byteUnit === "TB") sizeLimit = sizeLimit * 1024 * 1024 * 1024 * 1024;

      if (file.size <= sizeLimit) observable.next(file);
      else {
        observable.error({
          fileSizeErrorLimit: `${size}${byteUnit ? byteUnit : "Byte"}`,
          fileSize: file.size,
        });
      }
    });
  }
}
