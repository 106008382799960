import Typography from "@view/widget/typography";
import styled from "styled-components";
import colors from "@view/styles/colors";

interface FormGroupProps {
  content: React.ReactNode;
  title: string;
  titleContent?: React.ReactNode;
  headerContent?: React.ReactNode;
  isRequired?: boolean;
  helpContent?: string;
  errorContent?: string;
  margin?: string;
  isFlex?: boolean;
}

export const FormGroup: React.FC<FormGroupProps> = (props) => {
  const { title, content, isRequired = false, margin = "0", isFlex = true } = props;

  return (
    <>
      <AdDetailViewInputFormContainer margin={margin} isFlex={isFlex}>
        <InputTitleContainer>
          <Typography.C2 bold value={title} color={colors.neutralGray400} />
          {isRequired && <Typography.C2 color={colors.semanticError} value="*" />}
          {props.titleContent && <TitleContentWrapper>{props.titleContent}</TitleContentWrapper>}
        </InputTitleContainer>
        {typeof content === "string" ? <Typography.B2 value={content} /> : content}
      </AdDetailViewInputFormContainer>
    </>
  );
};

const AdDetailViewInputFormContainer = styled.div<{ margin?: string; isFlex?: boolean }>`
  ${(props) => props.isFlex && `flex: 1;`}
  ${(props) => props.margin && `margin: ${props.margin};`}
`;

const InputTitleContainer = styled.div`
  margin-bottom: 6px;
`;

const TitleContentWrapper = styled.span`
  margin-left: 12px;
`;
