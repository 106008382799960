import styled from "styled-components";
import { ButtonProps } from ".";
import { addAlpha } from "@view/utils/common";
import colors from "@view/styles/colors";

export interface LinedButtonProps extends ButtonProps {
  icon?: React.ReactNode;
  disabledColor?: string;
  isLarge?: boolean;
  padding?: string;
}

const LinedButton = (props: LinedButtonProps) => {
  const { color = colors.neutralBlack, backgroundColor = colors.neutralWhiteDefault, disabledColor = colors.neutralGray600, isLarge = true, ...restProps } = props;
  return (
    <LinedButtonWrapper>
      <LinedButtonStyled color={color} backgroundColor={backgroundColor} isLarge={isLarge} {...restProps}>
        {props.icon}
        {props.text}
      </LinedButtonStyled>
    </LinedButtonWrapper>
  );
};

export const Large = ({ ...props }: LinedButtonProps) => {
  return <LinedButton isLarge={true} {...props} />;
};

export const Small = ({ ...props }: LinedButtonProps) => {
  return <LinedButton isLarge={false} {...props} />;
};

const LinedButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const LinedButtonStyled = styled.button<LinedButtonProps>`
  background-color: ${(props) => props.backgroundColor};
  border-radius: 4px;
  border: ${(props) => (props.isLarge ? 1.5 : 1)}px solid ${(props) => props.color};
  height: ${(props) => (props.isLarge ? props.height ?? "48" : props.height ?? "32")}px;
  width: ${(props) => (props.width ? `${props.width}px` : "100%")};
  /* ${(props) => props.width && `width: ${props.width}px;`} */
  padding: ${(props) => (props.padding ? props.padding : props.isLarge ? "12px 16px" : "7px 14px")};
  color: ${(props) => props.color};
  font-size: ${(props) => (props.fontSize ? props.fontSize : props.isLarge ? 16 : 14)}px;
  line-height: 130%;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 4px;
  :hover {
    cursor: pointer;
    border-color: ${(props) => addAlpha(props.color!, 0.7)};
    opacity: 0.7;
  }
  &:disabled {
    opacity: 1 !important;
    color: ${(props) => props.disabledColor};
    border-color: ${(props) => props.disabledColor};
    cursor: not-allowed;
  }
`;
