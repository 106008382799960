import { inject, injectable } from "inversify";
import {
  UISystemManager,
  type LoadingHandler,
  APP_STATE_TYPES,
  type PopupHandler,
  type ErrorHandler,
  type ScrollHandler,
  type ToastHandler,
} from "./model";

@injectable()
export default class UISystemManagerImpl implements UISystemManager {
  constructor(
    @inject(APP_STATE_TYPES.PopupHandler) readonly popupHandler: PopupHandler,
    @inject(APP_STATE_TYPES.LoadingHandler)
    readonly loadingHandler: LoadingHandler,
    @inject(APP_STATE_TYPES.ErrorHandler) readonly errorHandler: ErrorHandler,
    @inject(APP_STATE_TYPES.ScrollHandler) readonly scrollHandler: ScrollHandler,
    @inject(APP_STATE_TYPES.ToastHandler) readonly toastHandler: ToastHandler,
  ) {
    this.loadingHandler = loadingHandler;
    this.popupHandler = popupHandler;
    this.errorHandler = errorHandler;
    this.scrollHandler = scrollHandler;
    this.toastHandler = toastHandler;
  }
}
