import styled from "styled-components";
import Icon from "@icon/index";
import { Typo } from "@view/widget";

interface MenuItemProps {
  name: string;
  active: boolean;
  expanded: boolean;
  icon: React.ReactNode;
}

export const MenuItem: React.FC<MenuItemProps> = ({ name, expanded, active, icon }) => {
  return (
    <ItemWrapper
      $active={active}
      $expanded={expanded}
    >
      <IconWrapper>{icon}</IconWrapper>

      {expanded ? (
        <TextWrapper>
          <Typo.B2
            color={active ? "#033632" : "#8D9797"}
            value={name}
          />
        </TextWrapper>
      ) : null}
    </ItemWrapper>
  );
};

const ItemWrapper = styled.div<{ $active: boolean; $expanded: boolean }>`
  width: 100%;
  padding: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0 6px;

  border-radius: ${(props) => (props.$active ? "8px" : "0px")};
  background-color: ${(props) => (props.$active ? (props.$expanded ? "#E7EBDA" : "#ffffff") : "transparent")};
  &:hover {
    background-color: ${(props) => (props.$expanded ? "#E7EBDA" : "#36766D")};
    border-radius: 8px;
    color: #033632;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextWrapper = styled.div`
  min-width: 93px;
  overflow-x: hidden;
  text-align: left;
`;
