import type { AdRepository } from "@/domain/interactor/repository/AdRepository";
import { inject, injectable } from "inversify";
import { map, Observable } from "rxjs";
import {
  UpdateReservationMemoByAdvertise,
  UpdateReservationMemoByAdvertiseInput,
  UpdateReservationMemoByAdvertiseOutput,
} from "./model/UpdateReservationMemoByAdvertise";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class UpdateReservationMemoByAdvertiseImpl implements UpdateReservationMemoByAdvertise {
  constructor(@inject(TYPES.AdRepository) private readonly adRepository: AdRepository) {}

  execute = (input: UpdateReservationMemoByAdvertiseInput): Observable<UpdateReservationMemoByAdvertiseOutput> => {
    return this.adRepository.updateReservationMemo(input.noteId, input.memo).pipe(
      map((res) => {
        return {
          advertiseConsultingNoteId: res.advertisementDBId,
        };
      }),
    );
  };
}
