import { injectable } from "inversify";
import { FaceStyleViewModel } from "./model/FaceStyleViewModel";
import type { UISystemManager } from "../ui-system/view-model/model";
import type { GetFaceStyles } from "@/domain/usecase/consulting/model/GetFaceStyles";
import { FaceFit } from "@/application/view-data";
import { Subject } from "rxjs";

@injectable()
export class FaceStyleViewModelImpl implements FaceStyleViewModel {
  data: FaceStyleViewModel["data"] = {
    faceStyles: [],
  };
  output: FaceStyleViewModel["output"] = {
    faceStyles: new Subject<FaceFit.FaceStyle[]>(),
    selectedFaceStyle: new Subject<FaceFit.FaceStyle | null>(),
  };

  constructor(readonly uiSystem: UISystemManager, private readonly getFaceStyles: GetFaceStyles) {
    this.init();
  }

  input: FaceStyleViewModel["input"] = {
    clickFaceStyle: (style) => {
      this.output.selectedFaceStyle.next(null);
      const selectedFaceStyle = this.data.faceStyles.find((faceStyle) => faceStyle.id === style.id);
      if (selectedFaceStyle) {
        this.output.selectedFaceStyle.next(selectedFaceStyle);
      }
    },
  };

  private init = () => {
    const sub = this.getFaceStyles.execute().subscribe({
      next: ({ items }) => {
        items.sort((a, b) => {
          if (a.ordinal > b.ordinal) {
            return 1;
          } else if (a.ordinal < b.ordinal) {
            return -1;
          } else {
            return 0;
          }
        });
        this.data.faceStyles = items;
        this.output.faceStyles.next(items);
        this.output.selectedFaceStyle.next(items[0]);
        sub.unsubscribe();
      },
      error: () => {
        sub.unsubscribe();
      },
    });
  };
}
