import Icon from "@icon/index";
import colors from "@view/styles/colors";
import { Layout } from "@view/widget";
import Typography from "@view/widget/typography";
import { useState, useEffect } from "react";
import styled from "styled-components";

interface ToolTipProps {
  toolTipText: string;
}

const Tooltip: React.FC<ToolTipProps> = (props) => {
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  const onClickTooltipIcon = () => {
    setOpenTooltip(true);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (openTooltip) {
      timeout = setTimeout(() => {
        setOpenTooltip(false);
      }, 3000);
    }
    return () => clearTimeout(timeout);
  }, [openTooltip]);

  return (
    <Layout.Box position="relative">
      <Layout.Box
        onClick={onClickTooltipIcon}
        sx={{
          cursor: "pointer",
        }}
      >
        <Icon.QuestionLine
          xsmall
          color="neutralGray600"
        />
      </Layout.Box>
      {openTooltip && (
        <TooltipMessageBox>
          <Typography.C1
            value={props.toolTipText}
            color={colors.neutralWhiteDefault}
          />
        </TooltipMessageBox>
      )}
    </Layout.Box>
  );
};

export default Tooltip;

const TooltipMessageBox = styled.div`
  z-index: 50;
  position: absolute;
  width: 296px;
  background-color: #3d588f;
  padding: 10px 12px;
  border-radius: 8px;
  left: 25px;
  top: -155%;
  &::after {
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid #3d588f;
    border-bottom: 10px solid transparent;
    content: "";
    position: absolute;
    top: 30%;
    left: -15px;
  }
`;
