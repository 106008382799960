import type { Login } from "@/domain/usecase/auth/model/Login";
import logger from "@/logger";
import { injectable } from "inversify";
import { Subject } from "rxjs";
import { AuthorizationViewModel } from "./model/AuthorizationViewModel";

@injectable()
export class AuthorizationViewModelImpl implements AuthorizationViewModel {
  input: { authorizeByCode: (code: string, redirectUri: string) => void };
  route: { toHome: Subject<void>; toLogin: Subject<void> };

  constructor(private readonly ucLogin: Login) {
    this.route = {
      toHome: new Subject<void>(),
      toLogin: new Subject<void>(),
    };

    this.input = {
      authorizeByCode: (code, redirectUri) => {
        this.ucLogin
          .execute({
            code,
            redirectUri,
          })
          .subscribe({
            next: () => {
              this.route.toHome.next();
            },
            error: (error) => {
              alert(error);

              this.route.toLogin.next();

              logger.error(error);
            },
          });
      },
    };
  }
}
