import { inject, injectable } from "inversify";
import { Observable, of } from "rxjs";
import { GetConsultationKeywords, GetConsultationKeywordsInput, GetConsultationKeywordsOutput } from "./model/GetConsultationKeywords";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class GetConsultationKeywordsImpl implements GetConsultationKeywords {
  private data: GetConsultationKeywordsOutput = [
    {
      category: "체크포인트",
      keywords: [
        { keyword: "고민", subKeywords: [] },
        { keyword: "부작용", subKeywords: [] },
        { keyword: "주의", subKeywords: [] },
        { keyword: "통증", subKeywords: [] },
        { keyword: "안전", subKeywords: [] },
        { keyword: "재수술", subKeywords: [] },
      ],
    },
    {
      category: "수술",
      keywords: [
        { keyword: "수술", subKeywords: [] },
        { keyword: "계획", subKeywords: [] },
        { keyword: "방법", subKeywords: [] },
        { keyword: "효과", subKeywords: [] },
        { keyword: "기대", subKeywords: [] },
        { keyword: "회복", subKeywords: [] },
      ],
    },
  ];

  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute = (): Observable<GetConsultationKeywordsOutput> => {
    return of(this.data);
  };
}
