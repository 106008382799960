import { UISystemManager } from "@/application/ui-system/view-model/model";
import { useRouter as nextRouter } from "next/router";
import { ParsedUrlQueryInput } from "querystring";

interface RouterProps {
  pathname?: string;
  query?: ParsedUrlQueryInput;
  uiSystem?: UISystemManager;
  isShallow?: boolean;
}

const useRouter = () => {
  const router = nextRouter();

  const push = ({ pathname, query, uiSystem, isShallow }: RouterProps) => {
    uiSystem?.loadingHandler.backdropLoading.next(true);

    router
      .push({ pathname, query }, undefined, { shallow: isShallow })
      .then(() => {
        uiSystem?.loadingHandler.backdropLoading.next(false);
      })
      .finally(() => {
        uiSystem?.loadingHandler.backdropLoading.next(false);
      });
  };

  const replace = ({
    pathname,
    query,
    scrollTransition,
    uiSystem,
  }: {
    pathname?: string;
    query?: ParsedUrlQueryInput;
    scrollTransition?: boolean;
    uiSystem?: UISystemManager;
  }) => {
    uiSystem?.loadingHandler.backdropLoading.next(true);
    router
      .replace({ pathname, query }, undefined, {
        scroll: scrollTransition ?? true,
      })
      .then(() => {
        uiSystem?.loadingHandler.backdropLoading.next(false);
      })
      .finally(() => {
        uiSystem?.loadingHandler.backdropLoading.next(false);
      });
  };

  const back = () => {
    router.back();
  };

  return {
    pathname: router.pathname,
    query: router.query,
    push,
    replace,
    back,
  };
};

export default useRouter;
