import { Subject } from "rxjs";
import { UISystemManager } from "../ui-system/view-model/model";
import { ContactManagerViewModel } from "./model/ContactManagerViewModel";
import { Hospital } from "@/application/view-data";
import { GetHospital } from "@/domain/usecase/hospital/model/GetHospital";
import Utils from "./util/ViewModelUtil";

export class ContactManagerViewModelImpl implements ContactManagerViewModel {
  data: ContactManagerViewModel["data"] = {
    contacts: null,
  };

  output: ContactManagerViewModel["output"] = { contacts: new Subject<Hospital.Contact[] | null>() };

  constructor(readonly uiSystem: UISystemManager, private readonly getHospital: GetHospital) {
    this.init();
  }

  private init() {
    const sub = this.getHospital.execute({ infoTypes: ["CONTACTS"] }).subscribe({
      next: ({ contacts }) => {
        if (contacts) {
          this.data.contacts = contacts.map((contact) => {
            return contact;
          });
          this.data.contacts = this.data.contacts.filter((contact) => contact.type === "EVENT");
          this.output.contacts.next(this.data.contacts);
        }

        sub.unsubscribe();
      },
      error: (error) => {
        if (error?.error_message === "user status is not activated") {
          this.uiSystem.errorHandler.alert.next({ message: "비활성화된 계정입니다.\n관리자에게 문의해주세요." });
        }
        sub.unsubscribe();
      },
    });
  }
}
