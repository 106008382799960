import Button, { ButtonProps } from "@view/widget/button";
import Icon from "@icon/index";
import colors from "@view/styles/colors";

interface CancelButtonProps extends Partial<ButtonProps> {
  onClick: () => void;
  text?: string;
}

const CancelButton: React.FC<CancelButtonProps> = ({ onClick, text = "취소", color = colors.neutralBlack, ...props }) => {
  return <Button.Lined.Large icon={<Icon.Cancel color="neutralBlack" />} text={text} onClick={onClick} color={color} {...props} />;
};

export default CancelButton;
