import { injectable } from "inversify";
import { Subject } from "rxjs";
import { ToastHandler } from "./model";

@injectable()
export default class ToastHandlerImpl implements ToastHandler {
  toast = new Subject<{
    message: string;
    position?: {
      vertical: "bottom" | "top";
      horizontal: "left" | "center" | "right";
    };
    type: "SUCCESS" | "WARNING";
    noAutoHide?: boolean;
  }>();
}
