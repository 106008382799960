import { useEffect, useState } from "react";
import { CenterModalFrame } from "@view/widget/modal";
import { InputField } from "@view/widget/input";
import { Layout, Typo } from "@view/widget";
import colors from "@view/styles/colors";
import Button from "@view/widget/button";

interface MemoModalProps {
  open: boolean;
  memo: string | null;
  noteId: number | null;
  clickClose: () => void;
  clickConfirm: (noteId: number, memo: string) => void;
}

const MemoModal: React.FC<MemoModalProps> = ({ open, memo, noteId, clickClose, clickConfirm }) => {
  const [textareaValue, setTextareaValue] = useState<string>("");

  useEffect(() => {
    if (open) {
      setTextareaValue(memo ? memo : "");
    }
  }, [open]);

  return (
    <CenterModalFrame
      open={open}
      sx={{
        borderRadius: "20px",
      }}
      paperProps={{
        width: "440px",
      }}
      close={clickClose}
    >
      <Layout.Stack>
        <Layout.Box padding="24px 30px 16px">
          <Typo.T2
            bold
            value="메모 저장"
          />
        </Layout.Box>
        <Layout.Box padding="16px 30px">
          <InputField
            multiLine
            width="100%"
            height="125px"
            placeholder="상담 메모를 입력하세요"
            value={textareaValue}
            backgroundColor={colors.neutralBgActive}
            borderColor={colors.neutralBgActive}
            onChange={setTextareaValue}
          />
        </Layout.Box>
        <Layout.Stack
          direction="row"
          gap="8px"
          padding="16px 30px 24px"
          justifyContent="flex-end"
        >
          <Button.Rounded.Large
            backgroundColor={colors.drwo_bg_prim_point}
            color={colors.drwo_prim_disabled_600}
            width={73}
            height={36}
            text="취소"
            onClick={clickClose}
          />
          <Button.Rounded.Large
            backgroundColor={colors.drwo_prim_green_600}
            width={73}
            height={36}
            text="확인"
            onClick={() => {
              if (noteId) {
                clickConfirm(noteId, textareaValue);
              }
            }}
          />
        </Layout.Stack>
      </Layout.Stack>
    </CenterModalFrame>
  );
};

export default MemoModal;
