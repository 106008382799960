import { EyeClassification, Coordinate, ConnectionPoint, DenormalizedCoordinate, CanvasLineStyle, CanvasLineSyleBetweenPoints } from ".";

export class CanvasDrawer {
  private canvasEle: HTMLCanvasElement;
  private ctxs: CanvasRenderingContext2D[];

  constructor(imageEle: HTMLImageElement, canvasEle: HTMLCanvasElement, numOfCanvases: number) {
    this.canvasEle = canvasEle;
    canvasEle.width = imageEle.width;
    canvasEle.height = imageEle.height;

    const numbers = [...Array(numOfCanvases)].map((_, i) => i + 1);

    this.ctxs = numbers.map((_, i) => {
      if (i === 0) {
        const originCtx = canvasEle.getContext("2d");
        return originCtx as CanvasRenderingContext2D;
      } else {
        const newCloneNode = canvasEle.cloneNode() as HTMLCanvasElement;
        if (newCloneNode && canvasEle.parentNode) {
          canvasEle.parentNode.insertBefore(newCloneNode, canvasEle); // 새로운 canvas 요소를 이미 있는 canvas 요소 앞에 추가
          const ctx = newCloneNode.getContext("2d");
          return ctx as CanvasRenderingContext2D;
        } else {
          const originCtx = canvasEle.getContext("2d");
          return originCtx as CanvasRenderingContext2D;
        }
      }
    });
  }

  drawLineFromPoint(canvasNumber: number, center: Coordinate, type: "VERTICAL" | "HORIZONTAL", lineStyle: CanvasLineStyle) {
    const ctx = this.ctxs[canvasNumber];
    ctx.strokeStyle = lineStyle.lineColor;
    ctx.lineWidth = lineStyle.lineWidth;
    if (lineStyle.lineType === "DOT") {
      ctx.setLineDash([5, 5]);
    }

    const centerX = center.x;
    const centerY = center.y;
    const halfLength = lineStyle.lineLength / 2;

    let startTime: number;
    const animate = (timestamp: number) => {
      if (!startTime) startTime = timestamp;

      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / 150, 1);

      if (type === "VERTICAL") {
        const currentY = centerY - halfLength + lineStyle.lineLength * progress;
        ctx.beginPath();
        ctx.moveTo(centerX, centerY - halfLength);
        ctx.lineTo(centerX, currentY);
        ctx.stroke();

        if (progress < 1) {
          requestAnimationFrame(animate);
        }
      } else if (type === "HORIZONTAL") {
        const currentX = centerX - halfLength + lineStyle.lineLength * progress;
        ctx.beginPath();
        ctx.moveTo(centerX - halfLength, centerY);
        ctx.lineTo(currentX, centerY);
        ctx.stroke();

        if (progress < 1) {
          requestAnimationFrame(animate);
        }
      }
    };

    requestAnimationFrame(animate);
  }

  drawLineBetweenPoints(canvasNumber: number, point1: Coordinate, point2: Coordinate, lineStyle: CanvasLineSyleBetweenPoints) {
    const ctx = this.ctxs[canvasNumber];
    ctx.strokeStyle = lineStyle.lineColor;
    ctx.lineWidth = lineStyle.lineWidth;
    if (lineStyle.lineType === "DOT") {
      ctx.setLineDash([2, 2]);
    }

    const startX = point1.x;
    const startY = point1.y;
    const endX = point2.x;
    const endY = point2.y;

    let startTime: number;
    const animate = (timestamp: number) => {
      if (!startTime) startTime = timestamp;

      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / 150, 1);

      const currentX = startX + (endX - startX) * progress;
      const currentY = startY + (endY - startY) * progress;

      ctx.beginPath();
      ctx.moveTo(startX, startY);
      ctx.lineTo(currentX, currentY);
      ctx.stroke();

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  }

  drawTextBetweenPoints(
    canvasNumber: number,
    text: string,
    point1: Coordinate,
    point2: Coordinate,
    style: { x: number; y: number; fontSize: number },
  ) {
    const ctx = this.ctxs[canvasNumber];
    const centerX = (point1.x + point2.x) / 2;
    const centerY = (point1.y + point2.y) / 2;

    ctx.font = `${style.fontSize ?? 14}px SUIT`;
    ctx.fillStyle = "#fff";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";

    ctx.fillText(text, centerX + style?.x ?? 0, centerY + style?.y ?? 0);
  }

  clearCanvas(canvasNumber?: number) {
    if (canvasNumber) {
      const selctedCtx = this.ctxs[canvasNumber];
      selctedCtx.clearRect(0, 0, selctedCtx.canvas.width, selctedCtx.canvas.height);
    } else {
      this.ctxs.forEach((ctx, i) => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      });
    }
  }

  drawBrokenLine(canvasNumber: number, angle: number, lineStyle: CanvasLineStyle) {
    const ctx = this.ctxs[canvasNumber];

    if (lineStyle.lineType === "DOT") {
      ctx.setLineDash([2, 2]);
    } else {
      ctx.setLineDash([]);
    }

    // 캔버스 중심 계산
    const centerX = ctx.canvas.width / 2 + ctx.canvas.width * 0.4;
    const centerY = ctx.canvas.height / 2 + ctx.canvas.height * 0.33;

    // 아래로 40 정도 뻗는 직선 그리기
    const lineLength = lineStyle.lineLength; // 직선의 길이
    const endX = centerX; // X 좌표는 중심점과 같음
    const endY = centerY + lineLength; // 아래로 40 뻗음

    // 위의 선의 길이 계산
    const angleInRadians = (angle * Math.PI) / 180;
    const upperLineLength = lineLength;

    // 위의 선 그리기 (각도 angle 만큼 꺾임)
    const upperEndX = centerX - upperLineLength * Math.sin(angleInRadians);
    const upperEndY = centerY - upperLineLength * Math.cos(angleInRadians);

    // 선 색상 설정
    ctx.strokeStyle = lineStyle.lineColor;

    // 선 두께 설정
    ctx.lineWidth = 2;

    const rectSize = 80;
    // 사각형의 시작 좌표 계산
    // 사각형의 시작 좌표 계산
    const rectStartX = centerX - rectSize / 2 - 20; // 중심점으로부터 좌로 10 이동
    const rectStartY = centerY - rectSize / 2 + 5;
    // border-radius 설정
    const borderRadius = 20;
    // 사각형 그리기
    ctx.fillStyle = "rgba(0, 0, 0, 0.50)";
    ctx.beginPath();
    ctx.moveTo(rectStartX + borderRadius, rectStartY);
    ctx.lineTo(rectStartX + rectSize - borderRadius, rectStartY);

    ctx.quadraticCurveTo(rectStartX + rectSize, rectStartY, rectStartX + rectSize, rectStartY + borderRadius);

    ctx.lineTo(rectStartX + rectSize, rectStartY + rectSize - borderRadius);

    ctx.quadraticCurveTo(rectStartX + rectSize, rectStartY + rectSize, rectStartX + rectSize - borderRadius, rectStartY + rectSize);
    ctx.lineTo(rectStartX + borderRadius, rectStartY + rectSize);

    ctx.quadraticCurveTo(rectStartX, rectStartY + rectSize, rectStartX, rectStartY + rectSize - borderRadius);
    ctx.lineTo(rectStartX, rectStartY + borderRadius);

    ctx.quadraticCurveTo(rectStartX, rectStartY, rectStartX + borderRadius, rectStartY);

    ctx.closePath();
    ctx.fill();

    ctx.fillStyle = "white";
    ctx.font = "15px SUIT";
    ctx.textAlign = "right";
    ctx.fillText((180 - angle).toString() + "°", centerX - 10, centerY + 13);

    // 아랫선 그리기
    ctx.beginPath();
    ctx.moveTo(centerX, centerY); // 중심점에서 시작
    ctx.lineTo(endX, endY); // 아래로 40 정도 뻗는 직선 그리기
    ctx.stroke();

    // 위의 선 그리기

    ctx.beginPath();
    ctx.moveTo(centerX, centerY); // 중심점에서 시작
    ctx.lineTo(upperEndX, upperEndY); // 위의 선 그리기
    ctx.stroke();
  }
}
