import colors from "@view/styles/colors";
import styled from "styled-components";
import Typography from "../typography";
import { ButtonProps } from ".";

interface RoundedButtonProps extends ButtonProps {
  isLarge?: boolean;
  borderColor?: string;
}

const RoundedButton = (props: RoundedButtonProps) => {
  const { text, color = colors.neutralWhiteDefault, backgroundColor = colors.neutralBlack, ...restProps } = props;
  return (
    <RoundedButtonStyled
      color={color}
      backgroundColor={backgroundColor}
      {...restProps}
    >
      {props.isLarge ? (
        <Typography.B2
          bold
          value={text}
        />
      ) : (
        <Typography.C1
          bold
          value={text}
        />
      )}
    </RoundedButtonStyled>
  );
};

export const Large: React.FC<RoundedButtonProps> = (props) => {
  return (
    <RoundedButton
      isLarge={true}
      {...props}
    />
  );
};

export const Small: React.FC<RoundedButtonProps> = (props) => {
  return (
    <RoundedButton
      isLarge={false}
      {...props}
    />
  );
};

const RoundedButtonStyled = styled.button<Pick<RoundedButtonProps, "backgroundColor" | "color" | "borderColor" | "height" | "width">>`
  ${(props) => !props.width && `padding: 4px 12px;`}
  color: ${(props) => props.color};
  border: ${(props) => (props.borderColor ? `1px solid ${props.borderColor}` : "none")};
  border-radius: 30px;
  background-color: ${(props) => props.backgroundColor};
  ${(props) => props.width && `width: ${props.width}px;`}
  ${(props) => props.height && `height: ${props.height}px;`}
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
    border-color: ${colors.neutralGray800};
    background-color: ${colors.neutralGray800};
    color: ${colors.neutralGray600};
  }
`;

export default RoundedButton;
