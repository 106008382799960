import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled as muiStyled } from "@mui/material/styles";
import styled from "styled-components";

interface SwitchButtonProps {
  isChecked: boolean;
  width?: number;
  height?: number;
  translateX?: number;
  borderRadius?: number;
  disabled?: boolean;
  click: (checked: boolean) => void;
}

const SwitchButton: React.FC<SwitchButtonProps> = ({ isChecked, width, height, translateX, borderRadius, disabled, click }) => {
  return (
    <SwitchButtonLayout>
      <FormControlLabel
        sx={{ marginRight: 0 }}
        control={
          <IOSSwitch
            checked={isChecked}
            width={width}
            height={height}
            borderRadius={borderRadius}
            translateX={translateX}
            disabled={disabled}
          />
        }
        label=""
        disabled={disabled}
        onChange={(_, checked) => {
          click(checked);
        }}
      />
    </SwitchButtonLayout>
  );
};

export default SwitchButton;

const SwitchButtonLayout = styled.div`
  min-width: 58px;
`;

interface IOSSwitchProps extends SwitchProps {
  width?: number;
  height?: number;
  translateX?: number;
  borderRadius?: number;
  disabled?: boolean;
}

const IOSSwitch = muiStyled((props: IOSSwitchProps) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme, width = 42, height = 26, translateX, borderRadius, disabled }) => {
  const thumbSize = height - 4;
  const calculatedTranslateX = translateX !== undefined ? translateX : width - thumbSize - 4;
  const calculatedBorderRadius = borderRadius !== undefined ? borderRadius : height / 2;

  return {
    width,
    height,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: `translateX(${calculatedTranslateX}px)`,
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#1C5C56",
          opacity: 1,
          border: 0,
        },
      },
      "&.Mui-disabled.Mui-checked + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : disabled ? "#8D9797" : "#1C5C56",
        opacity: 1,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        backgroundColor: "#F3F4F0",
        opacity: 1,
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        backgroundColor: "#fff",
        color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: thumbSize,
      height: thumbSize,
    },
    "& .MuiSwitch-track": {
      borderRadius: calculatedBorderRadius,
      backgroundColor: theme.palette.mode === "light" ? "#F3F4F0" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  };
});
