import { UpdateBestPracticeExposureStatus, UpdateBestPracticeExposureStatusInput } from "./model/UpdateBestPracticeExposureStatus";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { inject, injectable } from "inversify";
import { Observable } from "rxjs";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class UpdateBestPracticeExposureStatusImpl implements UpdateBestPracticeExposureStatus {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute(input: UpdateBestPracticeExposureStatusInput): Observable<void> {
    return this.operationConsultingRepository.updateBestPracticeExposureStatus(input.exposureOn, input.exposureOff);
  }
}
