import React from "react";
import ComponentButton from "./ComponentButton";
import styled from "styled-components";

interface PrimaryProps {
  label: string;
  size: "LARGE" | "MEDIUM" | "SMALL";
  disabled?: boolean;
  click: () => void;
}

const Primary: React.FC<PrimaryProps> = ({ label, size, disabled, click }) => {
  const setSize = () => {
    if (size === "LARGE") {
      return 36;
    } else if (size === "MEDIUM") {
      return 30;
    } else {
      return 22;
    }
  };

  const setFontSize = () => {
    if (size === "LARGE") {
      return 14;
    } else if (size === "MEDIUM") {
      return 11;
    } else {
      return 11;
    }
  };

  const setPaddingInline = () => {
    if (size === "LARGE") {
      return 24;
    } else if (size === "MEDIUM") {
      return 12;
    } else {
      return 12;
    }
  };

  return (
    <ComponentButton
      component={
        <PrimaryLayout
          $height={setSize()}
          $paddingInline={setPaddingInline()}
          $disabled={disabled}
        >
          <Label $fontSize={setFontSize()}>{label}</Label>
        </PrimaryLayout>
      }
      borderRadius={99}
      disabled={disabled}
      onClick={click}
    />
  );
};

export default Primary;

const PrimaryLayout = styled.div<{ $height: number; $disabled?: boolean; $paddingInline: number }>`
  height: ${({ $height }) => $height}px;
  width: fit-content;
  border-radius: 99px;
  background-color: ${({ $disabled }) => ($disabled ? "#E4E4E4" : "#1c5c56")};
  padding-inline: ${({ $paddingInline }) => $paddingInline}px;
`;

const Label = styled.div<{ $fontSize: number; $disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: ${({ $fontSize }) => $fontSize}px;
  color: ${({ $disabled }) => ($disabled ? "#ABABAB" : "#fff")};
`;
