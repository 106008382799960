import styled from "styled-components";
import { Layout } from "@view/widget";
import Typography from "@view/widget/typography";
import Image from "next/image";
import Link from "next/link";
import { NextRouter, useRouter } from "next/router";

const FooterHeight = 80;
const PARTNER_USER_GUIDE_LINK = "https://afoter-partner.notion.site/ver-23-07-12-c5d7f1c311c54a2a8e7719af98dddc49";
const PRIVACY_POLICY_LINK = "https://app.catchsecu.com/document/P/b8d91226f90ae94";
const AD_GUIDE_LINK = "https://afoter-partner.notion.site/ver-22-12-19-1790a005852a42b8abaa33a19ef7a804";

export const FooterView: React.FC = () => {
  const router: NextRouter = useRouter();

  return (
    <FooterLayout>
      <FooterInnerLayout>
        <Layout.Stack
          direction={"row"}
          gap={"30px"}
        >
          <Image
            src={"/image/caremind-footer-logo.png"}
            alt={"caremind-logo"}
            width={120}
            height={18}
          />
          <Layout.Stack gap={"8px"}>
            <Layout.Stack
              direction={"row"}
              gap={"14px"}
              alignItems={"center"}
            >
              <Typography.B2
                value="케어마인드"
                color="#787878"
                bold
              />
              <Typography.C2
                value="대표이사 | 신윤제"
                color="#787878"
                bold
              />
              <Typography.C2
                value="사업자등록번호 | 417-81-51555"
                color="#787878"
                bold
              />
              <Typography.C2
                value="대전광역시 유성구 테크노4로17, A동 520호 (관평동, 비즈센터)"
                color="#787878"
                bold
              />
            </Layout.Stack>
            <Layout.Stack
              direction={"row"}
              gap={"14px"}
              alignItems={"center"}
            >
              <Typography.B2
                value="고객센터"
                color="#787878"
                bold
              />
              <Typography.C2
                value="02-6673-5853"
                color="#787878"
                bold
              />
              <Typography.C2
                value="afoter@icaremind.com"
                color="#787878"
                bold
              />
              <Link
                href={PRIVACY_POLICY_LINK}
                target="_blank"
              >
                <Typography.C2
                  value="개인정보처리방침"
                  color="#787878"
                  bold
                />
              </Link>
            </Layout.Stack>
          </Layout.Stack>
        </Layout.Stack>
      </FooterInnerLayout>
    </FooterLayout>
  );
};

const FooterLayout = styled.section`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 1024px;
  height: ${FooterHeight}px;
  background-color: #1e2527;
`;

const FooterInnerLayout = styled.section`
  position: relative;
  margin: auto;
  width: 1000px;
  padding: 10px 0px;
`;

const LogoLayout = styled.span`
  position: absolute;
  left: 0px;
  top: 10px;
`;

const FooterContent = styled.section`
  position: absolute;
  left: 150px;
  top: 10px;
`;

const FooterRowContent = styled.ul`
  align-items: center;
  overflow: hidden;
`;
