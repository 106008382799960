import { TYPES } from "@/data/interactor/repository/index.container.type";
import type { DoctorRepository } from "@/domain/interactor/repository/DoctorRepository";
import { inject, injectable } from "inversify";
import { Observable } from "rxjs";
import { AddDoctor, AddDoctorInput, AddDoctorOutput } from "./model/AddDoctor";

@injectable()
export class AddDoctorImpl implements AddDoctor {
  constructor(@inject(TYPES.DoctorRepository) private readonly doctorRepository: DoctorRepository) {}

  execute(input: AddDoctorInput): Observable<AddDoctorOutput> {
    return this.doctorRepository.createDoctor(input);
  }
}
