import type { AdAPI } from "@/data/remote/api/ad/model/AdAPI";
import * as Entity from "@/domain/entity";
import { AdRepository } from "@/domain/interactor/repository/AdRepository";
import { inject, injectable } from "inversify";
import { map, Observable } from "rxjs";
import Utils from "./Utils";
import { TYPES } from "@/data/remote/api/index.container.type";
import { ConsultationRequestDBItemV2 } from "@/domain/interactor/repository/vo/Ad";
import { Page } from "@/domain/interactor/repository/vo";

@injectable()
export class AdRepositoryImpl implements AdRepository {
  constructor(@inject(TYPES.AdAPI) private readonly adAPI: AdAPI) {}

  getConsultationRequestDbs = (page?: number, size?: number, keyword?: string): Observable<Page<ConsultationRequestDBItemV2>> => {
    return this.adAPI.getAdvertisementDBs(page, size, keyword).pipe(
      map((res) => {
        return {
          page: res.page,
          size: res.size,
          total: res.total,
          isLast: res.last,
          content: res.items.map((item) => {
            return {
              id: item.id,
              appliedAt: Utils.convertDateStringToLocalDate(item.appliedAt),
              consultingStatus: item.applicationStatus,
              reservationStatus: item.reservationStatus,
              reservationDate: item.reservationDate ? Utils.convertDateStringToLocalDate(item.reservationDate) : null,
              user: {
                afoter: item.user.afoter
                  ? {
                      id: item.user.afoter.id,
                      nickname: item.user.afoter.nickname,
                    }
                  : null,
                name: item.user.name,
                phoneNumber: item.user.phoneNumber,
              },
              advertisement: item.advertisement
                ? {
                    id: item.advertisement.id,
                    title: item.advertisement.title,
                  }
                : null,
              consulting: item.consulting
                ? {
                    memo: item.consulting.memo,
                    consultedAt: item.consulting.consultedAt ? Utils.convertDateStringToLocalDate(item.consulting.consultedAt) : null,
                    consultant: {
                      id: item.consulting.consultant?.id,
                      name: item.consulting.consultant?.name,
                    },
                  }
                : null,
            };
          }),
        };
      }),
    );
  };

  updateConsultingStatus = (
    adId: number,
    consultingStatus: Entity.Type.ApplicationStatusForAdvertisement,
  ): Observable<{ advertisementDBId: number }> => {
    return this.adAPI.updateConsultingStatus(adId, consultingStatus).pipe(
      map((res) => {
        return {
          advertisementDBId: res.advertisementDBId,
        };
      }),
    );
  };

  updateReservationStatus = (
    adId: number,
    reservationStatus: Entity.Type.ReservationStatusForAdvertisement,
  ): Observable<{ advertisementDBId: number }> => {
    return this.adAPI.updateReservationStatus(adId, reservationStatus).pipe(
      map((res) => {
        return {
          advertisementDBId: res.advertisementDBId,
        };
      }),
    );
  };

  updateReservationDate = (adId: number, date: string): Observable<{ advertisementDBId: number }> => {
    return this.adAPI.updateReservationDate(adId, date).pipe(
      map((res) => {
        return {
          advertisementDBId: res.advertisementDBId,
        };
      }),
    );
  };

  updateReservationMemo = (adId: number, memo: string): Observable<{ advertisementDBId: number }> => {
    return this.adAPI.updateReservationMemo(adId, memo).pipe(
      map((res) => {
        return {
          advertisementDBId: res.advertisementDBId,
        };
      }),
    );
  };
}
