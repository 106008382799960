import * as Entity from "@/domain/entity";
import { injectable } from "inversify";
import { Observable } from "rxjs";
import { API } from "../../API";
import { HospitalAPI } from "./model/HospitalAPI";
import { PutUserLoginIdValidationRequest, UpdateHospitalRequest } from "./model/Request";
import { GetContract, GetHospital, GetOperationCategories } from "./model/Response";

@injectable()
export class HospitalAPIImpl implements HospitalAPI {
  getOperationCategories(): Observable<GetOperationCategories> {
    return API.get({ credential: true, path: "/dr-wo/v1/operation-categories" });
  }

  getHospital(infoTypes: Entity.Type.HospitalInfoType[]): Observable<GetHospital> {
    return API.get({ path: "/dr-wo/v1/hospital", credential: true, queryParams: { info: infoTypes } });
  }

  getContract(): Observable<GetContract> {
    return API.get({ path: "/dr-wo/v1/contracts/activated", credential: true });
  }

  updateHospital(request: UpdateHospitalRequest): Observable<void> {
    return API.put({ path: `/dr-wo/v1/hospital`, credential: true, queryParams: { info: request.infoTypes }, data: request });
  }

  getUserLoginIdValidation(request: PutUserLoginIdValidationRequest): Observable<{ exists: boolean }> {
    return API.get({
      path: "/dr-wo/v1/accounts/validate/exists",
      credential: true,
      queryParams: { type: request.type, value: request.value },
    });
  }
}
