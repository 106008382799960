import { ContainerModule, interfaces } from "inversify";
import ImageManagerImpl from "@/image/interactor/image-manger/ImageManagerImpl";
import ImageProcessorImpl from "@/image/processor/ImageProcessorImpl";
import { StorageImpl } from "@/storage/interactor/storage/StorageImpl";
import GcsImpl from "@/storage/gcs/GcsImpl";
import { ImageManager } from "@/domain/interactor/image-manager";
import { Storage } from "@/domain/interactor/storage";
import { Gcs } from "@/storage/gcs";

export const Images = new ContainerModule((bind: interfaces.Bind) => {
  bind<ImageManager>("ImageManager").to(ImageManagerImpl);
  bind<ImageManager>("ImageProcessor").to(ImageProcessorImpl);
  bind<Storage>("Storage").to(StorageImpl);
  bind<Gcs>("Gcs").to(GcsImpl);
});
