import React from "react";
import styled from "styled-components";
import StatusBadge from "./StatusBadge";
import Icon from "@icon/index";
import ResultBadge from "./ResultBadge";
import * as Entity from "@/domain/entity";
interface ConsultationStatusProps {
  consultingStatus: Entity.Type.ConsultStatusForFaceFit | null;
  reservationStatus: Entity.Type.ReservationStatus | null;
  clickConsultingStatus: (status: Entity.Type.ConsultStatusForFaceFit) => void;
  clickReservationStatus: (status: Entity.Type.ReservationStatus) => void;
}

const ConsultationStatusBadge: React.FC<ConsultationStatusProps> = ({
  consultingStatus,
  reservationStatus,
  clickConsultingStatus,
  clickReservationStatus,
}) => {
  return (
    <ConsultationStatusLayout onClick={(e) => e.stopPropagation()}>
      <BadgeLayout>
        <StatusBadge
          status={consultingStatus}
          click={clickConsultingStatus}
        />
      </BadgeLayout>
      <Icon.ArrowRightSLine color="neutralBlack" />
      <BadgeLayout>
        <ResultBadge
          reservationStatus={reservationStatus}
          clickReservationStatus={clickReservationStatus}
        />
      </BadgeLayout>
    </ConsultationStatusLayout>
  );
};

export default ConsultationStatusBadge;

const ConsultationStatusLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BadgeLayout = styled.div`
  z-index: 3;
`;
