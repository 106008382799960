import { injectable } from "inversify";
import { BehaviorSubject, Subject } from "rxjs";
import * as Entity from "@/domain/entity";
import type { UISystemManager } from "../ui-system/view-model/model";
import type { AdvertiseConsultingCustomersViewModel } from "./model/AdvertiseConsultingCustomersViewModel";
import type { GetConsultingNotesByAdvertiseApply } from "@/domain/usecase/advertisement/model/GetConsultingNotesByAdvertiseApply";
import type { UpdateApplicationStatusByAdvertise } from "@/domain/usecase/advertisement/model/UpdateApplicationStatusByAdvertise";
import { Note } from "@view-data/index";
import type { UpdateReservationStatusByAdvertise } from "@/domain/usecase/advertisement/model/UpdateReservationStatusByAdvertise";
import type { UpdateReservationDateByAdvertise } from "@/domain/usecase/advertisement/model/UpdateReservationDateByAdvertise";
import type { UpdateReservationMemoByAdvertise } from "@/domain/usecase/advertisement/model/UpdateReservationMemoByAdvertise";

@injectable()
export class AdvertiseConsultingCustomersViewModelImpl implements AdvertiseConsultingCustomersViewModel {
  data: AdvertiseConsultingCustomersViewModel["data"] = {
    page: 1,
    size: 20,
  };

  output: AdvertiseConsultingCustomersViewModel["output"] = {
    page: new Subject<number>(),
    keyword: new BehaviorSubject<string>(""),
    consultingCustomerNotes: new Subject<Entity.Type.Pagination<Note.AdvertiseConsultingNote>>(),
    isConsultingStatusCompleted: new Subject<{
      noteId: number | null;
    }>(),
    reservationDatePopup: new Subject<{ isOpen: boolean; noteId: number | null; reservationDate: Date | null }>(),
  };

  event: AdvertiseConsultingCustomersViewModel["event"] = {
    onGetNotes: (page: number, size: number, keyword?: string) => {
      this.data.page = page;
      this.data.size = size;
      this.data.keyword = keyword;

      this.getConsultingNotes();
    },
  };
  route: AdvertiseConsultingCustomersViewModel["route"] = {};

  constructor(
    readonly uiSystem: UISystemManager,
    private readonly ucGetConsultingNotesByAdvertiseApply: GetConsultingNotesByAdvertiseApply,
    private readonly ucUpdateApplicationStatusByAdvertise: UpdateApplicationStatusByAdvertise,
    private readonly ucUpdateReservationStatusByAdvertise: UpdateReservationStatusByAdvertise,
    private readonly ucUpdateReservationDateByAdvertise: UpdateReservationDateByAdvertise,
    private readonly ucUpdateReservationMemoByAdvertise: UpdateReservationMemoByAdvertise,
  ) {
    this.init();
  }
  input: AdvertiseConsultingCustomersViewModel["input"] = {
    changeKeyword: (value: string) => {
      this.data.keyword = value;
      this.output.keyword.next(this.data.keyword);
    },
    changeApplicationStatus: (noteId: number, status: Entity.Type.ApplicationStatusForAdvertisement) => {
      this.output.isConsultingStatusCompleted.next({ noteId: null });
      this.ucUpdateApplicationStatusByAdvertise.execute({ noteId, status }).subscribe({
        complete: () => {
          if (status === "COMPLETED") {
            this.output.reservationDatePopup.next({ isOpen: true, noteId, reservationDate: null });
          }
          this.getConsultingNotes();
        },
        error: (error) => {
          console.log(error);
        },
      });
    },
    changeReservationStatus: (noteId: number, status: Entity.Type.ReservationStatusForAdvertisement) => {
      this.ucUpdateReservationStatusByAdvertise.execute({ noteId, status }).subscribe({
        complete: () => {
          this.getConsultingNotes();
        },
        error: (error) => {
          console.log(error);
        },
      });
    },
    clickResetFilter: () => {
      this.data.keyword = "";
      this.output.keyword.next(this.data.keyword);

      this.getConsultingNotes();
    },
    clickPagination: (page: number) => {
      this.data.page = page;

      this.getConsultingNotes();
    },
    clickSearch: () => {
      this.getConsultingNotes();
    },
    clickExcelDownLoad: () => {},
    clickSubmitMemo: (memo: string, noteId: number) => {
      this.ucUpdateReservationMemoByAdvertise
        .execute({
          noteId,
          memo: memo,
        })
        .subscribe({
          next: () => {
            this.getConsultingNotes();
          },
          error: (err) => {
            console.log(err);
          },
        });
    },
    clickSubmitReservationDate: (date: string, noteId: number) => {
      this.ucUpdateReservationDateByAdvertise.execute({ date: new Date(date).toISOString(), noteId }).subscribe({
        complete: () => {
          this.getConsultingNotes();
        },
        error: (err) => {
          console.log(err);
        },
      });
    },
    clickOpenReservationDate: (isOpen: boolean, noteId: number | null, reservationDate: Date | null) => {
      this.output.reservationDatePopup.next({ isOpen, noteId, reservationDate });
    },
    clickUpdateReservationDate: (noteId: number, date: string) => {
      this.uiSystem.loadingHandler.backdropLoading.next(true);
      this.ucUpdateReservationDateByAdvertise.execute({ noteId, date: new Date(date).toISOString() }).subscribe({
        complete: () => {
          this.getConsultingNotes();
          this.uiSystem.loadingHandler.backdropLoading.next(false);
        },
        error: () => {
          this.uiSystem.loadingHandler.backdropLoading.next(false);
        },
      });
    },
  };

  private init() {
    this.ucGetConsultingNotesByAdvertiseApply
      .execute({ page: this.data.page, size: this.data.size, keyword: this.data.keyword })
      .subscribe({
        next: (res) => {
          this.output.consultingCustomerNotes.next(res);
        },
        error: (error) => {
          if (error?.error_message === "user status is not activated") {
            this.uiSystem.errorHandler.alert.next({ message: "비활성화된 계정입니다.\n관리자에게 문의해주세요." });
          }
        },
      });
  }

  private getConsultingNotes() {
    this.ucGetConsultingNotesByAdvertiseApply
      .execute({ page: this.data.page, size: this.data.size, keyword: this.data.keyword })
      .subscribe((notes) => {
        this.output.consultingCustomerNotes.next(notes);
      });
  }
}
