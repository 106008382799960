import styled from "styled-components";
import { ButtonProps } from ".";
import colors from "@view/styles/colors";

export interface TextButtonProps extends ButtonProps {
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  label: string;
  color?: string;
}

export const TextButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const IconLabel = styled.div`
  color: ${(props) => props.color};
  font-weight: 500;
  font-size: 14px;
  text-align: center;
`;

export const TextButtonStyled = styled.button<TextButtonProps>`
  border: none;
  background-color: transparent;
  padding: 9px 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  ${(props) => props.width ?? `width: ${props.width}px;`}
  ${(props) => props.height ?? `height: ${props.height}px;`}
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export const TextButton = ({ color = colors.neutralBlack, ...props }: TextButtonProps) => {
  return (
    <TextButtonWrapper>
      <TextButtonStyled {...props}>
        <IconLabel color={color}>{props.label}</IconLabel>
        {props.icon && <props.icon />}
      </TextButtonStyled>
    </TextButtonWrapper>
  );
};
