import { Config, Coordinate } from "../index.interface";
import { DrawingFacialLandmarks } from "../face/DrawingFacialLandmarks";
import Calc from "../utils/Calc";
import { BestRatioLineStyle, DotLineStyle, PhotoBaseLine } from "../utils/Styles";

export class FaceContourSymmetryRatio extends DrawingFacialLandmarks {
  private coordinate: {
    top: {
      right: Coordinate;
      left: Coordinate;
    };
    bottom: {
      right: Coordinate;
      left: Coordinate;
    };
  };

  angle: {
    top: {
      right: number;
      left: number;
    };
    bottom: {
      right: number;
      left: number;
    };
  };

  constructor(config: Config) {
    super(config);
    this.coordinate = this.getCoordinate();

    this.angle = this.calcAngle();
  }

  drawPhotoBase = async () => {
    this.photoBaseDrawer.drawGuideLines();
    await this.delay(800);
    this.photoBaseDrawer.drawFaceLines();
    await this.delay(800);
    this.photoBaseDrawer.drawAngleText();
  };

  drawBestRatio = async () => {
    this.bestRatioDrawer.drawGuideLines();
    await this.delay(800);
    this.bestRatioDrawer.drawFaceLines();
    await this.delay(800);
    this.bestRatioDrawer.drawAngleText();
  };

  private photoBaseDrawer = {
    drawFaceLines: () => {
      this.drawLineBetweenPoints({
        point1: this.coordinate.top.right,
        point2: this.coordinate.top.left,
        lineStyle: { ...PhotoBaseLine, lineType: "DOT" },
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: this.coordinate.bottom.right,
        point2: this.coordinate.bottom.left,
        lineStyle: { ...PhotoBaseLine, lineType: "DOT" },
        isDrawEndPoint: true,
      });
      this.drawArcBetweenPoints({
        center: this.faceCoordinates[6],
        point1: this.coordinate.top.right,
        point2: this.coordinate.top.left,
        lineStyle: PhotoBaseLine,
        radius: 15,
        reverse: true,
      });

      this.drawArcBetweenPoints({
        center: this.faceCoordinates[13],
        point1: this.coordinate.bottom.right,
        point2: this.coordinate.bottom.left,
        lineStyle: PhotoBaseLine,
        radius: 15,
        reverse: true,
      });
    },
    drawGuideLines: () => {
      this.drawLineBetweenPoints({
        point1: this.faceCoordinates[10],
        point2: this.faceCoordinates[6],
        lineStyle: DotLineStyle,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: this.faceCoordinates[6],
        point2: this.faceCoordinates[13],
        lineStyle: DotLineStyle,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: this.faceCoordinates[13],
        point2: this.faceCoordinates[152],
        lineStyle: DotLineStyle,
        isDrawEndPoint: true,
      });
    },
    drawAngleText: () => {
      const textStyle = { x: 0, y: 30, fontSize: 18, color: "#FF9900" };
      this.drawTextBetweenPoints(`${this.angle.top.right}°`, this.faceCoordinates[155], this.faceCoordinates[6], { ...textStyle, x: -15 });
      this.drawTextBetweenPoints(`${this.angle.top.left}°`, this.faceCoordinates[382], this.faceCoordinates[6], { ...textStyle, x: 15 });

      this.drawTextBetweenPoints(`${this.angle.bottom.right}°`, this.faceCoordinates[61], this.faceCoordinates[13], {
        ...textStyle,
        x: -10,
      });
      this.drawTextBetweenPoints(`${this.angle.bottom.left}°`, this.faceCoordinates[291], this.faceCoordinates[13], {
        ...textStyle,
        x: 10,
      });
    },
  };

  private bestRatioDrawer = {
    drawFaceLines: () => {
      this.drawLineBetweenPoints({
        point1: this.coordinate.top.right,
        point2: this.coordinate.top.left,
        lineStyle: { ...BestRatioLineStyle, lineType: "DOT" },
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: this.coordinate.bottom.right,
        point2: this.coordinate.bottom.left,
        lineStyle: { ...BestRatioLineStyle, lineType: "DOT" },
        isDrawEndPoint: true,
      });

      this.drawArcBetweenPoints({
        center: { ...this.faceCoordinates[10], y: this.faceCoordinates[6].y },
        point1: this.coordinate.top.right,
        point2: this.coordinate.top.left,
        lineStyle: BestRatioLineStyle,
        radius: 15,
        reverse: true,
      });

      this.drawArcBetweenPoints({
        center: { ...this.faceCoordinates[10], y: this.faceCoordinates[13].y },
        point1: this.coordinate.bottom.right,
        point2: this.coordinate.bottom.left,
        lineStyle: BestRatioLineStyle,
        radius: 15,
        reverse: true,
      });
    },
    drawGuideLines: () => {
      this.drawLineBetweenPoints({
        point1: this.faceCoordinates[10],
        point2: { ...this.faceCoordinates[10], y: this.faceCoordinates[6].y },
        lineStyle: DotLineStyle,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: { ...this.faceCoordinates[10], y: this.faceCoordinates[6].y },
        point2: { ...this.faceCoordinates[10], y: this.faceCoordinates[13].y },
        lineStyle: DotLineStyle,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: { ...this.faceCoordinates[10], y: this.faceCoordinates[13].y },
        point2: { ...this.faceCoordinates[10], y: this.faceCoordinates[152].y },
        lineStyle: DotLineStyle,
        isDrawEndPoint: true,
      });
    },
    drawAngleText: () => {
      const textStyle = { x: 0, y: -17, fontSize: 18, color: "#00D7CA" };
      this.drawTextBetweenPoints(`90°`, this.faceCoordinates[155], this.faceCoordinates[6], { ...textStyle, x: -10 });
      this.drawTextBetweenPoints(`90°`, this.faceCoordinates[382], this.faceCoordinates[6], { ...textStyle, x: 10 });
      this.drawTextBetweenPoints(`90°`, this.faceCoordinates[61], this.faceCoordinates[13], textStyle);
      this.drawTextBetweenPoints(`90°`, this.faceCoordinates[291], this.faceCoordinates[13], textStyle);
    },
  };

  private getCoordinate = () => {
    const rightXPoint = this.faceCoordinates[127].x;
    const leftXPoint = this.faceCoordinates[356].x;
    return {
      top: {
        right: { ...this.faceCoordinates[6], x: rightXPoint },
        left: { ...this.faceCoordinates[6], x: leftXPoint },
      },
      bottom: {
        right: { ...this.faceCoordinates[13], x: rightXPoint },
        left: { ...this.faceCoordinates[13], x: leftXPoint },
      },
    };
  };

  private calcAngle = () => {
    const rightTopAngle = Calc.calcAngle(
      { point1: this.coordinate.top.right, center: this.faceCoordinates[6], point2: this.faceCoordinates[13] },
      "2d",
    );

    const leftTopAngle = Calc.calcAngle(
      { point1: this.coordinate.top.left, center: this.faceCoordinates[6], point2: this.faceCoordinates[13] },
      "2d",
    );

    const rightBottomAngle = Calc.calcAngle(
      {
        point1: this.coordinate.bottom.right,
        center: this.faceCoordinates[13],
        point2: this.faceCoordinates[152],
      },
      "2d",
    );

    const leftBottomAngle = Calc.calcAngle(
      {
        point1: this.coordinate.bottom.left,
        center: this.faceCoordinates[13],
        point2: this.faceCoordinates[152],
      },
      "2d",
    );

    return {
      top: {
        right: rightTopAngle,
        left: leftTopAngle,
      },
      bottom: {
        right: rightBottomAngle,
        left: leftBottomAngle,
      },
    };
  };

  private delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
}
