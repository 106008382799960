import { injectable } from "inversify";
import { LocalRepository } from "@/domain/interactor/repository/LocalRepository";

export class LocalStorage {
    constructor() {}

    static setItem = (key: string, item: string) => {
        if (typeof window !== "undefined") {
            localStorage.setItem(key, item);
        }
    };

    static getItem = (key: string) => {
        if (typeof window !== "undefined") {
            return localStorage.getItem(key);
        }
        return null;
    };

    static removeItem = (key: string) => {
        if (typeof window !== "undefined") {
            localStorage.removeItem(key);
        }
    };

    static clearAll = () => {
        if (typeof window !== "undefined") {
            localStorage.clear();
        }
    };
}

@injectable()
export class LocalRepositoryImpl implements LocalRepository {

    saveRefreshToken = (refreshToken: string) => {
        LocalStorage.setItem("refreshToken", refreshToken)
    }

    removeItem = (key: string) => {
        LocalStorage.removeItem(key);
    }

    removeAll = () => {
        LocalStorage.clearAll();
    }

}
