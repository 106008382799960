import React from "react";
import styled from "styled-components";
import Button from "@view/widget/button";

interface LogoProps {
  click: () => void;
}

const Logo: React.FC<LogoProps> = ({ click }) => {
  return (
    <Button.ComponentButton
      component={
        <LogoImage
          src="/image/dr-wo-text-logo.png"
          alt="logo"
        />
      }
      sx={{ padding: "8px" }}
      onClick={click}
    />
  );
};

export default Logo;

const LogoImage = styled.img`
  width: 136px;
  height: 14px;
`;
