import { UI_POLICY } from "../main/useMainView";
import Logo from "./components/Logo";
import { ProductMenuItem } from "./components/ProductMenuItem";
import UserProfile from "./components/UserProfile";
import { useGlobalNavigationBar } from "./useGlobalNavigationBar";
import { MainMenu } from "@view-data/Product";
import { UserInfo } from "@view-data/User";
import styled from "styled-components";

interface GlobalNavigationBarProps {
  userInfo: UserInfo;
  menu: MainMenu[];
}

export const GlobalNavigationBar: React.FC<GlobalNavigationBarProps> = (props) => {
  const { state, input } = useGlobalNavigationBar(props.userInfo);

  return (
    <GlobalNavigationBarViewLayout $isShow={state.isShow}>
      <InnerLayout>
        <MenuLayout>
          <LogoLayout>
            <Logo click={input.clickLogo} />
          </LogoLayout>
          <ProductMenuLayout>
            {props.menu.map((menu) => (
              <ProductMenuItem
                key={menu.pathname}
                menuName={menu.name}
                isSelected={menu.isSelected}
                hasNewBadge={menu.isNew}
                click={menu.click}
              />
            ))}
          </ProductMenuLayout>
        </MenuLayout>
        <UserProfile
          user={state.userInfo}
          clickSignOut={input.clickSignOut}
          clickToKakao={input.clickToKakao}
        />
      </InnerLayout>
    </GlobalNavigationBarViewLayout>
  );
};

const GlobalNavigationBarViewLayout = styled.nav<{ $isShow: boolean }>`
  display: ${({ $isShow }) => ($isShow ? "block" : "none")};
  width: 100%;
  height: ${UI_POLICY.globalNavigationBar.height}px;
  min-width: 1024px;
`;

const InnerLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: #1e2527;
  /* border-bottom: 1px solid #f2f4f3; */
  padding: 0px 20px 0px 12px;
`;

const LogoLayout = styled.div`
  margin-right: 32px;
`;

const MenuLayout = styled.div`
  display: flex;
  align-items: center;
`;

const ProductMenuLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 42px;
  padding: 0px 36px;
`;
