import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { inject, injectable } from "inversify";
import { Observable } from "rxjs";
import { GetConsultantsByFaceFit, GetConsultantsByFaceFitOutput } from "./model/GetConsultantsByFaceFit";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class GetConsultantsByFaceFitImpl implements GetConsultantsByFaceFit {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute(): Observable<GetConsultantsByFaceFitOutput> {
    return this.operationConsultingRepository.getConsultants();
  }
}
