import { inject, injectable } from "inversify";
import { GetOperationCategories, GetOperationCategoriesOutput } from "./model/GetOperationCategories";
import type { HospitalRepository } from "@/domain/interactor/repository/HospitalRepository";
import { Observable } from "rxjs";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class GetOperationCategoriesImpl implements GetOperationCategories {
  constructor(@inject(TYPES.HospitalRepository) private readonly hospitalRepository: HospitalRepository) {}

  execute(): Observable<GetOperationCategoriesOutput> {
    return this.hospitalRepository.getOperationCategories();
  }
}
