export const utcDateToKoreanDate = (utc: Date | string) => {
  if (typeof utc === "string") {
    const utcDate = new Date(utc);
    utcDate.setHours(utcDate.getHours() + 9);
    return utcDate;
  } else {
    utc.setHours(utc.getHours() + 9);
    return utc;
  }
};
