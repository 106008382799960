import styled from "styled-components";
import DatePicker, { DatePickerProps } from "./index";

interface TimeRangePickerProps extends Omit<DatePickerProps, "date" | "onChange"> {
  startTime?: string;
  endTime?: string;
  onChangeStart: (dateString?: string, index?: number) => void;
  onChangeEnd: (dateString?: string, index?: number) => void;
  disabled?: boolean;
}

export const TimeRangePicker = ({ ...props }: TimeRangePickerProps) => {
  return (
    <TimeRangePickerContainer>
      <DatePicker.Time
        placeHolderText={props.placeHolderText || "시작시간"}
        date={props.startTime}
        width={props.width}
        height={props.height}
        timeFormat={props.timeFormat}
        onChange={props.onChangeStart}
        {...(props.index && { index: props.index })}
        dateFormat={props.dateFormat}
        isStartOfDay
        disabled={props.disabled}
      />
      <DatePicker.Time
        placeHolderText={props.placeHolderText || "종료시간"}
        date={props.endTime}
        width={props.width}
        height={props.height}
        timeFormat={props.timeFormat}
        onChange={props.onChangeEnd}
        {...(props.index && { index: props.index })}
        dateFormat={props.dateFormat}
        isEndOfDay
        disabled={props.disabled}
      />
    </TimeRangePickerContainer>
  );
};

const TimeRangePickerContainer = styled.div`
  display: flex;
  gap: 8px;
`;
