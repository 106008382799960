import { injectable, inject } from "inversify";
import { DrawConnectorsByMediaPipe, DrawConnectorsByMediaPipeInput } from "./model/DrawConnectorsByMediaPipe";
import { TYPES } from "@/classification/index.container.types";
import { Observable } from "rxjs";
import type { MediaPipeHandler } from "@/domain/interactor/classification/MediaPipeHandler";

@injectable()
export class DrawConnectorsByMediaPipeImpl implements DrawConnectorsByMediaPipe {
  constructor(@inject(TYPES.MediaPipeHandler) private readonly mediaPipeHandler: MediaPipeHandler) {}

  execute(input: DrawConnectorsByMediaPipeInput): Observable<string> {
    return this.mediaPipeHandler.drawConnectors(
      input.imageEle,
      input.canvasEle,
      input.normalizedFaceLandmarks,
      input.connections,
      input.style,
    );
  }
}
