import React from "react";
import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

interface BackgroundLoadingProps {
  loading?: boolean;
  progressColor?: "#14443F" | "#377bff";
}

const BackgroundLoading: React.FC<BackgroundLoadingProps> = ({ loading, progressColor }) => {
  return (
    <Backdrop
      open={loading === undefined ? true : loading}
      sx={{ zIndex: 1500 }}
    >
      <CircularProgress sx={{ color: progressColor ?? "#14443F" }} />
    </Backdrop>
  );
};

export default BackgroundLoading;
