import { Box, Stack } from "@mui/material";

import PageContentLayout from "./PageContentLayout";
import PageHeadWrapper from "./PageHeadWrapper";
import PageFooterWrapper from "./PageFooterWrapper";
import SlideInLayout from "./SlideInLayout";

export interface LayoutProps {
    children: React.ReactNode;
}

export { PageContentLayout, PageHeadWrapper, PageFooterWrapper, Box, Stack, SlideInLayout };
