import { injectable, inject } from "inversify";
import { GetDoctor, GetDoctorInput } from "./model/GetDoctor";
import type { DoctorRepository } from "@/domain/interactor/repository/DoctorRepository";
import { Information } from "@/domain/entity/Doctor";
import { Observable } from "rxjs";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class GetDoctorImpl implements GetDoctor {
  constructor(@inject(TYPES.DoctorRepository) private readonly doctorRepository: DoctorRepository) {}

  execute(input: GetDoctorInput): Observable<Information> {
    return this.doctorRepository.getDoctor(input.doctorId);
  }
}
