import React from "react";
import { CenterModalFrame } from "../modal";
import styled from "styled-components";
import { Button } from "..";

interface AlertProps {
  message: string;
  confirm?: () => void;
  close: () => void;
}

const Alert: React.FC<AlertProps> = ({ message, confirm, close }) => {
  return (
    <CenterModalFrame
      open
      borderRadius="28px"
      close={close}
    >
      <AlertLayout>
        <Layout>
          <InfoMessageLayout>
            <InfoMessage>{message}</InfoMessage>
          </InfoMessageLayout>
        </Layout>
        <ButtonsLayout>
          <Button.ComponentButton
            component={<ConfirmButton>확인</ConfirmButton>}
            borderRadius={30}
            onClick={() => {
              confirm && confirm();
              close();
            }}
          />
        </ButtonsLayout>
      </AlertLayout>
    </CenterModalFrame>
  );
};

export default Alert;

const AlertLayout = styled.div`
  width: 380px;
  border-radius: 28px;
  border: 1px solid rgba(255, 255, 255, 0.31);
  background: #e7ebda;
  backdrop-filter: blur(50px);
  padding: 24px 30px;
`;

const Layout = styled.div``;

const TitleLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 62px;
  /* padding-inline: 30px; */
`;

const InfoMessageLayout = styled.div`
  display: flex;
  align-items: center;
  height: 49px;
`;

const InfoMessage = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #777777;
  white-space: pre-wrap;
`;

const ButtonsLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 16px;
`;

const ConfirmButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 73px;
  height: 36px;
  background-color: #1c5c56;
  color: #fff;
  font-weight: 500;
  border-radius: 30px;
`;
