import * as Loading from "./loading";
import * as Photo from "./photo";
import * as Modal from "./modal";
import * as Tooltip from "./tooltip";
import * as Table from "./table";
import * as MainSection from "./main-section";
import * as Select from "./select";
import * as Input from "./input";
import * as Button from "./button";
import * as Navigation from "./navigation";
import * as Badge from "./badge";

export { Loading, Photo, Modal, Tooltip, Table, MainSection, Select, Input, Button, Navigation, Badge };
