import { injectable, inject } from "inversify";
import { Observable } from "rxjs";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { GetBestPractices, GetBestPracticesInput, GetBestPracticesOutput } from "./model/GetBestPractices";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class GetBestPracticesImpl implements GetBestPractices {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute(input: GetBestPracticesInput): Observable<GetBestPracticesOutput> {
    return this.operationConsultingRepository.getBestPractice(input.page, input.size, input.gender, input.categories, input.exposure);
  }
}
