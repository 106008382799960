import { injectable, inject } from "inversify";
import { Observable } from "rxjs";
import { GetOperationCategoryGroups, GetOperationCategoryGroupsOutput } from "./model/GetOperationCategoryGroups";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class GetOperationCategoryGroupsImpl implements GetOperationCategoryGroups {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute = (): Observable<GetOperationCategoryGroupsOutput> => {
    return this.operationConsultingRepository.getOperationCategoryGroups();
  };
}
