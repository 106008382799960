import { CanvasLineStyle } from "..";

export const PhotoBaseLine: CanvasLineStyle = {
  lineLength: 245,
  lineWidth: 1.8,
  lineColor: "#FF9900",
  lineType: "SOLID",
};

export const BestRatioLineStyle: CanvasLineStyle = {
  lineLength: 245,
  lineWidth: 1.8,
  lineColor: "#00D7CA",
  lineType: "SOLID",
};

export const DotLineStyle: CanvasLineStyle = {
  lineLength: 100,
  lineWidth: 1,
  lineColor: "#fff",
  lineType: "DOT",
};
