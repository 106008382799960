import type { HospitalRepository } from "@/domain/interactor/repository/HospitalRepository";
import { inject, injectable } from "inversify";
import { Observable } from "rxjs";
import { GetContract, GetContractOutput } from "./model/GetContract";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class GetContractImpl implements GetContract {
  constructor(@inject(TYPES.HospitalRepository) private readonly hospitalRepository: HospitalRepository) {}

  execute(): Observable<GetContractOutput> {
    return this.hospitalRepository.getContract();
  }
}
