import { type LoadingHandler } from "./LoadingHandler";
import { type UISystemManager } from "./UISystemManager";
import { type PopupHandler } from "./PopupHandler";
import { type ErrorHandler } from "./ErrorHandler";
import { type ScrollHandler } from "./ScrollHandler";
import { type ToastHandler } from "./ToastHandler";

const APP_STATE_TYPES = {
  UISystemManager: Symbol.for("UISystemManager"),
  LoadingHandler: Symbol.for("LoadingHandler"),
  PopupHandler: Symbol.for("PopupHandler"),
  ErrorHandler: Symbol.for("ErrorHandler"),
  ScrollHandler: Symbol.for("ScrollHandler"),
  ToastHandler: Symbol.for("ToastHandler"),
};

export { LoadingHandler, UISystemManager, PopupHandler, ErrorHandler, ScrollHandler, APP_STATE_TYPES, ToastHandler };
