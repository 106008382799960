import { common } from "@mui/material/colors";
import { MainMenu, Product, SubMenu } from "@view-data/Product";
import { DeprecatedUserInfo, UserInfo } from "@view-data/User";
import { MainViewModel } from "@view-model/model/MainViewModel";
import useResize from "@view/hooks/useResize";
import useRouter from "@view/hooks/useRouter";
import { useContainer } from "inversify-react";
import { useEffect, useState } from "react";

export const UI_POLICY = Object.freeze({
  globalNavigationBar: {
    height: 60,
  },
  sideMenu: {
    width: {
      expanded: 160,
      collapse: 60,
    },
  },
});

export const useMainView = () => {
  const vm = useContainer((container) => {
    const factory = container.get<() => MainViewModel>("MainViewModel");
    return factory();
  });
  const router = useRouter();
  const window = useResize();
  const [isSideMenuExpanded, setIsSideMenuExpended] = useState<boolean>(true);
  const [deprecatedUserInfo, setDeprecatedUserInfo] = useState<DeprecatedUserInfo | null>(null);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [sidebarMenu, setSidebarMenu] = useState<Product[]>([]);
  const isHomePage = router.pathname === "/home" || router.pathname === "/";
  const isRecentConsultationsPage = router.pathname === "/face-fit/consultations/recent";
  const sideMenuWidth = isSideMenuExpanded ? UI_POLICY.sideMenu.width.expanded : UI_POLICY.sideMenu.width.collapse;
  const mainMenuPathname = router.pathname.split("/")[1];
  const selectedProduct = sidebarMenu.find(({ name }) => name === mainMenuPathname) ?? null;

  useEffect(() => {
    vm.route.toLogin.subscribe(() => router.push({ pathname: "/login" }));
    vm.route.toHome.subscribe(() => router.push({ pathname: "/home" }));
    vm.output.deprecatedUserInfo.subscribe(setDeprecatedUserInfo);
    vm.output.userInfo.subscribe(setUserInfo);
    vm.output.accessibleProducts.subscribe(setSidebarMenu);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      vm.input.authorizeByPathname(router.pathname);
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [router.pathname]);

  const displayTitle = (pathname: string) => {
    if (pathname.startsWith("/login")) {
      return "Dr.Whole in One | 로그인";
    }

    if (pathname.startsWith("/home")) {
      return "Dr.Whole in One | 홈";
    }

    if (pathname.startsWith("/face-fit")) {
      return "Dr.Whole in One | Face Fit";
    }

    if (pathname.startsWith("/advertisement")) {
      return "Dr.Whole in One | 상담신청 관리";
    }

    if (pathname.startsWith("/hospital")) {
      return "Dr.Whole in One | 병원 정보";
    }

    return "Dr.Whole in One";
  };

  const displayProductMenu = (products: Product[]): MainMenu[] => {
    return products
      .map((product) => {
        const defaultFeaturePathname = product.subMenus?.sort((a, b) => a.displayOrdinal - b.displayOrdinal)[0]?.name;
        const mainMenu: MainMenu = {
          ordinal: product.displayOrdinal,
          name: "",
          pathname: "",
          isSelected: router.pathname.startsWith(`/${product.name}`),
          isNew: product.isNewMenu,
          click: () => router.push({ pathname: `/${product.name}/${defaultFeaturePathname}`, uiSystem: vm.uiSystem }),
        };
        switch (product.name) {
          case "face-fit":
            return {
              ...mainMenu,
              pathname: "face-fit",
              name: "Face Fit",
            };
          case "advertisement":
            return {
              ...mainMenu,
              pathname: "advertisement",
              name: "상담신청 관리",
            };
          case "hospital":
            return {
              ...mainMenu,
              pathname: "hospital",
              name: "병원 관리",
            };
          default:
            return mainMenu;
        }
      })
      .filter((_) => _.name && _.pathname)
      .sort((a, b) => a.ordinal - b.ordinal);
  };

  const displayFeatureMenu = (product: Product | null): SubMenu[] => {
    if (product === null) return [];

    return product.subMenus
      .map<SubMenu>((feature) => {
        const common = {
          ordinal: feature.displayOrdinal,
          name: "",
          pathname: feature.name,
          isSelected: router.pathname.split("/")[1] === feature.name,
          click: () => router.push({ pathname: `/${product.name}/${feature.name}`, uiSystem: vm.uiSystem }),
        };

        switch (product.name) {
          case "face-fit":
            switch (feature.name) {
              case "customers":
                return {
                  ...common,
                  name: "상담고객 관리",
                };

              case "best-practice":
                return {
                  ...common,
                  name: "전후사진 설정",
                };

              case "face-styles":
                return {
                  ...common,
                  name: "스타일핏 설정",
                };
            }
          case "advertisement":
            switch (feature.name) {
              case "customers":
                return {
                  ...common,
                  name: "상담신청 관리",
                };
            }
          case "hospital":
            switch (feature.name) {
              case "information":
                return {
                  ...common,
                  name: "병원정보",
                };
              case "doctors":
                return {
                  ...common,
                  name: "의사정보",
                };
              // case "contacts":
              //   return {
              //     ...common,
              //     name: "알림담당자 관리",
              //   };
              case "accounts":
                return {
                  ...common,
                  name: "계정관리",
                };
              case "plan":
                return {
                  ...common,
                  name: "플랜관리",
                };
            }
          default:
            return common;
        }
      })
      .filter((_) => _.name)
      .sort((a, b) => a.ordinal - b.ordinal);
  };

  return {
    state: {
      public: router.pathname.startsWith("/login"),
      window,
      title: displayTitle(router.pathname),
      deprecatedUserInfo: deprecatedUserInfo,
      userInfo: userInfo,
      isRecentConsultationsPage,
      globalNavigationBar: {
        height: UI_POLICY.globalNavigationBar.height,
        menu: displayProductMenu(sidebarMenu),
      },
      sideMenu: {
        display: !isHomePage,
        width: sideMenuWidth,
        expanded: isSideMenuExpanded,
        menu: displayFeatureMenu(selectedProduct ?? null),
      },
      footer: {
        display: isHomePage,
      },
      mainMenuPathname,
    },
    input: {
      clickExpand: () => setIsSideMenuExpended(true),
      clickCollapse: () => setIsSideMenuExpended(false),
    },
  };
};
