import { useEffect, useState } from "react";

interface BreakPoint {
  mobile: number;
  tablet: number;
  smallDesktop: number;
}

export type DeviceType = "mobile" | "tablet" | "desktop" | null;

export default function useDeviceDetect(
  detectResize = false,
  breakPoint: BreakPoint = {
    mobile: 360,
    tablet: 768,
    smallDesktop: 1287,
  },
) {
  const [deviceType, setDeviceType] = useState<DeviceType>(null);
  const validate = () => {
    setDeviceType(window.innerWidth <= breakPoint.mobile ? "mobile" : window.innerWidth <= breakPoint.tablet ? "tablet" : "desktop");
  };

  useEffect(() => {
    validate();

    if (detectResize) {
      window.addEventListener("resize", validate);

      return () => {
        window.removeEventListener("resize", validate);
      };
    }
  }, []);

  return deviceType;
}
