import { TypographyProps, typographyConfig } from ".";
import styled from "styled-components";

interface StyledTypographyProps extends Omit<TypographyProps, "value" | "underline" | "lineThrough"> {
  textDecorate?: string;
  margin?: string;
}

const StyledTypography = styled.span<StyledTypographyProps>`
  /* line-height: 1.3; */
  letter-spacing: 0px;
  display: inline-block;
  vertical-align: middle;
  ${({ color = "inherit", variant, bold, italic, fontWeight }) => ({
    color,
    fontSize: typographyConfig[variant].fontSize,
    fontWeight: fontWeight ? fontWeight : bold ? typographyConfig[variant].boldFontWeight : typographyConfig[variant].defaultFontWeight,
    fontStyle: italic ? "italic" : "normal",
  })}
  ${(props) => props.textDecorate && `text-decoration: ${props.textDecorate};`}
  ${(props) => props.margin && `margin: ${props.margin};`}
  white-space:pre-wrap;
`;

const decorate = {
  underLine: "underline",
  lineThrough: "line-through",
};

const Typography = (props: TypographyProps) => {
  const { value, underLine, strikeThrough, ...restProps } = props;
  const textDecorate = props.strikeThrough ? decorate.lineThrough : props.underLine ? decorate.underLine : undefined;

  return (
    <StyledTypography
      textDecorate={textDecorate}
      {...restProps}
      style={props.style}
    >
      {props.value}
    </StyledTypography>
  );
};

type CommonTypographyProps = Omit<TypographyProps, "variant">;

const H1: React.FC<CommonTypographyProps> = (props) => (
  <Typography
    variant="H1"
    {...props}
  />
);
const H2: React.FC<CommonTypographyProps> = (props) => (
  <Typography
    variant="H2"
    {...props}
  />
);
const T1: React.FC<CommonTypographyProps> = (props) => (
  <Typography
    variant="T1"
    {...props}
  />
);
const T2: React.FC<CommonTypographyProps> = (props) => (
  <Typography
    variant="T2"
    {...props}
  />
);
const B1: React.FC<CommonTypographyProps> = (props) => (
  <Typography
    variant="B1"
    {...props}
  />
);
const B2: React.FC<CommonTypographyProps> = (props) => (
  <Typography
    variant="B2"
    {...props}
  />
);
const C1: React.FC<CommonTypographyProps> = (props) => (
  <Typography
    variant="C1"
    {...props}
  />
);
const C2: React.FC<CommonTypographyProps> = (props) => (
  <Typography
    variant="C2"
    {...props}
  />
);

Typography.H1 = H1;
Typography.H2 = H2;
Typography.T1 = T1;
Typography.T2 = T2;
Typography.B1 = B1;
Typography.B2 = B2;
Typography.C1 = C1;
Typography.C2 = C2;

export default Typography;
