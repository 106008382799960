import { injectable } from "inversify";
import { UserAPI } from "./model/UserAPI";
import { Observable } from "rxjs";
import { GetUserResponse } from "./model/Response";
import { API } from "../../API";
import { PostUserRequest, PutUserLoginIdValidationRequest, PutUserRequest } from "./model/Request";

@injectable()
export class UserAPIImpl implements UserAPI {
  getUser = (): Observable<GetUserResponse> => {
    return API.get({ path: "/dr-wo/v1/user", credential: true });
  };
  
  postUser = (request: PostUserRequest): Observable<{ userId: number }> => {
    return API.post({ path: "/dr-wo/v1/accounts", credential: true, data: request });
  };

  putUser = (request: PutUserRequest): Observable<{ userId: number }> => {
    return API.put({
      path: `/dr-wo/v1/account/${request.userId}`,
      credential: true,
      data: {
        currentLoginPassword: request.currentLoginPassword,
        newLoginPassword: request.newLoginPassword,
        name: request.name,
        role: request.role,
        status: request.status,
      },
    });
  };

  putUserLoginIdValidation = (request: PutUserLoginIdValidationRequest): Observable<{ exists: boolean }> => {
    return API.put({ path: "/dr-wo/v1/accounts/validate/exists", credential: true, data: { type: request.type, value: request.value } });
  };
}
