import * as Entity from "@/domain/entity";
import * as ViewData from "@/application/view-data";

type NestedObject = {
  [key: string]: NestedObject | boolean;
};
class ViewModelUtil {
  formatDateFromYYYYMMDD = (yyyymmdd: string, formatType: "yyyy.mm.dd" | "yyyy-mm-dd" | "yy.mm.dd" | "mm.dd"): string => {
    if (yyyymmdd.length !== 8) {
      throw new Error("Invalid date string format");
    }

    const year = yyyymmdd.substring(0, 4);
    const month = yyyymmdd.substring(4, 6);
    const day = yyyymmdd.substring(6, 8);

    switch (formatType) {
      case "yyyy.mm.dd":
        return `${year}.${month}.${day}`;

      case "yy.mm.dd":
        return `${year.substring(2, 4)}.${month}.${day}`;

      case "yyyy-mm-dd":
        return `${year}-${month}-${day}`;

      case "mm.dd":
        return `${month}.${day}`;

      default:
        return "";
    }
  };

  formatDate = (
    date: Date,
    formatType: "yyyy-mm-dd" | "yy-mm-dd" | "hh:mm" | "yy.mm.dd hh:mm" | "yy-mm-dd hh:mm" | "yy.mm.dd" | "mm.dd" | "yyyy-mm-dd hh:mm",
  ) => {
    const padZero = (num: number): string => (num < 10 ? `0${num}` : num.toString());
    const year = date.getFullYear();
    const month = padZero(date.getMonth() + 1);
    const day = padZero(date.getDate());
    const hours = padZero(date.getHours());
    const minutes = padZero(date.getMinutes());

    switch (formatType) {
      case "yyyy-mm-dd":
        return `${year}-${month}-${day}`;

      case "yy-mm-dd":
        return `${year.toString().slice(-2)}-${month}-${day}`;

      case "hh:mm":
        return `${hours}:${minutes}`;

      case "yy.mm.dd hh:mm":
        return `${year.toString().slice(-2)}.${month}.${day} ${hours}:${minutes}`;

      case "yy-mm-dd hh:mm":
        return `${year.toString().slice(-2)}-${month}-${day} ${hours}:${minutes}`;

      case "yy.mm.dd":
        return `${year.toString().slice(-2)}.${month}.${day}`;

      case "mm.dd":
        return `${month}.${day}`;

      case "yyyy-mm-dd hh:mm":
        return `${year}-${month}-${day} ${hours}:${minutes}`;

      default:
        return "";
    }
  };

  formatPhoneNumber = (number: string) => {
    const cleaned = number.replace(/\D/g, "");

    if (cleaned.length === 10) {
      return cleaned.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
    } else if (cleaned.length === 11) {
      return cleaned.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    } else {
      return number;
    }
  };

  calculateDaysSince = (date: string, type: "yyyymmdd") => {
    switch (type) {
      case "yyyymmdd":
        const year = parseInt(date.substring(0, 4), 10);
        const month = parseInt(date.substring(4, 6), 10) - 1;
        const day = parseInt(date.substring(6, 8), 10);
        const givenDate = new Date(year, month, day);

        const today = new Date();

        const diffTime = today.getTime() - givenDate.getTime();

        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        return diffDays;
    }
  };

  formatProductCode = (productCodes: string[]): ViewData.Hospital.ProductCode => {
    const result: NestedObject = {};

    productCodes.forEach((code) => {
      const parts = code.split(".");
      let current = result;

      parts.forEach((part, index) => {
        if (index === parts.length - 1) {
          current[part] = true;
        } else {
          if (!(part in current)) {
            current[part] = {};
          }
          current = current[part] as NestedObject;
        }
      });
    });

    return result as ViewData.Hospital.ProductCode;
  };

  createObjectURL = async (url: string) => {
    const file = await this.convertURLtoFile(url);
    const objectURL = window.URL.createObjectURL(file);

    return objectURL;
  };

  removeSignedURL = (signedURL: string) => {
    const [url, _] = signedURL.split("?");
    return url;
  };

  formatMsToTime = (ms: number): string => {
    const totalSeconds = Math.floor(ms / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  formatUCT = (utc: string, type: "yyyy.mm.dd") => {
    const utcDate = new Date(utc);
    const kstOffset = 9 * 60 * 60 * 1000;
    const kstDate = new Date(utcDate.getTime() + kstOffset);

    const yy = String(kstDate.getFullYear()).slice(2);
    const mm = String(kstDate.getMonth() + 1).padStart(2, "0");
    const dd = String(kstDate.getDate()).padStart(2, "0");

    switch (type) {
      case "yyyy.mm.dd":
        return `${yy}.${mm}.${dd}`;

      default:
        return "";
    }
  };

  private convertURLtoFile = async (url: string) => {
    try {
      const response = await fetch(url);
      const data = await response.blob();
      const ext = url.split(".").pop();
      const filename = url.split("/").pop();
      const metadata = { type: `image/${ext}` };
      return new File([data], filename!, metadata);
    } catch (err) {
      throw err;
    }
  };
}

export default new ViewModelUtil();
