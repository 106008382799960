import { injectable } from "inversify";
import { PlanSettingViewModel } from "./model/PlanSettingViewModel";
import { BehaviorSubject, Subject } from "rxjs";
import type { UISystemManager } from "../ui-system/view-model/model";
import type { GetContract } from "@/domain/usecase/hospital/model/GetContract";
import Utils from "./util/ViewModelUtil";
import { Hospital } from "@/application/view-data";
@injectable()
export class PlanSettingViewModelImpl implements PlanSettingViewModel {
  output: PlanSettingViewModel["output"] = {
    planName: new BehaviorSubject<string | null>(null),
    period: new BehaviorSubject<Hospital.ContractPeriod | null>(null),
    faceFitPart: new BehaviorSubject<string | null>(null),
    openPlanInfo: new Subject<boolean>(),
    totalAccounts: new BehaviorSubject<number | null>(null),
    limit: new BehaviorSubject<number | null>(null),
  };

  constructor(readonly uiSystem: UISystemManager, private readonly getContract: GetContract) {
    this.init();
  }

  private init = () => {
    const sub = this.getContract.execute().subscribe({
      next: (contract) => {
        const availableFaceFitPart = this.setAvailableFaceFitPart(contract.plan.productCodes);
        const total = contract.plan.accounts.filter((account) => account.usage !== null).length;

        this.output.planName.next(contract.plan.name);
        this.output.period.next({
          startAt: { date: contract.period.startAt, "yy.mm.dd": Utils.formatDate(contract.period.startAt, "yy.mm.dd") },
          endAt: { date: contract.period.endAt, "yy.mm.dd": Utils.formatDate(contract.period.endAt, "yy.mm.dd") },
        });

        this.output.faceFitPart.next(availableFaceFitPart);
        this.output.totalAccounts.next(total);
        this.output.limit.next(contract.plan.accounts.length);

        sub.unsubscribe();
      },
      error: (error) => {
        if (error?.error_message === "user status is not activated") {
          this.uiSystem.errorHandler.alert.next({ message: "비활성화된 계정입니다.\n관리자에게 문의해주세요." });
        }
        sub.unsubscribe();
      },
    });
  };

  private setAvailableFaceFitPart = (productCodes: string[]) => {
    const productCode = Utils.formatProductCode(productCodes);
    let result = [];

    if (productCode.facefit?.operations?.eyes) {
      result.push("눈");
    }

    if (productCode.facefit?.operations?.nose) {
      result.push("코");
    }

    if (productCode.facefit?.operations?.facialcontouring) {
      result.push("윤곽");
    }

    return result.join(",");
  };
}
