import React from "react";
import Dialog from "@mui/material/Dialog";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";

interface FullScreenModalProps {
  open: boolean;
  children: React.ReactNode;
  disableAnimation?: boolean;
  bottomSlideTransitionComponent?: boolean;
}

const FullScreenModal: React.FC<FullScreenModalProps> = (props) => {
  const { disableAnimation = false } = props;
  return (
    <Dialog
      open={props.open}
      fullScreen
      TransitionProps={disableAnimation ? { timeout: 0 } : undefined}
      TransitionComponent={props.bottomSlideTransitionComponent ? Transition : undefined}
    >
      {props.children}
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

export default FullScreenModal;
