import { FaceFit } from "@view-data/index";
import { injectable } from "inversify";
import { ConsultationRecordingViewModel } from "./model/ConsultationRecordingViewModel";
import { Subject, mergeMap } from "rxjs";
import type { UISystemManager } from "../ui-system/view-model/model";
import type { GetConsultingNote } from "@/domain/usecase/consulting/model/GetConsultingNote";
import type { CreateConsultationRecording } from "@/domain/usecase/consulting/model/CreateConsultationRecording";
import type { CreateConsultingTranscriptions } from "@/domain/usecase/consulting/model/CreateConsultingTranscriptions";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
import Utils from "./util/ViewModelUtil";
import { v4 } from "uuid";
import { ObjectStorage } from "@/storage/ncloud/ObjectStorage";
import type { CreateOperationConsultingLog } from "@/domain/usecase/consulting/model/CreateOperationConsultingLog";
@injectable()
export class ConsultationRecordingViewModelImpl implements ConsultationRecordingViewModel {
  private mediaRecorder: MediaRecorder | null = null;
  private audioChunks: Blob[] = [];
  private stream: MediaStream | null = null;
  private isPaused: boolean = false;
  private recordingInterval: any = null;
  private recordingStartTime: number = 0;
  private elapsedTimeInSeconds: number = 0;
  private objectStorage = new ObjectStorage("caremind-v1");
  private clovaSpeechPath = `clova-speech/recording`;

  data: ConsultationRecordingViewModel["data"] = {
    consultation: null,
  };

  output: ConsultationRecordingViewModel["output"] = {
    consultation: new Subject<FaceFit.Consultation>(),
    recordingStatus: new Subject<"RECORDING" | "PAUSED">(),
    recordingTime: new Subject<string>(),
  };

  constructor(
    noteId: number,
    readonly uiSystem: UISystemManager,
    private readonly ucGetConsultingNote: GetConsultingNote,
    private readonly ucCreateConsultationRecording: CreateConsultationRecording,
    private readonly ucCreateConsultingTranscriptions: CreateConsultingTranscriptions,
    private readonly ucCreateOperationConsultingLog: CreateOperationConsultingLog,
  ) {
    if (noteId) {
      this.init(noteId);
    }
  }

  input: ConsultationRecordingViewModel["input"] = {
    clickPause: () => {
      this.togglePauseRecording();
      this.output.recordingStatus.next(this.isPaused ? "PAUSED" : "RECORDING");
    },
    clickStop: (close) => {
      this.uiSystem.popupHandler.alert.confirm.next({
        open: true,
        infoMessage: "상담녹음을 종료하시겠습니까?\n녹음이 종료되면 상담노트로 자동변환됩니다.",
        confirm: () => {
          this.event.onStopRecording(true, close);
          this.uiSystem.popupHandler.alert.confirm.next({ open: false });
        },
      });
    },
  };

  event: ConsultationRecordingViewModel["event"] = {
    onStartRecording: async (noteId, startRecording) => {
      const sub = this.ucCreateOperationConsultingLog
        .execute({ noteId: noteId, productCode: "facefit.recording", logCode: "RECORDING_START" })
        .subscribe({
          next: () => {
            startRecording();
            sub.unsubscribe();
          },
          error: () => {
            sub.unsubscribe();
          },
        });

      try {
        setTimeout(async () => {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          this.stream = stream;
          this.audioChunks = [];
          this.mediaRecorder = new MediaRecorder(stream);
          this.mediaRecorder.ondataavailable = (event) => {
            this.audioChunks.push(event.data);
          };
          this.mediaRecorder.start();
          this.startRecordingTimer();
        }, 300);
      } catch (error) {
        this.uiSystem.errorHandler.alert.next({ message: "음성녹음 권한이 거부되었습니다." });
      }
    },

    onStopRecording: async (isSave, close) => {
      if (this.mediaRecorder) {
        this.uiSystem.loadingHandler.backdropLoading.next(true);
        const uuid = v4();
        this.stopRecordingTimer();
        this.mediaRecorder.stop();
        this.mediaRecorder.onstop = async () => {
          try {
            if (this.data.consultation) {
              const recordingName = `${this.data.consultation.id}_${Utils.formatDate(new Date(), "yy-mm-dd")}_${uuid}`;
              const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" });
              const audioFile = new File([audioBlob], `${recordingName}.wav`, {
                type: "audio/wav",
              });
              const tracks = this.mediaRecorder?.stream.getTracks();
              tracks?.forEach((track) => track.stop());
              const ffmpeg = new FFmpeg();
              await ffmpeg.load();
              await ffmpeg.writeFile(`${recordingName}.wav`, await fetchFile(audioFile));
              await ffmpeg.exec(["-i", `${recordingName}.wav`, `${recordingName}.mp3`]);
              const mp3Data = await ffmpeg.readFile(`${recordingName}.mp3`);
              const mp3Blob = new Blob([mp3Data], { type: "audio/mp3" });
              const mp3File = new File([mp3Blob], `${recordingName}.mp3`, {
                type: "audio/mp3",
              });

              if (isSave) {
                this.createConsultationRecording(this.data.consultation?.id, mp3File, recordingName, () => {
                  close();
                  this.uiSystem.loadingHandler.backdropLoading.next(false);
                });
              } else {
                this.ucCreateOperationConsultingLog
                  .execute({
                    noteId: this.data.consultation?.id,
                    productCode: "facefit.recording",
                    logCode: "RECORDING_CANCEL",
                  })
                  .subscribe({
                    next: () => {
                      this.uiSystem.loadingHandler.backdropLoading.next(false);
                      close();
                    },
                    error: () => {
                      this.uiSystem.loadingHandler.backdropLoading.next(false);
                      this.uiSystem.errorHandler.alert.next({ message: "상담종료 로깅을 실패하였습니다." });
                      this.event.onStopRecording(false, close);
                    },
                  });
              }
            }
          } catch (err) {
            this.uiSystem.loadingHandler.backdropLoading.next(false);
            this.uiSystem.errorHandler.alert.next({ message: `파일 생성 실패\n${err}` });
            this.event.onStopRecording(false, close);
          }
        };
      } else {
        close();
      }
    },

    onResetRecording: () => {
      this.mediaRecorder = null;
      this.audioChunks = [];
      this.isPaused = false;
      this.recordingInterval = null;
      this.elapsedTimeInSeconds = 0;
      this.output.recordingTime.next("00:00");
    },

    onInit: (noteId) => {
      this.init(noteId);
    },
  };

  private init = (noteId: number) => {
    const sub = this.ucGetConsultingNote.execute({ noteId }).subscribe({
      next: (note) => {
        this.data.consultation = note;
        this.output.consultation.next(note);
        sub.unsubscribe();
      },
      error: (err) => {
        console.log("에러", err);
        this.uiSystem.errorHandler.alert.next({ message: "상담정보를 불러오지 못했습니다." });
        sub.unsubscribe();
      },
    });
  };

  private togglePauseRecording = () => {
    if (this.mediaRecorder) {
      if (this.isPaused) {
        this.mediaRecorder.resume();
        this.isPaused = false;
        this.startRecordingTimer();
      } else {
        this.mediaRecorder.pause();
        this.isPaused = true;
        this.stopRecordingTimer();
      }
    } else {
    }
  };

  private createConsultationRecording = async (noteId: number, recordingFile: File, recordingFileName: string, complete: () => void) => {
    const recordingDurationTime = await this.getAudioDuration(recordingFile);

    if (this.data.consultation) {
      this.objectStorage
        .putObject(recordingFileName, this.clovaSpeechPath, recordingFile)
        .then((url) => {
          const sub = this.ucCreateConsultationRecording
            .execute({
              noteId,
              recordingFilename: recordingFileName + ".mp3",
              recordingOriginUrl: url,
              recordingDurationTime,
            })
            .pipe(
              mergeMap(({ recordId }) => {
                return this.ucCreateConsultingTranscriptions.execute({ recordId });
              }),
              mergeMap(() => {
                return this.ucCreateOperationConsultingLog.execute({
                  noteId: noteId,
                  productCode: "facefit.recording",
                  logCode: "RECORDING_END",
                });
              }),
              mergeMap(() => {
                return this.ucCreateOperationConsultingLog.execute({
                  noteId: noteId,
                  productCode: "facefit.recording",
                  logCode: "RECORDING_UPLOAD",
                });
              }),
              mergeMap(() => {
                return this.ucCreateOperationConsultingLog.execute({
                  noteId: noteId,
                  productCode: "facefit.recording",
                  logCode: "RECORDING_TRANSCRIPT",
                });
              }),
            )
            .subscribe({
              next: () => {
                complete();
                sub.unsubscribe();
              },
              error: () => {
                complete();
                this.uiSystem.errorHandler.alert.next({ message: "상담노트 생성을 실패하였습니다." });
                sub.unsubscribe();
              },
            });
        })
        .catch((err) => {
          complete();
          this.uiSystem.errorHandler.alert.next({ message: `상담노트 생성을 실패하였습니다.\n${err}` });
        });
    }
  };

  private startRecordingTimer = () => {
    this.recordingInterval = setInterval(() => {
      this.elapsedTimeInSeconds += 1;
      const minutes = Math.floor(this.elapsedTimeInSeconds / 60);
      const seconds = this.elapsedTimeInSeconds % 60;
      const timeString = `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
      this.output.recordingTime.next(timeString);
    }, 1000);
  };

  private stopRecordingTimer = () => {
    if (this.recordingInterval) {
      clearInterval(this.recordingInterval);
      this.recordingInterval = null;
      this.output.recordingStatus.next("PAUSED");
    }
  };

  private getAudioDuration = async (file: File): Promise<number> => {
    return new Promise((resolve, reject) => {
      const audio = new Audio();
      audio.preload = "metadata";

      audio.onloadedmetadata = () => {
        const duration = audio.duration * 1000;
        resolve(duration);
      };

      audio.onerror = () => {
        this.uiSystem.errorHandler.alert.next({ message: "MP3 파일의 길이를 가져올 수 없습니다." });
      };

      audio.src = URL.createObjectURL(file);
    });
  };
}
