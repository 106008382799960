import { inject, injectable } from "inversify";
import { Observable, map, mergeMap } from "rxjs";
import { Login, LoginInput, LoginOutput } from "./model/Login";
import type { TokenRepository } from "@/domain/interactor/repository/TokenRepository";
import type { UserRepository } from "@/domain/interactor/repository/UserRepository";
import type { SessionRepository } from "@/domain/interactor/repository/SessionRepository";
import type { LocalRepository } from "@/domain/interactor/repository/LocalRepository";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class LoginImpl implements Login {
  private readonly clientId = process.env.CLIENT_ID ?? "d6653243-49e3-49b8-9cf3-380a3d4d0501";
  private readonly grantType = "authorization_code";

  constructor(
    @inject(TYPES.TokenRepository) private readonly tokenRepository: TokenRepository,
    @inject(TYPES.SessionRepository) private readonly sessionRepository: SessionRepository,
    @inject(TYPES.LocalRepository) private readonly localRepository: LocalRepository,
    @inject(TYPES.UserRepository) private readonly userRepository: UserRepository,
  ) {}

  execute = (input: LoginInput): Observable<LoginOutput> => {
    return this.tokenRepository
      .getTokenBy({
        clientId: this.clientId,
        grantType: this.grantType,
        redirectUri: input.redirectUri,
        code: input.code,
      })
      .pipe(
        mergeMap(({ sessionToken, refreshToken }) => {
          this.sessionRepository.saveSessionToken(sessionToken);
          this.localRepository.saveRefreshToken(refreshToken);
          return this.userRepository.getUserV2();
        }),
        map((userInfo) => {
          this.sessionRepository.saveUser(userInfo);
          return;
        }),
      );
  };
}
