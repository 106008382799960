import { DrawingFacialLandmarks } from "../face/DrawingFacialLandmarks";
import { Config, ConnectionPoint, DenormalizedCoordinate, CanvasLineStyle, Coordinate, LandmarkPoint } from "../index.interface";
import Calc from "../utils/Calc";
import { BestRatioLineStyle, DotLineStyle, PhotoBaseLine } from "../utils/Styles";

export class FaceShapeType extends DrawingFacialLandmarks {
  ratio: {
    top: number;
    bottom: number;
  };

  constructor(config: Config) {
    super(config);
    this.ratio = this.calcRatio();
  }

  drawPhotoBase = async () => {
    this.photoBaseDrawer.drawFaceShapeLines();
    await this.delay(900);
    this.photoBaseDrawer.drawRatioText();
  };

  drawBestRatio = async () => {
    this.bestRatioDrawer.drawFaceShapeLines();
    await this.delay(900);
    this.bestRatioDrawer.drawRatioText();
  };

  private photoBaseDrawer = {
    drawFaceShapeLines: async () => {
      this.drawLineBetweenPoints({
        point1: this.faceCoordinates[227],
        point2: this.faceCoordinates[447],
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: this.faceCoordinates[136],
        point2: this.faceCoordinates[365],
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
      await this.delay(500);
      this.drawLineBetweenPoints({ point1: this.faceCoordinates[10], point2: this.faceCoordinates[152], lineStyle: DotLineStyle });
    },
    drawRatioText: async () => {
      const textStyle = { x: -10, y: -20, fontSize: 18, color: "#FF9900" };
      this.drawTextBetweenPoints(
        `${parseFloat((this.ratio.bottom * 100).toFixed(2)).toString()}%`,
        this.faceCoordinates[365],
        this.faceCoordinates[365],
        textStyle,
      );
    },
  };

  private bestRatioDrawer = {
    drawFaceShapeLines: async () => {
      const topFaceLength = Calc.calculateDistance(this.faceCoordinates[227], this.faceCoordinates[447], "2d");
      const bestLength = topFaceLength * 0.7;
      const intersectionPoint = Calc.findIntersectionPoint({
        lineA: { pointA: this.faceCoordinates[10], pointB: this.faceCoordinates[152] },
        lineB: { pointA: this.faceCoordinates[136], pointB: this.faceCoordinates[365] },
      });

      this.drawLineBetweenPoints({
        point1: this.faceCoordinates[227],
        point2: this.faceCoordinates[447],
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      if (intersectionPoint) {
        const bestRightCoordinate = { ...intersectionPoint, x: intersectionPoint.x - bestLength / 2 };
        const bestLeftCoordinate = { ...intersectionPoint, x: intersectionPoint.x + bestLength / 2 };
        this.drawLineBetweenPoints({
          point1: bestRightCoordinate,
          point2: bestLeftCoordinate,
          lineStyle: BestRatioLineStyle,
          isDrawEndPoint: true,
        });
      }
    },
    drawRatioText: async () => {
      const textStyle = { x: 87, y: -20, fontSize: 18, color: "#00D7CA" };
      const intersectionPoint = Calc.findIntersectionPoint({
        lineA: { pointA: this.faceCoordinates[10], pointB: this.faceCoordinates[152] },
        lineB: { pointA: this.faceCoordinates[136], pointB: this.faceCoordinates[365] },
      });
      if (intersectionPoint) {
        this.drawTextBetweenPoints(`70%`, intersectionPoint, intersectionPoint, textStyle);
      }
    },
  };

  private calcRatio = () => {
    const topFaceLength = Calc.calculateDistance(this.faceCoordinates[227], this.faceCoordinates[447], "2d");
    const bottomFaceLength = Calc.calculateDistance(this.faceCoordinates[136], this.faceCoordinates[365], "2d");

    return {
      top: 1,
      bottom: Calc.calcRatio(topFaceLength, bottomFaceLength),
    };
  };

  private delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
}
