import styled from "styled-components";
import { ModalProps, ModalLayout } from "@view/widget/modal";
import { useEffect, useState } from "react";
import Icon from "@icon/index";
import { Layout, Typo } from "@view/widget";
import colors from "@view/styles/colors";

interface ImageListModalProps {
    open: boolean;
    disableBackdropClick?: boolean;
    // width: number;
    // height: number;
    imageList: string[];
    defaultIndex: number;
    close: () => void;
}

const ImageListModal: React.FC<ImageListModalProps> = ({ open, close, imageList, defaultIndex }) => {
    const [displayedIndex, setDisplayedIndex] = useState<number>(0);

    useEffect(() => {
        setDisplayedIndex(defaultIndex);
    }, [defaultIndex]);

    const handlePrevArrow = () => {
        setDisplayedIndex((prev) => (prev > 0 ? prev - 1 : prev));
    };
    const handleNextArrow = () => {
        setDisplayedIndex((prev) => (prev < imageList.length - 1 ? prev + 1 : prev));
    };

    return (
        <>
            <ModalLayout
                open={open}
                close={close}
            >
                <ImageModalStyled>
                    <Layout.Stack
                        alignItems="center"
                        justifyContent="center"
                    >
                        <ImageContainer>
                            <ImageStyled src={imageList[displayedIndex]} />
                        </ImageContainer>
                        <Layout.Stack
                            direction="row"
                            alignItems="center"
                            width="188px"
                            justifyContent="space-between"
                        >
                            <Icon.ArrowLeftSLine
                                xxlarge
                                onClick={handlePrevArrow}
                            />
                            <Layout.Box>
                                <Typo.H1
                                    value={`${displayedIndex + 1}/${imageList.length}`}
                                    color={colors.neutralWhiteDefault}
                                />
                            </Layout.Box>
                            <Icon.ArrowRightSLine
                                xxlarge
                                onClick={handleNextArrow}
                            />
                        </Layout.Stack>
                    </Layout.Stack>
                    <CloseIconStyled onClick={close}>
                        <Icon.Close color="neutralWhiteDefault" />
                    </CloseIconStyled>
                </ImageModalStyled>
            </ModalLayout>
        </>
    );
};

const ImageContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 800px;
    height: 800px;
    img {
        height: 100%;
    }
`;

const ImageModalStyled = styled.div<{ overflow?: boolean }>`
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
    z-index: 999;
`;

export const CloseIconStyled = styled.div`
    position: absolute;
    width: 76px;
    height: 76px;
    top: 50px;
    right: 0px;
    cursor: pointer;
`;

const ImageStyled = styled.img`
    height: 100%;
    position: relative;
    object-fit: contain;
    top: 0;
`;

export default ImageListModal;
