import { injectable, inject } from "inversify";
import { Observable } from "rxjs";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { GetBestPracticePhotos, GetBestPracticePhotosInput, GetBestPracticePhotosOutput } from "./model/GetBestPracticePhotos";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class GetBestPracticePhotosImpl implements GetBestPracticePhotos {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute(input: GetBestPracticePhotosInput): Observable<GetBestPracticePhotosOutput> {
    return this.operationConsultingRepository.getBestPracticePhotos(input.ids);
  }
}
