import { injectable, inject } from "inversify";
import { Observable } from "rxjs";
import { GetFaceStyles, GetFaceStylesOutput } from "./model/GetFaceStyles";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class GetFaceStylesImpl implements GetFaceStyles {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute = (): Observable<GetFaceStylesOutput> => {
    return this.operationConsultingRepository.getFaceStyles();
  };
}
