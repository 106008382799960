import type { HospitalRepository } from "@/domain/interactor/repository/HospitalRepository";
import { inject, injectable } from "inversify";
import {
  GetAccountLoginIdValidation,
  GetAccountLoginIdValidationInput,
  GetAccountLoginIdValidationOutput,
} from "./model/GetAccountLoginIdValidation";
import { Observable } from "rxjs";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class GetAccountLoginIdValidationImpl implements GetAccountLoginIdValidation {
  constructor(@inject(TYPES.HospitalRepository) private readonly hospitalRepository: HospitalRepository) {}

  execute(input: GetAccountLoginIdValidationInput): Observable<GetAccountLoginIdValidationOutput> {
    return this.hospitalRepository.getAccountLoginIdValidation(input.type, input.value);
  }
}
