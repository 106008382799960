import { PersonaShiftStyle } from "@/domain/entity/ai-analysis/aiAnalysis.entity";
import { DoctorInspectStatus, DoctorSubjectCode, ViewData } from "@view-data/index";
import moment from "moment";
import "moment/locale/ko";

moment.locale("ko");

export const addComma = (price: number | string) => {
  if (!price) return "";
  const regexp = /\B(?=(\d{3})+(?!\d))/g;
  return price.toString().replace(regexp, ",");
};

export const parseDateString = (dateString?: string | Date, format = "YY.MM.DD HH:mm") => {
  if (!dateString) return "";
  const date = dateString;
  const formattedDate = moment(date).format(format);

  if (formattedDate === "Invalid date") return "00.00.00";
  return formattedDate;
};

export const addAlpha = (color: string, opacity: number): string => {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};

export const parseCategory = (input: number, option: ViewData.GetAdOptionsOutput) => {
  return option.category.find((item) => item.id === input)?.label || -1;
};

const parseRegionItem = (input: number, option: ViewData.GetAdOptionsOutput) => {
  return option.region.find((item) => item.id === input)?.label || -1;
};

export const parseRegionArray = (input: number[], option: ViewData.GetAdOptionsOutput) => {
  return input.map((item) => parseRegionItem(item, option));
};

export const passwordValidation = (password: string) => {
  const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/;

  return regex.test(password);
};

export const getDbPrice = (isSeoul: boolean, price: number) => {
  switch (isSeoul) {
    case false:
      if (price <= 50000) {
        return 17000;
      } else if (price <= 100000) {
        return 17000;
      } else if (price <= 200000) {
        return 23000;
      } else if (price <= 300000) {
        return 25000;
      } else if (price <= 500000) {
        return 30000;
      } else if (price <= 800000) {
        return 35000;
      } else if (price <= 1000000) {
        return 38000;
      } else if (price <= 1500000) {
        return 40000;
      } else if (price <= 2000000) {
        return 45000;
      } else if (price <= 2500000) {
        return 50000;
      } else if (price <= 5000000) {
        return 65000;
      } else {
        return 60000;
      }
    default:
      if (price <= 50000) {
        return 17000;
      } else if (price <= 100000) {
        return 20000;
      } else if (price <= 200000) {
        return 25000;
      } else if (price <= 300000) {
        return 30000;
      } else if (price <= 500000) {
        return 35000;
      } else if (price <= 800000) {
        return 40000;
      } else if (price <= 1000000) {
        return 45000;
      } else if (price <= 1500000) {
        return 47000;
      } else if (price <= 2000000) {
        return 50000;
      } else if (price <= 2500000) {
        return 55000;
      } else if (price <= 5000000) {
        return 60000;
      } else {
        return 65000;
      }
  }
};

export const convertUTCtoKST = (date: string, format?: string) => {
  return moment(date)
    .add(9, "hours")
    .format(format ? format : "YYYY-MM-DD HH:mm:ss");
};

export const convertInspectionStatus = (status: ViewData.InspectionHistory["status"]) => {
  switch (status) {
    case 0:
      return "검수대기";
    case 1:
      return "검수반려";
    case 2:
      return "검수승인";
    case 3:
      return "검수취소";
    default:
      return "";
  }
};

export const getInspectDateFromList = (inspectHistoryList: ViewData.InspectionHistory[]) => {
  const lastHistory = inspectHistoryList.reverse().find((e) => e.acceptedAt || e.rejectedAt);

  if (lastHistory?.acceptedAt) {
    return lastHistory.acceptedAt;
  } else if (lastHistory?.rejectedAt) {
    return lastHistory.rejectedAt;
  } else {
    return "-";
  }
};

export const getInspectDate = (inspectHistory: ViewData.InspectionHistory) => {
  if (inspectHistory.acceptedAt) {
    return inspectHistory.acceptedAt;
  } else if (inspectHistory.rejectedAt) {
    return inspectHistory.rejectedAt;
  } else {
    return "-";
  }
};

export const getDoctorConfirmStatus = (confirmCode: DoctorInspectStatus) => {
  switch (confirmCode) {
    case DoctorInspectStatus.PENDING:
      return "제출";
    case DoctorInspectStatus.REJECTED:
      return "반려";
    case DoctorInspectStatus.APPROVED:
      return "승인";
    default:
      return "";
  }
};

export const getSubjectLabel = (subjectCode: string) => {
  switch (subjectCode) {
    case DoctorSubjectCode.EYES:
      return "눈";
    case DoctorSubjectCode.NOSE:
      return "코";
    case DoctorSubjectCode.FACIAL_CONTOURING:
      return "윤곽/양악";
    case DoctorSubjectCode.LIPS:
      return "입술/인증";
    case DoctorSubjectCode.LIFTING:
      return "실리프팅/거상";
    case DoctorSubjectCode.SKINCARE:
      return "피부";
    case DoctorSubjectCode.FILLER:
      return "필러/보톡스/쁘띠";
    case DoctorSubjectCode.BREAST:
      return "가슴";
    case DoctorSubjectCode.MICRO_LIPO_INJECTION:
      return "지방이식";
    case DoctorSubjectCode.LIPO_SUCTION:
      return "지방흡입";
    case DoctorSubjectCode.ALL:
      return "전체";
    default:
      return "";
  }
};

export const maskMiddleNumber = (phone: string) => {
  if (!phone) return "";
  const numberPattern = /\d+/g;
  const numbers = phone.match(numberPattern);

  if (numbers && numbers.length > 0) {
    const number = numbers[0];
    const length = number.length;

    if (length > 4) {
      const startIndex = Math.floor((length - 4) / 2);
      const endIndex = startIndex + 4;
      const maskedNumber = number.substring(0, startIndex) + "*".repeat(4) + number.substring(endIndex);
      return phone.replace(number, maskedNumber);
    }
  }

  return phone;
};

export const getConsultationDay = (day: ViewData.OpeningDayOfWeek) => {
  switch (day) {
    case "MONDAY":
      return "월요일";
    case "TUESDAY":
      return "화요일";
    case "WEDNESDAY":
      return "수요일";
    case "THURSDAY":
      return "목요일";
    case "FRIDAY":
      return "금요일";
    case "SATURDAY":
      return "토요일";
    case "SUNDAY":
      return "일요일";
  }
};

export const getOperationName = (style: PersonaShiftStyle) => {
  switch (style) {
    case "inline":
      return "Style A";

    case "outline":
      return "Style B";

    case "demo":
      return "";
  }
};
