import styled from "styled-components";

import { ModalFrame, ModalProps } from "@view/widget/modal";
import Button from "@view/widget/button";
import colors from "@view/styles/colors";
import Typography from "@view/widget/typography";

export interface CommonModalProps extends ModalProps {
  title?: string;
  onClose: () => void;
  onClickClose?: () => void;
  onClickReject?: () => void;
  onClickOk?: () => void;
  okText?: string;
  cancelText?: string;
  rejectText?: string;
  buttonAlignCenter?: boolean;
  width?: number;
  footerContent?: React.ReactNode;
  isLarge?: boolean;
  minHeight?: string;
  okDisabled?: boolean | undefined;
}

const CommonModal = (props: CommonModalProps) => {
  const {
    maskDimmed = false,
    width = 280,
    okText = "확인",
    onClickOk,
    cancelText = "취소",
    buttonAlignCenter = false,
    onClickClose,
    rejectText = "반려",
    onClickReject,
    children,
    title,
    footerContent,
    isLarge = false,
    minHeight,
    okDisabled = false,
    ...restProps
  } = props;

  return (
    <ModalFrame
      maskDimmed={props.maskDimmed}
      modalLevel={200}
      {...restProps}
    >
      <Container
        width={width}
        minHeight={minHeight}
      >
        <TopContent>
          {title && (
            <Header>
              <Typography.B2
                bold
                value={title}
              />
            </Header>
          )}
          <Body>{children}</Body>
        </TopContent>
        <Footer buttonAlignCenter={buttonAlignCenter}>
          {footerContent}
          {onClickClose && (
            <Button.Rounded.Large
              backgroundColor={colors.drwo_bg_prim_point}
              color={colors.drwo_prim_disabled_600}
              text={cancelText}
              onClick={onClickClose}
            />
          )}
          {onClickOk &&
            (isLarge ? (
              <Button.Rounded.Large
                backgroundColor={colors.drwo_prim_green_600}
                text={okText}
                onClick={onClickOk}
                disabled={okDisabled}
              />
            ) : (
              <Button.Rounded.Small
                backgroundColor={colors.drwo_prim_green_600}
                text={okText}
                onClick={onClickOk}
                disabled={okDisabled}
              />
            ))}
          {onClickReject &&
            (isLarge ? (
              <Button.Rounded.Large
                backgroundColor={colors.semanticError}
                text={rejectText}
                onClick={onClickReject}
              />
            ) : (
              <Button.Rounded.Small
                backgroundColor={colors.semanticError}
                text={rejectText}
                onClick={onClickReject}
              />
            ))}
        </Footer>
      </Container>
    </ModalFrame>
  );
};

const Container = styled.div<Pick<CommonModalProps, "width" | "minHeight">>`
  min-width: ${(props) => props.width}px;
  ${(props) =>
    props.minHeight &&
    `
    min-height: ${props.minHeight};
  `}
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
`;

const TopContent = styled.div``;

const Header = styled.div`
  padding: 8px 16px;
  border-bottom: 1px solid ${colors.neutralLineDefault};
`;

const Body = styled.div`
  padding: 16px;
`;

const Footer = styled.div<Pick<CommonModalProps, "buttonAlignCenter">>`
  padding: 16px 16px;
  display: flex;
  justify-content: ${(props) => (props.buttonAlignCenter ? "center" : "flex-end")};
  gap: 4px;
`;

export default CommonModal;
