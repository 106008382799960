import type { HospitalRepository } from "@/domain/interactor/repository/HospitalRepository";
import { inject, injectable } from "inversify";
import { CreateAccount, CreateAccountInput, CreateAccountOutput } from "./model/CreateAccount";
import { Observable } from "rxjs";
import { TYPES } from "@/data/interactor/repository/index.container.type";
import type { UserRepository } from "@/domain/interactor/repository/UserRepository";

@injectable()
export class CreateAccountImpl implements CreateAccount {
  constructor(@inject(TYPES.UserRepository) private readonly userRepository: UserRepository) {}

  execute(input: CreateAccountInput): Observable<CreateAccountOutput> {
    return this.userRepository.createUser(input.loginId, input.loginPassword, input.name, input.role);
  }
}
