import { injectable, inject } from "inversify";
import { CreateConsultingNote, CreateConsultingNoteInput, CreateConsultingNoteOutput } from "./model/CreateConsultingNote";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { Observable } from "rxjs";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class CreateConsultingNoteImpl implements CreateConsultingNote {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute(input: CreateConsultingNoteInput): Observable<CreateConsultingNoteOutput> {
    const { afoterUserId, customerName, photos, customerPhoneNumber, customerGender, referral, memo } = input;
    return this.operationConsultingRepository.createConsultingNote({
      afoterUserId: afoterUserId ?? null,
      customerGender: customerGender ?? null,
      photos: photos
        ? photos.map((photo, idx) => {
            return {
              ...photo,
              ordinal: idx + 1,
            };
          })
        : [],
      customerName: customerName ?? null,
      customerPhoneNumber: customerPhoneNumber ?? null,
    });
  }
}
