import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/material/styles";

interface SkeletonUIProps {
  width: number | "100%" | string;
  variant?: "rectangular" | "circular";
  height: number | "100%";
  sx?: React.CSSProperties;
  color?: string; // 색상 추가
  animation?: "pulse" | "wave";
}

const CustomSkeleton = styled(Skeleton)<{ color?: string }>`
  ${({ color }) => color && `background-color: ${color};`}
  &::after {
    ${({ color }) => color && `background: linear-gradient(90deg, ${color}, #777777, ${color})`}
  }
`;

const SkeletonUI: React.FC<SkeletonUIProps> = ({ width, height, animation = "wave", variant = "rectangular", sx, color }) => {
  return (
    <CustomSkeleton
      animation={animation}
      variant={variant ?? "rectangular"}
      width={width}
      height={height}
      sx={{ borderRadius: variant === "rectangular" ? "4px" : "", ...sx }}
      color={color}
    />
  );
};

export default SkeletonUI;
