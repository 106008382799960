class Utils {
  convertDateStringToLocalDate = (utc: string) => {
    const utcRegex = /Z$/;

    if (!utcRegex.test(utc)) {
      utc = utc + "Z";
    }

    return new Date(utc);
  };
}

export default new Utils();
