import * as Lined from "./LinedButton";
import { TextButton as Text } from "./TextButton";
import * as Filled from "./FilledButton";
import * as Rounded from "./RoundedButton";
import ComponentButton from "./ComponentButton";
import Switch from "./Switch";
import CheckBox from "./CheckBox";
import Primary from "./Primary";
import Cancel from "./Cancel";

export interface ButtonProps {
  text?: string;
  width?: number;
  height?: number;
  disabled?: boolean;
  fontSize?: number;
  onClick?: () => void;
  backgroundColor?: string;
  color?: string;
}

export default {
  Lined,
  Text,
  Filled,
  Rounded,
  ComponentButton,
  Switch,
  CheckBox,
  Primary,
  Cancel,
};
