import type { DoctorAPI } from "@/data/remote/api/doctor/model/DoctorAPI";
import { TYPES } from "@/data/remote/api/index.container.type";
import { DoctorRepository } from "@/domain/interactor/repository/DoctorRepository";
import * as VO from "@/domain/interactor/repository/vo";
import { inject, injectable } from "inversify";
import { Observable } from "rxjs";

@injectable()
export class DoctorRepositoryImpl implements DoctorRepository {
  constructor(@inject(TYPES.DoctorAPI) private readonly doctorAPI: DoctorAPI) {}

  getDoctors = (): Observable<VO.Doctor.InformationItems> => {
    return this.doctorAPI.getDoctors();
  };

  getDoctor = (doctorId: number): Observable<VO.Doctor.Information> => {
    return this.doctorAPI.getDoctor(doctorId);
  };

  createDoctor = (doctor: VO.Doctor.InformationForCreate): Observable<{ doctorId: number }> => {
    return this.doctorAPI.createDoctor(doctor);
  };

  updateDoctor = (doctor: VO.Doctor.InformationForUpdate): Observable<void> => {
    return this.doctorAPI.updateDoctor(doctor);
  };

  deleteDoctor = (doctor: VO.Doctor.InformationForDelete): Observable<void> => {
    return this.doctorAPI.deleteDoctor(doctor);
  };
}
