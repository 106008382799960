import { catchError, from, map, Observable, Subscriber } from "rxjs";
import { injectable } from "inversify";
import { Gcs, GetFaceLandmarksResponse, PrivateUploadInput, UploadResponse } from "./index";

import axios from "axios";
interface RequestUpload {
  bucket?: string;
  filename?: string;
  file?: Buffer;
}

@injectable()
export default class GcsImpl implements Gcs {
  uploadBy(requestUpload: RequestUpload): Observable<UploadResponse> {
    return from(
      axios.post<UploadResponse>(`/api/gcs?bucket=${requestUpload.bucket}&filename=${requestUpload.filename}`, requestUpload.file),
    ).pipe(
      map((res) => {
        if (res.status !== 200) {
          throw new Error("이미지 업로드를 실패하였습니다. 다시 시도해주세요.");
        } else {
          const event = new Event("event");
          event.preventDefault();
          return res.data;
        }
      }),
      catchError((err) => {
        alert("이미지 업로드를 실패하였습니다. 다시 시도해주세요.");
        throw err;
      }),
    );
  }

  uploadPrivateBucket(request: PrivateUploadInput): Observable<UploadResponse> {
    const bucketBase = process.env.ENVIRONMENT === "prd" ? "caremind-v1" : "caremind-v1-test";

    return from(
      axios.post<UploadResponse>(
        `/api/gcs?bucket=${bucketBase}${request.path}&filename=${request.filename}`,
        this.stringToBuffer(request.fileSrc),
      ),
    ).pipe(
      map((res) => {
        if (res.status !== 200) {
          throw new Error("이미지 업로드를 실패하였습니다. 다시 시도해주세요.");
        } else {
          const event = new Event("event");
          event.preventDefault();
          return res.data;
        }
      }),
      catchError((err) => {
        alert("이미지 업로드를 실패하였습니다. 다시 시도해주세요.");
        throw err;
      }),
    );
  }

  getFaceLandmarks(url: string): Observable<GetFaceLandmarksResponse> {
    return new Observable<GetFaceLandmarksResponse>((observable: Subscriber<GetFaceLandmarksResponse>) => {
      axios
        .get(url)
        .then((res) => {
          return observable.next(res.data);
        })
        .catch((err) => {
          console.log(err, "<<에러");
          return observable.error(err);
        });
    });
  }

  generateSignedUrlV4(originUrl: string, expiresIn: number): Observable<string> {
    const bucketBase = process.env.ENVIRONMENT === "prd" ? "caremind-v1" : "caremind-v1-test";
    const filename = originUrl.split(bucketBase)[1];
    const expires = Date.now() + expiresIn;

    return from(axios.post<{ url: string }>(`/api/gcs?bucket=${bucketBase}&file=${filename}&expires=${expires}`)).pipe(
      map((res) => {
        if (res.status !== 200) {
          throw new Error("SigedUrl 생성에 실패했습니다. 다시 시도해주세요.");
        } else {
          const event = new Event("event");
          event.preventDefault();
          return res.data.url;
        }
      }),
      catchError((err) => {
        alert("SigedUrl 생성에 실패했습니다. 다시 시도해주세요.");
        throw err;
      }),
    );
  }

  getSignedURL = (bucket: string, pathAndFilename: string, expiresAt: number): Observable<{ signedUrl: string }> => {
    return new Observable<{ signedUrl: string }>((observable) => {
      axios
        .post<{ signedUrl: string }>("/api/gcs/signed-url", undefined, {
          params: {
            bucket,
            filename: pathAndFilename,
            expires: expiresAt,
          },
        })
        .then((res) => {
          observable.next(res.data);
        })
        .catch((error) => observable.error(error));
    });
  };

  private stringToBuffer = (inputString: string): Buffer => {
    const buf = Buffer.from(inputString, "utf-8");
    return buf;
  };
}
