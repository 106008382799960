import Calc from "../utils/Calc";
import { CanvasDrawer } from "../utils/CanvasDrawer";
import { Config, ConnectionPoint, DenormalizedCoordinate, CanvasLineStyle, LandmarkPoint, Coordinate } from "../index.interface";
import { PhotoBaseLine, BestRatioLineStyle, DotLineStyle } from "../utils/Styles";

export class AsymmetricalEye extends CanvasDrawer {
  private landmarkPoints: LandmarkPoint[] = [159, 145, 386, 374, 226, 155, 359, 362];

  private coordinate: {
    rightEye: {
      topRight: Coordinate;
      topLeft: Coordinate;
      bottomRight: Coordinate;
      bottomLeft: Coordinate;
    };
    leftEye: {
      topRight: Coordinate;
      topLeft: Coordinate;
      bottomRight: Coordinate;
      bottomLeft: Coordinate;
    };
  };

  private bestRatioCoordinate: {
    rightEye: {
      topRight: Coordinate;
      topLeft: Coordinate;
      bottomRight: Coordinate;
      bottomLeft: Coordinate;
    };
    leftEye: {
      topRight: Coordinate;
      topLeft: Coordinate;
      bottomRight: Coordinate;
      bottomLeft: Coordinate;
    };
  };

  denormalizedCoordinate: DenormalizedCoordinate;
  ratio: { 좌: number; 우: number };

  constructor({ imageEle, canvasEle, normalizedCoordinate }: Config) {
    super(imageEle, canvasEle);
    this.denormalizedCoordinate = Calc.getDenormalizedCoordinates(imageEle, normalizedCoordinate, this.landmarkPoints);
    this.coordinate = this.getCoordinates();
    this.bestRatioCoordinate = this.getBestRatioCoordinate();
    this.ratio = this.calcBalanceRatio();
  }

  drawPhotoBase = () => {
    const rightEyeTextStyle = { x: -12, y: 13, fontSize: 18, color: "#FF9900" };
    const leftEyeTextStyle = { x: 24, y: 13, fontSize: 18, color: "#FF9900" };

    this.drawLineBetweenPoints({
      point1: this.coordinate.rightEye.topRight,
      point2: this.coordinate.rightEye.topLeft,
      lineStyle: PhotoBaseLine,
      isDrawEndPoint: true,
    });
    this.drawLineBetweenPoints({
      point1: this.coordinate.rightEye.bottomLeft,
      point2: this.coordinate.rightEye.bottomRight,
      lineStyle: PhotoBaseLine,
      isDrawEndPoint: true,
    });
    this.drawLineBetweenPoints({
      point1: this.coordinate.leftEye.bottomRight,
      point2: this.coordinate.leftEye.bottomLeft,
      lineStyle: PhotoBaseLine,
      isDrawEndPoint: true,
    });

    this.drawLineBetweenPoints({
      point1: this.coordinate.leftEye.topRight,
      point2: this.coordinate.leftEye.topLeft,
      lineStyle: PhotoBaseLine,
      isDrawEndPoint: true,
    });

    setTimeout(() => {
      this.drawTextBetweenPoints(
        `${this.ratio.우}`,
        this.coordinate.rightEye.topRight,
        this.coordinate.rightEye.bottomRight,
        rightEyeTextStyle,
      );
      this.drawTextBetweenPoints(
        `${this.ratio.좌}`,
        this.coordinate.leftEye.topRight,
        this.coordinate.leftEye.bottomLeft,
        leftEyeTextStyle,
      );
    }, 500);

    setTimeout(() => {
      this.drawLineBetweenPoints({
        point1: this.coordinate.rightEye.topRight,
        point2: this.coordinate.rightEye.bottomRight,
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
      this.drawLineBetweenPoints({
        point1: this.coordinate.leftEye.topRight,
        point2: this.coordinate.leftEye.bottomLeft,
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
    }, 300);
  };

  drawBestRatio = () => {
    const rightEyeTextStyle = { x: -12, y: -13, fontSize: 18, color: "#00D7CA" };
    const leftEyeTextStyle = { x: 12, y: -13, fontSize: 18, color: "#00D7CA" };

    setTimeout(() => {
      this.drawLineBetweenPoints({
        point1: this.bestRatioCoordinate.rightEye.topRight,
        point2: this.bestRatioCoordinate.rightEye.topLeft,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: this.bestRatioCoordinate.rightEye.bottomLeft,
        point2: this.bestRatioCoordinate.rightEye.bottomRight,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: this.bestRatioCoordinate.leftEye.bottomRight,
        point2: this.bestRatioCoordinate.leftEye.bottomLeft,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: this.bestRatioCoordinate.leftEye.topRight,
        point2: this.bestRatioCoordinate.leftEye.topLeft,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });
    }, 600);

    setTimeout(() => {
      this.drawTextBetweenPoints(`${1}`, this.coordinate.rightEye.topRight, this.coordinate.rightEye.bottomRight, rightEyeTextStyle);
      this.drawTextBetweenPoints(`${1}`, this.coordinate.leftEye.topRight, this.coordinate.leftEye.bottomLeft, leftEyeTextStyle);
    }, 800);

    setTimeout(() => {
      this.drawLineBetweenPoints({
        point1: this.coordinate.rightEye.topRight,
        point2: this.coordinate.rightEye.bottomRight,
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
      this.drawLineBetweenPoints({
        point1: this.bestRatioCoordinate.leftEye.topRight,
        point2: this.bestRatioCoordinate.leftEye.bottomLeft,
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
    }, 900);
  };

  private getCoordinates = () => {
    const rightEye = {
      topRight: {
        ...this.denormalizedCoordinate[159],
        x: this.denormalizedCoordinate[226].x,
      },
      topLeft: {
        ...this.denormalizedCoordinate[159],
        x: this.denormalizedCoordinate[155].x,
      },
      bottomRight: {
        ...this.denormalizedCoordinate[145],
        x: this.denormalizedCoordinate[226].x,
      },
      bottomLeft: {
        ...this.denormalizedCoordinate[145],
        x: this.denormalizedCoordinate[155].x,
      },
    };

    const leftEye = {
      topRight: {
        ...this.denormalizedCoordinate[386],
        x: this.denormalizedCoordinate[359].x,
      },
      topLeft: {
        ...this.denormalizedCoordinate[386],
        x: this.denormalizedCoordinate[362].x,
      },
      bottomLeft: {
        ...this.denormalizedCoordinate[374],
        x: this.denormalizedCoordinate[359].x,
      },
      bottomRight: {
        ...this.denormalizedCoordinate[374],
        x: this.denormalizedCoordinate[362].x,
      },
    };

    return {
      rightEye,
      leftEye,
    };
  };

  private getBestRatioCoordinate = () => {
    const rightEye = {
      topRight: {
        ...this.denormalizedCoordinate[159],
        x: this.denormalizedCoordinate[226].x,
      },
      topLeft: {
        ...this.denormalizedCoordinate[159],
        x: this.denormalizedCoordinate[155].x,
      },
      bottomRight: {
        ...this.denormalizedCoordinate[145],
        x: this.denormalizedCoordinate[226].x,
      },
      bottomLeft: {
        ...this.denormalizedCoordinate[145],
        x: this.denormalizedCoordinate[155].x,
      },
    };

    const leftEye = {
      topRight: {
        ...this.denormalizedCoordinate[159],
        x: this.denormalizedCoordinate[359].x,
        y: this.denormalizedCoordinate[159].y,
      },
      topLeft: {
        ...this.denormalizedCoordinate[159],
        x: this.denormalizedCoordinate[362].x,
        y: this.denormalizedCoordinate[159].y,
      },
      bottomLeft: {
        ...this.denormalizedCoordinate[374],
        x: this.denormalizedCoordinate[359].x,
      },
      bottomRight: {
        ...this.denormalizedCoordinate[374],
        x: this.denormalizedCoordinate[362].x,
      },
    };

    return { rightEye, leftEye };
  };

  //눈 높이 비율 계산
  private calcBalanceRatio = () => {
    const rightEyeHeight = Calc.calculateDistance(this.coordinate.rightEye.topRight, this.coordinate.rightEye.bottomRight, "2d");
    const leftEyeHeight = Calc.calculateDistance(this.coordinate.leftEye.topRight, this.coordinate.leftEye.bottomLeft, "2d");

    const balance = {
      좌: Calc.calcRatio(rightEyeHeight, leftEyeHeight),
      우: Calc.calcRatio(rightEyeHeight, rightEyeHeight),
    };

    return balance;
  };
}
