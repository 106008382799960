import type { AdRepository } from "@/domain/interactor/repository/AdRepository";
import { inject, injectable } from "inversify";
import { map, Observable } from "rxjs";
import { TYPES } from "@/data/interactor/repository/index.container.type";
import {
  GetConsultingNotesByAdvertiseApply,
  GetConsultingNotesByAdvertiseApplyInput,
  GetConsultingNotesByAdvertiseApplyOutput,
} from "./model/GetConsultingNotesByAdvertiseApply";

@injectable()
export class GetConsultingNotesByAdvertiseApplyImpl implements GetConsultingNotesByAdvertiseApply {
  constructor(@inject(TYPES.AdRepository) private readonly adRepository: AdRepository) {}

  execute = (input: GetConsultingNotesByAdvertiseApplyInput): Observable<GetConsultingNotesByAdvertiseApplyOutput> => {
    return this.adRepository.getConsultationRequestDbs(input.page ?? 1, input.size ?? 20, input.keyword).pipe(
      map((res) => {
        return {
          page: res.page,
          size: res.size,
          total: res.total,
          last: res.isLast,
          items: res.content.map((content) => {
            return {
              id: content.id,
              memo: content.consulting?.memo ?? "",
              consultDate: content.consulting?.consultedAt ? content.consulting.consultedAt : null,
              reservationDate: content.reservationDate,
              applicationDate: content.appliedAt,
              advertisement: content.advertisement
                ? {
                    id: content.advertisement.id,
                    title: content.advertisement.title,
                  }
                : null,
              applicationStatus: content.consultingStatus,
              reservationStatus: content.reservationStatus,
              customer: {
                id: content.user.afoter?.id ?? 0,
                name: content.user.name,
                phoneNumber: content.user.phoneNumber,
              },
            };
          }),
        };
      }),
    );
  };
}
