import { injectable, inject } from "inversify";
import { Observable } from "rxjs";
import { GetConsultingNote, GetConsultingNoteInput, GetConsultingNoteOutput } from "./model/GetConsultingNote";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class GetConsultingNoteImpl implements GetConsultingNote {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute(input: GetConsultingNoteInput): Observable<GetConsultingNoteOutput> {
    return this.operationConsultingRepository.getConsultingNote(input.noteId);
  }
}
