import LoggerImpl from "./LoggerImpl";
import { OperatorFunction } from "rxjs";

export interface Logger {
  data: {
    env: Environment;
    loggerLevel: LoggerLevel;
    urgentLevel: UrgentLevel;
  };
  info: (logType: LogType, message?: string, ...data: object[]) => void;
  error: (logType: LogType, message?: string, ...data: object[]) => void;
  warn: (logType: LogType, message?: string, ...data: object[]) => void;
  debug: (logType: LogType, message?: string, ...data: object[]) => void;
  write<T>(
    logType: LogType,
    location: string,
    ...data: object[]
  ): OperatorFunction<T, T>;
}

export interface LoggerData {
  input?: object | string | number;
  output?: object | string | number;
  error?: object | string | number;
}

export type LogType = "public" | "private";
export type Environment = "stg" | "prd" | "dev";
export type LoggerStatus = "info" | "error" | "warn" | "debug";
export type UrgentLevel = Record<Environment, number>;
export type LoggerLevel = Record<LoggerStatus, number>;

export default new LoggerImpl();
