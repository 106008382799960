import { injectable, inject } from "inversify";
import { map, Observable } from "rxjs";
import { GetConsultingNotes, GetConsultingNotesInput, GetConsultingNotesOutput } from "./model/GetConsultingNotes";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class GetConsultingNotesImpl implements GetConsultingNotes {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute(input: GetConsultingNotesInput): Observable<GetConsultingNotesOutput> {
    return this.operationConsultingRepository
      .getConsultingNotes(
        input.page,
        input.size,
        input.consultingStatus,
        input.reservationStatus,
        input.keyword ?? null,
        input.consultant ?? undefined,
      )
      .pipe(
        map((res) => {
          res.items.sort((a, b) => {
            return b.createdAt.getTime() - a.createdAt.getTime();
          });
          return res;
        }),
      );
  }
}
