import { Layout } from "@view/widget";
import styled from "styled-components";
import Icon from "@icon/index";

interface ExpandablePhotoProps {
  onClickDetail: () => void;
  src: string;
  width?: string;
  height?: string;
  isAttachMent?: boolean;
}

const ExpandablePhoto: React.FC<ExpandablePhotoProps> = ({ src, width = "100%", height = "100%", onClickDetail, isAttachMent }) => {
  return isAttachMent ? (
    <Layout.Box
      position="relative"
      onClick={onClickDetail}
      width={width}
      sx={{ cursor: "pointer" }}
    >
      <AttachMentImageContainer width={width}>
        <img src={src} />
      </AttachMentImageContainer>
      <ButtonContainer>
        <Icon.ArrowZoom small />
      </ButtonContainer>
    </Layout.Box>
  ) : (
    <ImageContainer
      onClick={onClickDetail}
      width={width}
      height={height}
    >
      <img src={src} />
      <ButtonContainer>
        <Icon.ArrowZoom small />
      </ButtonContainer>
    </ImageContainer>
  );
};

const ImageContainer = styled.div<Pick<ExpandablePhotoProps, "width" | "height">>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: 323232;
  border: 1px solid #bdbdbd;
  background-color: #323232;
  border-radius: 8px;
  overflow: hidden;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  img {
    height: 100%;
  }
`;

const AttachMentImageContainer = styled.div<Pick<ExpandablePhotoProps, "width">>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${(props) => props.width};
  img {
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
  }
`;

const ButtonContainer = styled.div`
  background-color: #00000080;
  position: absolute;
  /* width: calc(100% * 0.195);
    height: calc(100% * 0.195); */
  width: 24px;
  height: 24px;
  right: 4px;
  bottom: 4px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ExpandablePhoto;
