import React, { useEffect } from "react";
import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import useRouter from "@view/hooks/useRouter";

interface BackgroundLoadingAndReplaceProps {
  loading?: boolean;
  progressColor?: "#14443F" | "#377bff";
  replace: {
    path: string;
    query?: { [key: string]: string | number };
  };
}

const BackgroundLoadingAndReplace: React.FC<BackgroundLoadingAndReplaceProps> = ({ loading, progressColor, replace }) => {
  const router = useRouter();
  useEffect(() => {
    router.replace({ pathname: replace.path, query: replace.query });
  }, []);

  return (
    <Backdrop
      open={loading === undefined ? true : loading}
      sx={{ zIndex: 1500 }}
    >
      <CircularProgress sx={{ color: progressColor ?? "#14443F" }} />
    </Backdrop>
  );
};

export default BackgroundLoadingAndReplace;
