import { injectable } from "inversify";
import { RecentConsultationsViewModel } from "./model/RecentConsultationsViewModel";
import { Subject } from "rxjs";
import type { UISystemManager } from "../ui-system/view-model/model";
import type { GetConsultingNotes } from "@/domain/usecase/consulting/model/GetConsultingNotes";
import { FaceFit } from "@/application/view-data";
import * as Entity from "@/domain/entity";
import type { CreateOperationConsultingLog } from "@/domain/usecase/consulting/model/CreateOperationConsultingLog";
@injectable()
export class RecentConsultationsViewModelImpl implements RecentConsultationsViewModel {
  data: RecentConsultationsViewModel["data"] = {
    consultations: null,
  };

  output: RecentConsultationsViewModel["output"] = {
    consultations: new Subject<FaceFit.Consultation[] | null>(),
    openRecodingModal: new Subject<{ noteId: number | null }>(),
  };

  route: RecentConsultationsViewModel["route"] = {
    toOriginPath: new Subject<{ noteId: number }>(),
    toHome: new Subject<void>(),
    toConsultationStatusTab: new Subject<{ status: Entity.Type.ConsultStatusForFaceFit }>(),
  };

  constructor(
    readonly uiSystem: UISystemManager,
    private readonly getConsultingNotes: GetConsultingNotes,
    private readonly ucCreateOperationConsultingLog: CreateOperationConsultingLog,
  ) {
    this.init();
  }

  input: RecentConsultationsViewModel["input"] = {
    clickLogo: () => {
      this.route.toHome.next();
    },
    clickRecording: (noteId) => {
      this.route.toConsultationStatusTab.next({ status: "IN_PROGRESS" });
      setTimeout(() => {
        this.output.openRecodingModal.next({ noteId });
      }, 300);
      setTimeout(() => {
        this.init("IN_PROGRESS");
      }, 1000);
    },
    clickTab: (tab: Entity.Type.ConsultStatusForFaceFit) => {
      this.route.toConsultationStatusTab.next({ status: tab });
    },
    clickCancelRecording: (noteId) => {
      this.uiSystem.popupHandler.alert.confirm.next({
        open: true,
        infoMessage: `녹음중 상태를 취소하시겠습니까?\n취소시 상태를 녹음대기로 변경하고 다시 녹음을 진행 할 수 있습니다.`,
        confirm: () => {
          this.uiSystem.loadingHandler.backdropLoading.next(true);
          const sub = this.ucCreateOperationConsultingLog
            .execute({ noteId, logCode: "RECORDING_CANCEL", productCode: "facefit.recording" })
            .subscribe({
              next: () => {
                this.init("IN_PROGRESS");
                sub.unsubscribe();
              },
              error: () => {
                this.uiSystem.loadingHandler.backdropLoading.next(false);
                sub.unsubscribe();
              },
            });
        },
      });
    },
  };

  event: RecentConsultationsViewModel["event"] = {
    onStartRecording: (noteId) => {
      this.route.toOriginPath.next({ noteId });
    },
    onCloseRecording: (toStatus) => {
      this.init(toStatus);
      this.route.toConsultationStatusTab.next({ status: toStatus });
      this.output.openRecodingModal.next({ noteId: null });
    },
    onGetFilteredConsultations: (status, showLoading) => {
      this.init(status, showLoading);
    },
    onLogRecordingEnd: () => {
      const noteId = localStorage.getItem("end_recording_note_id");
      if (noteId) {
        const sub = this.ucCreateOperationConsultingLog
          .execute({
            noteId: Number(noteId),
            logCode: "RECORDING_CANCEL",
            productCode: "facefit.recording",
          })
          .subscribe({
            next: () => {
              this.init();
              localStorage.removeItem("end_recording_note_id");
              sub.unsubscribe();
            },
            error: () => {
              sub.unsubscribe();
            },
          });
      }
    },
  };

  private init = (status?: Entity.Type.ConsultStatusForFaceFit, showLoading?: boolean) => {
    if (showLoading) {
      this.output.consultations.next(null);
    }

    const sub = this.getConsultingNotes
      .execute({
        page: 1,
        size: 100,
        consultingStatus: null,
        reservationStatus: null,
        keyword: null,
      })
      .subscribe({
        next: (paginatedNotes) => {
          this.data.consultations = paginatedNotes.items;
          if (status) {
            switch (status) {
              case "PENDING":
                const pendingConsultations = this.filterRecordingPending(paginatedNotes.items);
                this.output.consultations.next(pendingConsultations);
                break;

              case "IN_PROGRESS":
                const inProgressResults = this.filterOngoingRecordings(paginatedNotes.items);
                this.output.consultations.next(inProgressResults);
                break;

              case "COMPLETED":
                const completedResults = this.filterCompletedRecording(paginatedNotes.items);
                this.output.consultations.next(completedResults);
                break;
            }
          }

          this.uiSystem.loadingHandler.backdropLoading.next(false);
          sub.unsubscribe();
        },
        error: () => {
          this.uiSystem.loadingHandler.backdropLoading.next(false);
          sub.unsubscribe();
        },
      });
  };

  private filterRecordingPending = (consultations: FaceFit.Consultation[]): FaceFit.Consultation[] => {
    return consultations.filter((consultation) => {
      const logList = consultation.logs
        .map((log) => log)
        .filter((log) => log.code === "RECORDING_CANCEL" || log.code === "RECORDING_START" || log.code === "RECORDING_END")
        .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());

      if (logList[0]?.code === "RECORDING_CANCEL") return true;
      if (logList[0]?.code === "RECORDING_START") return false;
      if (consultation.recordings.length === 0) return true;
    });
  };

  private filterOngoingRecordings = (consultations: FaceFit.Consultation[]): FaceFit.Consultation[] => {
    const results = consultations.filter((consultation) => {
      const logList = consultation.logs
        .map((log) => log)
        .filter((log) => log.code === "RECORDING_CANCEL" || log.code === "RECORDING_START" || log.code === "RECORDING_END")
        .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());

      if (logList[0]?.code === "RECORDING_START") return true;
    });

    return results;
  };

  private filterCompletedRecording = (consultations: FaceFit.Consultation[]): FaceFit.Consultation[] => {
    const results = consultations.filter((consultation) => {
      const logList = consultation.logs
        .map((log) => log)
        .filter((log) => log.code === "RECORDING_CANCEL" || log.code === "RECORDING_START" || log.code === "RECORDING_END")
        .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());

      if (logList[0]?.code === "RECORDING_END") return true;
      if (logList[0]?.code === "RECORDING_CANCEL") return false;
      if (consultation.recordings.length > 0) return true;
    });

    return results;
  };
}
