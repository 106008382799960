import React from "react";
import Dialog from "@mui/material/Dialog";

interface CenterModalFrameProps {
    open: boolean;
    children: React.ReactNode;
    disableBackdropClick?: boolean;
    close: () => void;
}

const ModalLayout: React.FC<CenterModalFrameProps> = (props) => {
    const handleClose = (reason: "backdropClick" | "escapeKeyDown") => {
        if (props.disableBackdropClick) {
            if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                props.close();
            }
        } else {
            props.close();
        }
    };
    return (
        <Dialog
            open={props.open}
            onClose={(_, reason) => {
                handleClose(reason);
            }}
            PaperProps={{ sx: { borderRadius: "2px" } }}
            maxWidth="lg"
        >
            {props.children}
        </Dialog>
    );
};

export default ModalLayout;
