import Typography from "./Typography";

export interface TypographyProps {
  variant: typographyVariant;
  value?: string | number;
  bold?: boolean;
  color?: string;
  italic?: boolean;
  underLine?: boolean;
  strikeThrough?: boolean;
  margin?: string;
  fontWeight?: string;
  style?: React.CSSProperties;
}

type typographyVariant = "H1" | "H2" | "T1" | "T2" | "B1" | "B2" | "C1" | "C2";

type typographyOptionItem = {
  fontSize: number;
  boldFontWeight: number;
  defaultFontWeight: number;
};

export const typographyConfig: Record<typographyVariant, typographyOptionItem> = {
  H1: {
    fontSize: 24,
    boldFontWeight: 700,
    defaultFontWeight: 500,
  },
  H2: {
    fontSize: 22,
    boldFontWeight: 700,
    defaultFontWeight: 500,
  },
  T1: {
    fontSize: 20,
    boldFontWeight: 600,
    defaultFontWeight: 400,
  },
  T2: {
    fontSize: 18,
    boldFontWeight: 600,
    defaultFontWeight: 400,
  },
  B1: {
    fontSize: 16,
    boldFontWeight: 500,
    defaultFontWeight: 400,
  },
  B2: {
    fontSize: 14,
    boldFontWeight: 500,
    defaultFontWeight: 400,
  },
  C1: {
    fontSize: 12,
    boldFontWeight: 500,
    defaultFontWeight: 400,
  },
  C2: {
    fontSize: 11,
    boldFontWeight: 500,
    defaultFontWeight: 400,
  },
};

export default Typography;
