import React from "react";
import styled from "styled-components";
import * as ViewData from "@/application/view-data";
import { DropDown } from "@view/widget";
import Button from "@view/widget/button";
import * as Entity from "@/domain/entity";
interface ResultBadgeProps {
  reservationStatus: Entity.Type.ReservationStatus | null;
  clickReservationStatus: (status: Entity.Type.ReservationStatus) => void;
}

const ResultBadge: React.FC<ResultBadgeProps> = ({ reservationStatus, clickReservationStatus }) => {
  return (
    <DropDown.BasicMenu
      button={<Badge reservationStatus={reservationStatus} />}
      items={[
        {
          component: <Badge reservationStatus={"RESERVED"} />,
          click: () => {
            clickReservationStatus("RESERVED");
          },
        },
        {
          component: <Badge reservationStatus={"CANCELLED"} />,
          click: () => {
            clickReservationStatus("CANCELLED");
          },
        },
        {
          component: <Badge reservationStatus={"COMPLETED"} />,
          click: () => {
            clickReservationStatus("COMPLETED");
          },
        },
      ]}
    />
  );
};

export default ResultBadge;

const Badge: React.FC<Pick<ResultBadgeProps, "reservationStatus">> = ({ reservationStatus }) => {
  switch (reservationStatus) {
    case "NONE":
      return <BadgeLabel $result="NONE">수술예약</BadgeLabel>;

    case "RESERVED":
      return <BadgeLabel $result="RESERVATION">수술예약</BadgeLabel>;

    case "CANCELLED":
      return <BadgeLabel $result="CANCEL">취소</BadgeLabel>;

    case "COMPLETED":
      return <BadgeLabel $result="OPERATION_COMPLEDTED">수술완료</BadgeLabel>;

    default:
      return <BadgeLabel $result="NONE">수술예약</BadgeLabel>;
  }
};

const BadgeLabel = styled.div<{ $result: ViewData.Consulting.Result }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 32px;
  border-radius: 5px;
  border: ${({ $result }) => ($result === "NONE" ? "1px" : "0px")} solid #bfbfbf;
  background: ${({ $result }) => {
    switch ($result) {
      case "NONE":
        return "#fff";

      case "RESERVATION":
        return "#29D8FE";

      case "CANCEL":
        return "#FF9900";

      case "OPERATION_COMPLEDTED":
        return "#02EFC4";
    }
  }};
  color: ${({ $result }) => ($result === "NONE" ? "#BFBFBF" : "#000")};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  z-index: 3;
  margin-bottom: 6px;
  margin-inline: 6px;

  &:nth-child(1) {
    margin-top: 6px;
  }
`;
