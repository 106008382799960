import React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

interface BottomModalProps {
  open: boolean;
  children: React.ReactNode;
  disableBackdropClick?: boolean;
  paperProps?: React.CSSProperties;
  close: () => void;
}

const BottomModal: React.FC<BottomModalProps> = (props) => {
  const handleClose = (reason: "backdropClick" | "escapeKeyDown") => {
    if (props.disableBackdropClick) {
      if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
        props.close();
      }
    } else {
      props.close();
    }
  };
  return (
    <Dialog
      open={props.open}
      onClose={(_, reason) => {
        handleClose(reason);
      }}
      PaperProps={{
        sx: {
          borderTopLeftRadius: "28px",
          borderTopRightRadius: "28px",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          position: "fixed",
          bottom: 0,
          margin: 0,
          width: "100%",
          maxHeight: "80vh",
          height: "auto",
          transform: "translateY(0)",
        },
      }}
      TransitionComponent={Transition}
    >
      {props.children}
    </Dialog>
  );
};

export default BottomModal;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});
