import React from "react";
import styled from "styled-components";
import { Typo } from "@view/widget";

interface HeaderProps {
  title: string;
  rightComponent?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({ title, rightComponent }) => {
  return (
    <HeaderLayout>
      <Typo.H1
        value={title}
        style={{ wordBreak: "keep-all" }}
        bold
      />
      <RightSide>{rightComponent}</RightSide>
    </HeaderLayout>
  );
};

export default Header;

const HeaderLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 28px;
  min-width: 1280px;
  height: 80px;
  padding-inline: 24px;
  border-bottom: 1px solid #eceef2;
`;

const RightSide = styled.div``;
