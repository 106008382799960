import React from "react";
import styled from "styled-components";

const PageFooterWrapper: React.FC<PageFooterWrapperProps> = ({ rightContent, leftContent }) => {
  return (
    <FooterContainer>
      <ContentContainer>{leftContent}</ContentContainer>
      <ContentContainer>{rightContent}</ContentContainer>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

interface PageFooterWrapperProps {
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
}

export default PageFooterWrapper;
