import { injectable, inject } from "inversify";
import { GetFaceLandmarks, GetFaceLandmarksInput, GetFaceLandmarksOutput } from "./model/GetFaceLandmarks";
import { Observable } from "rxjs";
import type { Storage } from "@/domain/interactor/storage";

@injectable()
export class GetFaceLandmarksImpl implements GetFaceLandmarks {
  constructor(
    @inject("Storage")
    private readonly storage: Storage,
  ) {}

  execute = (input: GetFaceLandmarksInput): Observable<GetFaceLandmarksOutput> => {
    return this.storage.getFaceLandmarks(input.url);
  };
}
