import type { OperationConsultingAPI } from "@/data/remote/api/operation-consulting/model/OperationConsultingAPI";
import { utcDateToKoreanDate } from "@/data/util";
import { CalculateSimilarityRequestType, PersonaShiftPart, PersonaShiftStyle } from "@/domain/entity/ai-analysis/aiAnalysis.entity";
import { AnalysisStatus } from "@/domain/entity/consulting/consulting.entity";
import {
  AIAnalysis,
  BestPractice,
  Consultant,
  ConsultingNote,
  CreateConsultingNote,
  Pagination,
  UpdateConsultingNote,
} from "@/domain/interactor/repository/vo/OperationConsulting";
import { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { injectable, inject } from "inversify";
import { Observable, map } from "rxjs";
import * as Entity from "@/domain/entity";
import * as VO from "@/domain/interactor/repository/vo";
import { TYPES } from "@/data/remote/api/index.container.type";
import Utils from "./Utils";

@injectable()
export class OperationConsultingRepositoryImpl implements OperationConsultingRepository {
  constructor(@inject(TYPES.OperationConsultingAPI) private readonly operationConsultingAPI: OperationConsultingAPI) {}

  createConsultingNote(model: CreateConsultingNote): Observable<{ consultingNoteId: number }> {
    const { customerName, customerGender, customerPhoneNumber, photos, referral, memo } = model;

    const requestPhotos = photos?.map((photo) => {
      return {
        type: photo.type,
        ordinal: photo.ordinal,
        originUrl: photo.originUrl,
        resizedUrl: photo.resizedUrl,
      };
    });

    return this.operationConsultingAPI
      .createConsultingNote({
        customerName: customerName ?? null,
        customerGender: customerGender ?? null,
        photos: requestPhotos ?? [],
        customerPhoneNumber: customerPhoneNumber ?? null,
        referral: referral ?? null,
        memo: memo ?? null,
      })
      .pipe(
        map((res) => {
          return { consultingNoteId: res.operationConsultingNoteId };
        }),
      );
  }

  updateConsultingNote(noteId: number, model: UpdateConsultingNote): Observable<{ consultingNoteId: number }> {
    const requestPhotos = model.photos?.map((photo) => {
      return {
        id: photo.id,
        type: photo.type,
        ordinal: photo.ordinal,
        originUrl: photo.originUrl,
        resizedUrl: photo.resizedUrl,
      };
    });

    const request = {
      customerName: model.customerName ?? null,
      customerPhoneNumber: model.customerPhoneNumber ?? null,
      customerGender: model.customerGender ?? null,
      referral: model.referral ?? null,
      photos: requestPhotos ?? [],
      memo: model.memo ?? null,
      categories: model.hospitalCategories ?? [],
      reservationDate: model.reservationDate?.toISOString() ?? null,
    };

    return this.operationConsultingAPI.putConsultingNote(noteId, request).pipe(
      map((res) => {
        return { consultingNoteId: res.operationConsultingNoteId };
      }),
    );
  }

  getConsultingNote(noteId: number): Observable<ConsultingNote> {
    return this.operationConsultingAPI.getConsultingNoteV2(noteId).pipe(
      map((res) => {
        return {
          id: res.id,
          hospitalId: 0,
          createdAt: utcDateToKoreanDate(res.createdAt),
          afoterUserId: res.afoterUserId,
          afoterUserNickname: res.afoterUserNickname,
          customerName: res.customerName,
          customerPhoneNumber: res.customerPhoneNumber,
          customerGender: res.customerGender,
          referral: res.referral,
          memo: res.memo,
          reservationDate: res.reservationDate ? utcDateToKoreanDate(res.reservationDate) : null,
          consultantName: res.consultantName,
          consultantUserKey: res.consultantUserId,
          consultingStatus: res.consultingStatus,
          reservationStatus: res.reservationStatus,
          photos: res.photos.map((photo) => {
            return {
              id: photo.id,
              type: photo.type,
              ordinal: photo.ordinal,
              originUrl: photo.originUrl,
              resizedUrl: photo.resizedUrl,
            };
          }),
          operationCategories: res.categories.map((category) => {
            return {
              id: category.id,
              ordinal: category.ordinal,
              operationName: category.operationName,
              subjectCode: category.subjectCode,
            };
          }),
          records: res.records,
          logs:
            res.logs?.map((log) => {
              return {
                ...log,
                createdAt: Utils.convertDateStringToLocalDate(log.createdAt),
              };
            }) ?? [],
          recordings: res.recordings,
        };
      }),
    );
  }

  requestFaceLandmark(noteId: number, photoId: number): Observable<{ analysisId: number; status: AnalysisStatus }> {
    return this.operationConsultingAPI.postFaceLandmark(noteId, photoId).pipe(
      map((res) => {
        return {
          analysisId: res.analysisId,
          status: res.status,
        };
      }),
    );
  }

  getBestPractice(
    page: number,
    size: number,
    gender: Entity.Type.GENDER | null,
    categories: number[] | null,
    exposure: boolean | null,
  ): Observable<Entity.Type.Pagination<BestPractice>> {
    return this.operationConsultingAPI
      .getBestPractice({
        page,
        size,
        gender: gender ? gender : undefined,
        categories: categories ? categories : undefined,
        exposure: exposure !== null ? exposure : undefined,
      })
      .pipe(
        map((res) => {
          const items: BestPractice[] = res.items.map((item) => {
            return {
              id: item.id,
              gender: item.gender,
              elapsedTime: item.elapsedTime,
              photos: item.photos.map((photo) => {
                return {
                  id: photo.id,
                  type: photo.type,
                  ordinal: photo.ordinal,
                  originUrl: photo.originUrl,
                  resizedUrl: photo.resizedUrl,
                  exposure: photo.exposure,
                  kind: photo.kind,
                };
              }),
              categories: item.categories.map((category) => {
                return {
                  id: category.id,
                  operationCategoryId: category.operationCategoryId,
                  subjectCode: category.subjectCode,
                  operationName: category.operationName,
                  ordinal: category.ordinal,
                };
              }),
              tags: item.tags,
            };
          });

          return {
            page: res.page,
            size: res.size,
            last: res.last,
            total: res.total,
            items,
          };
        }),
      );
  }

  getAIAnalysis(noteId: number): Observable<AIAnalysis> {
    return this.operationConsultingAPI.getAIAnalysis(noteId).pipe(
      map(({ faceShifts, faceLandmarks, faceSimilarities }) => {
        return {
          personaShifts: faceShifts.map((personaShift) => {
            return {
              id: personaShift.id,
              notePhotoId: personaShift.notePhotoId,
              code: "PERSONA_SHIFT",
              status: personaShift.status,
              result: personaShift.result
                ? {
                    imageURL: personaShift.result?.imageUrl,
                    operations: personaShift.result?.operations,
                  }
                : undefined,
            };
          }),
          faceLandmarks: faceLandmarks.map((faceLandmark) => {
            return {
              id: faceLandmark.id,
              code: "FACE_LANDMARKS",
              status: faceLandmark.status,
              notePhotoId: faceLandmark.notePhotoId,
              result: faceLandmark.result
                ? {
                    alignedImageURL: faceLandmark.result?.alignedImageUrl,
                    faceLandmarksJSONURL: faceLandmark.result?.faceLandmarksJsonUrl,
                  }
                : undefined,
            };
          }),
          faceSimilarities: faceSimilarities.map((faceSimilarity) => {
            return {
              id: faceSimilarity.id,
              code: "FACE_SIMILARITY",
              status: faceSimilarity.status,
              notePhotoId: faceSimilarity.note_photo_id,
              result: faceSimilarity.result
                ? {
                    photos: faceSimilarity.result?.photos?.map((photo) => {
                      return {
                        id: photo.id,
                        similarity: photo.similarity,
                      };
                    }),
                  }
                : undefined,
              requestAt: faceSimilarity.request_at,
              responseAt: faceSimilarity.response_at,
            };
          }),
        };
      }),
    );
  }

  getConsultingNotes(
    page: number,
    size: number,
    consultingStatus: Entity.Type.ConsultStatusForFaceFit | null,
    reservationStatus: Entity.Type.OperationStatusForFaceFit | null,
    keyword?: string | null,
    consultant?: number,
  ): Observable<Pagination<ConsultingNote>> {
    return this.operationConsultingAPI
      .getConsultingNotesV2(
        page,
        size,
        consultingStatus ?? undefined,
        reservationStatus ?? undefined,
        keyword ?? null,
        consultant ?? undefined,
      )
      .pipe(
        map((res) => {
          return {
            page: res.page,
            size: res.size,
            total: res.total,
            last: res.last,
            items: res.items.map((item) => {
              return {
                id: item.id,
                hospitalId: 0,
                createdAt: utcDateToKoreanDate(item.createdAt),
                afoterUserId: item.afoterUserId,
                afoterUserNickname: item.afoterUserNickname,
                customerName: item.customerName,
                customerPhoneNumber: item.customerPhoneNumber,
                customerGender: item.customerGender,
                referral: item.referral,
                memo: item.memo,
                reservationDate: item.reservationDate ? utcDateToKoreanDate(item.reservationDate) : null,
                consultantName: item.consultantName,
                consultantUserKey: item.consultantUserId,
                consultingStatus: item.consultingStatus,
                reservationStatus: item.reservationStatus,
                photos: item.photos.map((photo) => {
                  return {
                    id: photo.id,
                    type: photo.type,
                    ordinal: photo.ordinal,
                    originUrl: photo.originUrl,
                    resizedUrl: photo.resizedUrl,
                  };
                }),
                operationCategories: item.categories.map((category) => {
                  return {
                    id: category.id,
                    ordinal: category.ordinal,
                    operationName: category.operationName,
                    subjectCode: category.subjectCode,
                  };
                }),
                records: [],
                logs: item.logs.map((log) => {
                  return {
                    ...log,
                    createdAt: Utils.convertDateStringToLocalDate(log.createdAt),
                  };
                }),
                recordings: item.recordings,
              };
            }),
          };
        }),
      );
  }

  requestPersonaShift(
    noteId: number,
    model: { photoId: number; operations: { part: PersonaShiftPart; styles: PersonaShiftStyle[] }[] },
  ): Observable<{ analysisId: number; status: AnalysisStatus }> {
    return this.operationConsultingAPI
      .postPersonaShift(noteId, {
        operationConsultingNotePhotoId: model.photoId,
        operations: model.operations,
      })
      .pipe(
        map((res) => {
          return {
            analysisId: res.analysisId,
            status: res.status,
          };
        }),
      );
  }

  requestFaceSimilarity(
    noteId: number,
    photoId: number,
    photoURL: string,
    similarityRequestType: CalculateSimilarityRequestType,
    photos: { id: number }[],
  ): Observable<{
    analysisId: number;
    status: AnalysisStatus;
    calculateSimilarityRequestType: CalculateSimilarityRequestType;
    results: { id: number; similarity: number }[];
  }> {
    return this.operationConsultingAPI
      .postFaceSimilarity({
        noteId,
        operationConsultingNotePhotoId: photoId,
        calculatePhotoOriginUrl: photoURL,
        similarityRequestType: similarityRequestType,
        photos: photos,
      })
      .pipe(
        map((res) => {
          return {
            analysisId: res.analysisId,
            status: res.status,
            calculateSimilarityRequestType: res.calculateSimilarityRequestType,
            results: res.results,
          };
        }),
      );
  }

  getInfluencerPhotos(): Observable<{ items: { id: number; originURL: string }[] }> {
    return this.operationConsultingAPI.getInfluencerPhotos().pipe(
      map((res) => {
        return {
          items: res.items.map((item) => {
            return {
              id: item.id,
              originURL: item.originUrl,
            };
          }),
        };
      }),
    );
  }

  updateBestPracticeExposureStatus(exposureOn?: number[], exposureOff?: number[]): Observable<void> {
    return this.operationConsultingAPI.patchBestPracticeExposureStatus({
      exposureOn: exposureOn,
      exposureOff: exposureOff,
    });
  }

  getConsultants(): Observable<Consultant[]> {
    return this.operationConsultingAPI.getConsultants().pipe(
      map((consultants) => {
        return consultants.items.map((consultant) => {
          return {
            id: consultant.id,
            name: consultant.name,
          };
        });
      }),
    );
  }

  getBestPracticePhotos(ids: number[]): Observable<{ items: VO.OperationConsulting.BestPracticePhoto[] }> {
    return this.operationConsultingAPI.getBestPracticePhotos({ ids });
  }

  updateConsultingStatus({
    noteId,
    consultingStatus,
  }: {
    noteId: number;
    consultingStatus: Entity.Type.ConsultStatusForFaceFit;
  }): Observable<{ operationConsultingNoteId: number }> {
    return this.operationConsultingAPI.patchConsultingStatus({ noteId, consultingStatus });
  }

  updateReservationStatus({
    noteId,
    reservationStatus,
  }: {
    noteId: number;
    reservationStatus: Entity.Type.ReservationStatus;
  }): Observable<{ operationConsultingNoteId: number }> {
    return this.operationConsultingAPI.patchReservationStatus({ noteId, reservationStatus });
  }

  updateConsultingMemo({ noteId, memo }: { noteId: number; memo: string }): Observable<{ operationConsultingNoteId: number }> {
    return this.operationConsultingAPI.patchConsultingMemo({ noteId, memo });
  }

  updateConsultingReservationDate({
    noteId,
    reservationDate,
  }: {
    noteId: number;
    reservationDate: string;
  }): Observable<{ operationConsultingNoteId: number }> {
    return this.operationConsultingAPI.patchConsultingReservationDate({ noteId, reservationDate });
  }

  getTags = (): Observable<{ tags: VO.OperationConsulting.Tag[] }> => {
    return this.operationConsultingAPI.getTags();
  };

  getFaceStyles = (): Observable<{ items: VO.OperationConsulting.FaceStyle[] }> => {
    return this.operationConsultingAPI.getFaceStyles();
  };

  getOperationCategoryGroups = (): Observable<{ items: VO.OperationConsulting.OperationCategoryGroup[] }> => {
    return this.operationConsultingAPI.getOperationCategoryGroups();
  };

  createConsultationRecording = (
    noteId: number,
    recordingOriginUrl: string,
    recordingFilename: string,
    recordingDurationTime: number,
  ): Observable<{ recordId: number }> => {
    return this.operationConsultingAPI.postConsultationRecording({ noteId, recordingOriginUrl, recordingFilename, recordingDurationTime });
  };

  getConsultationRecording = (recordId: number) => {
    return this.operationConsultingAPI.getConsultationRecording({ recordId });
  };

  createConsultingTranscriptions = (recordId: number) => {
    return this.operationConsultingAPI.postConsultingTranscriptions({ recordId });
  };

  createOperationConsultingLog = (noteId: number, productCode: "facefit.recording", logCode: Entity.Type.LOG_CODE) => {
    return this.operationConsultingAPI.postOperationConsultingLog({ noteId, productCode, logCode });
  };

  createConsultingTranscriptionAnalysis = (recordId: number, assistantId: string) => {
    return this.operationConsultingAPI.postConsultingTranscriptionAnalysis({ recordId, assistantId });
  };
}
