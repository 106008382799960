import type { AuthAPI } from "@/data/remote/api/auth/model/AuthAPI";
import { Token } from "@/domain/entity/auth/token.entity";
import { TokenRepository } from "@/domain/interactor/repository/TokenRepository";
import { GetTokenModel } from "@/domain/interactor/repository/vo/Token";
import { inject, injectable } from "inversify";
import { Observable, map } from "rxjs";
import { TYPES } from "@/data/remote/api/index.container.type";

@injectable()
export class TokenRepositoryImpl implements TokenRepository {
  constructor(@inject(TYPES.AuthAPI) private readonly authAPI: AuthAPI) {}

  getTokenBy = (model: GetTokenModel): Observable<Token> => {
    return this.authAPI.generateTokenBy(model).pipe(
      map(({ session_token, refresh_token }) => {
        return {
          sessionToken: session_token,
          refreshToken: refresh_token,
        };
      }),
    );
  };
}
