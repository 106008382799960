import { API } from "@/data/remote/API";
import { injectable } from "inversify";
import { DoctorAPI } from "./model/DoctorAPI";
import { Observable } from "rxjs";
import { GetDoctorsResponse, GetDoctorResponse } from "./model/Response";
import { CreateDoctorRequest, DeleteDoctorRequest, UpdateDoctorRequest } from "./model/Request";

@injectable()
export class DoctorAPIImpl implements DoctorAPI {
  getDoctors = (): Observable<GetDoctorsResponse> => {
    return API.get({ path: "/dr-wo/v1/doctors", credential: true });
  };

  getDoctor = (doctorId: number): Observable<GetDoctorResponse> => {
    return API.get({ path: `/dr-wo/v1/doctor/${doctorId}`, credential: true });
  };

  createDoctor = (request: CreateDoctorRequest): Observable<{ doctorId: number }> => {
    return API.post({ path: `/dr-wo/v1/doctors`, credential: true, data: request });
  };

  updateDoctor = (request: UpdateDoctorRequest): Observable<void> => {
    return API.put({ path: `/dr-wo/v1/doctor/${request.doctorId}`, credential: true, data: request });
  };

  deleteDoctor = (request: DeleteDoctorRequest): Observable<void> => {
    return API.delete({ path: `/dr-wo/v1/doctor/${request.doctorId}`, credential: true });
  };
}
