import { injectable, inject } from "inversify";
import { Observable } from "rxjs";
import { GetInfluencerPhotos, GetInfluencerPhotosOutput } from "./model/GetInfluencerPhotos";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class GetInfluencerPhotosImpl implements GetInfluencerPhotos {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute(): Observable<GetInfluencerPhotosOutput> {
    return this.operationConsultingRepository.getInfluencerPhotos();
  }
}
