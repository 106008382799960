import { injectable } from "inversify";
import { FaceScanLeft45ViewModel } from "./model/FaceScanLeft45ViewModel";
import { Subject } from "rxjs";
import type { GetAIAnalysis } from "@/domain/usecase/consulting/model/GetAIAnalysis";
import type { ReqeustFaceLandmark } from "@/domain/usecase/consulting/model/RequestFaceLandmark";
import { FaceFit, FaceLandmark, Consulting } from "@/application/view-data";
import type { GetFaceLandmarks } from "@/domain/usecase/gcs/model/GetFaceLandmarks";
import { DrawingFacialLandmarks } from "./face-classification/face/DrawingFacialLandmarks";

@injectable()
export class FaceScanLeft45ViewModelImpl implements FaceScanLeft45ViewModel {
  data: FaceScanLeft45ViewModel["data"] = {
    landmarks: null,
    faceScanResult: null,
  };
  output: FaceScanLeft45ViewModel["output"] = {
    landmarks: new Subject<FaceLandmark.Coordinate[][]>(),
  };

  constructor(private readonly ucGetFaceLandmarksByGCS: GetFaceLandmarks) {}

  event: FaceScanLeft45ViewModel["event"] = {
    onInit: (faceScanResult) => {
      const sub = this.ucGetFaceLandmarksByGCS.execute({ url: faceScanResult.faceLandmarksJSONURL }).subscribe({
        next: ({ landmarks }) => {
          this.data.faceScanResult = faceScanResult;
          this.data.landmarks = landmarks;
          this.output.landmarks.next(this.data.landmarks);
          sub.unsubscribe();
        },
        error: () => {
          sub.unsubscribe();
        },
      });
    },

    face: {
      onSetFaceLandmark: (photo, canvas) => {
        if (this.data.landmarks) {
          const drawingFacialLandmarks = new DrawingFacialLandmarks({
            imageEle: photo,
            canvasEle: canvas,
            normalizedCoordinate: this.data.landmarks,
          });

          drawingFacialLandmarks.drawLandmarkPoints();
        }
      },
    },
  };
}
