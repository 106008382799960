import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    font-family: 'SUIT', sans-serif;
    margin: 0px;
    padding: env(safe-area-inset);
    height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    box-sizing: border-box;
  }

  div {
    font-family: 'SUIT', sans-serif;
  }

  img {
    user-drag: none;
    -webkit-user-drag: none; 
  }

  input {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    -moz-appearance: none;
    appearance: none;
  } 

  button {
    border: none;
    cursor: pointer;
  }

  input:focus {
    outline: none;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-out-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  textarea {
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none; 
  }

  ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  /* 공통 스크롤바 스타일 */
  ::-webkit-scrollbar {
    cursor: pointer;
  }

  /* 세로 스크롤바 스타일 */
  ::-webkit-scrollbar:vertical {
    width: 5px; /* 트랙의 너비 */
  }

  /* 가로 스크롤바 스타일 */
  ::-webkit-scrollbar:horizontal {
    height: 10px; 
  }

  ::-webkit-scrollbar-thumb {
    background-color: #36766d;
    border-radius: 3px;
    width: 3px; 
    box-sizing: border-box;
    border: solid 1px transparent;
  }

  ::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #C3C3C333;
  }
`;

export default GlobalStyle;
