import type { UISystemManager } from "@/application/ui-system/view-model/model";
import type { Logout } from "@/domain/usecase/auth/model/Logout";
import type { UserInfo } from "@view-data/User";
import { GlobalNavigationBarViewModel } from "@view-model/model/GlobalNavigationBarViewModel";
import { injectable } from "inversify";
import { Subject } from "rxjs";

@injectable()
export class GlobalNavigationBarViewModelImpl implements GlobalNavigationBarViewModel {
  uiSystem: UISystemManager;
  data: {
    user: UserInfo;
  };
  input: {
    clickSignOut: () => void;
  };
  route: {
    toLogin: Subject<void>;
  };

  constructor(private readonly uiSystemManager: UISystemManager, private readonly userInfo: UserInfo, private readonly ucLogout: Logout) {
    this.uiSystem = this.uiSystemManager;

    this.data = {
      user: this.userInfo,
    };

    this.route = {
      toLogin: new Subject(),
    };

    this.input = {
      clickSignOut: () => {
        this.uiSystem.loadingHandler.backdropLoading.next(true);
        this.ucLogout.execute().subscribe({
          complete: () => {
            this.route.toLogin.next();
          },
          error: (err) => {},
        });
      },
    };
  }
}
