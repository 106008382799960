import { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import DatePicker from "@view/widget/date-picker";
import CommonModal, { CommonModalProps } from "@view/component/modal/CommonModal";
import { FormGroup } from "@view/component/form/FormGroup";

interface ReservationDateModalProps extends Omit<CommonModalProps, "children" | "title"> {
  noteId: number | null;
  reservationDate: Date | null;
  inputFieldTitle: string;
  clickConfirm: (noteId: number, date: string) => void;
}

const ReservationDateModal: React.FC<ReservationDateModalProps> = ({
  reservationDate,
  noteId,
  inputFieldTitle,
  clickConfirm,
  ...props
}) => {
  const [targetDate, setTargetDate] = useState<string | undefined>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleDate = (value?: string) => {
    setTargetDate(value);
  };

  const handleOkButton = () => {
    if (targetDate && noteId) {
      clickConfirm(noteId, targetDate);
      props.onClose();
    }
  };

  const onClickInput = () => {
    setIsOpen(true);
  };

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref, isOpen]);

  useEffect(() => {
    setTargetDate(reservationDate ? reservationDate.toISOString() : "");
  }, [reservationDate]);

  return (
    <CommonModal
      title="예약일시"
      width={400}
      minHeight={isOpen ? "498px" : undefined}
      onClickOk={handleOkButton}
      onClickClose={props.onClose}
      isLarge
      {...props}
    >
      <FormGroup
        title={inputFieldTitle}
        content={
          <DatePickerLayout
            onClick={onClickInput}
            ref={ref}
          >
            <DatePicker.Default
              width="100%"
              placeHolderText="예약일시"
              dateFormat="yyyy.MM.dd HH:mm"
              date={targetDate}
              showTimeSelect
              onChange={handleDate}
            />
          </DatePickerLayout>
        }
      />
    </CommonModal>
  );
};

const DatePickerLayout = styled.div``;

export default ReservationDateModal;
