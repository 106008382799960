import * as Consulting from "./Consulting";
import * as Hospital from "./Hospital";
import * as FaceLandmark from "./FaceLandmark";
import * as AIAnalysis from "./AIAnalysis";
import * as Common from "./Common";
import * as User from "./User";
import * as FaceFit from "./FaceFit";
import * as Plan from "./Plan";
import * as Doctor from "./Doctor";
import * as Note from "./Note";
import * as UI from "./UI";
import * as Constant from "./Constant";
// import * as HospitalV2 from "./HospitalV2";

export declare namespace ViewData {
  interface AdListItem {
    adId?: number;
    draftId?: number;
    status: number;
    inspectionId?: number;
    partnerId: number;
    title: string;
    startsAt: string;
    endsAt: string;
    price: number;
    dbPrice: number;
    discount: number;
    category: number;
    region: number[];
    thumbnail: string;
    detailImages: string[];
    deliberationCode: string;
    closeAt?: string;
    applyCount?: number; // 신청 db 수
    registeredAt?: string;
    finishedAt?: string;
    createdAt?: string;
    rejectedComment?: string;
    requestCnt?: number;
    inspectType?: number;
  }
  interface LoginOutput {
    accountType: string;
    id: number;
  }

  interface AuthToken {
    sessionToken: string;
    refreshToken: string;
  }

  interface CommonSearchInput {
    [key: string]: number | string;
  }

  interface CommonListDisplayOutput<ListItem> {
    totalCount: number;
    page: number;
    list: ListItem[];
  }

  interface SaveDraftAdEventInput {
    adId?: number;
    adTitle: string;
    adStartDate?: string;
    adEndDate?: string;
    generalCost?: number;
    discountCost?: number;
    category?: number;
    region?: number[];
    thumbnail?: string;
    detailImages?: string[];
    dbCost?: number;
    deliberationCode?: string;
    price?: number;
    draftId?: number | null;
    type?: "CREATE" | "UPDATE" | "RESTART";
    finishedAt?: string;
    [key: string]: string | number | string[] | number[] | InspectionHistory[] | undefined | null;
  }

  interface AdOption {
    id: number;
    label: string;
  }

  interface GetAdOptionsOutput {
    category: AdOption[];
    region: AdOption[];
  }

  interface ValidationErrors {
    [key: string]: string;
  }

  interface InspectionHistory {
    id: number;
    draftId: number;
    status: AdInspectionStatus;
    rejectedAt?: string;
    rejectedBy?: string;
    rejectedComment?: string;
    createdAt: string;
    deletedAt?: string;
    acceptedAt?: string;
    acceptedBy?: string;
    finishedAt?: string;
    finishedBy?: string;
    canceledAt?: string;
    canceledBy?: string;
    type: AdInspectionType;
  }

  interface AdDetail {
    adId: number;
    title: string;
    startsAt: string;
    endsAt: string;
    price: number;
    dbPrice: number;
    discount: number;
    category: number;
    region: number[];
    thumbnail: string;
    detailImages: string[];
    deliberationCode: string;
    createdAt: string;
    finishedAt: string;
    pausedAt?: string;
    status: number;
    registeredAt?: string;
    inspectionHistory: InspectionHistory[];
    registerCnt: number;
  }

  type DraftAdDetailItem = AdDetail;

  interface MessageOutput {
    message: string;
    navigatePath: string;
    type?: string;
  }

  type AdsListDisplayOutput = CommonListDisplayOutput<AdListItem>;
  interface AdsListSearchOptions {
    startDate?: string;
    endDate?: string;
    searchWord?: string;
  }

  interface ConsultationRequestDBItem {
    id: number;
    partnerId: number;
    partnerName: string;
    adId: number;
    title: string;
    createdAt: string;
    registeredAt?: string;
    reservedAt?: string;
    memo?: string;
    status: number;
    requestedBy?: string;
    requesterName: string;
    phone?: string;
    marketingAgreedAt: boolean;
    isRefundRequested: boolean;
    refundMemo?: string;
    refundRequestStatus?: "PENDING" | "ACCEPTED" | "REJECTED";
    refundRequestComment?: string;
    missedCallMessageSendCount: number;
  }

  type ApplyEventListDisplayOutput = CommonListDisplayOutput<ConsultationRequestDBItem>;
  // interface ApplyEventSearchFieldOption = Omit<GetConsultationSubmissionsInput, "page">;
  interface ApplyEventSearchFieldOption {
    searchWord?: string;
    status?: ConsultationSubmissionStatus;
    startDate?: string;
    endDate?: string;
  }

  interface PaymentListItem {
    id: number;
    payMethod: string;
    paymentStatus: string;
    amt: number;
    vatAmt: number;
    taxAmt: number;
    regDate: string;
  }

  // interface PaymentListSearchFieldOption = Omit<GetSettlementTransactionsInput, "page">;
  interface PaymentListSearchFieldOption {
    payMethod?: string;
    startDate?: string;
    endDate?: string;
  }

  interface ReportListItem {
    id: number;
    date: string;
    adTitle: string;
    advertiserName: string;
    clickedCount: number;
    ctr: number;
    dbCost: number;
    deductedCost: number;
    exposedCount: number;
    registeredDate: string;
    requestedCount: number;
    updatedDate: string;
  }

  type ReportListDisplayOutput = CommonListDisplayOutput<ReportListItem>;

  interface ReportListSearchOptionItem {
    adId: number;
    adTitle: string;
    adStatus: number;
  }

  interface ReportListSearchFieldOption {
    adId?: number;
    startDate?: string;
    endDate?: string;
    size?: number;
  }

  interface Notice {
    id: number;
    context: string;
    created: string;
    title: string;
  }

  interface Pagination {
    count: number;
    limit: number;
    page: number;
  }

  interface NoticeListDisplayOutput {
    notices: Notice[];
    pagination: Pagination;
  }

  interface DoctorPhotos {
    id: number;
    location: string;
    locationThumbnail: string;
    tempDoctorId: number;
  }

  interface Doctor {
    id: number;
    partnerId: number;
    hospitalName: string;
    status: string;
    type: DoctorType;
    rank: DoctorRank;
    profile: string;
    name: string;
    description: string;
    specialty: string;
    experienceYears: number;
    phoneNumber?: string;
    exposureOrdinal?: string;
    loginId?: string;
    createdAt: string;
    privacyAgreedAt?: string;
    registeredAt: string;
    deletedAt?: string;
    subjects: DoctorSubjectCode[];
    certificatePhotos: CertificatePhotos[];
    lastSubmission?: LastSubmission;
    briefHistory: Brief[];
    commentCnt?: number;
  }

  interface DoctorListDisplayOutput {
    items: Doctor[];
    isLast: boolean;
    last: boolean;
    page: number;
    size: number;
    total: number;
  }

  interface FacilityPhotos {
    id: number;
    partnerId: number;
    imgUrl: string;
    thumbnailImgUrl: string;
    ordinal: number;
  }

  interface Temp {
    id: number;
    created: string;
    state: string;
    city: string;
    subway: string;
    clinic: string;
    profile?: string;
    zipcode: string;
    address1: string;
    address2: string;
    subject: string;
    tel: string;
    manager: string;
    description: string;
    partnerId: number;
    confirmedStatus: string;
    confirmedMessage?: string;
    positionX: string;
    positionY: string;
    managerTel: string;
    managerDepartment: string;
    managerPosition: string;
    bizTime: ConsultationTime[];
    specialNotice?: string;
    kakaoUrl: string;
    isChangeProfile?: boolean;
    tempPartnerId?: number;
    facilityPhotos: FacilityPhotos[];
  }

  interface Comment {
    id: number;
    created: string;
    updated: string;
    confirmed?: string;
    answer?: string;
    pdate: string;
    userName: string;
    confirmedStatus: string;
    doctorViewCount: number;
  }

  interface CommentListDisplayLayout {
    comments: Comment[];
    pagination: Pagination;
  }

  interface FeedbackListItem {
    name: string;
    operationId: number;
    channelId: string;
    phoneNumber: string;
    requestDate: string;
    operationList: string[];
    status: FeedbackStatus;
  }

  type FeedbackStatus = "PENDING" | "DONE" | "INPROGRESS";

  interface FeedbackListSearchOptions {
    status?: FeedbackStatus;
    patientName?: string;
  }

  interface PatientConfirm {
    id: number;
    requestDtm: string;
    patientName: string;
    patientTel: string;
    operationDate: string;
    operationPart: string;
    confirmedStatus: string;
  }

  interface PatientInfo {
    requestDtm: string;
    patientName: string;
    patientTel: string;
    operationDate: Date;
    operationPart: string[];
    operationDayCount: number;
    doctorName: string;
  }

  interface DateRange {
    startDate?: Date;
    endDate?: Date;
  }

  interface PatientInfoFilterOption {
    recoveryRate: DateRange;
    noteList: DateRange;
  }

  interface PatientRecoveryPercent {
    percent: number;
    date: Date;
  }

  interface PatientConfirmListItem {
    id: number;
    requestDtm: string;
    patientName: string;
    patientTel: string;
    operationDate: string;
    operationPart: string;
    confirmedStatus: ConfirmStatus;
    doctorId?: number;
    doctorName?: string;
    rejectReason: string;
  }

  type ConfirmStatus = "APPROVE" | "REJECT" | "CONFIRM_FAIL" | "CONFIRM_REQUEST" | "CONFIRM_RETRY";
  interface PatientConfirmSearchOptions {
    startDate?: string;
    endDate?: string;
    patientName?: string;
    confirmStatus?: ConfirmStatus;
  }

  interface PatientConfirmListDisplayLayout {
    pagination: Pagination;
    patientConfirms: PatientConfirm[];
  }

  interface PartnerFeedback {
    pendingConfirmPatientCnt: number;
    requestedCommentCnt: number;
    answeredCommentCnt: number;
    pendingCommentCnt: number;
  }

  interface Representative {
    id: number;
    email: string;
    name: string;
    phoneNumber: string;
    createdAt: string;
    updatedAt: string;
    type: "CONSULTATION" | "EVENT" | "CONTRACT" | "MISSED_CALL_SUPPORT";
  }

  interface PartnerDetail {
    id: number;
    partnerName: string;
    accountId: string;
    phoneNumber: string;
    bizAccountNumber: string;
    bizAccountCertificationUrl: string;
    representatives: Representative[];
    smsPhoneNumber?: string;
  }

  interface PartnerBizRegistrationInfo {
    id: number;
    partnerId: number;
    phoneNumber?: string;
    bizAccountNumber?: string;
    bizAccountCertificationUrl: string;
    smsPhoneNumber?: string;
    representatives: Representative[];
  }

  interface PartnerRepresentative {
    id?: number | null;
    partnerId: number;
    name: string;
    phoneNumber: string;
    email?: string | null;
    type: "CONSULTATION" | "EVENT" | "CONTRACT" | "MISSED_CALL_SUPPORT";
  }

  interface DoctorPasswordInfo {
    partnerDoctorId: number;
    doctorTel: string;
    password: string;
    commenter: boolean;
    isCreate?: boolean;
  }

  interface GetAdConsultationRequestDBResponse {
    size: number;
    page: number;
    total: number;
    isLast: boolean;
    content: ConsultationRequestDBItem[];
  }

  interface GetSettlementTransactionsResponse {
    size: number;
    page: number;
    total: number;
    isLast: boolean;
    items: SettlementTransaction[];
  }

  interface SettlementTransaction {
    id: number;
    accountId: number;
    amount: number;
    balance: number;
    operation: "DEPOSIT" | "REFUND" | "WITHDRAWAL" | "USED";
    createdAt: string;
    createdBy?: string;
  }
  interface LoginInput {
    accountType: string;
    loginId: string;
    password: string;
  }

  interface ChangePasswordFormData {
    partnerDoctorId: number;
    newPassword: string;
    currentPassword: string;
  }

  interface DoctorFormData {
    confirmedStatus: string;
    doctor: string;
    doctorTel: string;
    experience: string;
    isChangeProfile?: boolean;
    originProfile?: string;
    profile?: File;
    partnerId: number;
    partnerDoctorId?: number;
    tempPartnerDoctorId?: number;
    subject: string;
    description: string;
    hasAccount?: boolean;
    password?: string;
    commenter: boolean;
    head: boolean;
    certificate?: string;
    addedCertificationPhotos?: File[];
    deletedCertificationPhotoIds?: number[];
    newProfile?: File[];
    [key: string]: any;
  }

  interface PartnerFormData {
    address1: string;
    address2?: string;
    bizTime?: Temp["bizTime"];
    clinic?: string;
    description?: string;
    partnerId: number;
    tempPartnerId?: number;
    positionX?: string;
    positionY?: string;
    subject: string;
    subway: string;
    zipcode?: string;
    isChangeProfile?: boolean;
    profile?: File;
    addedFacilityPhotos?: File[] | null;
    deletedFacilityPhotoIds?: number[] | null;
    [key: string]: any;
    originProfile: string;
  }

  interface GetAdReportsInput {
    page: number;
    size?: number;
    adId: number;
    startDate: string;
    endDate: string;
  }

  interface CommenterData {
    id: number;
    doctorTel?: string;
    phoneNumber?: string;
    password?: string;
    currentPassword?: string;
    newPassword?: string;
    accountType?: string;
  }

  interface BizTime {
    A: "PM" | "AM";
    hh: string;
    mm: string;
  }

  type OpeningDayOfWeek = "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY";

  interface ConsultationTime {
    id?: number;
    day: OpeningDayOfWeek;
    open: string | null;
    close: string | null;
    dayOff: boolean;
    nightCare: boolean;
  }

  interface SavePartnerInformationInput {
    address1: string;
    address2?: string;
    bizTime?: Temp["bizTime"];
    clinic?: string;
    description?: string;
    partnerId: number;
    tempPartnerId?: number;
    positionX?: string;
    positionY?: string;
    subject: string;
    subway: string;
    zipcode?: string;
    isChangeProfile?: boolean;
    profile?: File;
    originProfile?: string;
    addedFacilityPhotos?: { ordinal: number; file: File }[] | null;
    deletedFacilityPhotoIds?: number[] | null;
  }

  interface AddedFacilityPhotos {
    imageUrl: string;
    fileList: File;
  }

  interface OperationType {
    operation: "PAUSE_FROM_ON_PROGRESS" | "ON_PROGRESS_FROM_PAUSE" | "FINISH_FROM_ON_PROGRESS" | "CANCEL_FROM_PENDING";
  }

  interface DraftAdDetail {
    adId?: number;
    draftId: number;
    partnerId: number;
    title: string;
    startsAt: string;
    endsAt: string;
    price: number;
    dbPrice: number;
    discount: number;
    category: number;
    region: number[];
    thumbnail: string;
    detailImages: string[];
    deliberationCode: string;
    inspectionHistory: InspectionHistory;
    status: number;
  }

  interface MissedCallHistoryList {
    items: MissedCallHistory[];
  }

  interface MissedCallHistory {
    id: number;
    consultationRequestDbId: number;
    sentAt: string;
    result: string;
  }

  interface CertificatePhotos {
    id?: number;
    originUrl: string;
    resizeUrl: string;
  }

  interface Brief {
    id?: number;
    ordinal: number;
    content: string;
  }

  interface DoctorInfoParams {
    profile: string;
    name: string;
    description: string;
    specialty?: string;
    experienceYears: number;
    rank: DoctorRank;
    briefHistory: Brief[];
    subjects: DoctorSubjectCode[];
    certificatePhotos: CertificatePhotos[];
    inspectType: DoctorInspectType;
  }

  interface SetDoctorResponse {
    partnerDoctorSubmissionId: number;
  }

  interface LastSubmission {
    id: number;
    partnerDoctorId: number;
    inspectType: DoctorInspectType;
    inspectStatus: DoctorInspectStatus;
    requestedFrom: "PARTNER" | "ADMIN";
    requestedBy: string;
    requestedAt: string;
    processedComment: string | null;
    processedFrom: "PARTNER" | "ADMIN" | null;
    processedBy: string | null;
    processedAt: string | null;
  }

  interface DoctorsQueryParams {
    type?: DoctorType;
    status?: DoctorStatus;
    size?: number;
    page?: number;
    keyword?: string;
  }

  interface SetCommenterParams {
    partnerDoctorId: number;
    loginId: string;
    password: string;
    phoneNumber: string;
  }

  interface DoctorInspectDetail {
    id: number;
    partnerDoctorId?: number;
    inspectType: DoctorInspectType;
    inspectStatus: DoctorInspectStatus;
    rank: DoctorRank;
    profile: string;
    name: string;
    description: string;
    specialty: string;
    experienceYears: number;
    phoneNumber?: string;
    exposureOrdinal?: number;
    loginId?: string;
    requestedFrom?: "PARTNER" | "ADMIN";
    requestedBy?: string;
    requestedAt?: string;
    processedComment?: string;
    processedFrom?: "ADMIN" | "PARTNER";
    processedBy?: string;
    processedAt?: string;
    certificatePhotos: CertificatePhotos[];
    subjects: DoctorSubjectCode[];
    briefHistory: Brief[];
  }

  interface ToastMessage {
    isSuccess: boolean;
    message: string;
    route?: string;
  }

  interface Patient {
    name: string;
    phone: string;
    operationDate: string;
    afterOperationDayCount: number;
    operationPartList: string[];
    consultationRequestDate: string;
    doctor: string;
  }

  interface NotePhoto {
    thumbNail: string;
    origin: string;
  }

  interface BESInfo {
    percent: number;
    leftDays: number;
  }

  interface PatientNote {
    noteDate: Date;
    dayCount: number;
    recoveryPercent: number;
    discomforts: string[];
    photoList: NotePhoto[];
    recoveryManagementList: string[];
    bes: {
      bruise: BESInfo;
      edema: BESInfo;
      scar: BESInfo;
    };
    inflammationRiskPercent: number;
  }

  export interface FeedbackMessageCreation {
    localId: string;
    content: string;
    attachmentList: FeedbackMessageImageInput[];
    sentAt: Date;
    status: "PENDING" | "FAIL";
  }

  export interface FeedbackMessageSending extends FeedbackMessageCreation {
    status: "PENDING";
  }

  export interface FeedbackMessageFailed extends FeedbackMessageCreation {
    status: "FAIL";
    errorMessage: string;
  }

  export interface FeedbackMessageImageInput {
    originalUrl: string;
    thumbnailUrl: string;
  }

  export interface FeedbackMessageImageInputWithObjectURL extends FeedbackMessageImageInput {
    objectURL: string;
  }

  export type FeedbackMessageSenderType = "USER" | "SYSTEM" | "DOCTOR" | "HOSPITAL";

  export type FeedbackMessageType = "CHAT" | "REQUEST" | "END_SUGGESTION" | "END" | "CONTINUE";
  export interface FeedbackMessage {
    id: string;
    sentAt: Date;
    type: FeedbackMessageType;
    content: string;
    sender: {
      type: FeedbackMessageSenderType;
      channelMemberId: number;
      name: string;
      profile: string;
    };
    actualSender: {
      type: FeedbackMessageSenderType;
      id: number;
      name: string;
    };
    read: boolean;
    attachmentList: FeedbackMessageAttachment[];
  }

  export interface FeedbackMessageAttachment {
    id: number;
    chatId: string;
    type: AttachmentType;
    ordinal: number;
    originUrl: string;
    thumbnailUrl: string;
    originObjectURL: string | null;
    thumbnailObjectURL: string | null;
  }

  export type AttachmentType = "IMAGE";

  interface FeedbackChannelStatus {
    startedAt: Date | null;
    closedAt: Date | null;
    doctorName: string;
    patientName: string;
  }

  interface Page<T> {
    size: number;
    page: number;
    total: number;
    isLast?: boolean;
    content: T[];
  }
}

export enum AdStatus {
  Progress = 0,
  Closed,
}

export enum AdDetailStatus {
  Draft = 0,
  INSPECTION_PENDING,
  INSPECTION_REJECTED,
  INSPECTION_APPROVED,
  INSPECTION_DELETED,
}

export enum InspectionType {
  Registration = 0,
  Exit,
  Edit,
}

export enum AdsListType {
  ProgressOrPause = 0,
  Closed,
  Inspection,
  Reject,
  Draft,
}

export enum AdResponseStatus {
  INSPECTION_PENDING = 0,
  INSPECTION_REJECTED,
  ON_PROGRESS,
  FINISHED,
  READY,
  CANCELLED,
  DELETED,
  PAUSE,
}

export enum InspectionStatus {
  Pending = 1,
  Deny,
  Reject,
}

export enum ConsultationSubmissionStatus {
  New = 0,
  NOT_RESPONDING, // 부재중
  CANCELED,
  ETC,
  RESERVED,
  RESERVED_CANCELED,
  VISITED,
}

export { ConsultationSubmissionStatus as ApplyEventStatus };

enum ConsultationSubmissionRefundStatus {
  Initial = 0,
  Pending,
  Approve,
  Reject,
}

export { ConsultationSubmissionRefundStatus as ApplyEventRefundStatus };

export enum AdInspectionStatus {
  PENDING = 0,
  REJECTED,
  APPROVED,
  CANCELED,
}

export enum AdInspectionType {
  CREATE = 0,
  UPDATE,
  RESTART,
}

export enum DoctorSubjectCode {
  EYES = "EYES",
  NOSE = "NOSE",
  FACIAL_CONTOURING = "FACIAL_CONTOURING",
  LIPS = "LIPS",
  LIFTING = "LIFTING",
  SKINCARE = "SKINCARE",
  FILLER = "FILLER",
  BREAST = "BREAST",
  MICRO_LIPO_INJECTION = "MICRO_LIPO_INJECTION",
  LIPO_SUCTION = "LIPO_SUCTION",
  ALL = "ALL",
}

export enum DoctorStatus {
  ACTIVATED = "ACTIVATED",
  DEACTIVATED = "DEACTIVATED",
}

export enum DoctorRank {
  CHIEF = "CHIEF",
  NORMAL = "NORMAL",
}

export enum DoctorType {
  NORMAL = "NORMAL",
  AFOTER_ATTENDING_PHYSICIAN = "AFOTER_ATTENDING_PHYSICIAN",
}

export enum DoctorInspectType {
  CREATE = "CREATE",
  UPDATE_INFORMATION = "UPDATE_INFORMATION",
  REGISTER_ACCOUNT = "REGISTER_ACCOUNT",
  DELETE = "DELETE",
  RESET_ACCOUNT = "RESET_ACCOUNT",
  UPDATE_PASSWORD = "UPDATE_PASSWORD",
  UPDATE_ACCOUNT = "UPDATE_ACCOUNT",
}

export enum DoctorInspectStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export interface Pagination<T> {
  page: number;
  size: number;
  total: number;
  isLast: boolean;
  items: T[];
}

export { User, Consulting, Hospital, FaceLandmark, AIAnalysis, Common, FaceFit, Plan, Doctor, Note, UI, Constant };
