import { injectable, inject } from "inversify";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { Observable } from "rxjs";
import { RequestFaceSimilarity, RequestFaceSimilarityInput, RequestFaceSimilarityOuput } from "./model/RequestFaceSimilarity";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class RequestFaceSimilarityImpl implements RequestFaceSimilarity {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute(input: RequestFaceSimilarityInput): Observable<RequestFaceSimilarityOuput> {
    return this.operationConsultingRepository.requestFaceSimilarity(
      input.noteId,
      input.photoId,
      input.photoURL,
      input.similarityRequestType,
      input.photos,
    );
  }
}
