import { Paper, Slide } from "@mui/material";
interface SlideInLayoutProps {
  open: boolean;
  children: React.ReactElement;
  container?: Element | ((element: Element) => Element) | null;
}

const SlideInLayout: React.FC<SlideInLayoutProps> = ({ open, children, container }) => {
  return (
    <Slide
      in={open}
      direction="left"
      container={container}
      mountOnEnter
      unmountOnExit
    >
      <Paper elevation={0}>{children}</Paper>
    </Slide>
  );
};

export default SlideInLayout;
