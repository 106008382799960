import { Config, Coordinate } from "../index.interface";
import { DrawingFacialLandmarks } from "../face/DrawingFacialLandmarks";
import Calc from "../utils/Calc";
import { BestRatioLineStyle, DotLineStyle, PhotoBaseLine } from "../utils/Styles";

export class MandibularRatio extends DrawingFacialLandmarks {
  private coordinate: {
    face: {
      top: { left: Coordinate; right: Coordinate };
      middle: { left: Coordinate; right: Coordinate };
      bottom: { left: Coordinate; right: Coordinate };
    };
    jaw: {
      middle: { left: Coordinate; right: Coordinate };
    };
  };

  private bestCoordinate: {
    face: {
      bottom: { left: Coordinate; right: Coordinate };
    };
    jaw: {
      middle: { left: Coordinate; right: Coordinate };
    };
  };

  ratio: {
    face: {
      length: {
        top: number;
        bottom: number;
      };
      ratio: {
        top: number;
        bottom: number;
      };
    };
    jaw: {
      length: {
        top: number;
        bottom: number;
      };
      ratio: {
        top: number;
        bottom: number;
      };
    };
  };

  constructor(config: Config) {
    super(config);
    this.coordinate = this.getCoordinate();
    this.ratio = this.calcRatio();
    this.bestCoordinate = this.getBestCoordinate();
  }

  drawPhotoBase = async () => {
    this.photoBaseDrawer.drawFaceLines();
    await this.delay(800);
    this.photoBaseDrawer.drawGuideLines();
    await this.delay(800);
    this.photoBaseDrawer.drawRatioText();
  };

  drawBestRatio = async () => {
    this.bestRatioDrawer.drawFaceLines();
    await this.delay(800);
    this.bestRatioDrawer.drawGuideLines();
    await this.delay(800);
    this.bestRatioDrawer.drawRatioText();
  };

  private photoBaseDrawer = {
    drawFaceLines: async () => {
      this.drawLineBetweenPoints({
        point1: this.coordinate.face.top.right,
        point2: this.coordinate.face.top.left,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: this.coordinate.face.middle.right,
        point2: this.coordinate.face.middle.left,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: this.coordinate.face.bottom.right,
        point2: this.coordinate.face.bottom.left,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: this.coordinate.jaw.middle.right,
        point2: this.coordinate.jaw.middle.left,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
    },
    drawGuideLines: async () => {
      this.drawLineBetweenPoints({
        point1: this.coordinate.face.top.left,
        point2: this.coordinate.face.middle.left,
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });

      this.drawLineBetweenPoints({
        point1: this.coordinate.face.middle.left,
        point2: this.coordinate.face.bottom.left,
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });

      this.drawLineBetweenPoints({
        point1: { ...this.coordinate.face.middle.right, x: this.faceCoordinates[207].x },
        point2: { ...this.coordinate.face.bottom.right, x: this.faceCoordinates[207].x },
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
    },
    drawRatioText: async () => {
      const textStyle = { x: 30, y: 0, fontSize: 18, color: "#FF9900" };

      this.drawTextBetweenPoints(
        `${this.ratio.face.ratio.top}`,
        this.coordinate.face.top.left,
        this.coordinate.face.middle.left,
        textStyle,
      );
      this.drawTextBetweenPoints(
        `${this.ratio.face.ratio.bottom}`,
        this.coordinate.face.middle.left,
        this.coordinate.face.bottom.left,
        textStyle,
      );
      this.drawTextBetweenPoints(
        `${this.ratio.jaw.ratio.top * 100}%`,
        this.coordinate.jaw.middle.right,
        this.coordinate.face.middle.right,
        textStyle,
      );
      this.drawTextBetweenPoints(
        `${this.ratio.jaw.ratio.bottom * 100}%`,
        this.coordinate.jaw.middle.right,
        this.coordinate.face.bottom.right,
        textStyle,
      );
    },
  };

  private bestRatioDrawer = {
    drawFaceLines: () => {
      this.drawLineBetweenPoints({
        point1: this.coordinate.face.top.right,
        point2: this.coordinate.face.top.left,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: this.coordinate.face.middle.right,
        point2: this.coordinate.face.middle.left,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: { ...this.coordinate.face.middle.right, y: this.coordinate.face.middle.right.y + this.ratio.face.length.top * 0.8 },
        point2: { ...this.coordinate.face.middle.left, y: this.coordinate.face.middle.right.y + this.ratio.face.length.top * 0.8 },
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: this.bestCoordinate.jaw.middle.right,
        point2: this.bestCoordinate.jaw.middle.left,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });
    },
    drawGuideLines: () => {
      this.drawLineBetweenPoints({
        point1: this.coordinate.face.top.left,
        point2: this.coordinate.face.middle.left,
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
      this.drawLineBetweenPoints({
        point1: this.coordinate.face.middle.left,
        point2: this.bestCoordinate.face.bottom.left,
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
      this.drawLineBetweenPoints({
        point1: { ...this.bestCoordinate.face.bottom.right, x: this.faceCoordinates[207].x },
        point2: { ...this.coordinate.face.middle.right, x: this.faceCoordinates[207].x },
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
    },
    drawRatioText: () => {
      const textStyle = { x: 30, y: -30, fontSize: 18, color: "#00D7CA" };
      const jawTextStyle = { x: -25, y: 0, fontSize: 18, color: "#00D7CA" };

      this.drawTextBetweenPoints(
        `${this.ratio.face.ratio.top}`,
        this.coordinate.face.top.left,
        this.coordinate.face.middle.left,
        textStyle,
      );

      this.drawTextBetweenPoints(`${0.8}`, this.coordinate.face.middle.left, this.bestCoordinate.face.bottom.left, textStyle);

      this.drawTextBetweenPoints(`${33}%`, this.bestCoordinate.jaw.middle.right, this.coordinate.face.middle.right, jawTextStyle);
      this.drawTextBetweenPoints(`${67}%`, this.bestCoordinate.jaw.middle.right, this.bestCoordinate.face.bottom.right, jawTextStyle);
    },
  };

  private getCoordinate = () => {
    const leftEndXPoint = this.faceCoordinates[454].x;
    const rightEndXPoint = this.faceCoordinates[234].x;
    return {
      face: {
        top: { left: { ...this.faceCoordinates[9], x: leftEndXPoint }, right: { ...this.faceCoordinates[9], x: rightEndXPoint } },
        middle: { left: { ...this.faceCoordinates[2], x: leftEndXPoint }, right: { ...this.faceCoordinates[2], x: rightEndXPoint } },
        bottom: { left: { ...this.faceCoordinates[152], x: leftEndXPoint }, right: { ...this.faceCoordinates[152], x: rightEndXPoint } },
      },
      jaw: {
        middle: { right: { ...this.faceCoordinates[13], x: rightEndXPoint }, left: this.faceCoordinates[13] },
      },
    };
  };

  private getBestCoordinate = () => {
    const bottomRightPoint = {
      ...this.coordinate.face.middle.right,
      y: this.coordinate.face.middle.right.y + this.ratio.face.length.top * 0.8,
    };

    const bottomLeftPoint = {
      ...this.coordinate.face.middle.left,
      y: this.coordinate.face.middle.right.y + this.ratio.face.length.top * 0.8,
    };

    const middelRightPoint = {
      ...this.coordinate.face.middle.right,
      y: this.coordinate.face.middle.right.y + this.ratio.face.length.top * 0.3,
    };

    return {
      face: {
        bottom: {
          left: bottomLeftPoint,
          right: bottomRightPoint,
        },
      },
      jaw: {
        middle: {
          left: { ...middelRightPoint, x: this.faceCoordinates[13].x },
          right: middelRightPoint,
        },
      },
    };
  };

  private calcRatio = () => {
    const faceTopLength = Calc.calculateDistance(this.coordinate.face.top.left, this.coordinate.face.middle.left, "2d");
    const faceBottomLength = Calc.calculateDistance(this.coordinate.face.middle.left, this.coordinate.face.bottom.left, "2d");
    const jawTopLength = Calc.calculateDistance(this.coordinate.face.middle.right, this.coordinate.jaw.middle.right, "2d");
    const jawBottomLength = Calc.calculateDistance(this.coordinate.jaw.middle.right, this.coordinate.face.bottom.right, "2d");
    const jawTotalLength = jawTopLength + jawBottomLength;

    return {
      face: {
        length: {
          top: faceTopLength,
          bottom: faceBottomLength,
        },
        ratio: {
          top: Calc.calcRatio(faceTopLength, faceTopLength),
          bottom: Calc.calcRatio(faceTopLength, faceBottomLength),
        },
      },
      jaw: {
        length: {
          top: jawTopLength,
          bottom: jawBottomLength,
        },
        ratio: {
          top: Calc.calcRatio(jawTotalLength, jawTopLength),
          bottom: Calc.calcRatio(jawTotalLength, jawBottomLength),
        },
      },
    };
  };

  private delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
}
