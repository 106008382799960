import { inject, injectable } from "inversify";
import { Observable } from "rxjs";
import { GetNotices, GetNoticesInput, GetNoticesOutput } from "../model/GetNotices";
import type { NoticeRepository } from "@/domain/interactor/repository/NoticeRepository";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class GetNoticesImpl implements GetNotices {
    constructor(@inject(TYPES.NoticeRepository) private readonly noticeRepository: NoticeRepository) {}

    execute = (input: GetNoticesInput): Observable<GetNoticesOutput> => {
        return this.noticeRepository.getNotices(input.page, input.size);
    };
}
