import type { InputProps } from ".";
import styled, { css } from "styled-components";
import colors from "@view/styles/colors";

interface TextAreaProps extends InputProps {
  value?: string;
  backgroundColor?: string;
  focusColor?: string;
  hideScrollbar?: boolean;
  disabled?: boolean;
}
type TextAreaStyledProps = Omit<TextAreaProps, "onChange">;

const TextAreaStyled = styled.textarea<TextAreaStyledProps>`
  font-family: inherit;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: ${(props) => props.backgroundColor};
  outline: none;
  resize: none;
  padding: 16px 8px;
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 10px;
  font-size: 14px;
  overflow: hidden scroll;
  ${(props) =>
    props.hideScrollbar &&
    css`
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      ::-webkit-scrollbar {
        display: none;
      }
    `}
  &:disabled {
    background-color: #f8f8f8;
    border-color: #e6e6e6;
  }
  &::placeholder {
    color: ${colors.neutralGray600};
  }
  &:hover {
    border-color: ${(props) => props.focusColor};
  }
  &:focus {
    border-color: ${(props) => props.focusColor};
  }
`;

const TextArea = ({
  onChange,
  height = "100%",
  width = "100%",
  borderColor = colors.neutralLineDefault,
  backgroundColor = colors.neutralWhiteDefault,
  focusColor = colors.neutralLineActive,
  hideScrollbar = false,
  disabled = false,
  ...props
}: TextAreaProps) => {
  const handleTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) onChange(e.target.value);
  };

  return (
    <TextAreaStyled
      onChange={handleTextArea}
      borderColor={borderColor}
      hideScrollbar={hideScrollbar}
      backgroundColor={backgroundColor}
      focusColor={focusColor}
      height={height}
      width={width}
      disabled={disabled}
      {...props}
    />
  );
};

export default TextArea;
