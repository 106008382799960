import { DrawingFacialLandmarks } from "./DrawingFacialLandmarks";
import { Config } from "../index.interface";

export class FaceContourGuideLine extends DrawingFacialLandmarks {
  private frontalFaceCanvasDrawer: DrawingFacialLandmarks;

  constructor({ config, guideLineCanvas }: { config: Config; guideLineCanvas: HTMLCanvasElement }) {
    super(config);
    this.frontalFaceCanvasDrawer = new DrawingFacialLandmarks({
      imageEle: config.imageEle,
      canvasEle: guideLineCanvas,
      normalizedCoordinate: config.normalizedCoordinate,
    });
  }

  drawGuideLine = () => {
    const contourKeyPoints = [
      162, 127, 234, 93, 132, 58, 172, 136, 150, 149, 176, 148, 152, 377, 400, 378, 379, 365, 397, 288, 361, 323, 454, 356, 389,
    ];

    contourKeyPoints.forEach((keyPoints) => {
      this.drawPoint(this.faceCoordinates[keyPoints], { color: "#FF42E9" });
    });
  };
}
