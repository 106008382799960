import { injectable } from "inversify";
import { BehaviorSubject, Subject, zip } from "rxjs";
import { HospitalInformationViewModel } from "./model/HospitalInformationViewModel";
import type { UISystemManager } from "../ui-system/view-model/model";
import type { GetHospital } from "@/domain/usecase/hospital/model/GetHospital";
import type { UpdateHospital } from "@/domain/usecase/hospital/model/UpdateHospital";
import { Hospital, UI, Constant } from "@/application/view-data";
import type { UploadImage } from "@/domain/usecase/gcs/model/UploadImage";
import { v4 } from "uuid";
import type { GetUserV2 } from "@/domain/usecase/auth/model/GetUserV2";

@injectable()
export class HospitalInformationViewModelImpl implements HospitalInformationViewModel {
  data: HospitalInformationViewModel["data"] = {
    myRole: null,
    hospitalId: null,
    profile: null,
    address: null,
    openingHours: null,
    medicalSubjects: Constant.MEDICAL_SUBJECTS.map((subject) => {
      return {
        data: subject,
        isSelected: false,
      };
    }),
    facilityPhotos: null,
    businessRegistration: null,
  };

  output: HospitalInformationViewModel["output"] = {
    editMode: new Subject<boolean>(),
    isLoading: new BehaviorSubject<boolean>(false),
    profile: new BehaviorSubject<Hospital.Profile | null>(null),
    address: new BehaviorSubject<Hospital.Address | null>(null),
    contact: new BehaviorSubject<Hospital.Contact | null>(null),
    openingHours: new BehaviorSubject<Hospital.OpeningHours[] | null>(null),
    medicalSubjects: new BehaviorSubject<UI.SelectableItem<Hospital.MedicalSubject>[] | null>(null),
    facilityPhotos: new BehaviorSubject<Hospital.FacilityPhoto[] | null>(null),
    businessRegistration: new BehaviorSubject<Hospital.BusinessRegistration | null>(null),
  };

  constructor(
    readonly uiSystem: UISystemManager,
    private readonly getHospital: GetHospital,
    private readonly updateHospital: UpdateHospital,
    private readonly uploadImage: UploadImage,
    private readonly getUser: GetUserV2,
  ) {
    this.init();
  }

  input: HospitalInformationViewModel["input"] = {
    clickEditMode: () => {
      if (this.data.myRole === "ADMIN") {
        this.output.editMode.next(true);
      } else {
        this.uiSystem.errorHandler.alert.next({ message: "관리자계정으로 관리해주세요." });
      }
    },
    changeProfile: (profile) => {
      this.data.profile = profile;
      this.output.profile.next(profile);
    },
    changeAddress: (address) => {
      this.data.address = address;
      this.output.address.next(address);
    },
    changeFacilityPhotos: (facilityPhotos) => {
      this.data.facilityPhotos = facilityPhotos;
      this.output.facilityPhotos.next(facilityPhotos);
    },
    changeMedicalSubject: (medicalSubjects) => {
      this.data.medicalSubjects = medicalSubjects;
      this.output.medicalSubjects.next(medicalSubjects);
    },
    changeOpeningHours: (openingHours) => {
      this.data.openingHours = openingHours;
      this.output.openingHours.next(openingHours);
    },
    changeDescription: (description) => {
      if (this.data.profile) {
        this.data.profile.description = description;
        this.output.profile.next({ ...this.data.profile });
      }
    },
    clickUploadLogo: (logo: File) => {
      if (this.data.hospitalId) {
        this.uiSystem.loadingHandler.backdropLoading.next(true);
        const sub = this.uploadImage
          .execute({ public: true, ordinal: 1, file: logo, bucketPath: `/hospitals/${this.data.hospitalId}/logo` })
          .subscribe({
            next: (image) => {
              if (this.data.profile) {
                this.data.profile.logoImageUrl = image.originUrl;
                this.output.profile.next({ ...this.data.profile });
              }
              this.uiSystem.loadingHandler.backdropLoading.next(false);
              sub.unsubscribe();
            },
            error: () => {
              this.uiSystem.loadingHandler.backdropLoading.next(false);
              sub.unsubscribe();
            },
          });
      }
    },
    clickUploadFaceFitStandbyLogo: (file: File) => {
      if (this.data.hospitalId) {
        this.uiSystem.loadingHandler.backdropLoading.next(true);
        const sub = this.uploadImage
          .execute({ public: true, ordinal: 1, file, bucketPath: `/hospitals/${this.data.hospitalId}/logo`, type: "png" })
          .subscribe({
            next: (image) => {
              if (this.data.profile) {
                this.data.profile.faceFitLogoImageUrl = image.originUrl;
                this.output.profile.next({ ...this.data.profile });
              }
              this.uiSystem.loadingHandler.backdropLoading.next(false);
              sub.unsubscribe();
            },
            error: () => {
              this.uiSystem.loadingHandler.backdropLoading.next(false);
              sub.unsubscribe();
            },
          });
      }
    },
    clickAddFacilityPhoto: (facilityPhoto: File) => {
      this.uiSystem.loadingHandler.backdropLoading.next(true);
      if (this.data.facilityPhotos !== null) {
        const ordinal = this.data.facilityPhotos.length + 1;
        const sub = this.uploadImage
          .execute({
            public: true,
            ordinal: ordinal,
            file: facilityPhoto,
            bucketPath: `/hospitals/${this.data.hospitalId}/facility`,
          })
          .subscribe({
            next: (image) => {
              const uuid = v4();
              const facilityPhoto = image.originUrl;
              const resizedFacilityPhoto = image.resizedUrl;
              this.data.facilityPhotos?.push({
                id: null,
                tempId: uuid,
                ordinal,
                originUrl: facilityPhoto,
                representative: false,
                resizedUrl: resizedFacilityPhoto,
              });

              if (this.data.facilityPhotos) {
                this.output.facilityPhotos.next([...this.data.facilityPhotos]);
              }
              this.uiSystem.loadingHandler.backdropLoading.next(false);
              sub.unsubscribe();
            },
            error: () => {
              this.uiSystem.loadingHandler.backdropLoading.next(false);
              sub.unsubscribe();
            },
          });
      }
    },
    clickDeleteFacilityPhoto: (tempId) => {
      this.data.facilityPhotos = this.data.facilityPhotos?.filter((p) => p.tempId !== tempId) ?? [];
      this.output.facilityPhotos.next(this.data.facilityPhotos);
    },
    clickSubway: (subway) => {
      const selectedIndex = this.data.address?.subways.findIndex((selectedSubway) => selectedSubway === subway);
      if (selectedIndex !== undefined && selectedIndex > -1 && this.data.address) {
        this.data.address.subways = this.data.address.subways.filter((selectedSubway) => selectedSubway !== subway);
      } else {
        if (this.data.address && this.data.address.subways.length === 4) {
          this.uiSystem.errorHandler.alert.next({ message: "최대 4개까지 선택할 수 있습니다." });
        } else {
          this.data.address?.subways.push(subway);
        }
      }
      if (this.data.address) {
        this.data.address.subways.filter((subway) => {
          return subway !== "UNKNOWN";
        });
        this.output.address.next({ ...this.data.address });
      }
    },
    clickMedicalSubject: (type, subject) => {
      if (type === "SINGLE") {
        if (this.data.medicalSubjects !== null) {
          this.data.medicalSubjects = this.data.medicalSubjects.map((selectedSubject) => {
            if (selectedSubject.data.id === subject?.id) {
              selectedSubject.isSelected = !selectedSubject.isSelected;
              return selectedSubject;
            } else {
              return selectedSubject;
            }
          });
          this.output.medicalSubjects.next(this.data.medicalSubjects);
        }
      } else {
        if (this.data.medicalSubjects.every((subject) => subject.isSelected)) {
          this.data.medicalSubjects = this.data.medicalSubjects.map((subject) => {
            return { ...subject, isSelected: false };
          });
        } else {
          this.data.medicalSubjects = this.data.medicalSubjects.map((subject) => {
            return { ...subject, isSelected: true };
          });
        }
        this.output.medicalSubjects.next(this.data.medicalSubjects);
      }
    },
    changeAddressDetails: (address2) => {
      if (this.data.address) {
        this.data.address.address2 = address2;
        this.output.address.next({ ...this.data.address });
      }
    },
    clickClinicHours: (type, day, value) => {
      const findedIndex = this.data.openingHours?.findIndex((hours) => hours.day === day);
      if (findedIndex !== undefined && findedIndex > -1 && this.data.openingHours) {
        switch (type) {
          case "DAY_OFF":
            this.data.openingHours[findedIndex].dayOff = !this.data.openingHours[findedIndex].dayOff;
            break;

          case "OPEN":
            if (typeof value === "string") {
              this.data.openingHours[findedIndex].open = this.formatTime(value);
            }
            break;

          case "CLOSE":
            if (typeof value === "string") {
              this.data.openingHours[findedIndex].close = this.formatTime(value);
            }
            break;

          case "NIGHT_CARE":
            this.data.openingHours[findedIndex].nightCare = !this.data.openingHours[findedIndex].nightCare;
            break;
        }
        this.output.openingHours.next([...this.data.openingHours]);
      }
    },
    clickSave: () => {
      const logoImageUrl = this.data.profile?.logoImageUrl;
      const representativePhoneNumber = this.data.profile?.representativePhoneNumber;
      const medicalSubjects = this.data.medicalSubjects
        ?.filter((subject) => {
          return subject.isSelected;
        })
        .map((subject) => {
          return subject.data;
        });

      if (
        this.data.profile &&
        this.data.facilityPhotos &&
        this.data.address &&
        logoImageUrl &&
        representativePhoneNumber &&
        this.data.facilityPhotos.length > 0
      ) {
        this.uiSystem.loadingHandler.backdropLoading.next(true);
        const sub = this.updateHospital
          .execute({
            infoTypes: ["PROFILE", "ADDRESS", "FACILITY_PHOTOS", "OPENING_HOURS", "SUBJECTS"],
            profile: { ...this.data.profile, logoImageUrl, representativePhoneNumber },
            facilityPhotos: this.data.facilityPhotos ?? [],
            address: this.data.address,
            medicalSubjects: medicalSubjects ?? [],
            openingHours: this.data.openingHours ?? [],
          })
          .subscribe({
            next: () => {
              this.output.editMode.next(false);
              this.uiSystem.loadingHandler.backdropLoading.next(false);
              this.uiSystem.toastHandler.toast.next({
                message: "저장이 완료되었습니다.",
                position: { vertical: "top", horizontal: "center" },
                type: "SUCCESS",
              });
              sub.unsubscribe();
            },
            error: (error) => {
              this.uiSystem.loadingHandler.backdropLoading.next(false);
              if (error?.error_message.include("not allowed to access")) {
                this.uiSystem.errorHandler.alert.next({ message: "관리자계정으로 관리해주세요." });
              } else {
                this.uiSystem.errorHandler.alert.next({ message: "알 수 없는 에러가 발생했습니다.\n잠시후 다시 시도해주세요." });
              }
              sub.unsubscribe();
            },
          });
      } else {
        this.uiSystem.errorHandler.alert.next({ message: "필수 병원 정보를 모두 입력해주세요." });
      }
    },
  };

  private init = () => {
    this.output.isLoading.next(false);
    const sub = zip(
      this.getHospital.execute({
        infoTypes: ["ADDRESS", "PROFILE", "OPENING_HOURS", "FACILITY_PHOTOS", "BUSINESS_INFO", "CONTACTS", "SUBJECTS"],
      }),
      this.getUser.execute(),
    ).subscribe({
      next: ([hospital, user]) => {
        this.data.myRole = user.user.role;
        this.data.hospitalId = hospital.id;
        this.data.profile = hospital.profile;
        this.data.address = { ...hospital.address, subways: hospital.address.subways.filter((subway) => subway !== "UNKNOWN") };

        if (hospital.openingHours && hospital.openingHours.length === 0) {
          this.data.openingHours = [
            { id: 1, day: "MONDAY", open: "", close: "", dayOff: true, nightCare: false },
            { id: 2, day: "TUESDAY", open: "", close: "", dayOff: true, nightCare: false },
            { id: 3, day: "WEDNESDAY", open: "", close: "", dayOff: true, nightCare: false },
            { id: 4, day: "THURSDAY", open: "", close: "", dayOff: true, nightCare: false },
            { id: 5, day: "FRIDAY", open: "", close: "", dayOff: true, nightCare: false },
            { id: 6, day: "SATURDAY", open: "", close: "", dayOff: true, nightCare: false },
            { id: 7, day: "SUNDAY", open: "", close: "", dayOff: true, nightCare: false },
          ];
        } else {
          this.data.openingHours = hospital.openingHours;
        }

        this.data.businessRegistration = hospital.businessRegistration;
        if (hospital.facilityPhotos) {
          this.data.facilityPhotos = hospital.facilityPhotos?.map((photo) => {
            const uuid = v4();
            return { ...photo, tempId: uuid };
          });
        }

        this.data.facilityPhotos?.sort((a, b) => {
          if (a && b) {
            return a.ordinal - b.ordinal;
          } else {
            return 0;
          }
        });

        this.data.medicalSubjects = this.data.medicalSubjects.map((subject) => {
          if (hospital.medicalSubjects?.some((s) => s.code === subject.data.code)) {
            return { ...subject, isSelected: true };
          } else {
            return subject;
          }
        });

        this.output.profile.next(this.data.profile);
        this.output.address.next(this.data.address);
        this.output.openingHours.next(this.data.openingHours);
        this.output.medicalSubjects.next(this.data.medicalSubjects);
        this.output.facilityPhotos.next(this.data.facilityPhotos);
        this.output.businessRegistration.next(this.data.businessRegistration);
        this.output.isLoading.next(false);
      },
      error: (error) => {
        if (error?.error_message === "user status is not activated") {
          this.uiSystem.errorHandler.alert.next({ message: "비활성화된 계정입니다.\n관리자에게 문의해주세요." });
        }
        sub.unsubscribe();
      },
    });
  };

  private formatTime(dateTime: string) {
    const date = new Date(dateTime);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }
}
