import Calc from "../utils/Calc";
import { CanvasDrawer } from "../utils/CanvasDrawer";
import { PhotoBaseLine, BestRatioLineStyle, DotLineStyle } from "../utils/Styles";
import { Config, Coordinate, LandmarkPoint } from "../index.interface";

export class EyeGoldenRatio extends CanvasDrawer {
  private landmarkPoints: LandmarkPoint[] = [162, 226, 130, 133, 362, 446, 359, 389, 356, 10, 2, 389];

  private coordinate: {
    top: Coordinate;
    bottom: Coordinate;
    right: { top: Coordinate; bottom: Coordinate };
    rightEye: {
      right: { top: Coordinate; bottom: Coordinate };
      left: { top: Coordinate; bottom: Coordinate };
    };
    leftEye: {
      right: { top: Coordinate; bottom: Coordinate };
      left: { top: Coordinate; bottom: Coordinate };
    };
    left: { top: Coordinate; bottom: Coordinate };
  };

  private bestRatioCoordinate: {
    right: { top: Coordinate; bottom: Coordinate };
    rightEye: {
      right: { top: Coordinate; bottom: Coordinate };
      left: { top: Coordinate; bottom: Coordinate };
    };
    leftEye: {
      right: { top: Coordinate; bottom: Coordinate };
      left: { top: Coordinate; bottom: Coordinate };
    };
    left: { top: Coordinate; bottom: Coordinate };
  };

  ratio: {
    좌얼굴: number;
    좌눈: number;
    중안: number;
    우눈: number;
    우얼굴: number;
  };

  constructor(config: Config) {
    super(config.imageEle, config.canvasEle);
    this.coordinate = this.getCoordinates(config.imageEle, config.normalizedCoordinate, this.landmarkPoints);
    this.ratio = this.calcRatioByPhotoBase();
    this.bestRatioCoordinate = this.getBestRatioCoordinates(config.imageEle, config.normalizedCoordinate, this.landmarkPoints);
  }

  drawPhotoBase = async () => {
    const textStyle = { x: 0, y: -18, fontSize: 18, color: "#FF9900" };

    this.drawLineBetweenPoints({
      point1: this.coordinate.right.top,
      point2: this.coordinate.left.top,
      lineStyle: DotLineStyle,
      isDrawEndPoint: false,
    });
    // await this.delay(300);
    this.drawLineBetweenPoints({
      point1: this.coordinate.right.top,
      point2: this.coordinate.right.bottom,
      lineStyle: PhotoBaseLine,
      isDrawEndPoint: true,
    });
    this.drawLineBetweenPoints({
      point1: this.coordinate.rightEye.right.top,
      point2: this.coordinate.rightEye.right.bottom,
      lineStyle: PhotoBaseLine,
      isDrawEndPoint: true,
    });
    this.drawLineBetweenPoints({
      point1: this.coordinate.rightEye.left.top,
      point2: this.coordinate.rightEye.left.bottom,
      lineStyle: PhotoBaseLine,
      isDrawEndPoint: true,
    });
    this.drawLineBetweenPoints({
      point1: this.coordinate.leftEye.right.top,
      point2: this.coordinate.leftEye.right.bottom,
      lineStyle: PhotoBaseLine,
      isDrawEndPoint: true,
    });
    this.drawLineBetweenPoints({
      point1: this.coordinate.leftEye.left.top,
      point2: this.coordinate.leftEye.left.bottom,
      lineStyle: PhotoBaseLine,
      isDrawEndPoint: true,
    });
    this.drawLineBetweenPoints({
      point1: this.coordinate.left.top,
      point2: this.coordinate.left.bottom,
      lineStyle: PhotoBaseLine,
      isDrawEndPoint: true,
    });
    // await this.delay(300);
    this.drawTextBetweenPoints(`${this.ratio.우얼굴}`, this.coordinate.right.top, this.coordinate.rightEye.right.top, textStyle);
    this.drawTextBetweenPoints(`${this.ratio.우눈}`, this.coordinate.rightEye.right.top, this.coordinate.rightEye.left.top, textStyle);
    this.drawTextBetweenPoints(`${this.ratio.중안}`, this.coordinate.rightEye.left.top, this.coordinate.leftEye.right.top, textStyle);
    this.drawTextBetweenPoints(`${this.ratio.좌눈}`, this.coordinate.leftEye.right.top, this.coordinate.leftEye.left.top, textStyle);
    this.drawTextBetweenPoints(`${this.ratio.좌얼굴}`, this.coordinate.leftEye.left.top, this.coordinate.left.top, textStyle);
  };

  drawBestRatio = async () => {
    const textStyle = { x: 0, y: -43, fontSize: 18, color: "#00D7CA" };

    this.drawLineBetweenPoints({
      point1: this.bestRatioCoordinate.right.top,
      point2: this.bestRatioCoordinate.left.top,
      lineStyle: DotLineStyle,
      isDrawEndPoint: false,
    });
    // await this.delay(300);
    this.drawLineBetweenPoints({
      point1: this.bestRatioCoordinate.right.top,
      point2: this.bestRatioCoordinate.right.bottom,
      lineStyle: BestRatioLineStyle,
      isDrawEndPoint: true,
    });
    this.drawLineBetweenPoints({
      point1: this.bestRatioCoordinate.rightEye.right.top,
      point2: this.bestRatioCoordinate.rightEye.right.bottom,
      lineStyle: BestRatioLineStyle,
      isDrawEndPoint: true,
    });

    this.drawLineBetweenPoints({
      point1: this.bestRatioCoordinate.rightEye.left.top,
      point2: this.bestRatioCoordinate.rightEye.left.bottom,
      lineStyle: BestRatioLineStyle,
      isDrawEndPoint: true,
    });

    this.drawLineBetweenPoints({
      point1: this.bestRatioCoordinate.leftEye.right.top,
      point2: this.bestRatioCoordinate.leftEye.right.bottom,
      lineStyle: BestRatioLineStyle,
      isDrawEndPoint: true,
    });

    this.drawLineBetweenPoints({
      point1: this.bestRatioCoordinate.leftEye.left.top,
      point2: this.bestRatioCoordinate.leftEye.left.bottom,
      lineStyle: BestRatioLineStyle,
      isDrawEndPoint: true,
    });

    this.drawLineBetweenPoints({
      point1: this.bestRatioCoordinate.left.top,
      point2: this.bestRatioCoordinate.left.bottom,
      lineStyle: BestRatioLineStyle,
      isDrawEndPoint: true,
    });

    // await this.delay(300);
    this.drawTextBetweenPoints(`${0.5}`, this.bestRatioCoordinate.right.top, this.bestRatioCoordinate.rightEye.right.top, textStyle);
    this.drawTextBetweenPoints(`${1}`, this.bestRatioCoordinate.rightEye.right.top, this.bestRatioCoordinate.rightEye.left.top, textStyle);
    this.drawTextBetweenPoints(`${1}`, this.bestRatioCoordinate.rightEye.left.top, this.bestRatioCoordinate.leftEye.right.top, textStyle);
    this.drawTextBetweenPoints(`${1}`, this.bestRatioCoordinate.leftEye.right.top, this.bestRatioCoordinate.leftEye.left.top, textStyle);
    this.drawTextBetweenPoints(`${0.5}`, this.bestRatioCoordinate.leftEye.left.top, this.bestRatioCoordinate.left.top, textStyle);
  };

  //황금 비율 계산
  calcRatioByPhotoBase = () => {
    const rightAndRightEyeRatio = Calc.calculateDistance(this.coordinate.right.top, this.coordinate.rightEye.right.top, "2d");
    const rightEyeRatio = Calc.calculateDistance(this.coordinate.rightEye.right.top, this.coordinate.rightEye.left.top, "2d");
    const middleRatio = Calc.calculateDistance(this.coordinate.rightEye.left.top, this.coordinate.leftEye.right.top, "2d");
    const leftEyeRatio = Calc.calculateDistance(this.coordinate.leftEye.right.top, this.coordinate.leftEye.left.top, "2d");
    const leftEyeAndLeftRatio = Calc.calculateDistance(this.coordinate.leftEye.left.top, this.coordinate.left.top, "2d");

    Calc.calcRatio(rightEyeRatio, rightAndRightEyeRatio);

    return {
      우얼굴: Calc.calcRatio(rightEyeRatio, rightAndRightEyeRatio),
      우눈: Calc.calcRatio(rightEyeRatio, rightEyeRatio),
      중안: Calc.calcRatio(rightEyeRatio, middleRatio),
      좌눈: Calc.calcRatio(rightEyeRatio, leftEyeRatio),
      좌얼굴: Calc.calcRatio(rightEyeRatio, leftEyeAndLeftRatio),
    };
  };

  private getCoordinates = (
    imageEle: HTMLImageElement,
    coordinates: Coordinate[][],
    landmarkPoints: LandmarkPoint[],
  ): {
    top: Coordinate;
    bottom: Coordinate;
    right: { top: Coordinate; bottom: Coordinate };
    rightEye: {
      right: { top: Coordinate; bottom: Coordinate };
      left: { top: Coordinate; bottom: Coordinate };
    };
    leftEye: {
      right: { top: Coordinate; bottom: Coordinate };
      left: { top: Coordinate; bottom: Coordinate };
    };
    left: { top: Coordinate; bottom: Coordinate };
  } => {
    const coordinate = Calc.getDenormalizedCoordinates(imageEle, coordinates, landmarkPoints);

    return {
      top: coordinate[10],
      bottom: coordinate[2],
      right: {
        top: { ...coordinate[162], y: coordinate[10].y },
        bottom: { ...coordinate[162], y: coordinate[2].y },
      },
      rightEye: {
        right: {
          top: { ...coordinate[226], y: coordinate[10].y },
          bottom: { ...coordinate[226], y: coordinate[2].y },
        },
        left: {
          top: { ...coordinate[133], y: coordinate[10].y },
          bottom: { ...coordinate[133], y: coordinate[2].y },
        },
      },
      leftEye: {
        right: {
          top: { ...coordinate[362], y: coordinate[10].y },
          bottom: { ...coordinate[362], y: coordinate[2].y },
        },
        left: {
          top: { ...coordinate[446], y: coordinate[10].y },
          bottom: { ...coordinate[446], y: coordinate[2].y },
        },
      },
      left: {
        top: { ...coordinate[389], y: coordinate[10].y },
        bottom: { ...coordinate[389], y: coordinate[2].y },
      },
    };
  };

  private getBestRatioCoordinates = (
    imageEle: HTMLImageElement,
    coordinates: Coordinate[][],
    landmarkPoints: LandmarkPoint[],
  ): {
    right: { top: Coordinate; bottom: Coordinate };
    rightEye: {
      right: { top: Coordinate; bottom: Coordinate };
      left: { top: Coordinate; bottom: Coordinate };
    };
    leftEye: {
      right: { top: Coordinate; bottom: Coordinate };
      left: { top: Coordinate; bottom: Coordinate };
    };
    left: { top: Coordinate; bottom: Coordinate };
  } => {
    const coordinate = Calc.getDenormalizedCoordinates(imageEle, coordinates, landmarkPoints);
    const rightEyeDistance = Calc.calculateDistance(this.coordinate.rightEye.right.top, this.coordinate.rightEye.left.top, "2d");

    return {
      right: {
        top: { ...coordinate[226], x: coordinate[226].x - rightEyeDistance / 2, y: coordinate[10].y },
        bottom: { ...coordinate[226], x: coordinate[226].x - rightEyeDistance / 2, y: coordinate[2].y },
      },
      rightEye: {
        right: {
          top: { ...coordinate[226], y: coordinate[10].y },
          bottom: { ...coordinate[226], y: coordinate[2].y },
        },
        left: {
          top: { ...coordinate[133], y: coordinate[10].y },
          bottom: { ...coordinate[133], y: coordinate[2].y },
        },
      },
      leftEye: {
        right: {
          top: { ...coordinate[133], x: coordinate[133].x + rightEyeDistance, y: coordinate[10].y },
          bottom: { ...coordinate[133], x: coordinate[133].x + rightEyeDistance, y: coordinate[2].y },
        },
        left: {
          top: { ...coordinate[133], x: coordinate[133].x + rightEyeDistance * 2, y: coordinate[10].y },
          bottom: { ...coordinate[133], x: coordinate[133].x + rightEyeDistance * 2, y: coordinate[2].y },
        },
      },
      left: {
        top: { ...coordinate[133], x: coordinate[133].x + rightEyeDistance * 2 + rightEyeDistance / 2, y: coordinate[10].y },
        bottom: { ...coordinate[133], x: coordinate[133].x + rightEyeDistance * 2 + rightEyeDistance / 2, y: coordinate[2].y },
      },
    };
  };

  private delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
}
