import moment from "moment";
import styled from "styled-components";
import DatePicker, { ReactDatePickerCustomHeaderProps, registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
import "react-datepicker/dist/react-datepicker.css";
import Icon from "@icon/index";
import colors from "@view/styles/colors";
import Typography, { typographyConfig } from "../typography";
import { DatePickerProps } from ".";

registerLocale("ko", ko);

const DatePickerCustomHeader: React.FC<ReactDatePickerCustomHeaderProps> = (props) => {
  const { date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled } = props;

  return (
    <CustomHeaderWrapper>
      <button
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <Icon.ArrowLeftSLine color="neutralBlack" />
      </button>
      <Typography.B1
        bold
        value={`${[date.getMonth() + 1]}월, ${date.getFullYear()}`}
      />
      <button
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        <Icon.ArrowRightSLine color="neutralBlack" />
      </button>
    </CustomHeaderWrapper>
  );
};

export const DefaultDatePicker: React.FC<DatePickerProps> = ({
  dateFormat = "yyyy-MM-dd",
  placeHolderText = "시작일",
  showTimeSelect = false,
  onChange,
  timeInterval = 30,
  ...props
}) => {
  let dateParsed: any;
  if (props.date !== undefined) dateParsed = props.date ? moment(props.date).toDate() : undefined;

  const month = new Date().getMonth();

  const handleDate = (date: any) => {
    if (props.isISOFormat) {
      onChange(moment(date).toISOString());
    } else {
      if (props.isStartOfDay && !showTimeSelect) onChange(moment(date).startOf("day").format("yyyy-MM-DD HH:mm:ss"));
      else if (props.isEndOfDay && !showTimeSelect) onChange(moment(date).endOf("day").format("yyyy-MM-DD HH:mm:ss"));
      else onChange(moment(date).format("yyyy-MM-DD HH:mm:ss"));
    }
  };
  let startDate: any;
  if (props.startDate !== undefined) startDate = props.startDate ? moment(props.startDate).toDate() : undefined;

  let endDate: any;
  if (props.endDate !== undefined) endDate = props.endDate ? moment(props.endDate).toDate() : undefined;

  return (
    <DatePickerWrapper {...props}>
      <DatePickerStyled
        wrapperClassName="custom-class"
        locale={ko}
        selected={dateParsed}
        dateFormat={dateFormat}
        showTimeSelect={showTimeSelect}
        timeCaption=""
        date={dateParsed}
        onChange={handleDate}
        placeholderText={placeHolderText}
        focusColor={colors.neutralLineActive}
        timeIntervals={timeInterval}
        dayClassName={(d) =>
          d.getDate() === dateParsed?.getDate() && d.getMonth() === month
            ? "custom-day selected-day"
            : d.getMonth() === month
            ? "custom-day"
            : "custom-day gray-day"
        }
        renderCustomHeader={(props) => <DatePickerCustomHeader {...props} />}
        disabled={props.disabled}
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        disabledKeyboardNavigation
        {...props}
      />
      <Icon.Calendar />
    </DatePickerWrapper>
  );
};

const CustomHeaderWrapper = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 8px;
  button {
    border: none;
    background: inherit;
  }
`;

const DatePickerStyled = styled(DatePicker)<Omit<DatePickerProps, "date">>`
  font-family: "SUIT";
  width: 100%;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: ${colors.neutralBlack};
  border-radius: 6px;
  &::placeholder {
    color: ${colors.neutralGray600};
  }
  &:focus {
    outline: none;
  }
`;

const DatePickerWrapper = styled.label<Pick<DatePickerProps, "width" | "height" | "focusColor" | "backgroundColor">>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "SUIT";
  font-size: ${typographyConfig.B2.fontSize}px;
  font-weight: ${typographyConfig.B2.boldFontWeight};
  width: ${(props) => props.width ?? "132px"};
  height: ${(props) => props.height ?? "48px"};
  padding: 16px 8px;
  cursor: pointer;
  border: 1px solid ${colors.neutralLineDefault};
  color: ${colors.neutralBlack};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 6px;
  &:hover {
    border-color: ${colors.neutralLineActive};
  }
  &:focus-within {
    border-color: ${colors.neutralLineActive};
  }
  .react-datepicker-popper {
    margin-left: -8px;
    &[data-placement^="bottom"] {
      padding-top: 21px;
    }
    &[data-placement^="top"] {
      padding-bottom: 21px;
    }
  }
  .react-datepicker {
    font-family: "SUIT" !important;
    overflow: visible;
    font-size: ${typographyConfig.C1.fontSize}px !important;
    font-weight: ${typographyConfig.C1.boldFontWeight} !important;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    border: none;
    border-radius: 8px;
    &-wrapper {
      width: calc(100% - 20px);
    }
    &__header {
      background-color: ${colors.neutralWhiteDefault};
      padding: 0px;
      border: none;
    }
    &__triangle {
      &::before,
      &::after {
        content: none !important;
      }
    }
    &__week {
      display: flex;
      flex-direction: row;
    }
    &__day,
    &__day-name {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      margin: 0px;
      padding: 0px;
    }
    &__day-names {
      display: flex;
      flex-direction: row;
      margin: 0px;
    }
    &__month {
      margin: 0px;
    }
    &__day:hover,
    &__month-text:hover,
    &__quarter-text:hover,
    &__year-text:hover {
      border-radius: 50%;
      background-color: ${colors.drwo_prim_green_200};
    }
    &__day--in-range {
      display: inline-flex;
      justify-content: center;
      background-color: ${colors.drwo_prim_green_200};
      color: ${colors.neutralBlack};
      border-radius: 0;
    }
    &__day--selected,
    &__day--keyboard-selected {
      background-color: ${colors.drwo_prim_green_400};
      border-radius: 50%;
      color: ${colors.neutralWhiteDefault};
      &:hover {
        background-color: ${colors.drwo_prim_green_400};
      }
    }
    &-time__caption {
      display: none;
    }
    &-time__input {
      input {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 8px;
        gap: 4px;
        width: 248px;
        height: 34px;
        background: ${colors.neutralWhiteDefault};
        border: 1px solid ${colors.neutralLineDefault};
        border-radius: 6px;
      }
    }
    &__time-container {
      border-left: none;
      width: 95px;
    }
    &__time {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    &__time-list {
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &__time-list-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 79px;
      height: 32px !important;
      padding: 0px !important;
      border-radius: 99px;
      &:hover {
        background-color: ${colors.drwo_prim_green_200} !important;
      }
      &--selected {
        background-color: ${colors.drwo_prim_green_400} !important;
      }
    }
  }
`;
