import * as Entity from "@/domain/entity";
export interface MainMenu {
  ordinal: number;
  name: string;
  pathname: string;
  isSelected: boolean;
  isNew: boolean;
  click: () => void;
}
export interface SubMenu {
  ordinal: number;
  name: string;
  pathname: string;
  isSelected: boolean;
  click: () => void;
}

export type FaceFitProductMenu = FaceFitProduct & MainMenu;
export type AdvertisementProductMenu = AdvertisementProduct & MainMenu;
export type HospitalManagementProductMenu = HospitalManagementProduct & MainMenu;

export interface BaseProduct {
  name: string;
  displayOrdinal: number;
  isNewMenu: boolean;
  subMenus: BaseProductFeature[];
}

export interface BaseProductFeature {
  name: string;
  displayOrdinal: number;
}

export interface FaceFitProduct extends BaseProduct {
  name: "face-fit";
  subMenus: FaceFitFeature[];
}

export interface FaceFitFeature extends BaseProductFeature {
  name: Entity.Type.FaceFitProductFeatureName;
}

export interface AdvertisementProduct extends BaseProduct {
  subMenus: AdvertisementFeature[];
}

export interface AdvertisementFeature extends BaseProductFeature {
  name: Entity.Type.AdvertisementProductFeatureName;
}

export interface HospitalManagementProduct extends BaseProduct {
  name: "hospital";
  subMenus: HospitalManagementFeature[];
}

export interface HospitalManagementFeature extends BaseProductFeature {
  name: Entity.Type.HospitalProductFeatureName;
}

export type Product = FaceFitProduct | AdvertisementProduct | HospitalManagementProduct;
export type Feature = FaceFitFeature | AdvertisementFeature | HospitalManagementFeature;
export type ProductName = Entity.Type.ProductName;
export type ProductFeatureName =
  | Entity.Type.FaceFitProductFeatureName
  | Entity.Type.AdvertisementProductFeatureName
  | Entity.Type.HospitalProductFeatureName;

export const PRODUCTS: Product[] = [
  {
    displayOrdinal: 1,
    name: "face-fit",
    isNewMenu: true,
    subMenus: [
      {
        displayOrdinal: 1,
        name: "customers",
      },
      {
        displayOrdinal: 2,
        name: "best-practice",
      },
      {
        displayOrdinal: 3,
        name: "face-styles",
      },
    ],
  },
  {
    displayOrdinal: 2,
    name: "advertisement",
    isNewMenu: false,
    subMenus: [
      {
        displayOrdinal: 1,
        name: "customers",
      },
    ],
  },
  {
    displayOrdinal: 3,
    name: "hospital",
    isNewMenu: false,
    subMenus: [
      {
        displayOrdinal: 1,
        name: "information",
      },
      {
        displayOrdinal: 2,
        name: "doctors",
      },
      {
        displayOrdinal: 3,
        name: "contacts",
      },
      {
        displayOrdinal: 4,
        name: "accounts",
      },
      {
        displayOrdinal: 5,
        name: "plan",
      },
    ],
  },
];
