import { injectable, inject } from "inversify";
import {
  CreateConsultationRecording,
  CreateConsultationRecordingInput,
  CreateConsultationRecordingOutput,
} from "./model/CreateConsultationRecording";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { Observable } from "rxjs";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class CreateConsultationRecordingImpl implements CreateConsultationRecording {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute = (input: CreateConsultationRecordingInput): Observable<CreateConsultationRecordingOutput> => {
    return this.operationConsultingRepository.createConsultationRecording(
      input.noteId,
      input.recordingOriginUrl,
      input.recordingFilename,
      input.recordingDurationTime,
    );
  };
}
