import React from "react";
import { Pagination as Page } from "@mui/material";
import styled from "styled-components";

interface PaginationProps {
  page: number;
  total: number;
  size: number;
  position?: "BOTTOM_CENTER";
  clickPage: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = (props) => {
  const setCount = () => {
    const quotient = Math.floor(props.total / props.size);
    const remainder = props.total % props.size;

    return remainder > 0 ? quotient + 1 : quotient;
  };

  const setLayoutStyle = (): React.CSSProperties => {
    if (props.position === "BOTTOM_CENTER") {
      return {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        margin: "24px 0px 48px 0px",
      };
    } else {
      return {};
    }
  };

  return (
    <Layout>
      <Page
        sx={{
          "& .MuiPaginationItem-root": {
            borderRadius: "50%",
            width: 32,
            height: 32,
            margin: "0 5px",
            border: "none",
            "&.Mui-selected": {
              backgroundColor: "#1C5C56",
              color: "white",
            },
            "&:hover": {
              backgroundColor: "#E7EBDA",
            },
          },
          "& .MuiPaginationItem-root.MuiPaginationItem-previousNext": {
            backgroundColor: "transparent",
            color: "#1C5C56",
            fontSize: "24px",
            "&:hover": {
              backgroundColor: "#E7EBDA",
            },
          },
        }}
        page={props.page}
        count={setCount()}
        variant="outlined"
        shape={"rounded"}
        onChange={(_, page) => props.clickPage(page)}
      />
    </Layout>
  );
};

export default Pagination;

const Layout = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 24px 0px 48px 0px;
`;
