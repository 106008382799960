import React from "react";
import { CenterModalFrame } from "../modal";
import styled from "styled-components";
import { Button } from "..";

interface ConfirmProps {
  open: boolean;
  message: string;
  close: () => void;
  confirm: () => void;
}

const Confirm: React.FC<ConfirmProps> = ({ open, message, confirm, close }) => {
  return (
    <CenterModalFrame
      open={open}
      close={close}
    >
      <AlertLayout>
        <Layout>
          <InfoMessageLayout>
            <InfoMessage>{message}</InfoMessage>
          </InfoMessageLayout>
        </Layout>
        <ButtonsLayout>
          <Button.ComponentButton
            component={<CancelButton>취소</CancelButton>}
            borderRadius={30}
            onClick={() => {
              close();
            }}
          />
          <Button.ComponentButton
            component={<ConfirmButton>확인</ConfirmButton>}
            borderRadius={30}
            onClick={() => {
              confirm && confirm();
              close();
            }}
          />
        </ButtonsLayout>
      </AlertLayout>
    </CenterModalFrame>
  );
};

export default Confirm;

const AlertLayout = styled.div`
  width: 380px;
  border-radius: 28px;
  background-color: #fff;
  padding: 24px 30px;

  @media screen and (max-width: 768px) {
    width: fit-content;
    max-width: calc(100vw - 60px);
  }
`;

const Layout = styled.div``;

const InfoMessageLayout = styled.div`
  display: flex;
  align-items: center;
  height: 49px;
`;

const InfoMessage = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #777777;
  white-space: pre-wrap;
`;

const ButtonsLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 16px;
`;

const CancelButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 73px;
  height: 36px;
  background-color: #e7ebda;
  color: #8d9797;
  font-weight: 500;
  border-radius: 30px;
`;

const ConfirmButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 73px;
  height: 36px;
  background-color: #1c5c56;
  color: #fff;
  font-weight: 500;
  border-radius: 30px;
`;
