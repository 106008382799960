import styled from "styled-components";
import { Portal, ModalProps } from ".";

type ModalMaskProps = Pick<ModalProps, "visible" | "maskDimmed" | "modalLevel">;
type ModalContainerProps = Omit<ModalProps, "maskDimmed" | "children" | "setVisible">;

interface ModalFrameProps extends ModalProps {}

const ModalMask = styled.div<ModalMaskProps>`
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  ${(props) => props.modalLevel && `z-index: ${props.modalLevel - 1};`}
  ${(props) => props.maskDimmed && `background-color: rgba(0, 0, 0, 0.45);`}
`;

const ModalContainer = styled.div.attrs<ModalContainerProps>((props) => ({
  className: `position-${props.position}`,
}))<ModalContainerProps>`
  display: ${(props) => (props.visible ? "block" : "none")};
  width: fit-content;
  ${(props) => props.modalLevel && `z-index: ${props.modalLevel};`}
  position: fixed;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  &.position {
    &-center {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &-top-center {
      top: 0%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const ModalFrame = ({
  visible,
  onClose,
  maskDimmed = true,
  modalLevel = 100,
  position = "center",
  closeable = true,
  children,
  disablePortal = false,
}: ModalFrameProps) => {
  if (typeof window === "undefined") return null;

  const handleModalMask = () => {
    if (closeable && onClose) {
      onClose();
    }
  };

  return disablePortal ? (
    <>
      <ModalMask
        modalLevel={modalLevel}
        maskDimmed={maskDimmed}
        visible={visible}
        onClick={handleModalMask}
      />
      <ModalContainer
        visible={visible}
        modalLevel={modalLevel}
        position={position}
      >
        {children}
      </ModalContainer>
    </>
  ) : (
    <Portal>
      <ModalMask
        modalLevel={modalLevel}
        maskDimmed={maskDimmed}
        visible={visible}
        onClick={handleModalMask}
      />
      <ModalContainer
        visible={visible}
        modalLevel={modalLevel}
        position={position}
      >
        {children}
      </ModalContainer>
    </Portal>
  );
};

export default ModalFrame;
