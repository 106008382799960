import { injectable, inject } from "inversify";
import { Observable } from "rxjs";
import { GetAIAnalysis, GetAIAnalysisInput, GetAIAnalysisOutput } from "./model/GetAIAnalysis";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class GetAIAnalysisImpl implements GetAIAnalysis {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute(input: GetAIAnalysisInput): Observable<GetAIAnalysisOutput> {
    return this.operationConsultingRepository.getAIAnalysis(input.noteId);
  }
}
