import React from "react";
import Drawer from "@mui/material/Drawer";
import useResize from "@view/hooks/useResize";

interface SideInModalFrameProps {
  open: boolean;
  children: React.ReactNode;
  maxWidth?: number;
  anchor: "bottom" | "left" | "top" | "right";
  close: () => void;
}

const SideInModalFrame: React.FC<SideInModalFrameProps> = (props) => {
  const { width } = useResize();

  return (
    <Drawer
      open={props.open}
      anchor={props.anchor}
      onClose={props.close}
      // hideBackdrop
      PaperProps={{
        sx: {
          maxWidth: props.maxWidth ?? "auto",
          margin: props.maxWidth ? (width < 768 ? "0 auto" : "0 0 0 50%") : 0,
        },
      }}
      slotProps={{
        backdrop: {
          sx: {
            background: "transparent",
          },
        },
      }}
    >
      {props.children}
    </Drawer>
  );
};

export default SideInModalFrame;
