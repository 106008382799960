import { MainMenu, Product } from "@view-data/Product";
import { UserInfo } from "@view-data/User";
import { GlobalNavigationBarViewModel } from "@view-model/model/GlobalNavigationBarViewModel";
import useRouter from "@view/hooks/useRouter";
import { useContainer } from "inversify-react";
import { useEffect, useState } from "react";

export const useGlobalNavigationBar = (userInfo: UserInfo) => {
  const vm = useContainer((container) => {
    const factory = container.get<(userInfo: UserInfo) => GlobalNavigationBarViewModel>("GlobalNavigationBarViewModel");
    return factory(userInfo);
  });
  const router = useRouter();
  const [isShow, setIsShow] = useState(true);

  useEffect(() => {
    vm.route.toLogin.subscribe(() => {
      router.push({ pathname: "/login" });
    });
  }, []);

  useEffect(() => {
    if (router.pathname === "/face-fit/consultations/recent") {
      setIsShow(false);
    } else {
      setIsShow(true);
    }
  }, [router.pathname]);

  const clickToOpenKakaoChannel = () => {
    window.open("https://pf.kakao.com/_ZxfHtC", "_blank");
  };
  return {
    state: {
      userInfo: userInfo,
      isShow,
    },
    input: {
      clickLogo: () => router.push({ pathname: "/home", uiSystem: vm.uiSystem }),
      clickSignOut: vm.input.clickSignOut,
      clickToKakao: clickToOpenKakaoChannel,
    },
  };
};
