import { MediaPipeHandler } from "@/domain/interactor/classification/MediaPipeHandler";
import { injectable } from "inversify";
import { FilesetResolver, FaceLandmarker, FaceLandmarkerResult, NormalizedLandmark, DrawingUtils } from "@mediapipe/tasks-vision";
import { Observable, of, Subscriber, throwError } from "rxjs";

@injectable()
export class MediaPipeHandlerImpl implements MediaPipeHandler {
  constructor() {}

  drawConnectors = (
    imageEle: HTMLImageElement,
    canvasEle: HTMLCanvasElement,
    normalizedFaceLandmarks: NormalizedLandmark[][],
    connections: { start: number; end: number }[],
    style: { lineWidth: number; color: string },
  ) => {
    const ctx = canvasEle.getContext("2d");
    canvasEle.setAttribute("width", imageEle.naturalWidth + "px");
    canvasEle.setAttribute("heigth", imageEle.naturalHeight + "px");

    canvasEle.setAttribute("width", 1000 + "px");
    canvasEle.setAttribute("height", 1000 + "px");

    if (ctx) {
      const drawingUtils = new DrawingUtils(ctx);

      for (const faceLandMark of normalizedFaceLandmarks) {
        drawingUtils.drawConnectors(faceLandMark, connections, style);
      }
      return of("SUCCESS");
    } else {
      return throwError(() => new Error("cavas load error"));
    }
  };
}
