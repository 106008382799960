import type { AdRepository } from "@/domain/interactor/repository/AdRepository";
import { inject, injectable } from "inversify";
import { map, Observable } from "rxjs";
import { TYPES } from "@/data/interactor/repository/index.container.type";

import {
  UpdateApplicationStatusByAdvertise,
  UpdateApplicationStatusByAdvertiseInput,
  UpdateApplicationStatusByAdvertiseOutput,
} from "./model/UpdateApplicationStatusByAdvertise";

@injectable()
export class UpdateApplicationStatusByAdvertiseImpl implements UpdateApplicationStatusByAdvertise {
  constructor(@inject(TYPES.AdRepository) private readonly adRepository: AdRepository) {}

  execute = (input: UpdateApplicationStatusByAdvertiseInput): Observable<UpdateApplicationStatusByAdvertiseOutput> => {
    return this.adRepository.updateConsultingStatus(input.noteId, input.status).pipe(
      map((res) => {
        return {
          advertiseConsultingNoteId: res.advertisementDBId,
        };
      }),
    );
  };
}
