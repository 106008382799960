import colors from "@view/styles/colors";
import styled from "styled-components";

const PageHeadWrapper = styled.div<{ marginBottom?: string; padding?: string }>`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "24px")};
    align-items: flex-start;
    background-color: ${colors.neutralWhiteDefault};
    width: 100%;
    position: sticky;
    top: 0;
    background-color: ${colors.neutralWhiteDefault};
    z-index: 10;
    ${(props) => props.padding && `padding: ${props.padding};`}
`;

export default PageHeadWrapper;
