import React from "react";
import styled from "styled-components";
import Button from "@view/widget/button";
import Icon from "@icon/index";

interface CheckBoxProps {
  label: string;
  disabled: boolean;
  checked: boolean;
}

const CheckBox: React.FC<CheckBoxProps> = ({ label, disabled, checked }) => {
  return (
    <Layout>
      {checked ? (
        <CheckBoxLayout $disabled={disabled}>
          <Icon.OutlinedCheck
            color={"neutralWhiteDefault"}
            xsmall
          />
        </CheckBoxLayout>
      ) : (
        <UnselectedCheckBox></UnselectedCheckBox>
      )}
      <Label>{label}</Label>
    </Layout>
  );
};

export default CheckBox;

const Layout = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const Label = styled.div`
  font-size: 14px;
  color: #000;
  font-weight: 400;
`;

const UnselectedCheckBox = styled.div`
  width: 24px;
  height: 24px;
  padding: 1px;
  border: 1px solid #eceef2;
  border-radius: 5px;
`;

const CheckBoxLayout = styled.div<{ $disabled: boolean }>`
  width: 24px;
  height: 24px;
  padding: 1px;
  border-radius: 5px;
  background-color: ${({ $disabled }) => ($disabled ? "#8D9797" : "#1c5c56")};
  display: flex;
  align-items: center;
  justify-content: center;
`;
