import Button, { ButtonProps } from "@view/widget/button";
import Icon from "@icon/index";
import colors from "@view/styles/colors";

interface SaveButtonProps extends Partial<ButtonProps> {
  onClick: () => void;
  text?: string;
}

const SaveButton: React.FC<SaveButtonProps> = ({ onClick, text = "임시저장", ...props }) => {
  return <Button.Filled.Large icon={<Icon.Save color="neutralWhiteDefault" />} text={text} onClick={onClick} backgroundColor={colors.semanticSuccess} {...props} />;
};

export default SaveButton;
