import { injectable } from "inversify";
import { ScrollHandler } from "./model";
import { ViewType } from "./model/ScrollHandler";
import { Subject } from "rxjs";

@injectable()
export default class ScrollHandlerImpl implements ScrollHandler {
  restoration: {
    view: ViewType;
    scroll: number;
  };
  output = {
    restoring: new Subject<boolean>(),
  };

  saveScroll: ({ view, scroll }: { view: ViewType; scroll?: number }) => void;
  resetScroll: () => void;
  restoreScroll: (view: ViewType, completion?: () => void) => void;

  constructor() {
    this.restoration = {
      view: null,
      scroll: 0,
    };

    this.saveScroll = ({ view, scroll }) => {
      this.restoration.view = view;
      this.restoration.scroll = scroll ?? window.scrollY;
    };
    this.resetScroll = () => {
      this.restoration = { view: null, scroll: 0 };
    };
    this.restoreScroll = (view, completion) => {
      this.output.restoring.next(true);
      if (this.restoration.view === view) {
        setTimeout(() => {
          window.scrollTo({ top: this.restoration.scroll, behavior: "auto" });
          setTimeout(() => {
            this.restoration = { view: null, scroll: 0 };
            this.output.restoring.next(false);
            completion && completion();
          }, 200);
        }, 300);
      } else {
        this.restoration = { view: null, scroll: 0 };
        this.output.restoring.next(false);
        completion && completion();
      }
    };
  }
}
