export default {
  afoterGradient: "linear-gradient(97.03deg, #FF3104 5.93%, #FF3495 94.96%)",
  afoterRed: "#ff3104",
  neutralBlack: "#000000",
  neutralWhiteDefault: "#ffffff",
  neutralGray200: "#3a3a3a",
  neutralGray400: "#636363",
  neutralGray600: "#ababab",
  neutralGray800: "#e4e4e4",
  neutralBgDefault: "#f4f5fa",
  neutralBgActive: "#EEF9FF",
  neutralLineDefault: "#eceef2",
  neutralLineActive: "#b5dbff",
  primaryPinkDefault: "#ff678c",
  primaryPink800: "#ffccd8",
  primaryPink900: "#FFF2F5",
  semanticGuide: "#E8488B",
  semanticError: "#FF3D31",
  semanticActive: "#377BFF",
  semanticSuccess: "#00C980",
  semanticNotice: "#FFCE22",
  taskCounselNew: "#A5FFFF",
  taskCounselUnknown: "#FFE500",
  taskCounselCancel: "#FF9900",
  taskCounselDone: "#02efc4",
  taskCounselBooking: "#29D8FE",
  taskButtonAlarm: "#881EF2",
  secondary: "#9BA5B7",
  drwo_prim_green_100: "#DEF1EE",
  drwo_prim_green_200: "#C9E6E2",
  drwo_prim_green_400: "#5AACA0",
  drwo_prim_green_600: "#1C5C56",
  drwo_prim_green_700: "#14443F",
  drwo_prim_green_800: "#033632",
  drwo_prim_green_900: "#012421",
  drwo_point_sand: "#E1CA97",
  drwo_bg_prim_point: "#E7EBDA",
  drwo_active_color: "#003E3B",
  task_counsel_done: "#00D7CA",
  disable: "rgba(0, 0, 0, 0.26)",
  drwo_prim_disabled_600: "#8D9797",
  drwo_bg_prim_icon: "#ABC8C8",
  drwo_bg_prim_point400: "#C0C5AE",
  semantic_success: "#00C980",
  drwo_bg_100: "#FAFDFF"
};
