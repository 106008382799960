import Icon from "@icon/index";
import colors from "@view/styles/colors";
import Button, { ButtonProps } from "@view/widget/button";

interface InspectionButtonProps extends Partial<ButtonProps> {
  onClick: () => void;
  text?: string;
}

const InspectionButton: React.FC<InspectionButtonProps> = ({ onClick, text = "검수 요청", ...props }) => {
  return (
    <Button.Filled.Large
      icon={<Icon.CheckWhite color={props.disabled ? "neutralGray600" : "neutralWhiteDefault"} />}
      text={text}
      onClick={onClick}
      backgroundColor={colors.neutralBlack}
      {...props}
    />
  );
};

export default InspectionButton;
