import { ConnectionPoint, Coordinate, DenormalizedCoordinate } from ".";

class FaceClassificationUtil {
  getDenormalizedFaceLandmark(
    imageEle: HTMLImageElement,
    coordinates: Coordinate[][],
    landmarkConnections: ConnectionPoint[],
  ): DenormalizedCoordinate {
    const list: number[] = [];

    landmarkConnections.forEach((connection) => {
      if (!list.includes(connection.start)) {
        list.push(connection.start);
      }

      if (!list.includes(connection.end)) {
        list.push(connection.end);
      }
    });

    if (coordinates[0]) {
      const normalizedLandmarkList = list.map((item) => {
        return { point: item, mark: coordinates[0][item] };
      });

      const coordinate: DenormalizedCoordinate = {};

      normalizedLandmarkList.forEach((result) => {
        const point = result.point;
        const landmark = result.mark;
        coordinate[point] = {
          x: imageEle.width * Number(landmark.x),
          y: imageEle.height * Number(landmark.y),
          z: imageEle.width * Number(landmark.z),
        };
      });

      return coordinate;
    } else {
      return {};
    }
  }

  findRightAngleTrianglePoint = (type: "LEFT" | "RIGHT", p1: Coordinate, p2: Coordinate): Coordinate => {
    switch (type) {
      case "LEFT":
        return {
          x: p1?.x ?? 0,
          y: p2?.y ?? 0,
          z: p1?.z ?? 0,
        };

      case "RIGHT":
        return {
          x: p2?.x ?? 0,
          y: p1?.y ?? 0,
          z: p1?.z ?? 0,
        };
    }
  };
  findLineIntersectionPoint = (linev1: [Coordinate, Coordinate], linev2: [Coordinate, Coordinate]): Coordinate => {
    const line1 = this.getLine(linev1[0], linev1[1]);
    const line2 = this.getLine(linev2[0], linev2[1]);

    if (line1.slope === line2.slope) {
      throw new Error("The lines are parallel and do not intersect.");
    }

    const x = (line2.yIntercept - line1.yIntercept) / (line1.slope - line2.slope);
    const y = line1.slope * x + line1.yIntercept;

    return { x, y, z: 0 };
  };

  calculateDistance(pointA: Coordinate, pointB: Coordinate, type: "3d" | "2d") {
    const point1 = type === "3d" ? pointA : { ...pointA, z: 0 };
    const point2 = type === "3d" ? pointB : { ...pointB, z: 0 };

    let dz = 0;
    const dx = point2.x - point1.x;
    const dy = point2.y - point1.y;

    if (point1.z && point2.z) {
      dz = point2.z - point1.z;
    }

    // 유클리디안 거리 계산
    const distance = Math.sqrt(dx * dx + dy * dy + dz * dz);

    return distance;
  }

  calcAngle(center: Coordinate, pointA: Coordinate, pointB: Coordinate, type: "3d" | "2d") {
    const point1 = type === "3d" ? pointA : { ...pointA, z: 0 };
    const point2 = type === "3d" ? pointB : { ...pointB, z: 0 };

    const firstVector = this.calculateVector(center, point1);
    const secondVector = this.calculateVector(center, point2);

    const angle = this.calculateAng(firstVector, secondVector);

    return angle;
  }

  truncateDecimals(number: number, digits: number) {
    const multiplier = Math.pow(10, digits);
    return Math.floor(number * multiplier) / multiplier;
  }

  findMidpoint = (point1: Coordinate, point2: Coordinate) => {
    const midX = (point1.x + point2.x) / 2;
    const midY = (point1.y + point2.y) / 2;
    const midZ = point1.z !== undefined && point2.z !== undefined ? (point1.z + point2.z) / 2 : undefined;

    return {
      x: midX,
      y: midY,
      z: midZ,
    };
  };

  private calculateVector(point1: Coordinate, point2: Coordinate) {
    const vector = {
      x: point2.x - point1.x,
      y: point2.y - point1.y,
      z: point2?.z ?? 0 - (point1?.z ?? 0),
    };

    return vector;
  }

  private calculateAng(vectorA: Coordinate, vectorB: Coordinate) {
    const dot = this.calculateDot(vectorA, vectorB);
    const magnitudeA = this.calculateMagnitude(vectorA);
    const magnitudeB = this.calculateMagnitude(vectorB);

    const cosTheta = dot / (magnitudeA * magnitudeB);
    const angleRad = Math.acos(cosTheta);

    const angleDeg = (angleRad * 180) / Math.PI;

    return Number(angleDeg.toFixed(8));
  }

  private calculateMagnitude(vector: Coordinate) {
    return Math.sqrt(vector.x ** 2 + vector.y ** 2 + (vector?.z ? vector.z ** 2 : 0));
  }

  private calculateDot(a: Coordinate, b: Coordinate) {
    return a.x * b.x + a.y * b.y + (a?.z ?? 0) * (b?.z ?? 0);
  }

  private getLine = (point1: Coordinate, point2: Coordinate) => {
    const dx = point2.x - point1.x;
    const dy = point2.y - point1.y;

    const slope = dy / dx;
    const yIntercept = point1.y - slope * point1.x;

    return {
      slope,
      yIntercept,
    };
  };

  getCenterPointOfLine = (point1: Coordinate, point2: Coordinate) => {
    const x = (point2.x + point1.x) / 2;
    const y = (point2.y + point1.y) / 2;
    const z = ((point2?.z ?? 0) + (point1?.z ?? 0)) / 2;

    return {
      x,
      y,
      z,
    };
  };
}

export default new FaceClassificationUtil();
