import React from "react";
import { Confirm } from "@view/widget/alert";

interface ConfirmAlertPopupProps {
  open: boolean;
  message?: string;
  close: () => void;
  confirm?: () => void;
}

const ConfirmAlertPopup: React.FC<ConfirmAlertPopupProps> = ({ open, message, close, confirm }) => {
  return (
    <Confirm
      open={open}
      message={message ?? ""}
      close={close}
      confirm={() => confirm && confirm()}
    />
  );
};

export default ConfirmAlertPopup;
