import "reflect-metadata";
import { MainView } from "../application/view/service/main/MainView";
import UISystem from "@/application/ui-system/view/UISystem";
import container from "@/ioc";
import { datadogRum } from "@datadog/browser-rum";
import { ContextProvider } from "@view/context";
import GlobalStyle from "@view/styles/GlobalStyles";
import "@view/styles/font.css";
import { Provider } from "inversify-react";
import type { AppContext, AppProps } from "next/app";
import getConfig from "next/config";
import Head from "next/head";
import Script from "next/script";
import useDatadogRUM from "@view/hooks/useDatadogRum";

function App({ Component, pageProps, router }: AppProps) {
  pageProps = { ...pageProps };
  useDatadogRUM({ init: true });

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="initial-scale=1.0,user-scalable=no,maximum-scale=1,width=device-width,viewport-fit=cover"
        />
      </Head>
      <Script
        strategy="beforeInteractive"
        type="text/javascript"
        src="//dapi.kakao.com/v2/maps/sdk.js?appkey=d03e2e9107203bca4d7d2976d2b159f0&autoload=false&libraries=services"
      />
      <GlobalStyle />
      <ContextProvider>
        <Provider container={container}>
          <MainView
            Component={Component}
            pageProps={pageProps}
            router={router}
          />
        </Provider>
        <UISystem />
      </ContextProvider>
    </>
  );
}

App.getInitialProps = async ({ Component, ctx }: AppContext) => {
  let pageProps = {};

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  pageProps = { ...pageProps };

  return { pageProps };
};

export default App;
