import { FaceLandmarks, Storage } from "@/domain/interactor/storage";
import { inject, injectable } from "inversify";
import { Observable } from "rxjs";
import type { Gcs, PrivateUploadInput } from "@/storage/gcs";

interface RequestUpload {
  bucket?: string;
  filename?: string;
  file: Buffer;
}

interface UploadResponse {
  url: string;
}

@injectable()
export class StorageImpl implements Storage {
  constructor(
    @inject("Gcs")
    private gcs: Gcs,
  ) {
    this.gcs = gcs;
  }
  uploadBy(requestUpload: RequestUpload): Observable<UploadResponse> {
    return this.gcs.uploadBy(requestUpload);
  }
  privateUpload(request: PrivateUploadInput): Observable<UploadResponse> {
    return this.gcs.uploadPrivateBucket(request);
  }

  getFaceLandmarks(url: string): Observable<FaceLandmarks> {
    return this.gcs.getFaceLandmarks(url);
  }

  generateSignedUrl(originUrl: string, expiresIn: number): Observable<string> {
    return this.gcs.generateSignedUrlV4(originUrl, expiresIn);
  }

  getSignedURL(bucket: string, pathAndFilename: string, expiresAt: number): Observable<{ signedUrl: string }> {
    return this.gcs.getSignedURL(bucket, pathAndFilename, expiresAt);
  }
}
