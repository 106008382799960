import colors from "@view/styles/colors";
import { Button, Typo } from "@view/widget";
import React from "react";
import styled from "styled-components";

interface ProductMenuItemProps {
  menuName: string;
  hasNewBadge?: boolean;
  isSelected: boolean;
  click: () => void;
}

export const ProductMenuItem: React.FC<ProductMenuItemProps> = ({ menuName, hasNewBadge, isSelected, click }) => {
  return (
    <Button.ComponentButton
      component={
        <ProductMenuItemLayout>
          <Typo.T2
            value={menuName}
            bold
            color={isSelected ? colors.drwo_point_sand : "#CBD4CA"}
            style={{ whiteSpace: "nowrap" }}
          />
          {hasNewBadge ? <NewBadge>NEW</NewBadge> : null}
        </ProductMenuItemLayout>
      }
      onClick={click}
    />
  );
};

const ProductMenuItemLayout = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 2px;
`;

const NewBadge = styled.div`
  width: 40.5px;
  height: 16px;
  background-color: #1c5c56;
  border-radius: 3px;
  font-size: 9px;
  color: ${colors.drwo_point_sand};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
`;
