import { injectable, inject } from "inversify";
import {
  RequestLandmarksForUnextractedPhotos,
  RequestLandmarksForUnextractedPhotosInput,
  RequestLandmarksForUnextractedPhotosOutput,
} from "./model/RequestLandmarksForUnextractedPhotos";
import { TYPES } from "@/data/interactor/repository/index.container.type";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { from, interval, Observable, concatMap, switchMap, takeWhile, map } from "rxjs";

@injectable()
export class RequestLandmarksForUnextractedPhotosImpl implements RequestLandmarksForUnextractedPhotos {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute = (input: RequestLandmarksForUnextractedPhotosInput): Observable<RequestLandmarksForUnextractedPhotosOutput> => {
    return from(input.photoIds).pipe(
      concatMap((photoId) => {
        return this.operationConsultingRepository.requestFaceLandmark(input.noteId, photoId).pipe(
          switchMap(({ analysisId }) => {
            return interval(2000).pipe(
              switchMap(() => this.operationConsultingRepository.getAIAnalysis(input.noteId)),
              takeWhile((faceFitAnalysis, attemptCount) => {
                const conditionMet = faceFitAnalysis.faceLandmarks.some(
                  (landmark) => landmark.notePhotoId === photoId && (landmark.status === "SUCCESS" || landmark.status === "FAIL"),
                );
                return !conditionMet && attemptCount < 13;
              }),
              map((faceFitAnalysis) => {
                return { faceFitAnalysis };
              }),
            );
          }),
        );
      }),
    );
  };
}
