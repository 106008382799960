import type { SessionRepository } from "@/domain/interactor/repository/SessionRepository";
import type { UserRepository } from "@/domain/interactor/repository/UserRepository";
import { inject, injectable } from "inversify";
import { map, Observable } from "rxjs";
import { GetUserOutput, GetUserV2, AccessibleProduct, ProductFeature } from "./model/GetUserV2";
import * as Entity from "@/domain/entity";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class GetUserV2Impl implements GetUserV2 {
  constructor(
    @inject(TYPES.SessionRepository) private readonly sessionRepository: SessionRepository,
    @inject(TYPES.UserRepository) private readonly userRepository: UserRepository,
  ) {}

  execute = (): Observable<GetUserOutput> => {
    return this.userRepository.getUserV2().pipe(
      map((userInfo) => {
        this.sessionRepository.saveUser(userInfo);
        return {
          ...userInfo,
          contract: {
            ...userInfo.contract,
            accessibleProducts: this.getAccessibleProducts(
              userInfo.user.productAccessRights,
              userInfo.contract.plan.products.map((p) => p.code),
            ).map((product) => {
              if (product.name === "face-fit" && userInfo.hospital.id === 40649) {
                return {
                  ...product,
                  features: product.features.filter(({ name }) => name !== "best-practice"),
                };
              }
              return product;
            }),
          },
        };
      }),
    );
  };

  private getAccessibleProducts = (
    productAccessRights: string[],
    contractProductCode: Entity.Type.ContractProduct[],
  ): AccessibleProduct[] => {
    const accessibleProductCode = productAccessRights
      .filter((right) => {
        const productCode = right.split(":")[0];
        const productAccessRights = right.split(":")[1]?.split(",");
        if (!productCode || !productAccessRights) return false;
        return productCode && productAccessRights.includes("read");
      })
      .map((right) => right.split(":")[0] as Entity.Type.ContractProduct)
      .filter((accessibleProductCode) => this.isAccessible(accessibleProductCode, contractProductCode))
      .filter((accessibleProductCode) => accessibleProductCode.split(".")[0] !== undefined)
      .map((accessibleProductCode) => {
        const product = accessibleProductCode.split(".")[0] as Entity.Type.ContractProduct;
        return {
          productName: this.productNameMapper(product) as Entity.Type.ProductName,
          accessibleProductCode,
        };
      });

    const groupBy = this.groupBy(accessibleProductCode, ({ productName }) => productName);

    const accessibleProducts = Object.keys(groupBy)
      .map((key) => key as Entity.Type.ProductName)
      .map<AccessibleProduct>((productName) => {
        const code = groupBy[productName].map((_) => _.accessibleProductCode);
        return {
          name: productName,
          features: this.productFeatureMapper(code),
        };
      });

    return accessibleProducts;
  };

  private isAccessible = (accessibleProductCode: Entity.Type.ContractProduct, contractProductCode: Entity.Type.ContractProduct[]) => {
    return contractProductCode.some((pc) => pc.startsWith(accessibleProductCode));
  };

  private productFeatureMapper = (products: Entity.Type.ContractProduct[]): ProductFeature[] => {
    let features: ProductFeature[] = [];
    if (products.includes("hospital.management")) {
      features = features.concat([
        {
          name: "information",
        },
        {
          name: "doctors",
        },
        {
          name: "contacts",
        },
        {
          name: "accounts",
        },
        {
          name: "plan",
        },
      ]);
    }
    if (products.includes("advertisement.database")) {
      features = features.concat([
        {
          name: "customers",
        },
      ]);
    }
    if (products.includes("facefit.customers")) {
      features = features.concat([
        {
          name: "customers",
        },
      ]);
    }
    if (products.includes("facefit.bestpractice")) {
      features = features.concat([
        {
          name: "best-practice",
        },
      ]);
    }

    if (products.includes("facefit.facestyles")) {
      features = features.concat([
        {
          name: "face-styles",
        },
      ]);
    }
    return features;
  };

  private productNameMapper = (producCode: Entity.Type.ContractProduct): Entity.Type.ProductName | null => {
    if (producCode.startsWith("hospital")) {
      return "hospital";
    }
    if (producCode.startsWith("advertisement")) {
      return "advertisement";
    }
    if (producCode.startsWith("facefit")) {
      return "face-fit";
    }
    return null;
  };

  private groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) => {
    return list.reduce((previous, currentItem) => {
      const group = getKey(currentItem);
      if (!previous[group]) previous[group] = [];
      previous[group].push(currentItem);
      return previous;
    }, {} as Record<K, T[]>);
  };
}
