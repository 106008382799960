import { injectable, inject } from "inversify";
import {
  UpdateOperationReservationStatus,
  UpdateOperationReservationStatusInput,
  UpdateOperationReservationStatusOutput,
} from "./model/UpdateOperationReservationStatus";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { Observable } from "rxjs";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class UpdateOperationReservationStatusImpl implements UpdateOperationReservationStatus {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute(input: UpdateOperationReservationStatusInput): Observable<UpdateOperationReservationStatusOutput> {
    return this.operationConsultingRepository.updateReservationStatus({ noteId: input.noteId, reservationStatus: input.reservationStatus });
  }
}
