import { injectable } from "inversify";
import { FaceScanFrontalCanvasViewModel } from "./model/FaceScanFrontalCanvasViewModel";
import { Subject } from "rxjs";
import type { GetFaceLandmarks } from "@/domain/usecase/gcs/model/GetFaceLandmarks";
import { FaceLandmark, FaceFit } from "@view-data/index";
import { AsymmetricalEye } from "./face-classification/eye/AsymmetricalEye";
import { EyeTail } from "./face-classification/eye/EyeTail";
import { EyesAspectRatio } from "./face-classification/eye/EyeAspectRatio";
import { DrawingFacialLandmarks } from "./face-classification/face/DrawingFacialLandmarks";
import { NoseLengthRatio } from "./face-classification/nose/NoseLengthRatio";
import { NoseHeightRatio } from "./face-classification/nose/NoseHeightRatio";
import { ForeheadNoseAngle } from "./face-classification/nose/ForeheadNoseAngle";
import { NasolabialAngle } from "./face-classification/nose/NasolabialAngle";
import { NoseTipShape } from "./face-classification/nose/NoseTipShape";
import { NoseBridgeWidthRatio } from "./face-classification/nose/NoseBridgeWidthRatio";
import { NoseGuideLine } from "./face-classification/face/NoseGuideLine";
import { FaceShapeType } from "./face-classification/face-contour/FaceShapeType";
import { FaceContourGuideLine } from "./face-classification/face/FaceContourGuideLine";
import { ZygomaticRatio } from "./face-classification/face-contour/ZygomaticRatio";
import { MandibularRatio } from "./face-classification/face-contour/MandibularRatio";
import { FaceContourSymmetryRatio } from "./face-classification/face-contour/SymmetryRatio";
import { EyeGoldenRatio } from "./face-classification/eye/EyeGoldenRatio";
@injectable()
export class FaceScanFrontalCanvasViewModelImpl implements FaceScanFrontalCanvasViewModel {
  data: FaceScanFrontalCanvasViewModel["data"] = {
    landmarks: null,
    faceScanResult: null,
    eyeClassification: {
      goldenRatio: null,
      asymmetricalEye: null,
      eyeTail: null,
      eyeAspectRatio: null,
    },
    noseClassification: {
      noseLengthRatio: null,
      noseHeightRatio: null,
      foreheadNoseAngle: null,
      nasolabialAngle: null,
      noseTipShape: null,
      noseBridgeWidthRatio: null,
    },
    faceContourClassification: {
      faceShapeType: null,
      zygomaticRatio: null,
      mandibularRatio: null,
      symmetryRatio: null,
    },
    eyeScanResults: {
      goldenRatio: { before: null, best: "0.5 : 1 : 1 : 1 : 0.5" },
      asymmetry: { before: null, best: "1 : 1" },
      aspectRatio: { before: null, best: "1 : 3 / 1 : 3" },
      eyeTail: { before: null, best: "4° : 4°" },
    },
    noseScanResults: {
      noseHeight: { before: null, best: "코길이의 67%" },
      noseLength: { before: null, best: "1 : 1" },
      foreheadNoseAngle: { before: null, best: "150° (Female) 148° (Male)" },
      nasolabialAngle: { before: null, best: "95°~100° (Female)\n90°~95° (Male)" },
      noseTipShape: { before: null, best: "2 : 6 : 2, 완만한 갈매기라인" },
      noseBridgeWidth: { before: null, best: "1 : 8 : 1" },
    },
    faceContourScanResults: {
      faceShapeType: { before: null, best: "70%" },
      zygomaticRatio: { before: null, best: "0.5 : 1 : 1 : 1 : 0.5" },
      mandibularRatio: { before: null, best: "1 : 0.8 / 33% : 67%" },
      symmetryRatio: { before: null, best: `90°:90°, 90°:90°` },
    },
    canvasDrawingState: {
      noseGuideLine: false,
      noseLength: false,
      noseHeight: false,
      foreheadNoseAngle: false,
    },
  };

  output: FaceScanFrontalCanvasViewModel["output"] = {
    landmarks: new Subject<FaceLandmark.Coordinate[][]>(),
    eyeScanResults: new Subject<FaceFit.EyeScanResults>(),
    noseScanResults: new Subject<FaceFit.NoseScanResults>(),
    faceContourScanResults: new Subject<FaceFit.FaceContourScanResults>(),
    eyeScanResultsDescription: new Subject<{ [key: string]: FaceFit.FaceScanResultDescription }>(),
    noseScanResultDescription: new Subject<{ [key: string]: FaceFit.FaceScanResultDescription }>(),
    faceContourScanResultDescription: new Subject<{ [key: string]: FaceFit.FaceScanResultDescription }>(),
  };

  constructor(private readonly ucGetFaceLandmarksByGCS: GetFaceLandmarks) {}

  event: FaceScanFrontalCanvasViewModel["event"] = {
    onInit: (faceScanResult) => {
      const sub = this.ucGetFaceLandmarksByGCS.execute({ url: faceScanResult.faceLandmarksJSONURL }).subscribe({
        next: ({ landmarks }) => {
          this.data.faceScanResult = faceScanResult;
          this.data.landmarks = landmarks;
          this.output.landmarks.next(this.data.landmarks);
          sub.unsubscribe();
        },
        error: () => {
          sub.unsubscribe();
        },
      });
    },

    face: {
      onSetFaceLandmark: (photo, canvas) => {
        if (this.data.landmarks) {
          const drawingFacialLandmarks = new DrawingFacialLandmarks({
            imageEle: photo,
            canvasEle: canvas,
            normalizedCoordinate: this.data.landmarks,
          });

          drawingFacialLandmarks.drawLandmarkPoints();
        }
      },
      onSetDrawingFacialLandmarks: (photo, canvas) => {
        if (this.data.landmarks) {
          const drawingFacialLandmarks = new DrawingFacialLandmarks({
            imageEle: photo,
            canvasEle: canvas,
            normalizedCoordinate: this.data.landmarks,
          });

          drawingFacialLandmarks.drawSideFace();
        }
      },
      onSetNoseGuideLine: (photo, noseCanvas, guideLine) => {
        if (this.data.landmarks) {
          const guideLineCanvas = new NoseGuideLine({
            config: {
              imageEle: photo,
              canvasEle: noseCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            guideLineCanvas: guideLine,
          });

          guideLineCanvas.drawGuideLine();
        }
      },
      onSetFaceContour: (photo, faceContourCanvas) => {
        if (this.data.landmarks) {
          const guideLineCanvas = new FaceContourGuideLine({
            config: { imageEle: photo, canvasEle: faceContourCanvas, normalizedCoordinate: this.data.landmarks },
            guideLineCanvas: faceContourCanvas,
          });

          guideLineCanvas.drawGuideLine();
        }
      },
    },

    eye: {
      onSetGoldenRatio: (canvasConfig) => {
        if (this.data.landmarks && this.data.faceScanResult?.alignedImageURL) {
          const beforeCanvas = new EyeGoldenRatio({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.beforeCanvas,
            normalizedCoordinate: this.data.landmarks,
          });
          const bestCanvas = new EyeGoldenRatio({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.bestCanvas,
            normalizedCoordinate: this.data.landmarks,
          });
          beforeCanvas.drawPhotoBase();
          bestCanvas.drawBestRatio();

          this.data.eyeClassification.goldenRatio = beforeCanvas;
          this.data.eyeScanResults.goldenRatio.before = `${beforeCanvas.ratio.우얼굴} : ${beforeCanvas.ratio.우눈} : ${beforeCanvas.ratio.중안} : ${beforeCanvas.ratio.좌눈} : ${beforeCanvas.ratio.좌얼굴}`;
          this.output.eyeScanResults.next({ ...this.data.eyeScanResults });

          const eyeScanDescription = this.getEyeScanResultsDescription();
          this.output.eyeScanResultsDescription.next(eyeScanDescription);
        }
      },
      onSetAsymmetricalRatio: (canvasConfig) => {
        if (this.data.landmarks && this.data.faceScanResult?.alignedImageURL) {
          const beforeCanvas = new AsymmetricalEye({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.beforeCanvas,
            normalizedCoordinate: this.data.landmarks,
          });

          const bestCanvas = new AsymmetricalEye({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.bestCanvas,
            normalizedCoordinate: this.data.landmarks,
          });

          beforeCanvas.drawPhotoBase();
          bestCanvas.drawBestRatio();

          this.data.eyeClassification.asymmetricalEye = beforeCanvas;
          this.data.eyeScanResults.asymmetry.before = `${beforeCanvas.ratio.우} : ${beforeCanvas.ratio.좌}`;
          this.output.eyeScanResults.next({ ...this.data.eyeScanResults });

          const eyeScanDescription = this.getEyeScanResultsDescription();
          this.output.eyeScanResultsDescription.next(eyeScanDescription);
        }
      },
      onSetEyeTailRatio: (canvasConfig) => {
        if (this.data.landmarks) {
          const beforeCanvas = new EyeTail({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.beforeCanvas,
            normalizedCoordinate: this.data.landmarks,
          });

          const bestCanvas = new EyeTail({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.bestCanvas,
            normalizedCoordinate: this.data.landmarks,
          });

          beforeCanvas.drawPhotoBase();
          bestCanvas.drawBestRatio();

          this.data.eyeClassification.eyeTail = beforeCanvas;
          this.data.eyeScanResults.eyeTail.before = `${beforeCanvas.eyeTailAngle.왼눈}° : ${beforeCanvas.eyeTailAngle.우눈}°`;
          this.output.eyeScanResults.next({ ...this.data.eyeScanResults });

          const eyeScanDescription = this.getEyeScanResultsDescription();
          this.output.eyeScanResultsDescription.next(eyeScanDescription);
        }
      },
      onSetEyeAspectRatio: (canvasConfig) => {
        if (this.data.landmarks) {
          const beforeCanvas = new EyesAspectRatio({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.beforeCanvas,
            normalizedCoordinate: this.data.landmarks,
          });

          const bestCanvas = new EyesAspectRatio({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.bestCanvas,
            normalizedCoordinate: this.data.landmarks,
          });

          beforeCanvas.drawPhotoBase();
          bestCanvas.drawBestRatio();

          this.data.eyeClassification.eyeAspectRatio = beforeCanvas;
          this.data.eyeScanResults.aspectRatio.before = `${beforeCanvas.ratio.right.높이} : ${beforeCanvas.ratio.right.길이} / ${beforeCanvas.ratio.left.높이} : ${beforeCanvas.ratio.left.길이}`;
          this.output.eyeScanResults.next({ ...this.data.eyeScanResults });

          const eyeScanDescription = this.getEyeScanResultsDescription();
          this.output.eyeScanResultsDescription.next(eyeScanDescription);
        }
      },
    },

    nose: {
      onSetNoseLength: (canvasConfig) => {
        if (this.data.landmarks && !Boolean(this.data.canvasDrawingState.noseLength)) {
          this.data.canvasDrawingState.noseLength = true;
          const beforeClassification = new NoseLengthRatio({
            config: {
              imageEle: canvasConfig.photo,
              canvasEle: canvasConfig.beforeCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            frontalFaceCanvas: canvasConfig.beforeFrontalFaceCanvas,
          });

          const bestCanvas = new NoseLengthRatio({
            config: {
              imageEle: canvasConfig.photo,
              canvasEle: canvasConfig.bestCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            frontalFaceCanvas: canvasConfig.bestFrontalFaceCanvas,
          });

          beforeClassification.drawPhotoBase();
          bestCanvas.drawBestRatio();

          this.data.noseClassification.noseLengthRatio = beforeClassification;
          this.data.noseScanResults.noseLength.before = `${beforeClassification.ratio.noseRatio} : ${beforeClassification.ratio.jawRatio}`;
          this.output.noseScanResults.next({ ...this.data.noseScanResults });

          const noseScanResultDescription = this.getNoseScanResultsDescription();
          this.output.noseScanResultDescription.next(noseScanResultDescription);
        }
      },
      onSetNoseHeight: (canvasConfig) => {
        if (this.data.landmarks) {
          const beforeCanvas = new NoseHeightRatio({
            config: {
              imageEle: canvasConfig.photo,
              canvasEle: canvasConfig.beforeCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            frontalFaceCanvas: canvasConfig.beforeFrontalFaceCanvas,
          });

          const bestClassification = new NoseHeightRatio({
            config: {
              imageEle: canvasConfig.photo,
              canvasEle: canvasConfig.bestCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            frontalFaceCanvas: canvasConfig.bestFrontalFaceCanvas,
          });

          beforeCanvas.drawPhotoBase();
          bestClassification.drawBestRatio();

          this.data.noseClassification.noseHeightRatio = beforeCanvas;
          this.data.noseScanResults.noseHeight.before = `1 : ${parseFloat((beforeCanvas.ratio.noseHeight * 100).toFixed(2)).toString()}%`;
          this.output.noseScanResults.next({ ...this.data.noseScanResults });

          const noseScanResultDescription = this.getNoseScanResultsDescription();
          this.output.noseScanResultDescription.next(noseScanResultDescription);
        }
      },
      onSetForeheadNoseAngle: (canvasConfig) => {
        if (this.data.landmarks) {
          const beforeCanvas = new ForeheadNoseAngle({
            config: {
              imageEle: canvasConfig.photo,
              canvasEle: canvasConfig.beforeCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            frontalFaceCanvas: canvasConfig.beforeFrontalFaceCanvas,
          });

          const bestClassification = new ForeheadNoseAngle({
            config: {
              imageEle: canvasConfig.photo,
              canvasEle: canvasConfig.bestCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            frontalFaceCanvas: canvasConfig.bestFrontalFaceCanvas,
          });

          beforeCanvas.drawPhotoBase();
          bestClassification.drawBestRatio();

          this.data.noseClassification.foreheadNoseAngle = beforeCanvas;
          this.data.noseScanResults.foreheadNoseAngle.before = `${beforeCanvas.ratio.angle}°`;
          this.output.noseScanResults.next({ ...this.data.noseScanResults });

          const noseScanResultDescription = this.getNoseScanResultsDescription();
          this.output.noseScanResultDescription.next(noseScanResultDescription);
        }
      },
      onSetNasolabialAngle: (canvasConfig) => {
        if (this.data.landmarks) {
          const beforeCanvas = new NasolabialAngle({
            config: {
              imageEle: canvasConfig.photo,
              canvasEle: canvasConfig.beforeCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            frontalFaceCanvas: canvasConfig.beforeFrontalFaceCanvas,
          });

          const bestClassification = new NasolabialAngle({
            config: {
              imageEle: canvasConfig.photo,
              canvasEle: canvasConfig.bestCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            frontalFaceCanvas: canvasConfig.bestFrontalFaceCanvas,
          });

          beforeCanvas.drawPhotoBase();
          bestClassification.drawBestRatio();
          this.data.noseClassification.nasolabialAngle = beforeCanvas;
          this.data.noseScanResults.nasolabialAngle.before = `${beforeCanvas.ratio.angle}°`;
          this.output.noseScanResults.next({ ...this.data.noseScanResults });

          const noseScanResultDescription = this.getNoseScanResultsDescription();
          this.output.noseScanResultDescription.next(noseScanResultDescription);
        }
      },
      onSetNoseTipShape: (canvasConfig) => {
        if (this.data.landmarks) {
          const beforeCanvas = new NoseTipShape({
            config: {
              imageEle: canvasConfig.photo,
              canvasEle: canvasConfig.beforeCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            frontalFaceCanvas: canvasConfig.beforeFrontalFaceCanvas,
          });
          const bestClassification = new NoseTipShape({
            config: {
              imageEle: canvasConfig.photo,
              canvasEle: canvasConfig.bestCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            frontalFaceCanvas: canvasConfig.bestFrontalFaceCanvas,
          });

          beforeCanvas.drawPhotoBase();
          bestClassification.drawBestRatio();

          this.data.noseClassification.noseTipShape = beforeCanvas;
          this.data.noseScanResults.noseTipShape.before = `${(beforeCanvas.ratio.right * 10).toFixed(1)} : ${(
            beforeCanvas.ratio.center * 10
          ).toFixed(1)} : ${(beforeCanvas.ratio.left * 10).toFixed(1)}`;
          this.output.noseScanResults.next({ ...this.data.noseScanResults });

          const noseScanResultDescription = this.getNoseScanResultsDescription();
          this.output.noseScanResultDescription.next(noseScanResultDescription);
        }
      },
      onSetNoseBridgeWidth: (canvasConfig) => {
        if (this.data.landmarks) {
          const beforeCanvas = new NoseBridgeWidthRatio({
            config: {
              imageEle: canvasConfig.photo,
              canvasEle: canvasConfig.beforeCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            frontalFaceCanvas: canvasConfig.beforeFrontalFaceCanvas,
          });
          const bestClassification = new NoseBridgeWidthRatio({
            config: {
              imageEle: canvasConfig.photo,
              canvasEle: canvasConfig.bestCanvas,
              normalizedCoordinate: this.data.landmarks,
            },
            frontalFaceCanvas: canvasConfig.bestFrontalFaceCanvas,
          });
          beforeCanvas.drawPhotoBase();
          bestClassification.drawBestRatio();

          this.data.noseClassification.noseBridgeWidthRatio = beforeCanvas;
          this.data.noseScanResults.noseBridgeWidth.before = `${(beforeCanvas.ratio.right * 10).toFixed()} : ${(
            beforeCanvas.ratio.center * 10
          ).toFixed(2)} : ${(beforeCanvas.ratio.left * 10).toFixed(2)}`;
          this.output.noseScanResults.next({ ...this.data.noseScanResults });

          const noseScanResultDescription = this.getNoseScanResultsDescription();
          this.output.noseScanResultDescription.next(noseScanResultDescription);
        }
      },
    },
    faceContour: {
      onSetFaceShapeType: (canvasConfig) => {
        if (this.data.landmarks) {
          const beforeClassification = new FaceShapeType({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.beforeCanvas,
            normalizedCoordinate: this.data.landmarks,
          });
          const bestClassification = new FaceShapeType({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.bestCanvas,
            normalizedCoordinate: this.data.landmarks,
          });

          beforeClassification.drawPhotoBase();
          bestClassification.drawBestRatio();

          this.data.faceContourClassification.faceShapeType = beforeClassification;
          this.data.faceContourScanResults.faceShapeType.before = `${parseFloat(
            (beforeClassification.ratio.bottom * 100).toFixed(2),
          ).toString()}%`;
          this.output.faceContourScanResults.next({ ...this.data.faceContourScanResults });

          const faceContourScanResultDescription = this.getFaceContourScanResultDescriptions();
          this.output.faceContourScanResultDescription.next(faceContourScanResultDescription);
        }
      },
      onSetFrontalFaceRatio: (canvasConfig) => {
        if (this.data.landmarks) {
          const beforeClassification = new ZygomaticRatio({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.beforeCanvas,
            normalizedCoordinate: this.data.landmarks,
          });
          const bestClassification = new ZygomaticRatio({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.bestCanvas,
            normalizedCoordinate: this.data.landmarks,
          });

          beforeClassification.drawPhotoBase();
          bestClassification.drawBestRatio();

          this.data.faceContourClassification.zygomaticRatio = beforeClassification;
          this.data.faceContourScanResults.zygomaticRatio.before = `${beforeClassification.ratio.rightFace} : ${beforeClassification.ratio.rightEye} : ${beforeClassification.ratio.midFace} : ${beforeClassification.ratio.leftEye} : ${beforeClassification.ratio.leftFace}`;
          this.output.faceContourScanResults.next({ ...this.data.faceContourScanResults });

          const faceContourScanResultDescription = this.getFaceContourScanResultDescriptions();
          this.output.faceContourScanResultDescription.next(faceContourScanResultDescription);
        }
      },
      onSetJawlineRatio: (canvasConfig) => {
        if (this.data.landmarks) {
          const beforeClassification = new MandibularRatio({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.beforeCanvas,
            normalizedCoordinate: this.data.landmarks,
          });
          const bestClassification = new MandibularRatio({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.bestCanvas,
            normalizedCoordinate: this.data.landmarks,
          });

          beforeClassification.drawPhotoBase();
          bestClassification.drawBestRatio();

          this.data.faceContourClassification.mandibularRatio = beforeClassification;
          this.data.faceContourScanResults.mandibularRatio.before = `${1} : ${beforeClassification.ratio.face.ratio.bottom} / ${
            beforeClassification.ratio.jaw.ratio.top * 100
          }% : ${beforeClassification.ratio.jaw.ratio.bottom * 100}%`;
          this.output.faceContourScanResults.next({ ...this.data.faceContourScanResults });

          const faceContourScanResultDescription = this.getFaceContourScanResultDescriptions();
          this.output.faceContourScanResultDescription.next(faceContourScanResultDescription);
        }
      },
      onSetSymmetryRatio: (canvasConfig) => {
        if (this.data.landmarks) {
          const beforeClassification = new FaceContourSymmetryRatio({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.beforeCanvas,
            normalizedCoordinate: this.data.landmarks,
          });
          const bestClassification = new FaceContourSymmetryRatio({
            imageEle: canvasConfig.photo,
            canvasEle: canvasConfig.bestCanvas,
            normalizedCoordinate: this.data.landmarks,
          });

          beforeClassification.drawPhotoBase();
          bestClassification.drawBestRatio();
          this.data.faceContourClassification.symmetryRatio = beforeClassification;
          this.data.faceContourScanResults.symmetryRatio.before = `${beforeClassification.angle.top.right}° : ${beforeClassification.angle.top.left}°, ${beforeClassification.angle.bottom.right}° : ${beforeClassification.angle.bottom.left}°`;

          const faceContourScanResultDescription = this.getFaceContourScanResultDescriptions();
          this.output.faceContourScanResultDescription.next(faceContourScanResultDescription);
        }
      },
    },
  };

  private getEyeScanResultsDescription = (): { [key: string]: FaceFit.FaceScanResultDescription } => {
    const descriptions: { [key: string]: FaceFit.FaceScanResultDescription } = {
      goldenRatio: {
        scanResults: [
          {
            typeName: "눈 좌우여백",
            metrics: [
              { position: "Right", value: (this.data.eyeClassification.goldenRatio?.ratio.우얼굴 ?? 0) - 0.5 },
              { position: "Left", value: (this.data.eyeClassification.goldenRatio?.ratio.좌얼굴 ?? 0) - 0.5 },
            ],
          },
          {
            typeName: "눈 사이여백",
            metrics: [{ position: null, value: (this.data.eyeClassification.goldenRatio?.ratio.중안 ?? 0) - 1 }],
          },
        ],
        description: "+수치만큼 여백을 축소조정하여 시원한 눈매로 개선",
      },
      asymmetry: {
        scanResults: [
          {
            typeName: "눈 높이차이",
            metrics: [{ position: null, value: (this.data.eyeClassification.asymmetricalEye?.ratio.좌 ?? 0) - 1 }],
          },
        ],
        description: "차이가 심한 경우 눈매교정 고려",
      },
      aspectRatio: {
        scanResults: [
          {
            typeName: "종횡비",
            metrics: [
              { position: "Right", value: (this.data.eyeClassification.eyeAspectRatio?.ratio.right.길이 ?? 0) - 3 },
              { position: "Left", value: (this.data.eyeClassification.eyeAspectRatio?.ratio.left.길이 ?? 0) - 3 },
            ],
          },
        ],
        description: `-값 만큼 길이조정하여 이상적인 비율로 개선\n+인경우 높이조정으로 이상적인 비율로 개선`,
      },
      tailAngle: {
        scanResults: [
          {
            typeName: "눈꼬리 각도",
            metrics: [
              { position: "Right", value: (this.data.eyeClassification.eyeTail?.eyeTailAngle.왼눈 ?? 0) - 4 },
              { position: "Left", value: (this.data.eyeClassification.eyeTail?.eyeTailAngle.우눈 ?? 0) - 4 },
            ],
          },
        ],
        description: "기준각도로 개선하여 순한 인상으로 조정",
      },
    };

    return descriptions;
  };

  private getNoseScanResultsDescription = () => {
    const descriptions: { [key: string]: FaceFit.FaceScanResultDescription } = {
      noseLength: {
        scanResults: [
          {
            typeName: "코길이",
            metrics: [{ position: null, value: (this.data.noseClassification.noseLengthRatio?.ratio.jawRatio ?? 0) - 1 }],
          },
        ],
        description: "구각점과 턱끝까지 길이와 1:1일때 이상적인 코길이로 차이가 많이 날경우 하관윤곽을 먼저 교정할 필요가 있음",
      },
      noseHeight: {
        scanResults: [
          {
            typeName: "코끝 높이",
            metrics: [
              {
                position: null,
                value: (this.data.noseClassification.noseHeightRatio?.ratio.noseHeight ?? 0) * 100 - 67,
                unit: "%",
              },
            ],
          },
        ],
        description: "67% 기준에서 오차범위만큼 코끝교정으로 이상적인 높이로 교정",
      },
      foreheadNoseAngle: {
        scanResults: [
          {
            typeName: "콧대 각도(비전두각)",
            metrics: [
              {
                position: null,
                value: (this.data.noseClassification.foreheadNoseAngle?.ratio.angle ?? 0) - 150,
                unit: "°",
              },
            ],
          },
        ],
        description:
          "비전두각을 기준보다 전방으로 전진하여 각도가 넓어지면 코끝높이가 감소되어 코가 길어보이고 후방으로 이동시켜 기준각도보다 좁아지면 코끝이 올라가 코길이를 짧아보이게 교정가능",
      },
      nasolabialAngle: {
        scanResults: [
          {
            typeName: "비순각",
            metrics: [
              {
                position: null,
                value: (this.data.noseClassification.nasolabialAngle?.ratio.angle ?? 0) - 97,
                unit: "°",
              },
            ],
          },
        ],
        description: "기준각도보다 낮은 경우 코끝을 올려 복코를 조정 기준보다 높은경우 코끝을 낮춰서 콧구멍이 덜 보이도록 조정",
      },
      noseTipShape: {
        scanResults: [
          {
            typeName: "코끝,비주모양",
            metrics: [
              {
                position: null,
                value: (this.data.noseClassification.noseTipShape?.ratio.right ?? 0) * 10 - 2,
              },
              {
                position: null,
                value: (this.data.noseClassification.noseTipShape?.ratio.center ?? 0) * 10 - 6,
              },
              {
                position: null,
                value: (this.data.noseClassification.noseTipShape?.ratio.left ?? 0) * 10 - 2,
              },
            ],
          },
        ],
        description:
          "비첨윤곽점을 기준만큼 보정해서 날렵한모양으로 개선 , 비익연과 비주의 윤곽이 부드러운 갈매기 모양 기준선에서 부족한 비주를 보충해서 개선",
      },
      noseBridgeWidth: {
        scanResults: [
          {
            typeName: "콧대폭 비율",
            metrics: [
              {
                position: null,
                value: (this.data.noseClassification.noseBridgeWidthRatio?.ratio.right ?? 0) * 10 - 1,
              },
              {
                position: null,
                value: (this.data.noseClassification.noseBridgeWidthRatio?.ratio.center ?? 0) * 10 - 8,
              },
              {
                position: null,
                value: (this.data.noseClassification.noseBridgeWidthRatio?.ratio.left ?? 0) * 10 - 1,
              },
            ],
          },
        ],
        description: "콧대폭의 완만한 곡선으로 개선, 콧대폭이 더 넓은경우, 콧볼기준선이 내안사이보다 크다면 콧볼축소 고려",
      },
    };

    return descriptions;
  };

  private getFaceContourScanResultDescriptions = () => {
    const descriptions: { [key: string]: FaceFit.FaceScanResultDescription } = {
      faceShapeType: {
        scanResults: [
          {
            typeName: "정면얼굴형",
            metrics: [
              { position: null, value: (this.data.faceContourClassification.faceShapeType?.ratio.bottom ?? 0) * 100 - 70, unit: "%" },
            ],
          },
        ],
        description: "양턱간의 넓이는 양쪽 과골사이널비이 70%가 적절하며 기준보다 넓은경우 사각턱 축소, 지방흡입으로 비율 개선",
      },
      zygomaticRatio: {
        scanResults: [
          {
            typeName: "정면얼굴형",
            metrics: [
              { position: null, value: (this.data.faceContourClassification.zygomaticRatio?.ratio.rightFace ?? 0) - 0.5 },
              { position: null, value: (this.data.faceContourClassification.zygomaticRatio?.ratio.rightEye ?? 0) - 1 },
              { position: null, value: (this.data.faceContourClassification.zygomaticRatio?.ratio.midFace ?? 0) - 1 },
              { position: null, value: (this.data.faceContourClassification.zygomaticRatio?.ratio.leftEye ?? 0) - 1 },
              { position: null, value: (this.data.faceContourClassification.zygomaticRatio?.ratio.leftFace ?? 0) - 0.5 },
            ],
          },
        ],
        description:
          "눈여백 라인보다 광대라인이 돌출된경우 광대축소로 옆비율은 줄이고 앞쪽으로 볼륨을 주어 얼굴이 작아보이도록 개선. 눈꼬리가 끝난는 직선라인보다 사각턱끝라인이 밖에 위치한경우 지방흡입과 사각턱축소로 개선. 교근 발달이 원인이라면 보톡스로 개선",
      },
      mandibularRatio: {
        scanResults: [
          {
            typeName: "하악비율",
            metrics: [{ position: null, value: (this.data.faceContourClassification.mandibularRatio?.ratio.face.ratio.bottom ?? 0) - 0.8 }],
          },
          {
            typeName: "인중:하관",
            metrics: [
              {
                position: null,
                value: (this.data.faceContourClassification.mandibularRatio?.ratio.jaw.ratio.top ?? 0) * 100 - 33,
                unit: "%",
              },
              {
                position: null,
                value: (this.data.faceContourClassification.mandibularRatio?.ratio.jaw.ratio.bottom ?? 0) * 100 - 67,
                unit: "%",
              },
            ],
          },
        ],
        description: `중안부 1/3과 아래 1/3의 비율은 1:0.8을 기준으로 작을수록 동안.인중과 하악비율을 체크하여 작은턱은 턱끝을 전방으로 이동하여 비율을 맞춰주고 긴턱일경우 윗턱과 함께 하악비율을 개선`,
      },
      symmetryRatio: {
        scanResults: [
          {
            typeName: "상안",
            metrics: [
              { position: null, value: (this.data.faceContourClassification.symmetryRatio?.angle.top.right ?? 0) - 90, unit: "°" },
              { position: null, value: (this.data.faceContourClassification.symmetryRatio?.angle.top.left ?? 0) - 90, unit: "°" },
            ],
          },
          {
            typeName: "하안",
            metrics: [
              { position: null, value: (this.data.faceContourClassification.symmetryRatio?.angle.bottom.right ?? 0) - 90, unit: "°" },
              { position: null, value: (this.data.faceContourClassification.symmetryRatio?.angle.bottom.left ?? 0) - 90, unit: "°" },
            ],
          },
        ],
        description: "비대칭의 원인이 골격적인지 연조직의 문제인지 구분하여 대칭성을 교정",
      },
    };
    return descriptions;
  };
}
