import type { HospitalAPI } from "@/data/remote/api/hospital/model/HospitalAPI";
import { TYPES } from "@/data/remote/api/index.container.type";
import { HospitalRepository } from "@/domain/interactor/repository/HospitalRepository";
import { inject, injectable } from "inversify";
import { map, Observable } from "rxjs";
import * as VO from "@/domain/interactor/repository/vo";
import * as Entity from "@/domain/entity";
import Utils from "./Utils";
import { UpdaetInformation } from "@/domain/interactor/repository/vo/Hospital";
@injectable()
export class HospitalRepositoryImpl implements HospitalRepository {
  constructor(@inject(TYPES.HospitalAPI) private readonly hospitalAPI: HospitalAPI) {}

  getHospital = (infoTypes: Entity.Type.HospitalInfoType[]): Observable<VO.Hospital.Information> => {
    return this.hospitalAPI.getHospital(infoTypes).pipe(
      map((res) => {
        const accounts = res.accounts?.map((account) => {
          const createdAt = Utils.convertDateStringToLocalDate(account.user.createdAt);
          return {
            usage: account.usage,
            user: {
              ...account.user,
              createdAt,
            },
          };
        });

        return {
          ...res,
          accounts: accounts ? accounts : null,
        };
      }),
    );
  };

  getOperationCategories = (): Observable<VO.Hospital.OperationCategories> => {
    return this.hospitalAPI.getOperationCategories().pipe(
      map((res) => {
        const items = res.items.map((item) => {
          return {
            id: item.id,
            operationName: item.operationName,
            subjectCode: item.subjectCode,
            ordinal: item.ordinal,
          };
        });
        return {
          items,
        };
      }),
    );
  };

  getContract = (): Observable<VO.Hospital.Contract> => {
    return this.hospitalAPI.getContract().pipe(
      map((res) => {
        return {
          ...res,
          contractedAt: Utils.convertDateStringToLocalDate(res.contractedAt),
          period: {
            startAt: Utils.convertDateStringToLocalDate(res.period.startAt),
            endAt: Utils.convertDateStringToLocalDate(res.period.endAt),
          },
        };
      }),
    );
  };

  updateHospital = (infoTypes: Entity.Type.HospitalInfoType[], hospital: UpdaetInformation): Observable<void> => {
    return this.hospitalAPI.updateHospital({
      infoTypes,
      profile: hospital.profile ?? null,
      address: hospital.address ?? null,
      embedding: hospital.embedding ?? null,
      businessRegistration: hospital.businessRegistration ?? null,
      facilityPhotos: hospital.facilityPhotos ?? null,
      contacts: hospital.contacts ?? null,
      openingHours: hospital.openingHours ?? null,
      medicalSubjects: hospital.medicalSubjects ?? null,
    });
  };

  getAccountLoginIdValidation = (type: "login_id", value: string): Observable<{ exists: boolean }> => {
    return this.hospitalAPI.getUserLoginIdValidation({ type, value });
  };
}
