import { ImageManager, ObjectURL, UploadGcsImage } from "@/domain/interactor/image-manager";
import type { ImageProcessor } from "@/image/processor";
import { inject, injectable } from "inversify";
import { Observable } from "rxjs";

@injectable()
export default class ImageManagerImpl implements ImageManager {
  constructor(
    @inject("ImageProcessor")
    private imageProcessor: ImageProcessor.ImageManager,
  ) {
    this.imageProcessor = imageProcessor;
  }
  resize(file: File, type?: "jpeg" | "png"): Observable<UploadGcsImage> {
    return this.imageProcessor.resize(file, type);
  }

  createObjectURL(url: string): Observable<ObjectURL> {
    return this.imageProcessor.createObjectURL(url);
  }

  createURLToFile(url: string): Observable<File> {
    return this.imageProcessor.createURLToFile(url);
  }

  sortOrientation(file: File): Observable<File> {
    return this.imageProcessor.sortOrientation(file);
  }

  limitSize(file: File, size: number, byteUnit?: "KB" | "MB" | "GB" | "TB"): Observable<File> {
    return this.imageProcessor.limitSize(file, size, byteUnit);
  }
}
