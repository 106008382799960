import { ContainerModule, interfaces } from "inversify";
import { AdAPIImpl } from "./ad/AdAPIImpl";
import { AdAPI } from "./ad/model/AdAPI";
import { AuthAPIImpl } from "./auth/AuthAPIImpl";
import { AuthAPI } from "./auth/model/AuthAPI";
import { DoctorAPIImpl } from "./doctor/DoctorAPIImpl";
import { DoctorAPI } from "./doctor/model/DoctorAPI";
import { HospitalAPIImpl } from "./hospital/HospitalAPIImpl";
import { HospitalAPI } from "./hospital/model/HospitalAPI";
import { TYPES } from "./index.container.type";
import { NoticeAPI } from "./notice/model/NoticeAPI";
import { NoticeAPIImpl } from "./notice/NoticeAPIImpl";
import { OperationConsultingAPI } from "./operation-consulting/model/OperationConsultingAPI";
import { OperationConsultingAPIImpl } from "./operation-consulting/OperationConsultingAPIImpl";
import { UserAPI } from "./user/model/UserAPI";
import { UserAPIImpl } from "./user/UserAPIImpl";

const APIs = new ContainerModule((bind: interfaces.Bind) => {
  bind<AdAPI>(TYPES.AdAPI).to(AdAPIImpl);
  bind<AuthAPI>(TYPES.AuthAPI).to(AuthAPIImpl);
  bind<DoctorAPI>(TYPES.DoctorAPI).to(DoctorAPIImpl);
  bind<HospitalAPI>(TYPES.HospitalAPI).to(HospitalAPIImpl);
  bind<NoticeAPI>(TYPES.NoticeAPI).to(NoticeAPIImpl);
  bind<OperationConsultingAPI>(TYPES.OperationConsultingAPI).to(OperationConsultingAPIImpl);
  bind<UserAPI>(TYPES.UserAPI).to(UserAPIImpl);
});

export default APIs;
