import { injectable, inject } from "inversify";
import { UpdateConsultingMemo, UpdateConsultingMemoInput, UpdateConsultingMemoOutput } from "./model/UpdateConsultingMemo";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { Observable } from "rxjs";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class UpdateConsultingMemoImpl implements UpdateConsultingMemo {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute(input: UpdateConsultingMemoInput): Observable<UpdateConsultingMemoOutput> {
    return this.operationConsultingRepository.updateConsultingMemo({ noteId: input.noteId, memo: input.memo });
  }
}
