import { injectable } from "inversify";
import { Observable } from "rxjs";
import { PageRequest } from "./model/Request";
import { PageResponse } from "./model/Response";
import { API } from "../../API";
import { NoticeAPI } from "./model/NoticeAPI";
import { Notice } from "./model/Response";

@injectable()
export class NoticeAPIImpl implements NoticeAPI {
  getListBy = (request: PageRequest): Observable<PageResponse<Notice>> => {
    return API.get({
      path: `/dr-wo/v1/notices`,
      credential: true,
      queryParams: {
        page: request.page,
        size: request.size,
      },
    });
  };
}
