import React from "react";
import styled from "styled-components";

const ContentsLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <Layout>{children}</Layout>;
};

export default ContentsLayout;

const Layout = styled.div`
  min-width: 100%;
  min-height: calc(100vh - 140px);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0) 1.65%), #f4f5fa;
  padding: 16px;
`;
