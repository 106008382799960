import * as Widgets from "@/application/view/widget";
import { styled as MuiTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { TableHeadColumn, TableProps } from ".";
import styled from "styled-components";

const BasicTable: React.FC<TableProps> = (props) => {
  const displayEmptyState = (headerCnt: number, placeholder: string) => {
    return (
      <TableRow key={placeholder}>
        <StyledTableCell
          colSpan={headerCnt}
          key={placeholder}
          sx={{ textAlign: "center", border: 0, paddingTop: 33, fontFamily: "SUIT" }}
        >
          {placeholder}
        </StyledTableCell>
      </TableRow>
    );
  };

  const displaySkeletonRows = (headInfo: TableHeadColumn[], size?: number) => {
    const headers: React.ReactNode[] = [];
    const list: React.ReactNode[] = [];
    const rowSize = size ? size : 20;

    headInfo.forEach((h, i) =>
      headers.push(
        <StyledTableCell
          colSpan={props.colSpan}
          key={i}
        >
          <Widgets.Layout.Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: h.align ? h.align : "left",
            }}
          >
            <Widgets.Skeleton
              width={"30%"}
              height={14}
            />
          </Widgets.Layout.Box>
        </StyledTableCell>,
      ),
    );

    for (let i = 0; i < rowSize; i++) {
      list.push(<TableRow key={i}>{headers}</TableRow>);
    }
    return list;
  };

  return (
    <TableContainer sx={{ overflowX: "unset", fontFamily: "SUIT" }}>
      {props.noTotalCountLabel ? null : (
        <TotalInfoLayout>
          <Widgets.Typo.C1
            value={"전체"}
            bold
            color="#ABABAB"
          />
          <Widgets.Typo.C1
            value={props.total ?? 0}
            bold
            color="#36766D"
          />
        </TotalInfoLayout>
      )}

      <Table>
        <TableHead>
          <TableRow>
            {props.head.map((headInfo, idx) => (
              <StyledTableCell
                key={idx}
                sx={{
                  width: headInfo.width ? headInfo.width : "initial",
                  height: "36px",
                  border: 0,
                  fontSize: 14,
                  lineHeight: 1.3,
                  fontWeight: 500,
                  wordBreak: "break-all",
                  whiteSpace: "pre-wrap",
                  padding: 0,
                  borderBottom: "1px solid #eceef2",
                  color: "#636363",
                  borderTopLeftRadius: idx === 0 ? "8px" : 0,
                  borderTopRightRadius: props.head.length - 1 === idx ? "8px" : 0,
                  fontFamily: "SUIT",
                  ...headInfo.style,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  headInfo.onClick && headInfo.onClick();
                }}
              >
                <Widgets.Layout.Box
                  sx={{
                    width: "100%",
                    padding: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: headInfo.align ? headInfo.align : "left",
                    textAlign: headInfo.align ? headInfo.align : "left",
                  }}
                >
                  {headInfo.value}
                </Widgets.Layout.Box>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.isLoading
            ? displaySkeletonRows(props.head, props.size)
            : props.rows && props.rows.length === 0 && props.emptyStatePlaceholder
            ? displayEmptyState(props.head.length, props.emptyStatePlaceholder)
            : props.rows &&
              props.rows.map((row, idx) => (
                <StyledTableRow
                  id={`body-${idx}`}
                  key={`${row.id}`}
                  onClick={() => {
                    //텍스트 드래그 중 클릭방지
                    const selection = window.getSelection();
                    const selectedText = selection?.toString();
                    if (!Boolean(selectedText) && row.onClick) {
                      row.onClick();
                    }
                  }}
                  sx={{
                    backgroundColor: row.isTaskDone === true ? "#edf6ed" : (theme) => theme.palette.success.contrastText,
                    cursor: row.onClick ? "pointer" : "default",
                    fontFamily: "SUIT",
                  }}
                  istaskdone={row.isTaskDone ? "true" : "false"}
                  isactivenone={row.isactivenone ? "true" : "false"}
                  $isDisabledClickRow={row.isDisabledClickRow}
                >
                  {row.contents.map((content, idx) => (
                    <StyledTableCell
                      key={idx}
                      sx={{
                        width: content?.width + "px" ?? "auto",
                        maxWidth: content?.width + "px" ?? "auto",
                        height: row.height ? row.height : "52px",
                        padding: "8px",
                        whiteSpace: "pre-wrap",
                        fontFamily: "SUIT",
                      }}
                      align={content.align ? content.align : "left"}
                      onClick={
                        content.onClick
                          ? (e) => {
                              e.stopPropagation();
                              content.onClick && content.onClick();
                            }
                          : undefined
                      }
                    >
                      {content.value}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasicTable;

const StyledTableCell = MuiTheme(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // position: "sticky",
    zIndex: "3",
    top: "0",
    backgroundColor: "#E9EDF1",
    borderRadius: "none",
  },
  [`&.${tableCellClasses.body}`]: {},
}));

interface StyleProps {
  istaskdone?: string;
  isactivenone?: string;
  $isDisabledClickRow?: boolean;
}

const StyledTableRow = MuiTheme(TableRow)<StyleProps>(({ theme, istaskdone, isactivenone, $isDisabledClickRow }) =>
  $isDisabledClickRow
    ? ""
    : {
        "&: hover": {
          backgroundColor: istaskdone === "true" ? "#DDECE8" : "#F3F5F9",
        },
        "&: active": {
          backgroundColor:
            istaskdone === "true" ? "#E0E8E1" : isactivenone === "true" ? theme.palette.background : theme.palette.action.selected,
        },
      },
);

const TotalInfoLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 2px;
`;
