import React from "react";
import styled from "styled-components";
import * as ViewData from "@/application/view-data";
import { DropDown } from "@view/widget";
import * as Entity from "@/domain/entity";
interface StatusBadgeProps {
  status: Entity.Type.ConsultStatusForFaceFit | null;
  click: (status: Entity.Type.ConsultStatusForFaceFit) => void;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ status, click }) => {
  return (
    <DropDown.BasicMenu
      button={<Badge status={status} />}
      items={[
        {
          component: <Badge status={"PENDING"} />,
          click: () => {
            click("PENDING");
          },
        },
        {
          component: <Badge status={"IN_PROGRESS"} />,
          click: () => {
            click("IN_PROGRESS");
          },
        },
        {
          component: <Badge status={"CANCELLED"} />,
          click: () => {
            click("CANCELLED");
          },
        },
        {
          component: <Badge status={"ETC"} />,
          click: () => {
            click("ETC");
          },
        },
        {
          component: <Badge status={"COMPLETED"} />,
          click: () => {
            click("COMPLETED");
          },
        },
      ]}
    />
  );
};

export default StatusBadge;

const Badge: React.FC<Pick<StatusBadgeProps, "status">> = ({ status }) => {
  switch (status) {
    case "PENDING":
      return <BadgeLabel $status="WAITING">상담대기</BadgeLabel>;

    case "IN_PROGRESS":
      return <BadgeLabel $status="PROGRESS">상담중</BadgeLabel>;

    case "CANCELLED":
      return <BadgeLabel $status="CANCEL">상담취소</BadgeLabel>;

    case "ETC":
      return <BadgeLabel $status="ETC">기타</BadgeLabel>;

    case "COMPLETED":
      return <BadgeLabel $status={"COMPLETED"}>상담완료</BadgeLabel>;

    default:
      return <BadgeLabel $status="WAITING">상담대기</BadgeLabel>;
  }
};

const BadgeLabel = styled.div<{ $status: ViewData.Consulting.Status }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 32px;
  margin-bottom: 6px;
  margin-inline: 6px;
  border-radius: 5px;
  background: ${({ $status }) => {
    switch ($status) {
      case "WAITING":
        return "#A5FFFF";

      case "PROGRESS":
        return "#FFE600";

      case "CANCEL":
        return "#FF9900";

      case "COMPLETED":
        return "#29D8FE";

      case "ETC":
        return "#FF9900";
    }
  }};
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  cursor: pointer;

  &:nth-child(1) {
    margin-top: 6px;
  }
`;
