import { TYPES } from "@/data/interactor/repository/index.container.type";
import type { DoctorRepository } from "@/domain/interactor/repository/DoctorRepository";
import { inject, injectable } from "inversify";
import { Observable } from "rxjs";
import { GetDoctors, GetDoctorsOutput } from "./model/GetDoctors";

@injectable()
export class GetDoctorsImpl implements GetDoctors {
  constructor(@inject(TYPES.DoctorRepository) private readonly doctorRepository: DoctorRepository) {}

  execute(): Observable<GetDoctorsOutput> {
    return this.doctorRepository.getDoctors();
  }
}
