import React from "react";
import { Alert } from "@/application/view/widget";

interface AnonymousErrorProps {
  open: boolean;
  message: string;
  confirm?: () => void;
  close: () => void;
}

const ErrorAlert: React.FC<AnonymousErrorProps> = ({ message, confirm, close }) => {
  return (
    <Alert.Basic
      message={message}
      close={close}
      confirm={confirm}
    />
  );
};

export default ErrorAlert;
