import React from "react";
import styled from "styled-components";

interface UploaderProps extends React.PropsWithChildren {
  id: string;
  disabled?: boolean;
  clickUploadImage: (file: File) => void;
}

const Uploader: React.FC<UploaderProps> = ({ id, children, disabled, clickUploadImage }) => {
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      clickUploadImage(e.target.files[0]);
    }
    e.target.value = "";
  };

  return (
    <UploaderLayout>
      <InputLabel
        htmlFor={id}
        $disabled={disabled}
      >
        {children}
        <FileInput
          id={id}
          type="file"
          accept="image/jpg,image/jpeg,image/gif,image/png,image/webp"
          disabled={disabled}
          onChange={handleFileChange}
        />
      </InputLabel>
    </UploaderLayout>
  );
};

export default Uploader;

const UploaderLayout = styled.div`
  width: 100%;
`;

const InputLabel = styled.label<{ $disabled?: boolean }>`
  display: flex;
  justify-content: center;
  cursor: ${({ $disabled }) => ($disabled ? "cursor" : "pointer")};
  padding: 0;
  width: fit-content;
`;

const FileInput = styled.input`
  display: none;
`;
