import { SessionRepository } from "@/domain/interactor/repository/SessionRepository";
import { injectable } from "inversify";
import { Observable, of } from "rxjs";
import * as VO from "@/domain/interactor/repository/vo";

export class SessionStorage {
  constructor() {}

  static setItem = (key: string, item: string) => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem(key, item);
    }
  };

  static getItem = (key: string) => {
    if (typeof window !== "undefined") {
      return sessionStorage.getItem(key);
    }
    return null;
  };

  static removeItem = (key: string) => {
    if (typeof window !== "undefined") {
      sessionStorage.removeItem(key);
    }
  };

  static clearAll = () => {
    if (typeof window !== "undefined") {
      sessionStorage.clear();
    }
  };
}

@injectable()
export class SessionRepositoryImpl implements SessionRepository {
  getSessionToken = (): Observable<string | null> => {
    const sessionToken = SessionStorage.getItem("sessionToken");
    return of(sessionToken);
  };

  getUser = (): Observable<VO.Session.UserInformation | null> => {
    const userJson = SessionStorage.getItem("userV2");
    if (userJson) {
      try {
        const user = JSON.parse(userJson);
        return of(user);
      } catch (e) {
        return of(null);
      }
    }

    return of(null);
  };

  saveSessionToken = (sessionToken: string): void => {
    return SessionStorage.setItem("sessionToken", sessionToken);
  };

  saveUser = (userInformation: VO.Session.UserInformation): void => {
    // if (userInformation.user.role === "DOCTOR" && userInformation.user.id) {
    //   SessionStorage.setItem("sessionUserInfo", JSON.stringify({ id: userInformation.user.id, accountType: "D" }));
    // }
    // if (userInformation.user.role === "ADMIN") {
    //   SessionStorage.setItem("sessionUserInfo", JSON.stringify({ id: userInformation.user.id, accountType: "C" }));
    // }
    return SessionStorage.setItem("userV2", JSON.stringify(userInformation));
  };

  removeItem = (key: string): void => {
    return SessionStorage.removeItem(key);
  };

  removeAll = (): void => {
    return SessionStorage.clearAll();
  };
}
