import { Observable, of } from "rxjs";
import { Logout } from "./model/Logout";
import { inject, injectable } from "inversify";
import type { SessionRepository } from "@/domain/interactor/repository/SessionRepository";
import type { LocalRepository } from "@/domain/interactor/repository/LocalRepository";
import { TYPES } from "@/data/interactor/repository/index.container.type";
@injectable()
export class LogoutImpl implements Logout {
  constructor(
    @inject(TYPES.SessionRepository) private readonly sessionRepository: SessionRepository,
    @inject(TYPES.LocalRepository) private readonly localRepository: LocalRepository,
  ) {}

  execute = (): Observable<void> => {
    this.sessionRepository.removeAll();
    this.localRepository.removeAll();
    return of();
  };
}
