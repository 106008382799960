import { CustomersViewModel } from "@view-model/model/CustomersViewModel";
import { BehaviorSubject, forkJoin, mergeMap, of, Subject } from "rxjs";
import { injectable } from "inversify";
import type { UISystemManager } from "@/application/ui-system/view-model/model";
import { CustomerNote } from "@view-data/Consulting";
import { Consulting, Pagination } from "@view-data/index";
import type { GetConsultingNotes } from "@/domain/usecase/consulting/model/GetConsultingNotes";
import * as Entity from "@/domain/entity";
import type { GetConsultantsByFaceFit } from "@/domain/usecase/consulting/model/GetConsultantsByFaceFit";
import type { UpdateConsultingStatus } from "@/domain/usecase/consulting/model/UpdateConsultingStatus";
import type { UpdateReservationStatus } from "@/domain/usecase/consulting/model/UpdateReservationStatus";
import type { UpdateConsultingMemo } from "@/domain/usecase/consulting/model/UpdateConsultingMemo";
import type { UpdateConsultingReservationDate } from "@/domain/usecase/consulting/model/UpdateConsultingReservationDate";

@injectable()
export class CustomersViewModelImpl implements CustomersViewModel {
  data: CustomersViewModel["data"] = {
    faceFitNotes: null,
    page: 1,
    size: 20,
    keyword: "",
    consultingStatus: null,
    reservationStatus: null,
    consultantId: undefined,
  };
  output = {
    consultingNotes: new Subject<Pagination<CustomerNote>>(),
    faceFitNotes: new BehaviorSubject<Consulting.CustomerNote[] | null>(null),
    consultants: new Subject<Consulting.Consultant[]>(),
    consultStatus: new Subject<Consulting.ConsultStatus[]>(),
    status: new Subject<Consulting.ConsultStatus | null>(),
    consultantId: new Subject<number | undefined>(),
    consultantName: new Subject<string | null>(),
    keyword: new Subject<string>(),
    memoPopup: new Subject<{ isOpen: boolean; noteId: number | null; memo: string | null }>(),
    reservationDatePopup: new Subject<{ isOpen: boolean; noteId: number | null; reservationDate: Date | null }>(),
  };

  route: CustomersViewModel["route"] = {
    toAssistant: new Subject<{ id: number | "new"; isMobile: boolean }>(),
    toQueryParams: new Subject<{ page: number; size: number; keyword?: string }>(),
    toConsultationNoteDetails: new Subject<{ noteId: number; page: number; size: number; keyword?: string }>(),
  };
  event: {
    onGetNotes: (page: number, size: number, keyword?: string) => void;
  };

  constructor(
    page: number,
    size: number,
    keyword: string | undefined,
    readonly uiSystem: UISystemManager,
    private readonly getConsultingNotes: GetConsultingNotes,
    private readonly ucGetConsultants: GetConsultantsByFaceFit,
    private readonly updateConsultingStatus: UpdateConsultingStatus,
    private readonly updateReservationStatus: UpdateReservationStatus,
    private readonly updateConsultingMemo: UpdateConsultingMemo,
    private readonly ucUpdateConsultingReservationDate: UpdateConsultingReservationDate,
  ) {
    this.uiSystem = uiSystem;
    this.init(page, size, keyword);
    this.event = {
      onGetNotes: (page, size, keyword) => {
        this.data.page = page;
        this.data.size = size;
        this.data.keyword = keyword ?? "";
        this.getConsultingNotesByFaceFit();
      },
    };
  }

  input: CustomersViewModel["input"] = {
    changeKeyword: (keyword: string) => {
      this.data.keyword = keyword;
      this.output.keyword.next(this.data.keyword);
    },
    changeStatus: (status: Consulting.ConsultStatus) => {
      this.data.consultingStatus = null;
      this.data.reservationStatus = null;

      if (status.type === "consulting") {
        this.data.consultingStatus = status.statusCode as Entity.Type.ConsultStatusForFaceFit;
      } else {
        this.data.reservationStatus = status.statusCode as Entity.Type.OperationStatusForFaceFit;
      }
      this.output.status.next(status);
    },
    changeConsultant: (consultantId: number | undefined, consultantName: string) => {
      this.data.consultantId = consultantId;
      this.output.consultantName.next(consultantName);
    },
    clickResetFilter: () => {
      this.data.keyword = "";
      this.data.consultingStatus = null;
      this.data.reservationStatus = null;
      this.data.consultantId = undefined;

      this.output.keyword.next(this.data.keyword);
      this.output.status.next(null);
      this.output.consultantId.next(this.data.consultantId);
      this.output.consultantName.next(null);

      this.getConsultingNotesByFaceFit();
    },
    clickPagination: (page: number) => {
      this.data.page = page;
      this.getConsultingNotesByFaceFit();
    },
    clickSearch: () => {
      this.data.page = 1;
      this.getConsultingNotesByFaceFit();
    },
    clickOpenMemo: (isOpen, noteId, memo) => {
      this.output.memoPopup.next({ isOpen, noteId, memo });
    },
    clickPage: (page, size, keyword) => {
      this.init(page, size, keyword);
      this.route.toQueryParams.next({ page, size, keyword });
    },
    clickConsultingStatus: (noteId, consultingStatus) => {
      const sub = this.updateConsultingStatus.execute({ noteId, consultingStatus }).subscribe({
        next: ({ operationConsultingNoteId }) => {
          if (this.data.faceFitNotes) {
            this.data.faceFitNotes = this.data.faceFitNotes.map((note) => {
              if (note.id === operationConsultingNoteId) {
                note.consultingStatus = consultingStatus;
                return note;
              } else {
                return note;
              }
            });
            this.output.faceFitNotes.next(this.data.faceFitNotes);
          }

          sub.unsubscribe();
        },
        error: () => {
          sub.unsubscribe();
        },
      });
    },
    clickReservationStatus: (noteId, reservationStatus) => {
      const sub = this.updateReservationStatus.execute({ noteId, reservationStatus }).subscribe({
        next: ({ operationConsultingNoteId }) => {
          this.init(this.data.page, this.data.size, this.data.keyword);
          if (reservationStatus === "RESERVED") {
            this.output.reservationDatePopup.next({ isOpen: true, noteId: operationConsultingNoteId, reservationDate: null });
          }
          sub.unsubscribe();
        },
        error: () => {
          sub.unsubscribe();
        },
      });
    },
    clickUpdateMemo: (noteId, memo) => {
      this.uiSystem.loadingHandler.backdropLoading.next(true);
      const sub = this.updateConsultingMemo.execute({ noteId, memo }).subscribe({
        next: () => {
          this.init(this.data.page, this.data.size, this.data.keyword);
          this.output.memoPopup.next({ isOpen: false, noteId: null, memo: null });
          this.uiSystem.loadingHandler.backdropLoading.next(false);
          sub.unsubscribe();
        },
        error: () => {
          this.uiSystem.loadingHandler.backdropLoading.next(false);
          sub.unsubscribe();
        },
      });
    },
    clickOpenReservationDate: (isOpen: boolean, noteId: number | null, reservationDate: Date | null) => {
      this.output.reservationDatePopup.next({ isOpen, noteId, reservationDate });
    },
    clickUpdateReservationDate: (noteId: number, date: string) => {
      this.uiSystem.loadingHandler.backdropLoading.next(true);
      this.ucUpdateConsultingReservationDate.execute({ noteId, reservationDate: new Date(date).toISOString() }).subscribe({
        next: () => {
          this.init(this.data.page, this.data.size, this.data.keyword);
          this.output.reservationDatePopup.next({ isOpen: false, noteId: null, reservationDate: null });
          this.uiSystem.loadingHandler.backdropLoading.next(false);
        },
        error: () => {
          this.uiSystem.loadingHandler.backdropLoading.next(false);
        },
      });
    },
  };

  private init(page: number, size: number, keyword?: string) {
    this.data.page = page;
    this.data.size = size;
    this.data.keyword = keyword ? keyword : "";
    this.getConsultingNotesByFaceFit();
  }
  private getConsultingNotesByFaceFit() {
    const sub = this.getConsultingNotes
      .execute({
        page: this.data.page,
        size: this.data.size,
        consultingStatus: this.data.consultingStatus,
        reservationStatus: this.data.reservationStatus,
        keyword: this.data.keyword ?? null,
        consultant: this.data.consultantId,
      })
      .pipe(
        mergeMap((paginatedNotes) => {
          return forkJoin([of(paginatedNotes), this.ucGetConsultants.execute()]);
        }),
      )
      .subscribe({
        next: ([paginatedNotes, consultants]) => {
          this.output.consultingNotes.next({
            page: paginatedNotes.page,
            size: paginatedNotes.size,
            isLast: paginatedNotes.last,
            total: paginatedNotes.total,
            items: paginatedNotes.items,
          });

          this.data.faceFitNotes = paginatedNotes.items;
          this.output.faceFitNotes.next(paginatedNotes.items);
          this.output.consultants.next(consultants);
          this.route.toQueryParams.next({ page: this.data.page, size: this.data.size, keyword: this.data.keyword });
          sub.unsubscribe();
        },
        error: (error) => {
          if (error?.error_message === "user status is not activated") {
            this.uiSystem.errorHandler.alert.next({ message: "비활성화된 계정입니다.\n관리자에게 문의해주세요." });
          }
          sub.unsubscribe();
        },
      });
  }
}
