import type { AdRepository } from "@/domain/interactor/repository/AdRepository";
import { inject, injectable } from "inversify";
import { map, Observable } from "rxjs";
import {
  UpdateReservationStatusByAdvertise,
  UpdateReservationStatusByAdvertiseInput,
  UpdateReservationStatusByAdvertiseOutput,
} from "./model/UpdateReservationStatusByAdvertise";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class UpdateReservationStatusByAdvertiseImpl implements UpdateReservationStatusByAdvertise {
  constructor(@inject(TYPES.AdRepository) private readonly adRepository: AdRepository) {}

  execute = (input: UpdateReservationStatusByAdvertiseInput): Observable<UpdateReservationStatusByAdvertiseOutput> => {
    return this.adRepository.updateReservationStatus(input.noteId, input.status).pipe(
      map((res) => {
        return {
          advertiseConsultingNoteId: res.advertisementDBId,
        };
      }),
    );
  };
}
