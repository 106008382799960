import { DrawingFacialLandmarks } from "../face/DrawingFacialLandmarks";
import Calc from "../utils/Calc";
import { Config, ConnectionPoint, DenormalizedCoordinate, CanvasLineStyle, Coordinate, LandmarkPoint } from "../index.interface";
import { BestRatioLineStyle, DotLineStyle, PhotoBaseLine } from "../utils/Styles";

interface Coordinates {
  noseWidth: {
    right: {
      right: {
        top: Coordinate;
        bottom: Coordinate;
      };
      left: {
        top: Coordinate;
        bottom: Coordinate;
      };
    };
    left: {
      right: {
        top: Coordinate;
        bottom: Coordinate;
      };
      left: {
        top: Coordinate;
        bottom: Coordinate;
      };
    };
  };
  noseBridge: {
    right: Coordinate[];
    left: Coordinate[];
  };
}

export class NoseBridgeWidthRatio extends DrawingFacialLandmarks {
  private frontalFaceCanvasDrawer: DrawingFacialLandmarks;
  private coordinate: Coordinates;
  ratio: {
    right: number;
    center: number;
    left: number;
  };

  constructor({ config, frontalFaceCanvas }: { config: Config; frontalFaceCanvas: HTMLCanvasElement }) {
    super(config);
    this.frontalFaceCanvasDrawer = new DrawingFacialLandmarks({
      imageEle: config.imageEle,
      canvasEle: frontalFaceCanvas,
      normalizedCoordinate: config.normalizedCoordinate,
    });
    this.coordinate = this.getCoordinates();
    this.ratio = this.calcRatio();
  }

  drawPhotoBase = async () => {
    this.drawPhotoBaseDrawer.drawNoseLines();
    await this.delay(300);
    this.drawPhotoBaseDrawer.drawRatioText();
  };

  drawBestRatio = async () => {
    this.bestRatioDrawer.drawNoseLines();
    await this.delay(300);
    this.bestRatioDrawer.drawRatioText();
  };

  private drawPhotoBaseDrawer = {
    drawNoseLines: async () => {
      this.frontalFaceCanvasDrawer.drawLineByCoordinates(this.coordinate.noseBridge.right, { ...DotLineStyle, lineColor: "#F900FE" }, true);
      this.frontalFaceCanvasDrawer.drawLineByCoordinates(this.coordinate.noseBridge.left, { ...DotLineStyle, lineColor: "#F900FE" }, true);
      await this.delay(300);
      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.coordinate.noseWidth.right.right.bottom,
        point2: this.coordinate.noseWidth.right.right.top,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.coordinate.noseWidth.right.left.bottom,
        point2: this.coordinate.noseWidth.right.left.top,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.coordinate.noseWidth.left.right.bottom,
        point2: this.coordinate.noseWidth.left.right.top,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.coordinate.noseWidth.left.left.bottom,
        point2: this.coordinate.noseWidth.left.left.top,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
      this.delay(300);
      this.drawLineBetweenPoints({
        point1: { ...this.sideFaceCoordinate[129], y: this.sideFaceCoordinate[2].y },
        point2: { ...this.sideFaceCoordinate[129], y: this.sideFaceCoordinate[4].y },
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
      this.delay(300);
      const keyPoints = [55, 193, 122, 196, 3, 51, 220];
      const sideLineCoordinates: Coordinate[] = [];
      keyPoints.forEach((keyPoint) => {
        sideLineCoordinates.push(this.sideFaceCoordinate[keyPoint]);
      });
      this.drawLineByCoordinates(sideLineCoordinates, { ...DotLineStyle, lineColor: "#F900FE" }, true);
    },
    drawRatioText: async () => {
      const textStyle = { x: 0, y: -10, fontSize: 18, color: "#FF9900" };

      this.frontalFaceCanvasDrawer.drawTextBetweenPoints(
        `${(this.ratio.right * 10).toFixed(2)}`,
        this.faceCoordinates[9],
        this.faceCoordinates[9],
        { ...textStyle, x: -60 },
      );
      this.frontalFaceCanvasDrawer.drawTextBetweenPoints(
        `${(this.ratio.center * 10).toFixed(2)}`,
        this.faceCoordinates[9],
        this.faceCoordinates[9],
        textStyle,
      );
      this.frontalFaceCanvasDrawer.drawTextBetweenPoints(
        `${(this.ratio.left * 10).toFixed(2)}`,
        this.faceCoordinates[9],
        this.faceCoordinates[9],
        { ...textStyle, x: 60 },
      );
    },
  };

  bestRatioDrawer = {
    drawNoseLines: async () => {
      this.frontalFaceCanvasDrawer.drawLineByCoordinates(this.coordinate.noseBridge.right, { ...DotLineStyle, lineColor: "#00D7CA" }, true);
      this.frontalFaceCanvasDrawer.drawLineByCoordinates(this.coordinate.noseBridge.left, { ...DotLineStyle, lineColor: "#00D7CA" }, true);
      await this.delay(300);
      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.coordinate.noseWidth.right.right.bottom,
        point2: this.coordinate.noseWidth.right.right.top,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });
      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.coordinate.noseWidth.right.left.bottom,
        point2: this.coordinate.noseWidth.right.left.top,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });
      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.coordinate.noseWidth.left.right.bottom,
        point2: this.coordinate.noseWidth.left.right.top,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });
      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.coordinate.noseWidth.left.left.bottom,
        point2: this.coordinate.noseWidth.left.left.top,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });
      this.delay(300);
      this.drawLineBetweenPoints({
        point1: { ...this.sideFaceCoordinate[129], y: this.sideFaceCoordinate[2].y },
        point2: { ...this.sideFaceCoordinate[129], y: this.sideFaceCoordinate[4].y },
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });
      this.delay(300);
      const keyPoints = [55, 193, 122, 196, 3, 51, 220];
      const sideLineCoordinates: Coordinate[] = [];
      keyPoints.forEach((keyPoint) => {
        sideLineCoordinates.push(this.sideFaceCoordinate[keyPoint]);
      });
      this.drawLineByCoordinates(sideLineCoordinates, { ...DotLineStyle, lineColor: "#00D7CA" }, true);
    },
    drawRatioText: async () => {
      const textStyle = { x: 0, y: -37, fontSize: 18, color: "#00D7CA" };

      this.frontalFaceCanvasDrawer.drawTextBetweenPoints(`${(1.0).toFixed(2)}`, this.faceCoordinates[9], this.faceCoordinates[9], {
        ...textStyle,
        x: -60,
      });
      this.frontalFaceCanvasDrawer.drawTextBetweenPoints(
        `${(8.0).toFixed(2)}`,
        this.faceCoordinates[9],
        this.faceCoordinates[9],
        textStyle,
      );
      this.frontalFaceCanvasDrawer.drawTextBetweenPoints(`${(1.0).toFixed(2)}`, this.faceCoordinates[9], this.faceCoordinates[9], {
        ...textStyle,
        x: 60,
      });
    },
  };

  private getCoordinates = (): Coordinates => {
    const bottomYPoint = this.faceCoordinates[2].y;
    const middleYPoint = this.faceCoordinates[4].y;
    const topYPoint = this.faceCoordinates[55].y;

    return {
      noseWidth: {
        right: {
          right: {
            top: { ...this.faceCoordinates[129], y: middleYPoint },
            bottom: { ...this.faceCoordinates[129], y: bottomYPoint },
          },
          left: {
            top: { ...this.faceCoordinates[55], y: topYPoint },
            bottom: { ...this.faceCoordinates[55], y: bottomYPoint },
          },
        },
        left: {
          right: {
            top: { ...this.faceCoordinates[285], y: topYPoint },
            bottom: { ...this.faceCoordinates[285], y: bottomYPoint },
          },
          left: {
            top: { ...this.faceCoordinates[358], y: middleYPoint },
            bottom: { ...this.faceCoordinates[358], y: bottomYPoint },
          },
        },
      },
      noseBridge: {
        right: [
          this.faceCoordinates[55],
          this.faceCoordinates[193],
          this.faceCoordinates[122],
          this.faceCoordinates[196],
          this.faceCoordinates[3],
          this.faceCoordinates[51],
          this.faceCoordinates[220],
        ],
        left: [
          this.faceCoordinates[285],
          this.faceCoordinates[417],
          this.faceCoordinates[351],
          this.faceCoordinates[419],
          this.faceCoordinates[248],
          this.faceCoordinates[281],
          this.faceCoordinates[440],
        ],
      },
    };
  };

  private calcRatio = () => {
    const rightLength = Calc.calculateDistance(
      this.coordinate.noseWidth.right.right.bottom,
      this.coordinate.noseWidth.right.left.bottom,
      "2d",
    );

    const middleLength = Calc.calculateDistance(
      this.coordinate.noseWidth.right.left.bottom,
      this.coordinate.noseWidth.left.right.bottom,
      "2d",
    );

    const leftLength = Calc.calculateDistance(
      this.coordinate.noseWidth.left.right.bottom,
      this.coordinate.noseWidth.left.left.bottom,
      "2d",
    );

    const totalLength = rightLength + middleLength + leftLength;

    return {
      right: Calc.calcRatio(totalLength, rightLength),
      center: Calc.calcRatio(totalLength, middleLength),
      left: Calc.calcRatio(totalLength, leftLength),
    };
  };

  private delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
}
