import { injectable, inject } from "inversify";
import { UploadConsultingPhoto, UploadConsultingPhotoInput, UploadConsultingPhotoOutput } from "./model/UploadConsultingPhoto";
import { Observable, map, mergeMap, zip } from "rxjs";
import type { ImageManager } from "@/domain/interactor/image-manager";
import type { Storage } from "@/domain/interactor/storage";
import { v4 } from "uuid";

@injectable()
export class UploadConsultingPhotoImpl implements UploadConsultingPhoto {
  constructor(
    @inject("ImageManager")
    private imageManager: ImageManager,
    @inject("Storage")
    private storage: Storage,
  ) {}

  execute(input: UploadConsultingPhotoInput): Observable<UploadConsultingPhotoOutput> {
    const isPrd = process.env.ENVIRONMENT === "prd";
    const timestamp = Date.now();
    const id = v4();

    return this.imageManager.resize(input.file, "jpeg").pipe(
      mergeMap((res) => {
        const body = {
          bucket: isPrd ? "caremind-v1/dr-wo/studio" : "caremind-v1-test/dr-wo/studio",
          filename: `origin_${timestamp}_${id}.png`,
          file: this.strToBuffer(res.origin),
        };
        const bodyResize = {
          bucket: isPrd ? "caremind-v1/dr-wo/studio" : "caremind-v1-test/dr-wo/studio",
          filename: `resized_${timestamp}_${id}.png`,
          file: this.strToBuffer(res.resized),
        };

        return zip(this.storage.uploadBy(body), this.storage.uploadBy(bodyResize));
      }),
      map(([origin, resize]) => {
        const output = {
          originUrl: origin.url,
          resizedUrl: resize.url,
          ordinal: input.ordinal,
        };

        return output;
      }),
    );
  }

  private strToBuffer = (str: string) => {
    const buf = Buffer.from(str, "utf-8");
    return buf;
  };
}
