import useDeviceDetect from "@view/hooks/useDeviceDetect";
import { useRouter } from "next/router";
import styled from "styled-components";
import { LayoutProps } from ".";
import colors from "@view/styles/colors";

interface PageLayoutProps extends LayoutProps {
  displayType?: string;
  hasPadding?: boolean;
  backgroundColor?: string;
}

const PageLayoutStyled = styled.div<Omit<PageLayoutProps, "children">>`
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  height: calc(100vh - 140px);
  width: 100%;
  justify-content: space-between;
  ${(props) =>
    props.hasPadding ? (props.displayType !== "desktop" ? `padding: 32px 16px;` : `padding: 40px 40px 0; min-width: 500px;`) : ""}
  background: ${(props) => props.backgroundColor};
`;

const PageContentLayout: React.FC<PageLayoutProps> = ({ displayType = "desktop", children }) => {
  const deviceType = useDeviceDetect(true);
  const router = useRouter();

  let backgroundColor = colors.neutralWhiteDefault;
  let hasPadding = true;

  if (router.pathname === "/login") return <>{children}</>;

  if (router.pathname.includes("partner") || router.pathname === "/digital/feedback" || router.pathname.includes("/patient/feedback/")) {
    backgroundColor = colors.neutralBgDefault;
    hasPadding = false;
  }

  if (router.pathname === "/home" || router.pathname.includes("/patient") || router.pathname.includes("/event")) hasPadding = false;

  if (router.pathname === "/partner/doctors" || router.pathname === "/digital/commenter") backgroundColor = colors.neutralWhiteDefault;

  return (
    <PageLayoutStyled
      displayType={deviceType ?? ""}
      hasPadding={hasPadding}
      backgroundColor={backgroundColor}
    >
      {children}
    </PageLayoutStyled>
  );
};

export default PageContentLayout;
