import { API } from "@/data/remote/API";
import { AdAPI } from "@/data/remote/api/ad/model/AdAPI";
import * as Entity from "@/domain/entity";
import { injectable } from "inversify";
import { Observable } from "rxjs";
import { GetAdvertisementDBsResponse } from "./model/Response";

@injectable()
export class AdAPIImpl implements AdAPI {
  getAdvertisementDBs = (page?: number, size?: number, keyword?: string): Observable<GetAdvertisementDBsResponse> => {
    return API.get({
      path: "/dr-wo/v1/advertisement-dbs",
      credential: true,
      queryParams: {
        page,
        size,
        keyword,
      },
    });
  };

  updateConsultingStatus(
    adId: number,
    consultingStatus: Entity.Type.ApplicationStatusForAdvertisement,
  ): Observable<{ advertisementDBId: number }> {
    return API.patch<{ advertisementDBId: number }>({
      credential: true,
      path: `/dr-wo/v1/advertisement-db/${adId}/consulting-status`,
      data: {
        consultingStatus: consultingStatus,
      },
    });
  }

  updateReservationStatus(
    adId: number,
    reservationStatus: Entity.Type.ReservationStatusForAdvertisement,
  ): Observable<{ advertisementDBId: number }> {
    return API.patch<{ advertisementDBId: number }>({
      credential: true,
      path: `/dr-wo/v1/advertisement-db/${adId}/reservation-status`,
      data: {
        reservationStatus: reservationStatus,
      },
    });
  }

  updateReservationDate(adId: number, date: string): Observable<{ advertisementDBId: number }> {
    return API.patch<{ advertisementDBId: number }>({
      credential: true,
      path: `/dr-wo/v1/advertisement-db/${adId}/reservation-date`,
      data: {
        reservationDate: date,
      },
    });
  }

  updateReservationMemo(adId: number, memo: string): Observable<{ advertisementDBId: number }>{
    return API.patch<{ advertisementDBId: number }>({
      credential: true,
      path: `/dr-wo/v1/advertisement-db/${adId}/memo`,
      data: {
        memo: memo,
      },
    });

  }

}
