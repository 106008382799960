import moment from "moment";
import styled from "styled-components";
import DatePicker, { ReactDatePickerCustomHeaderProps, registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
import "react-datepicker/dist/react-datepicker.css";
import Icon from "@icon/index";
import colors from "@view/styles/colors";
import Typography, { typographyConfig } from "../typography";
import { DatePickerProps } from ".";

registerLocale("ko", ko);

const TimePickerCustomHeader: React.FC<ReactDatePickerCustomHeaderProps> = (props) => {
  const { date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled } = props;

  return (
    <CustomHeaderWrapper>
      <button
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <Icon.ArrowLeftSLine color="neutralBlack" />
      </button>
      <Typography.B1
        bold
        value={`${[date.getMonth() + 1]}월, ${date.getFullYear()}`}
      />
      <button
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        <Icon.ArrowRightSLine color="neutralBlack" />
      </button>
    </CustomHeaderWrapper>
  );
};

export const DefaultTimePicker: React.FC<DatePickerProps> = ({
  timeFormat = "HH:mm",
  placeHolderText = "시작시간",
  onChange,
  timeInterval = 30,
  index,
  ...props
}) => {
  const dateParsed = props.date ? moment(props.date, "HH:mm").toDate() : undefined;

  const handleDate = (date: any) => {
    onChange(moment(date).format("yyyy-MM-DD HH:mm:ss"), Number(index));
  };

  let startDate: any;
  if (props.startDate !== undefined) startDate = props.startDate ? moment(props.startDate).toDate() : undefined;

  let endDate: any;
  if (props.endDate !== undefined) endDate = props.endDate ? moment(props.endDate).toDate() : undefined;

  return (
    <DatePickerWrapper {...props}>
      <DatePickerStyled
        wrapperClassName="custom-class"
        locale={ko}
        {...(dateParsed && {
          value: `${moment(dateParsed).format(timeFormat)}`,
        })}
        selected={dateParsed}
        timeFormat={timeFormat}
        showTimeSelect={true}
        showTimeSelectOnly={true}
        timeCaption=""
        date={moment(dateParsed).format(timeFormat)}
        onChange={handleDate}
        placeholderText={placeHolderText}
        focusColor={colors.neutralLineActive}
        timeIntervals={timeInterval}
        renderCustomHeader={(props) => <TimePickerCustomHeader {...props} />}
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        {...props}
      />
      <Icon.TimeLine colorCode="#ABABAB" />
    </DatePickerWrapper>
  );
};

const CustomHeaderWrapper = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 8px;
  button {
    border: none;
    background: inherit;
  }
`;

const DatePickerStyled = styled(DatePicker)<Omit<DatePickerProps, "date">>`
  font-family: SUIT;
  width: 100%;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: ${colors.neutralBlack};
  border-radius: 6px;
  background-color: #fff;

  &::placeholder {
    color: #ababab;
    ${(props) => props.disabled && `cursor: not-allowed;`}
  }
  &:focus {
    outline: none;
  }
`;

const DatePickerWrapper = styled.label<Pick<DatePickerProps, "width" | "height" | "focusColor" | "disabled">>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "SUIT";
  font-size: ${typographyConfig.B2.fontSize}px;
  font-weight: ${typographyConfig.B2.boldFontWeight};
  width: ${(props) => props.width ?? "132px"};
  height: ${(props) => props.height ?? "48px"};
  padding: 16px 8px;
  cursor: pointer;
  border: 1px solid #eceef2;
  color: ${colors.neutralBlack};
  border-radius: 6px;

  &:hover {
    border-color: ${colors.neutralLineActive};
    ${(props) => props.disabled && `cursor: not-allowed;`}
  }
  &:focus-within {
    border-color: ${colors.neutralLineActive};
  }
  .react-datepicker-popper {
    margin-left: -8px;
    &[data-placement^="bottom"] {
      padding-top: 21px;
    }
    &[data-placement^="top"] {
      padding-bottom: 21px;
    }
  }
  .react-datepicker {
    display: flex;
    font-family: "SUIT" !important;
    font-size: ${typographyConfig.C1.fontSize}px !important;
    font-weight: ${typographyConfig.C1.boldFontWeight} !important;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    border: none;
    border-radius: 8px;
    &-wrapper {
      width: calc(100% - 20px);
    }
    &__header {
      background-color: ${colors.neutralWhiteDefault};
      padding: 0px;
      border: none;
    }
    &__triangle {
      &::before,
      &::after {
        content: none !important;
      }
    }
    &__day,
    &__day-name {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      margin: 8px;
      padding: 0px;
    }
    &__day-names {
      margin: 0px;
    }
    &__month {
      margin: 0px;
    }
    &__day:hover,
    &__month-text:hover,
    &__quarter-text:hover,
    &__year-text:hover {
      border-radius: 50%;
      background-color: ${colors.neutralBgActive};
    }
    &__day--selected,
    &__day--keyboard-selected {
      // background-color: ${colors.semanticActive};
      background-color: ${colors.semanticActive};
      border-radius: 50%;
      color: ${colors.neutralWhiteDefault};
      &:hover {
        background-color: ${colors.semanticActive};
      }
    }
    &-time__caption {
      display: none;
    }
    &-time__input {
      input {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 8px;
        gap: 4px;
        width: 248px;
        height: 34px;
        background: ${colors.neutralWhiteDefault};
        border: 1px solid ${colors.neutralLineDefault};
        border-radius: 6px;
      }
    }
    &__time-container {
      border-left: none;
      width: 95px;
    }
    &__time {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    &__time-list {
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &__time-list-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 79px;
      height: 32px !important;
      padding: 0px !important;
      border-radius: 99px;
      &:hover {
        background-color: ${colors.neutralBgActive} !important;
      }
      &--selected {
        background-color: ${colors.semanticActive} !important;
      }
    }
  }
`;
