import colors from "@view/styles/colors";
import React from "react";
import styled from "styled-components";
import * as SVG from "./svg";

interface IconImplementProps {
  name: IconTypes;
  color?: ICON_COLOR_TYPE;
  colorCode?: string;
  size: number;
  hoverColor?: string;
  activeColor?: string;
  onClick?: () => void;
}

const IconImpl: React.FC<IconImplementProps> = (props) => {
  return (
    <SVG_Style
      size={props.size}
      hoverColor={props.hoverColor}
      activeColor={props.activeColor}
      colorCode={props.colorCode}
      onClick={props.onClick}
      color={props.color}
      style={{ cursor: props.onClick ? "pointer" : undefined }}
    >
      {Icons[props.name]}
    </SVG_Style>
  );
};

interface SVG_Props {
  size: number;
  color?: ICON_COLOR_TYPE;
  colorCode?: string;
  hoverColor?: string;
  activeColor?: string;
}

const SVG_Style = styled.div<SVG_Props>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: inherit;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  svg {
    vertical-align: middle;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
  }
  svg path:nth-last-child(1) {
    fill: ${(props) => {
      if (props.color) {
        return colors[props.color];
      } else if (props.colorCode) {
        return props.colorCode;
      } else {
        return "inherit";
      }
    }};
    background-color: ${(props) => props.activeColor};
  }
  &:hover {
    svg path:nth-last-child(1) {
      fill: ${(props) => {
        return props.hoverColor;
      }};
    }
    /* background-color: ${(props) => (props.hoverColor ? props.hoverColor : "initial")}; */
  }
  &:active {
    svg path:nth-last-child(1) {
      fill: ${(props) => {
        return props.activeColor;
      }};
    }
    /* background-color: ${(props) => (props.activeColor ? props.activeColor : "initial")}; */
  }
  @media (max-width: 768px) {
    &:hover {
      background-color: initial;
    }
    &:active {
      /* background-color: ${(props) => (props.activeColor ? props.activeColor : "initial")}; */
    }
  }
`;

export type IconTypes = keyof typeof SVG;

const Icons: Record<IconTypes, JSX.Element> = {
  AdCheck: <SVG.AdCheck viewBox={"0 0 24 24"} />,
  AdList: <SVG.AdList viewBox={"0 0 24 24"} />,
  Add: <SVG.Add viewBox={"0 0 24 24"} />,
  Admin: <SVG.Admin viewBox={"0 0 24 24"} />,
  ArrowClose: <SVG.ArrowClose viewBox={"0 0 24 24"} />,
  ArrowOpen: <SVG.ArrowOpen viewBox={"0 0 24 24"} />,
  AddImage: <SVG.AddImage viewBox={"0 0 36 36"} />,
  ArrowLeftSLine: <SVG.ArrowLeftSLine viewBox={"0 0 24 24"} />,
  ArrowRightSLine: <SVG.ArrowRightSLine viewBox={"0 0 24 24"} />,
  ArrowZoom: <SVG.ArrowZoom viewBox={"0 0 24 24"} />,
  Calendar: <SVG.Calendar viewBox={"0 0 24 24"} />,
  Cancel: <SVG.Cancel viewBox={"0 0 24 24"} />,
  CheckBlack: <SVG.CheckBlack viewBox={"0 0 24 24"} />,
  CheckWhite: <SVG.CheckWhite viewBox={"0 0 24 24"} />,
  CheckLine: <SVG.CheckLine viewBox={"0 0 24 24"} />,
  CloseLine: <SVG.CloseLine viewBox={"0 0 18 18"} />,
  CloseCircleFill: <SVG.CloseCircleFill viewBox={"0 0 24 24"} />,
  Close: <SVG.Close viewBox={"0 0 24 24"} />,
  Coin: <SVG.Coin viewBox={"0 0 24 24"} />,
  Database2Line: <SVG.Database2Line viewBox={"0 0 18 18"} />,
  DB: <SVG.DB viewBox={"0 0 24 24"} />,
  DraftLine: <SVG.DraftLine viewBox={"0 0 18 18"} />,
  Group: <SVG.Group viewBox={"0 0 24 24"} />,
  HospitalLine: <SVG.HospitalLine viewBox={"0 0 18 18"} />,
  Hospital: <SVG.Hospital viewBox={"0 0 24 24"} />,
  Info20Line: <SVG.Info20Line viewBox={"0 0 20 20"} />,
  LineChartLine: <SVG.LineChartLine viewBox={"0 0 18 18"} />,
  List: <SVG.List viewBox={"0 0 24 24"} />,
  LockPasswordLine: <SVG.LockPasswordLine viewBox={"0 0 18 18"} />,
  Logout: <SVG.Logout viewBox={"0 0 24 24"} />,
  Message2Line: <SVG.Message2Line viewBox={"0 0 24 24"} />,
  Minimize: <SVG.Minimize viewBox={"0 0 24 24"} />,
  PhoneLine: <SVG.PhoneLine viewBox={"0 0 18 18"} />,
  Preview: <SVG.Preview viewBox={"0 0 24 24"} />,
  ProfileLine: <SVG.ProfileLine viewBox={"0 0 24 24"} />,
  Register: <SVG.Register viewBox={"0 0 24 24"} />,
  Remove: <SVG.Remove viewBox={"0 0 24 24"} />,
  Reset: <SVG.Reset viewBox={"0 0 24 24"} />,
  ViewImage: <SVG.ViewImage viewBox="0 0 16 16" />,
  Save: <SVG.Save viewBox={"0 0 24 24"} />,
  Search: <SVG.Search viewBox={"0 0 24 24"} />,
  ShieldCheckLine: <SVG.ShieldCheckLine viewBox={"0 0 24 24"} />,
  Stay: <SVG.Stay viewBox={"0 0 24 24"} />,
  StethoscopeLine: <SVG.StethoscopeLine viewBox={"0 0 18 18"} />,
  TimeLine: <SVG.TimeLine viewBox={"0 0 24 24"} />,
  Upload16Line: <SVG.Upload16Line viewBox={"0 0 16 16"} />,
  HomeMail: <SVG.HomeMail viewBox={"0 0 38 38"} />,
  HomeKakao: <SVG.HomeKakao viewBox={"0 0 38 38"} />,
  UserSettingLine: <SVG.UserSettingLine viewBox={"0 0 18 18"} />,
  EmpathizeLine: <SVG.EmpathizeLine viewBox={"0 0 18 18"} />,
  EditPencil: <SVG.EditPencil viewBox={"0 0 32 32"} />,
  VisibilityOff: <SVG.VisibilityOff viewBox={"0 0 24 24"} />,
  // Visibility:<SVG.VI
  CheckGreen: <SVG.CheckGreen viewBox={"0 0 24 24"} />,
  PreviewWhite: <SVG.PreviewWhite viewBox={"0 0 24 24"} />,
  Empty: <SVG.Empty viewBox={"0 0 40 40"} />,
  Kakao: <SVG.Kakao viewBox={"0 0 151 32"} />,
  MenuLine: <SVG.MenuLine viewBox={"0 0 24 24"} />,
  ArrowLeftLine: <SVG.ArrowLeftLine viewBox={"0 0 24 24"} />,
  LogoutCircleRLine: <SVG.LogoutCircleRLine viewBox={"0 0 24 24"} />,
  CancelUnion: <SVG.CancelUnion viewBox={"0 0 24 24"} />,
  Notice: <SVG.Notice viewBox={"0 0 16 16"} />,
  FailRed: <SVG.FailRed viewBox={"0 0 24 24"} />,
  Attachment: <SVG.Attachment viewBox={"0 0 32 32"} />,
  ExclamationMark: <SVG.ExclamationMark viewBox="0 0 20 20" />,
  QuestionLine: <SVG.QuestionLine viewBox="0 0 16 16" />,
  Edit: <SVG.Edit viewBox="0 0 24 24" />,
  UploadImage: <SVG.UploadImage viewBox="0 0 16 16" />,
  Plus: <SVG.Plus viewBox="0 0 16 16" />,
  Visibility: <SVG.Visibility viewBox="0 0 24 24" />,
  FaceOnOff: <SVG.FaceOnOff viewBox="0 0 18 18" />,
  AddOutlined: <SVG.AddOutlined viewBox="0 0 16 16" />,
  FilledCheckBox: <SVG.FilledCheckBox viewBox="0 0 24 24" />,
  OutlinedCheck: <SVG.OutlinedCheck viewBox="0 0 16 16" />,
  MenuAccount: <SVG.MenuAccount viewBox="0 0 24 24" />,
  MenuAdCustomers: <SVG.MenuAdCustomers viewBox="0 0 24 24" />,
  MenuContacts: <SVG.MenuContacts viewBox="0 0 24 24" />,
  MenuDoctors: <SVG.MenuDoctors viewBox="0 0 24 24" />,
  MenuFaceFitCustomers: <SVG.MenuFaceFitCustomers viewBox="0 0 24 24" />,
  MenuInformation: <SVG.MenuInformation viewBox="0 0 24 24" />,
  MenuPlan: <SVG.MenuPlan viewBox="0 0 24 24" />,
  MenuExpand: <SVG.MenuExpand viewBox="0 0 24 24" />,
  MenuCollpase: <SVG.MenuCollpase viewBox="0 0 24 24" />,
  OutlinedSearch: <SVG.OutlinedSearch viewBox="0 0 24 24" />,
  OutlinedReset: <SVG.OutlinedReset viewBox="0 0 24 24" />,
  GlobalNavigationServiceCenter: <SVG.GlobalNavigationServiceCenter viewBox="0 0 24 24" />,
  OutlinedLinedArrowBack: <SVG.OutlinedLinedArrowBack viewBox="0 0 24 24" />,
  FilledCircleCheck: <SVG.FilledCircleCheck viewBox="0 0 24 25" />,
  OutlinedPencil: <SVG.OutlinedPencil viewBox="0 0 24 25" />,
  OutlinedTrash: <SVG.OutlinedTrash viewBox="0 0 24 24" />,
  FilledFolder: <SVG.FilledFolder viewBox="0 0 24 24" />,
  FilledCamera: <SVG.FilledCamera viewBox="0 0 24 24" />,
  OutlinedStyleFit: <SVG.OutlinedStyleFit viewBox="0 0 20 21" />,
  FilledRecording: <SVG.FilledRecording viewBox="0 0 24 24" />,
  OutlinedStop: <SVG.OutlinedStop viewBox="0 0 24 24" />,
  FilledPlay: <SVG.FilledPlay viewBox="0 0 24 24" />,
  FilledPause: <SVG.FilledPause viewBox="0 0 24 24" />,
  OutlinedDownload: <SVG.OutlinedDownload viewBox="0 0 24 24" />,
  RoundedArrowIOS: <SVG.RoundedArrowIOS viewBox="0 0 24 24" />,
  RoundedClose: <SVG.RoundedClose viewBox="0 0 24 24" />,
  FilledArrow: <SVG.FilledArrow viewBox="0 0 24 24" />,
  FilledArrowUp: <SVG.FilledArrowUp viewBox="0 0 24 24" />,
  FilledRuler: <SVG.FilledRuler viewBox="0 0 24 24" />,
  FilledArrowLeft: <SVG.FilledArrowLeft viewBox="0 0 18 18" />,
};

export type ICON_COLOR_TYPE = keyof typeof colors;

export default IconImpl;
