import { injectable, inject } from "inversify";
import { UploadImage, UploadImageInput, UploadImageOuput } from "./model/UploadImage";
import type { ImageManager } from "@/domain/interactor/image-manager";
import type { Storage } from "@/domain/interactor/storage";
import { map, mergeMap, Observable, zip } from "rxjs";
import { v4 } from "uuid";

@injectable()
export class UploadImageImpl implements UploadImage {
  constructor(
    @inject("ImageManager")
    private imageManager: ImageManager,
    @inject("Storage")
    private storage: Storage,
  ) {}

  execute(input: UploadImageInput): Observable<UploadImageOuput> {
    const isPrd = process.env.ENVIRONMENT === "prd";
    const timestamp = Date.now();
    const id = v4();

    const setBucket = () => {
      if (input.public) {
        if (isPrd) {
          return "afoter/dr-wo";
        } else {
          return "afoter-test/dr-wo";
        }
      } else {
        if (isPrd) {
          return "caremind-v1/dr-wo";
        } else {
          return "caremind-v1-test/dr-wo";
        }
      }
    };

    return this.imageManager.resize(input.file, input.type).pipe(
      mergeMap((res) => {
        const body = {
          bucket: isPrd ? `${setBucket()}${input.bucketPath}` : `${setBucket()}${input.bucketPath}`,
          filename: `origin_${timestamp}_${id}.png`,
          file: this.strToBuffer(res.origin),
        };
        const bodyResize = {
          bucket: isPrd ? `${setBucket()}${input.bucketPath}` : `${setBucket()}${input.bucketPath}`,
          filename: `resized_${timestamp}_${id}.png`,
          file: this.strToBuffer(res.resized),
        };

        return zip(this.storage.uploadBy(body), this.storage.uploadBy(bodyResize));
      }),
      map(([origin, resize]) => {
        const output = {
          originUrl: input.public ? origin.url : origin.url,
          resizedUrl: input.public ? resize.url : resize.url,
          ordinal: input.ordinal,
        };

        return output;
      }),
    );
  }

  private strToBuffer = (str: string) => {
    const buf = Buffer.from(str, "utf-8");
    return buf;
  };
}
