import { EyeClassification, Coordinate, ConnectionPoint, DenormalizedCoordinate, CanvasLineStyle } from ".";
import { CanvasDrawer } from "./CanvasDrawer";
import FaceClassificationUtil from "./Util";

export class EyeClassificationImpl extends CanvasDrawer implements EyeClassification {
  //황금비율
  private goldenRatioConnectionPoint: ConnectionPoint[] = [
    { start: 227, end: 226 },
    { start: 226, end: 133 },
    { start: 133, end: 362 },
    { start: 362, end: 446 },
    { start: 446, end: 447 },
    //시작
    { start: 9, end: 9 },
    //중간
    { start: 8, end: 8 },
    //끝
    { start: 197, end: 197 },
  ];

  //짝눈
  private readonly balanceConnectionPoint: ConnectionPoint[] = [
    { start: 159, end: 145 },
    { start: 386, end: 374 },
  ];

  //눈꼬리
  private readonly eyeTailConnectionPoint: ConnectionPoint[] = [
    { start: 133, end: 130 },
    { start: 362, end: 359 },
  ];

  //동안얼굴
  private readonly babyFaceConnectionPoint: ConnectionPoint[] = [
    { start: 9, end: 2 },
    { start: 2, end: 152 },
  ];

  //비대칭
  private readonly asymmetryRatioConnectionPoint: ConnectionPoint[] = [
    { start: 8, end: 2 },
    { start: 2, end: 152 },
    { start: 226, end: 446 },
    { start: 61, end: 291 },
    { start: 13, end: 13 },
  ];

  goldenRatioCoordinate: DenormalizedCoordinate;
  balanceCoordinate: DenormalizedCoordinate;
  eyeTailCoordinate: DenormalizedCoordinate;
  babyFaceCoordinate: DenormalizedCoordinate;
  asymmetryRatioCoordinate: DenormalizedCoordinate;

  calcGoldenRatio: () => { 좌얼굴: number; 좌눈: number; 중안: number; 우눈: number; 우얼굴: number };
  calcBalance: () => { left: number; right: number };
  calcBabyFaceRatio: () => { 상안: number; 하안: number };
  calcAsymmetryRatio: () => { 좌하단눈각도: number; 우하단눈각도: number; 좌입술하단각도: number; 우입술하단각도: number };
  calcEyeTailsRatio: () => { 왼눈: number; 우눈: number };

  drawGoldenRatio: (lineStyle?: CanvasLineStyle | undefined) => void;
  drawBalance: (lineStyle?: CanvasLineStyle) => void;
  drawEyeTailAngle: (lineStyle?: CanvasLineStyle) => void;
  drawBabyFaceRatio: (lineStyle?: CanvasLineStyle) => void;
  drawAsymmetryRatio: (lineStyle?: CanvasLineStyle) => void;

  //이상적인 비율 그리기
  drawBestGoldenRatio: (lineStyle?: CanvasLineStyle) => void;
  drawBestBalance: (lineStyle?: CanvasLineStyle) => void;
  drawBestEyeTailAngle: (lineStyle?: CanvasLineStyle) => void;
  drawBestBabyFaceRatio: (lineStyle?: CanvasLineStyle) => void;
  drawBestAsymmetryRatio: (lineStyle?: CanvasLineStyle) => void;

  constructor(imageEle: HTMLImageElement, normalizedFaceLandmark: Coordinate[][], canvas: HTMLCanvasElement, numberOfCanvas: number) {
    super(imageEle, canvas, numberOfCanvas ?? 1);
    this.goldenRatioCoordinate = FaceClassificationUtil.getDenormalizedFaceLandmark(
      imageEle,
      normalizedFaceLandmark,
      this.goldenRatioConnectionPoint,
    );
    this.balanceCoordinate = FaceClassificationUtil.getDenormalizedFaceLandmark(
      imageEle,
      normalizedFaceLandmark,
      this.balanceConnectionPoint,
    );
    this.eyeTailCoordinate = FaceClassificationUtil.getDenormalizedFaceLandmark(
      imageEle,
      normalizedFaceLandmark,
      this.eyeTailConnectionPoint,
    );

    this.babyFaceCoordinate = FaceClassificationUtil.getDenormalizedFaceLandmark(
      imageEle,
      normalizedFaceLandmark,
      this.babyFaceConnectionPoint,
    );

    this.asymmetryRatioCoordinate = FaceClassificationUtil.getDenormalizedFaceLandmark(
      imageEle,
      normalizedFaceLandmark,
      this.asymmetryRatioConnectionPoint,
    );

    //황금 비율 계산
    this.calcGoldenRatio = () => {
      const coordinate = this.goldenRatioCoordinate;
      const leftCheekVerticalPoint = FaceClassificationUtil.findRightAngleTrianglePoint("RIGHT", coordinate[446], coordinate[447]);

      const leftCheekDistance = FaceClassificationUtil.calculateDistance(coordinate[446], leftCheekVerticalPoint, "2d");

      const leftEye = FaceClassificationUtil.calculateDistance(coordinate[362], coordinate[446], "2d");
      const center = FaceClassificationUtil.calculateDistance(coordinate[133], coordinate[362], "2d");
      const rightEye = FaceClassificationUtil.calculateDistance(coordinate[226], coordinate[133], "2d");

      const rightCheekVerticalPoint = FaceClassificationUtil.findRightAngleTrianglePoint("RIGHT", coordinate[226], coordinate[227]);
      const rightCheekDistance = FaceClassificationUtil.calculateDistance(coordinate[226], rightCheekVerticalPoint, "2d");

      const leftCheekRatio = (leftCheekDistance / rightEye) * 100;
      const leftEyeRatio = (leftEye / rightEye) * 100;
      const centerEyeRatio = (center / rightEye) * 100;
      const rightEyeRatio = (rightEye / rightEye) * 100;
      const rightCheekRatio = (rightCheekDistance / rightEye) * 100;

      return {
        좌얼굴: Number((leftCheekRatio * 0.01).toFixed(2)),
        좌눈: Number((leftEyeRatio * 0.01).toFixed(2)),
        중안: Number((centerEyeRatio * 0.01).toFixed(2)),
        우눈: Number((rightEyeRatio * 0.01).toFixed(2)),
        우얼굴: Number((rightCheekRatio * 0.01).toFixed(2)),
      };
    };

    //짝눈 비율 계산
    this.calcBalance = () => {
      const rightEyeHeight = FaceClassificationUtil.calculateDistance(this.balanceCoordinate[159], this.balanceCoordinate[145], "3d");
      const leftEyeHeight = FaceClassificationUtil.calculateDistance(this.balanceCoordinate[386], this.balanceCoordinate[374], "3d");

      const leftEyeRatio = (leftEyeHeight / rightEyeHeight) * 100;
      const rightEyeRatio = (rightEyeHeight / rightEyeHeight) * 100;

      const result = {
        left: Number((leftEyeRatio * 0.01).toFixed(2)),
        right: Number((rightEyeRatio * 0.01).toFixed(2)),
      };
      return result;
    };

    //눈 꼬리 각도 비율 계산
    this.calcEyeTailsRatio = () => {
      const coordinate = this.eyeTailCoordinate;
      const rightEyeThirdPoint = FaceClassificationUtil.findRightAngleTrianglePoint("LEFT", coordinate[130], coordinate[133]);

      const leftEyeThirdPoint = FaceClassificationUtil.findRightAngleTrianglePoint("LEFT", coordinate[359], coordinate[362]);

      const rightEyeTailAngle = FaceClassificationUtil.calcAngle(coordinate[133], coordinate[130], rightEyeThirdPoint, "3d");

      const leftEyeTailAngle = FaceClassificationUtil.calcAngle(coordinate[362], coordinate[359], leftEyeThirdPoint, "3d");

      return {
        왼눈: FaceClassificationUtil.truncateDecimals(leftEyeTailAngle, 1),
        우눈: FaceClassificationUtil.truncateDecimals(rightEyeTailAngle, 1),
      };
    };

    //동안얼굴 비율 계산
    this.calcBabyFaceRatio = () => {
      const 상안 = FaceClassificationUtil.calculateDistance(this.babyFaceCoordinate[9], this.babyFaceCoordinate[2], "2d");
      const 하안 = FaceClassificationUtil.calculateDistance(this.babyFaceCoordinate[2], this.babyFaceCoordinate[152], "2d");

      const 하안비율 = (하안 / 상안) * 100;

      return {
        상안: 1,
        하안: FaceClassificationUtil.truncateDecimals(Number(하안비율 * 0.01), 2),
      };

      // const topHeight = this.calculateDistance(
      //   this.denormalizedLandmarksByBabyFaceRatio[9],
      //   this.denormalizedLandmarksByBabyFaceRatio[2],
      //   "2d"
      // );

      // const bottomHeight = this.calculateDistance(
      //   this.denormalizedLandmarksByBabyFaceRatio[2],
      //   this.denormalizedLandmarksByBabyFaceRatio[152],
      //   "2d"
      // );

      // const bottomHeightRatio = (bottomHeight / topHeight) * 100;

      // const result = {
      //   top: 1,
      //   bottom: this.truncateDecimals(Number(bottomHeightRatio * 0.01), 2)
      // };
    };

    //비대칭 비율 계산
    this.calcAsymmetryRatio = () => {
      const coordinate = this.asymmetryRatioCoordinate;
      const topLineIntersectionPoint = FaceClassificationUtil.findLineIntersectionPoint(
        [coordinate[2], coordinate[8]],
        [coordinate[226], coordinate[446]],
      );

      const leftEyeAngle = FaceClassificationUtil.calcAngle(topLineIntersectionPoint, coordinate[2], coordinate[226], "2d");

      const bottomLineIntersectionPoint = FaceClassificationUtil.findLineIntersectionPoint(
        [coordinate[2], coordinate[152]],
        [
          {
            ...coordinate[13],
            x: coordinate[13].x - 20,
          },
          {
            ...coordinate[13],
            x: coordinate[13].x + 20,
          },
        ],
      );

      const leftLipAngle = FaceClassificationUtil.calcAngle(
        bottomLineIntersectionPoint,
        {
          ...coordinate[13],
          x: coordinate[13].x + 20,
        },
        coordinate[152],
        "2d",
      );

      return {
        좌하단눈각도: FaceClassificationUtil.truncateDecimals(leftEyeAngle, 1),
        우하단눈각도: 180 - FaceClassificationUtil.truncateDecimals(leftEyeAngle, 1),
        좌입술하단각도: FaceClassificationUtil.truncateDecimals(leftLipAngle, 1),
        우입술하단각도: 180 - FaceClassificationUtil.truncateDecimals(leftLipAngle, 1),
      };
    };

    //내 상태 황금비율
    this.drawGoldenRatio = (lineStyle) => {
      const middlePoint = this.goldenRatioCoordinate[8];

      const leftCheekCoordinate = FaceClassificationUtil.findRightAngleTrianglePoint("LEFT", this.goldenRatioCoordinate[447], middlePoint);

      const leftCoordinate = FaceClassificationUtil.findRightAngleTrianglePoint("LEFT", this.goldenRatioCoordinate[446], middlePoint);

      const leftMiddleCoordinate = FaceClassificationUtil.findRightAngleTrianglePoint("LEFT", this.goldenRatioCoordinate[362], middlePoint);

      const rightMiddleCoordinate = FaceClassificationUtil.findRightAngleTrianglePoint(
        "RIGHT",
        middlePoint,
        this.goldenRatioCoordinate[133],
      );

      const rightCoordinate = FaceClassificationUtil.findRightAngleTrianglePoint("RIGHT", middlePoint, this.goldenRatioCoordinate[226]);

      const rightCheekCoordinate = FaceClassificationUtil.findRightAngleTrianglePoint(
        "RIGHT",
        middlePoint,
        this.goldenRatioCoordinate[227],
      );

      this.drawLineFromPoint(
        0,
        { ...leftCheekCoordinate, y: leftCoordinate.y + 30 },
        "VERTICAL",
        lineStyle ?? {
          lineLength: 150,
          lineWidth: 1,
          lineColor: "#fff",
          lineType: "SOLID",
        },
      );

      this.drawLineFromPoint(
        0,
        { ...rightCheekCoordinate, y: leftCoordinate.y + 30 },
        "VERTICAL",
        lineStyle ?? {
          lineLength: 150,
          lineWidth: 1,
          lineColor: "#fff",
          lineType: "SOLID",
        },
      );

      this.drawLineFromPoint(
        0,
        { ...leftCoordinate, y: leftCoordinate.y + 30 },
        "VERTICAL",
        lineStyle ?? {
          lineLength: 150,
          lineWidth: 1,
          lineColor: "#fff",
          lineType: "SOLID",
        },
      );

      this.drawLineFromPoint(
        0,
        { ...leftMiddleCoordinate, y: leftMiddleCoordinate.y + 30 },
        "VERTICAL",
        lineStyle ?? {
          lineLength: 150,
          lineWidth: 1,
          lineColor: "#fff",
          lineType: "SOLID",
        },
      );

      this.drawLineFromPoint(
        0,
        { ...rightMiddleCoordinate, y: rightMiddleCoordinate.y + 30 },
        "VERTICAL",
        lineStyle ?? {
          lineLength: 150,
          lineWidth: 1,
          lineColor: "#fff",
          lineType: "SOLID",
        },
      );

      this.drawLineFromPoint(
        0,
        { ...rightCoordinate, y: rightCoordinate.y + 30 },
        "VERTICAL",
        lineStyle ?? {
          lineLength: 150,
          lineWidth: 1,
          lineColor: "#fff",
          lineType: "SOLID",
        },
      );
    };

    //내 상태 짝눈
    this.drawBalance = (lineStyle) => {
      this.drawLineFromPoint(
        0,
        this.balanceCoordinate[159],
        "HORIZONTAL",
        lineStyle ?? {
          lineLength: 50,
          lineWidth: 1,
          lineColor: "#FF12E7",
          lineType: "SOLID",
        },
      );

      this.drawLineFromPoint(
        0,
        this.balanceCoordinate[374],
        "HORIZONTAL",
        lineStyle ?? {
          lineLength: 50,
          lineWidth: 1,
          lineColor: "#FF12E7",
          lineType: "SOLID",
        },
      );

      this.drawLineFromPoint(
        0,
        this.balanceCoordinate[145],
        "HORIZONTAL",
        lineStyle ?? {
          lineLength: 50,
          lineWidth: 1,
          lineColor: "#FF12E7",
          lineType: "SOLID",
        },
      );

      this.drawLineFromPoint(
        0,
        this.balanceCoordinate[386],
        "HORIZONTAL",
        lineStyle ?? {
          lineLength: 50,
          lineWidth: 1,
          lineColor: "#FF12E7",
          lineType: "SOLID",
        },
      );
    };

    //눈꼬리
    this.drawEyeTailAngle = (lineStyle) => {
      const style = lineStyle ?? { lineLength: 50, lineWidth: 1, lineColor: "#FF12E7", lineType: "SOLID" };
      const coordinate = this.eyeTailCoordinate;

      const rightEyeAnglePotint = FaceClassificationUtil.findRightAngleTrianglePoint("LEFT", coordinate[130], coordinate[133]);
      const leftEyeAnglePoint = FaceClassificationUtil.findRightAngleTrianglePoint("RIGHT", coordinate[362], coordinate[359]);

      this.drawLineBetweenPoints(0, coordinate[133], rightEyeAnglePotint, style);
      this.drawLineBetweenPoints(0, coordinate[362], leftEyeAnglePoint, style);
      // { ...style, lineType: "DOT" }
      this.drawLineBetweenPoints(0, coordinate[133], coordinate[130], style);
      this.drawLineBetweenPoints(0, coordinate[362], coordinate[359], style);
    };

    //동안얼굴
    this.drawBabyFaceRatio = (lineStyle) => {
      const style = lineStyle ?? { lineLength: 50, lineWidth: 1, lineColor: "#FF12E7", lineType: "SOLID" };
      const coordinate = this.babyFaceCoordinate;

      this.drawLineFromPoint(0, coordinate[9], "HORIZONTAL", style);
      this.drawLineFromPoint(0, coordinate[2], "HORIZONTAL", style);
      this.drawLineFromPoint(0, coordinate[152], "HORIZONTAL", style);
    };

    //비대칭정도
    this.drawAsymmetryRatio = (lineStyle) => {
      const style = lineStyle ?? { lineLength: 50, lineWidth: 1, lineColor: "#FF12E7", lineType: "SOLID" };
      const coordinate = this.asymmetryRatioCoordinate;

      this.drawLineBetweenPoints(0, coordinate[2], coordinate[8], style);
      this.drawLineBetweenPoints(0, coordinate[226], coordinate[446], style);
      this.drawLineFromPoint(0, coordinate[13], "HORIZONTAL", style);
      this.drawLineBetweenPoints(0, coordinate[2], coordinate[152], style);
    };

    this.drawBestGoldenRatio = (lineStyle) => {
      const coordinate = this.goldenRatioCoordinate;
      const style: CanvasLineStyle = {
        lineLength: 50,
        lineWidth: 1,
        lineColor: "#DDFF0B",
        lineType: "SOLID",
      };
      const rightEye = FaceClassificationUtil.calculateDistance(coordinate[226], coordinate[133], "2d");
      const middlePoint = this.goldenRatioCoordinate[8];

      const leftCoordinate = FaceClassificationUtil.findRightAngleTrianglePoint("LEFT", this.goldenRatioCoordinate[446], middlePoint);

      const leftMiddleCoordinate = FaceClassificationUtil.findRightAngleTrianglePoint("LEFT", this.goldenRatioCoordinate[362], middlePoint);

      //모니터 화면기준 우측부터 draw
      this.drawLineFromPoint(0, { ...leftCoordinate, x: leftCoordinate.x + rightEye / 2, y: leftCoordinate.y + 30 }, "VERTICAL", style);
      this.drawLineFromPoint(0, { ...leftCoordinate, y: leftCoordinate.y + 30 }, "VERTICAL", style);
      this.drawLineFromPoint(0, { ...leftMiddleCoordinate, y: leftMiddleCoordinate.y + 30 }, "VERTICAL", style);
      this.drawLineFromPoint(
        0,
        { ...leftMiddleCoordinate, x: leftMiddleCoordinate.x - rightEye, y: leftMiddleCoordinate.y + 30 },
        "VERTICAL",
        style,
      );
      this.drawLineFromPoint(
        0,
        { ...leftMiddleCoordinate, x: leftMiddleCoordinate.x - rightEye * 2, y: leftMiddleCoordinate.y + 30 },
        "VERTICAL",
        style,
      );
      this.drawLineFromPoint(
        0,
        { ...leftMiddleCoordinate, x: leftMiddleCoordinate.x - (rightEye * 2 + rightEye / 2), y: leftMiddleCoordinate.y + 30 },
        "VERTICAL",
        style,
      );
    };

    this.drawBestBalance = (lineStyle) => {
      const coordinate = this.balanceCoordinate;
      const style: CanvasLineStyle = {
        lineLength: 50,
        lineWidth: 1,
        lineColor: "#DDFF0B",
        lineType: "SOLID",
      };
      const rightEyeHeight = FaceClassificationUtil.calculateDistance(this.balanceCoordinate[159], this.balanceCoordinate[145], "3d");

      this.drawLineFromPoint(0, coordinate[386], "HORIZONTAL", style);
      this.drawLineFromPoint(0, coordinate[374], "HORIZONTAL", style);

      this.drawLineFromPoint(0, coordinate[145], "HORIZONTAL", style);
      this.drawLineFromPoint(0, { ...coordinate[145], y: coordinate[145].y - rightEyeHeight }, "HORIZONTAL", style);
    };

    this.drawBestEyeTailAngle = (lineStyle) => {
      const coordinate = this.eyeTailCoordinate;
      const style: CanvasLineStyle = {
        lineLength: 50, // 예: ab의 길이
        lineWidth: 1,
        lineColor: "#DDFF0B",
        lineType: "SOLID",
      };

      const rightEyePointB = coordinate[133];
      const rightEyePointC = { ...coordinate[133], x: coordinate[133].x - 40 };
      const angleRad = 4 * (Math.PI / 180);
      const b = Math.abs(rightEyePointC.x - rightEyePointB.x);
      const h = b * Math.tan(angleRad);

      const rightEyePointA = {
        x: rightEyePointC.x,
        y: rightEyePointB.y - h,
        z: 0,
      };

      this.drawLineBetweenPoints(0, rightEyePointB, rightEyePointC, style);
      this.drawLineBetweenPoints(0, rightEyePointB, rightEyePointA, style);

      const leftEyePointC = coordinate[362];
      const leftEyePointB = { ...coordinate[362], x: coordinate[362].x + 40 };

      const lb = Math.abs(leftEyePointC.x - leftEyePointB.x);
      const lh = b * Math.tan(angleRad);

      const leftEyePointA = {
        x: leftEyePointC.x,
        y: leftEyePointB.y - h,
        z: 0,
      };

      this.drawLineBetweenPoints(0, leftEyePointB, leftEyePointC, style);
      this.drawLineBetweenPoints(0, leftEyePointC, { ...leftEyePointA, x: leftEyePointB.x }, style);
    };

    this.drawBestBabyFaceRatio = (lineStyle) => {
      const coordinate = this.babyFaceCoordinate;
      const style: CanvasLineStyle = {
        lineLength: 175,
        lineWidth: 1,
        lineColor: "#DDFF0B",
        lineType: "SOLID",
      };

      const 상안 = FaceClassificationUtil.calculateDistance(coordinate[9], coordinate[2], "2d");

      this.drawLineFromPoint(0, coordinate[9], "HORIZONTAL", style);
      this.drawLineFromPoint(0, coordinate[2], "HORIZONTAL", style);
      this.drawLineFromPoint(0, { ...coordinate[2], y: coordinate[2].y + 상안 * 0.8 }, "HORIZONTAL", style);
    };

    this.drawBestAsymmetryRatio = (lineStyle) => {
      const coordinate = this.asymmetryRatioCoordinate;
      const style: CanvasLineStyle = {
        lineLength: 120,
        lineWidth: 1,
        lineColor: "#DDFF0B",
        lineType: "SOLID",
      };
      const topLineIntersectionPoint = FaceClassificationUtil.findLineIntersectionPoint(
        [coordinate[2], coordinate[8]],
        [coordinate[226], coordinate[446]],
      );
      const bottomLineIntersectionPoint = FaceClassificationUtil.findLineIntersectionPoint(
        [coordinate[2], coordinate[152]],
        [
          {
            ...coordinate[13],
            x: coordinate[13].x - 20,
          },
          {
            ...coordinate[13],
            x: coordinate[13].x + 20,
          },
        ],
      );

      this.drawLineFromPoint(0, { ...coordinate[8], y: coordinate[8].y + 60 }, "VERTICAL", { ...style, lineLength: 170 });
      this.drawLineFromPoint(0, topLineIntersectionPoint, "HORIZONTAL", style);
      this.drawLineFromPoint(0, bottomLineIntersectionPoint, "HORIZONTAL", style);
    };
  }
}
