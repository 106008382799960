import React from "react";
import styled from "styled-components";
import Button from "@view/widget/button";
import Icon from "@icon/index";

interface AIButtonProps {
  label: string;
  width: number | string;
  height: number;
  fontSize: number;
  fontWeight: number;
  type?: "WHITE" | "BLACK";
  noStarIcon?: boolean;
  disabled?: boolean;
  click: () => void;
}

const AIButton: React.FC<AIButtonProps> = ({ label, width, height, fontSize, fontWeight, type, noStarIcon, disabled, click }) => {
  return (
    <Button.ComponentButton
      component={
        <AIButtonLayout
          $width={width}
          $height={height}
          $type={type}
        >
          {noStarIcon ? null : (
            <StarLayout>
              {disabled ? (
                <Icon.MenuFaceFitCustomers
                  _18px
                  colorCode="#ABABAB"
                />
              ) : (
                <Star src="/image/ai-button-star.png" />
              )}
            </StarLayout>
          )}
          {disabled ? (
            <DisabledButtonLabel
              $fontSize={fontSize}
              $fontWeight={fontWeight}
            >
              {label}
            </DisabledButtonLabel>
          ) : (
            <ButtonLabel
              $fontSize={fontSize}
              $fontWeight={fontWeight}
            >
              {label}
            </ButtonLabel>
          )}
        </AIButtonLayout>
      }
      borderRadius={8}
      sx={{ width: typeof width === "string" ? width : `${width}px` }}
      disabled={disabled}
      onClick={click}
    />
  );
};

export default AIButton;

const AIButtonLayout = styled.div<{ $width: number | string; $height: number; $type?: "WHITE" | "BLACK" }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  height: ${({ $height }) => $height}px;
  width: ${({ $width }) => (typeof $width === "string" ? `${$width}` : `${$width}px`)};
  border-radius: 8px;
  border: ${({ $type }) => ($type === "WHITE" ? "1px solid rgba(236, 238, 242, 0.60)" : "1px solid rgba(69, 77, 74, 0.5)")};
  background: ${({ $type }) =>
    $type === "WHITE"
      ? "linear-gradient(180deg, #CECECE 10.21%, #FFF 50.05%)"
      : "linear-gradient(180deg, #131d20 1.04%, #111c20 50.52%, #2a2f31 94.79%)"};
  box-shadow: 3.854px 7.709px 13.49px 0px rgba(0, 0, 0, 0.2);
`;

const ButtonLabel = styled.div<{ $fontSize: number; $fontWeight: number }>`
  display: flex;
  align-items: center;
  position: relative;
  font-family: SUIT;
  font-size: ${({ $fontSize }) => $fontSize}px;
  font-style: normal;
  font-weight: ${({ $fontWeight }) => $fontWeight};
  background: linear-gradient(270deg, #4b93ff -4.53%, #60dcbe 46.62%, #5aaca0 97.93%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const DisabledButtonLabel = styled.div<{ $fontSize: number; $fontWeight: number }>`
  display: flex;
  align-items: center;
  position: relative;
  font-family: SUIT;
  font-size: ${({ $fontSize }) => $fontSize}px;
  font-style: normal;
  font-weight: ${({ $fontWeight }) => $fontWeight};
  color: #ababab;
`;

const StarLayout = styled.div``;

const Star = styled.img`
  display: flex;
  align-items: center;
  position: relative;
  bottom: 2px;
  width: 18px;
  height: 18px;
`;
