import { injectable, inject } from "inversify";
import { Observable } from "rxjs";
import { UpdateReservationStatus, UpdateReservationStatusInput, UpdateReservationStatusOutput } from "./model/UpdateReservationStatus";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class UpdateReservationStatusImpl implements UpdateReservationStatus {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute(input: UpdateReservationStatusInput): Observable<UpdateReservationStatusOutput> {
    return this.operationConsultingRepository.updateReservationStatus({ noteId: input.noteId, reservationStatus: input.reservationStatus });
  }
}
