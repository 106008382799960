import { GlobalNavigationBar } from "../global-navigation-bar/GlobalNavigationBar";
import { useMainView } from "./useMainView";
import { FooterView } from "@view/service/footer/FooterView";
import { SideMenu } from "@view/service/side-menu/SideMenu";
import type { AppProps } from "next/app";
import Head from "next/head";
import styled from "styled-components";

export const MainView: React.FC<AppProps> = ({ Component, pageProps }) => {
  const { state, input } = useMainView();

  if (state.public) {
    return (
      <>
        <Head>
          <title>{state.title}</title>
        </Head>
        <Component {...pageProps} />
      </>
    );
  }

  if (state.userInfo) {
    return (
      <>
        <Head>
          <title>{state.title}</title>
        </Head>
        <GlobalHeaderLayout role={"banner"}>
          <GlobalNavigationBar
            userInfo={state.userInfo}
            menu={state.globalNavigationBar.menu}
          />
        </GlobalHeaderLayout>
        <MainLayout
          windowWidth={state.window.width}
          windowHeight={state.window.height}
          globalNavigationBarHeight={state.globalNavigationBar.height}
          $isRecentConsultationsPage={state.isRecentConsultationsPage}
        >
          {state.sideMenu.display && !state.isRecentConsultationsPage ? (
            <SideBarLayout sideMenuWidth={state.sideMenu.width}>
              <SideMenu
                sideMenus={state.sideMenu.menu}
                expanded={state.sideMenu.expanded}
                clickExpand={input.clickExpand}
                clickCollpase={input.clickCollapse}
              />
            </SideBarLayout>
          ) : null}
          <ContentLayout
            sideMenuWidth={state.mainMenuPathname === "home" ? 0 : state.sideMenu.width}
            $isRecentConsultationsPage={state.isRecentConsultationsPage}
          >
            <Component {...{ ...pageProps, ...state.deprecatedUserInfo }} />
          </ContentLayout>
        </MainLayout>
        {state.footer.display && !state.isRecentConsultationsPage ? (
          <FooterLayout>
            <FooterView />
          </FooterLayout>
        ) : null}
      </>
    );
  }

  return null;
};

const GlobalHeaderLayout = styled.header`
  z-index: 10;
  position: sticky;
  top: 0px;
`;

const MainLayout = styled.main<{
  windowHeight: number;
  windowWidth: number;
  globalNavigationBarHeight: number;
  $isRecentConsultationsPage: boolean;
}>`
  display: flex;
  width: ${({ windowWidth, $isRecentConsultationsPage }) => ($isRecentConsultationsPage ? "100%" : `${windowWidth}px`)};
  min-width: ${({ $isRecentConsultationsPage }) => ($isRecentConsultationsPage ? "auto" : "1024px")};
  height: ${({ windowHeight, $isRecentConsultationsPage, globalNavigationBarHeight }) =>
    $isRecentConsultationsPage ? `100%` : `calc(${windowHeight}px - ${globalNavigationBarHeight}px)`};
  overflow: hidden;
`;

const SideBarLayout = styled.aside<{ sideMenuWidth: number }>`
  width: ${({ sideMenuWidth }) => sideMenuWidth}px;
  transition: 0.2s ease-in-out;
`;

const ContentLayout = styled.section<{ sideMenuWidth: number; $isRecentConsultationsPage: boolean }>`
  width: ${({ sideMenuWidth, $isRecentConsultationsPage }) => {
    if ($isRecentConsultationsPage) return "100vw";
    if (sideMenuWidth) return `calc(100vw - ${sideMenuWidth}px)`;
    return "100%";
  }};
  transition: 0.2s ease-in-out;
  overflow: auto;
`;

const FooterLayout = styled.footer`
  position: fixed;
  width: 100%;
  bottom: 0px;
`;
