import { injectable, inject } from "inversify";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { Observable } from "rxjs";
import { RequestPersonaShift, RequestPersonaShiftInput, RequestPersonaShiftOutput } from "./model/ReqeustPersonaShift";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class RequestPersonaShiftImpl implements RequestPersonaShift {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute(input: RequestPersonaShiftInput): Observable<RequestPersonaShiftOutput> {
    return this.operationConsultingRepository.requestPersonaShift(input.noteId, { photoId: input.photoId, operations: input.operations });
  }
}
