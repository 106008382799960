import { injectable, inject } from "inversify";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { Observable } from "rxjs";
import { ReqeustFaceLandmark, ReqeustFaceLandmarkInput, ReqeustFaceLandmarkOutput } from "./model/RequestFaceLandmark";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class RequestFaceLandmarkImpl implements ReqeustFaceLandmark {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute(input: ReqeustFaceLandmarkInput): Observable<ReqeustFaceLandmarkOutput> {
    return this.operationConsultingRepository.requestFaceLandmark(input.noteId, input.photoId);
  }
}
