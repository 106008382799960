import { Coordinate, CanvasLineStyle } from "../index.interface";

export class CanvasDrawer {
  private canvasEle: HTMLCanvasElement;
  private ctx: CanvasRenderingContext2D;

  constructor(imageEle: HTMLImageElement, canvasEle: HTMLCanvasElement) {
    this.canvasEle = canvasEle;
    const dpr = window.devicePixelRatio || 1;
    canvasEle.width = imageEle.width * dpr;
    canvasEle.height = imageEle.height * dpr;

    this.ctx = canvasEle.getContext("2d") as CanvasRenderingContext2D;
    this.ctx.scale(dpr, dpr);
  }

  private saveAndApplyStyle(ctx: CanvasRenderingContext2D, lineStyle: CanvasLineStyle) {
    ctx.save(); // 현재의 스타일과 변환 상태를 저장합니다.
    ctx.strokeStyle = lineStyle.lineColor;
    ctx.lineWidth = lineStyle.lineWidth || 1;
    ctx.lineCap = "butt";
    if (lineStyle.lineType === "DOT") {
      ctx.setLineDash([Math.round(3.5), Math.round(3.5)]); // 대시 간격을 정수로 설정
    } else {
      ctx.setLineDash([]);
    }
  }

  private restoreStyle(ctx: CanvasRenderingContext2D) {
    ctx.restore(); // 이전에 저장한 스타일과 변환 상태를 복원합니다.
  }

  drawLineFromPoint(center: Coordinate, type: "VERTICAL" | "HORIZONTAL", lineStyle: CanvasLineStyle) {
    const ctx = this.ctx;
    this.saveAndApplyStyle(ctx, lineStyle); // 스타일 저장 및 적용

    const centerX = center.x;
    const centerY = center.y;
    const halfLength = lineStyle.lineLength / 2;

    let startTime: number;

    const animate = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / 150, 1);

      if (type === "VERTICAL") {
        const currentY = centerY - halfLength + lineStyle.lineLength * progress;
        ctx.beginPath();
        ctx.moveTo(centerX, centerY - halfLength);
        ctx.lineTo(centerX, currentY);
        ctx.stroke();
      } else if (type === "HORIZONTAL") {
        const currentX = centerX - halfLength + lineStyle.lineLength * progress;
        ctx.beginPath();
        ctx.moveTo(centerX - halfLength, centerY);
        ctx.lineTo(currentX, centerY);
        ctx.stroke();
      }

      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        this.restoreStyle(ctx); // 스타일 복원
      }
    };

    requestAnimationFrame(animate);
  }
  drawLineBetweenPoints({
    point1,
    point2,
    lineStyle,
    isDrawEndPoint,
  }: {
    point1: Coordinate;
    point2: Coordinate;
    lineStyle: CanvasLineStyle;
    isDrawEndPoint?: boolean;
  }) {
    const ctx = this.ctx;
    this.saveAndApplyStyle(ctx, lineStyle);

    const startX = point1.x;
    const startY = point1.y;
    const endX = point2.x;
    const endY = point2.y;

    // 선 그리기
    ctx.beginPath();
    ctx.moveTo(startX, startY);
    ctx.lineTo(endX, endY);
    ctx.stroke();

    // 끝점에 다이아몬드 그리기 (옵션)
    if (isDrawEndPoint) {
      this.drawDiamond(ctx, startX, startY);
      this.drawDiamond(ctx, endX, endY);
    }

    this.restoreStyle(ctx); // 스타일 복원
  }
  // drawLineBetweenPoints({
  //   point1,
  //   point2,
  //   lineStyle,
  //   isDrawEndPoint,
  // }: {
  //   point1: Coordinate;
  //   point2: Coordinate;
  //   lineStyle: CanvasLineStyle;
  //   isDrawEndPoint?: boolean;
  // }) {
  //   const ctx = this.ctx;
  //   this.saveAndApplyStyle(ctx, lineStyle);

  //   const startX = point1.x;
  //   const startY = point1.y;
  //   const endX = point2.x;
  //   const endY = point2.y;

  //   let startTime: number;

  //   const animate = (timestamp: number) => {
  //     if (!startTime) startTime = timestamp;
  //     const elapsed = timestamp - startTime;
  //     const progress = Math.min(elapsed / 150, 1);

  //     const currentX = startX + (endX - startX) * progress;
  //     const currentY = startY + (endY - startY) * progress;

  //     ctx.beginPath();
  //     ctx.moveTo(startX, startY);
  //     ctx.lineTo(currentX, currentY);
  //     ctx.stroke();

  //     if (progress < 1) {
  //       requestAnimationFrame(animate);
  //     } else {
  //       if (isDrawEndPoint) {
  //         this.drawDiamond(ctx, startX, startY);
  //         this.drawDiamond(ctx, endX, endY);
  //       }
  //       this.restoreStyle(ctx); // 스타일 복원
  //     }
  //   };

  //   requestAnimationFrame(animate);
  // }

  drawTextBetweenPoints(
    text: string,
    point1: Coordinate,
    point2: Coordinate,
    style: { x: number; y: number; fontSize: number; color?: string },
  ) {
    const ctx = this.ctx;
    ctx.save(); // 현재 스타일 저장

    const centerX = (point1.x + point2.x) / 2;
    const centerY = (point1.y + point2.y) / 2;

    ctx.font = `${style?.fontSize ?? 14}px SUIT`;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";

    // Draw the stroke with fixed width and color
    ctx.lineWidth = 3;
    ctx.strokeStyle = "#000";
    ctx.strokeText(text, centerX + (style?.x ?? 0), centerY + (style?.y ?? 0));

    // Draw the fill
    ctx.fillStyle = style.color ? style.color : "#fff";
    ctx.fillText(text, centerX + (style?.x ?? 0), centerY + (style?.y ?? 0));

    ctx.restore(); // 이전 스타일로 복원
  }

  drawPoint = (coordinate: Coordinate, style?: { color: string }, text?: string) => {
    const ctx = this.ctx;
    const radius = 1;

    if (text) {
      ctx.font = "10px Arial";
      ctx.fillStyle = style?.color ?? "#000000";
      ctx.fillText(text, coordinate.x + 3, coordinate.y - 3);
    } else {
      ctx.beginPath();
      ctx.arc(coordinate.x, coordinate.y, radius, 0, 2 * Math.PI);
      ctx.fillStyle = style?.color ?? "#ababab";
      ctx.fill();
      ctx.closePath();
    }
  };

  drawLineByCoordinates(points: Coordinate[], lineStyle: CanvasLineStyle, isDrawEndPoint?: boolean) {
    const ctx = this.ctx;
    this.saveAndApplyStyle(ctx, lineStyle); // 스타일 저장 및 적용

    // dropShadow 스타일이 있는 경우 적용
    if (lineStyle.dropShadow) {
      ctx.filter = lineStyle.dropShadow;
    }

    let startTime: number;

    const animate = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / 150, 1);

      ctx.beginPath();
      ctx.moveTo(points[0].x, points[0].y);

      for (let i = 1; i < points.length; i++) {
        const startX = points[i - 1].x;
        const startY = points[i - 1].y;
        const endX = points[i].x;
        const endY = points[i].y;

        const currentX = startX + (endX - startX) * progress;
        const currentY = startY + (endY - startY) * progress;

        ctx.lineTo(currentX, currentY);

        if (progress < 1) {
          requestAnimationFrame(animate);
          return; // 애니메이션이 끝날 때까지 함수를 지속적으로 호출
        }
      }

      ctx.stroke();

      if (isDrawEndPoint) {
        this.drawDiamond(ctx, points[0].x, points[0].y); // 시작점에 다이아몬드 그리기
        this.drawDiamond(ctx, points[points.length - 1].x, points[points.length - 1].y); // 끝점에 다이아몬드 그리기
      }

      this.restoreStyle(ctx); // 스타일 복원
      ctx.filter = "none"; // 필터 초기화
    };

    requestAnimationFrame(animate);
  }

  // 부채꼴 모양을 그리는 함수
  drawArcBetweenPoints({
    center,
    point1,
    point2,
    radius,
    lineStyle,
    reverse = false,
  }: {
    center: Coordinate;
    point1: Coordinate;
    point2: Coordinate;
    radius: number;
    lineStyle: CanvasLineStyle;
    reverse?: boolean;
  }) {
    const ctx = this.ctx;
    this.saveAndApplyStyle(ctx, lineStyle);

    let angle1 = Math.atan2(point1.y - center.y, point1.x - center.x);
    let angle2 = Math.atan2(point2.y - center.y, point2.x - center.x);

    if (reverse) {
      [angle1, angle2] = [angle2, angle1];
    }

    ctx.beginPath();
    ctx.moveTo(center.x, center.y);
    ctx.arc(center.x, center.y, radius, angle1, angle2);
    ctx.closePath();
    ctx.stroke();

    this.restoreStyle(ctx);
  }

  clearCanvas() {
    const ctx = this.ctx;
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  }

  private drawDiamond(ctx: CanvasRenderingContext2D, x: number, y: number) {
    const size = 3.5;
    ctx.fillStyle = ctx.strokeStyle;
    ctx.beginPath();
    ctx.moveTo(x, y - size);
    ctx.lineTo(x + size, y);
    ctx.lineTo(x, y + size);
    ctx.lineTo(x - size, y);
    ctx.closePath();
    ctx.fill();
  }
}
